import React from "react";
import { useTranslation } from "react-i18next";

import './styles.scss';

interface IProps {
  title: string;
  description?: string;
}

const SectionTitleDescription = ({ title, description }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="section-description">
      <p className="title">{t(title)}</p>
      {description && (
        <p
          className="description"
        >{t(description)}</p>
      )}
    </div>
  );
};

export default SectionTitleDescription;
