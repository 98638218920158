import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { selectAccountsData } from "@client.reducers/accounts";
import { selectProfileData } from "@client.reducers/profile";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseFormReturn, useForm } from "react-hook-form";

import { IAccount } from "@client.types/account";
import { IProfile } from "@client.types/profile";
import useStableParams from "@client.hooks/useStableParams";
import useToast from "@client.hooks/useToast";
import ServiceProvider from "@client.services/provider";
import AdvertisementStatuses from "@client.enums/advertisementStatuses";
import { IAdvertisement } from "@client.types/advertisement";
import { ILocation } from "./CreateAdvertisementForm";

export enum Step {
  GeneralInformation = 0,
  Locations = 1,
  SlotCalculator = 2,
  Payment = 3,
  Summary = -1
}

export interface IRadiusLocations {
  id: string;
  geofenceRadiusLatitude: number;
  geofenceRadiusLongitude: number;
  geofenceRadiusRange: number;
}

export interface ICustomLocation {
  id: string;
  data: string;
}

export interface ICreateAdvertisementFormValues {
  title: string;
  advertiserId: string;
  startDate: string;
  endDate: string;
  targets: number[];
  mediaId: string;
  mediaUrl: string;
  budgetType: number;
  budgetAmount: number;
  consecutiveSlots: number;
  zoneIds: string[];
  radiusLocations: IRadiusLocations[];
  customPolygonLocations: ICustomLocation[];
}

interface CreateAdvertisementFormContextType {
  createAdvertisementFormMethods: UseFormReturn<ICreateAdvertisementFormValues>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isFormFilled: boolean;
  setFormFilled: Dispatch<SetStateAction<boolean>>;
  selectedImageUrl: string;
  setSelectedImageUrl: Dispatch<SetStateAction<string>>;
  accounts: IAccount[];
  profile: IProfile;
  advertisementTargets: { id: number, name: string }[];
  draftAdvertisement: IAdvertisement | null;
  selectedLocationsList: ILocation[];
  setSelectedLocationsList: Dispatch<SetStateAction<ILocation[]>>;
}

const CreateAdvertisementFormContext = createContext<
  CreateAdvertisementFormContextType | undefined
>(undefined);

export const CreateAdvertisementFormProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const { id } = useStableParams();

  const accounts: IAccount[] = useSelector(selectAccountsData);
  const profile = useSelector(selectProfileData);

  const [draftAdvertisement, setDraftAdvertisement] =
    useState<IAdvertisement | null>(null);
  const [advertisementTargets, setAdvertisementTargets] = useState<
    { id: number; name: string }[]
  >([]);
  const [currentStep, setCurrentStep] = useState(Step.GeneralInformation);

  useEffect(() => {
    if (id) {
      const fetchAdvertisement = async () => {
        try {
          const response =
            await ServiceProvider.Advertisement.getAdvertisementById(id);
          if (response.data.campaign.status === AdvertisementStatuses.Draft) {
            setDraftAdvertisement(response.data.campaign);
          } else {
            showErrorToast("Can't get advertisement information");
          }
        } catch (e) {
          const errorMessage =
            e instanceof Error
              ? e.message
              : "Can't get advertisement information";
          showErrorToast(errorMessage);
        }
      };

      fetchAdvertisement();
    }
  }, [id]);

  useEffect(() => {
    const fetchAdvertisementTargets = async () => {
      try {
        const response = await ServiceProvider.Advertisement.getTargets();
        if (response) {
          const formattedTargets = response.map((targetData) => ({
            id: targetData.id,
            name: targetData.name,
          }));
          setAdvertisementTargets(formattedTargets);
        } else {
          showErrorToast("Can't get targets");
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get targets";
        showErrorToast(errorMessage);
      }
    };

    fetchAdvertisementTargets();
  }, []);

  const validationSchemas: yup.ObjectSchema<
    Partial<ICreateAdvertisementFormValues>
  >[] = [
    yup.object().shape({
      title: yup
        .string()
        .required(t("components.Advertisers.validation.firstName")),
      advertiserId: yup
        .string()
        .required(t("components.Advertisers.validation.advertiserId")),
      startDate: yup
        .string()
        .required(t("components.Advertisers.validation.startDate")),
      endDate: yup
        .string()
        .required(t("components.Advertisers.validation.endDate")),
      targets: yup
        .array()
        .of(yup.number().required())
        .required(t("components.Advertisers.validation.target")),
      mediaId: yup
        .string()
        .required(t("components.Advertisers.validation.mediaId")),
    }) as yup.ObjectSchema<Partial<ICreateAdvertisementFormValues>>,
    yup.object().shape({
      zoneIds: yup
        .array()
        .of(yup.string().required())
        .min(1, "At least one zone is required")
        .required("Zone Id is required"),
    }) as yup.ObjectSchema<Partial<ICreateAdvertisementFormValues>>,
    yup.object().shape({
      budgetType: yup.number().required("Budget type is required"),
      budgetAmount: yup.number().required("Budget amount is required"),
      consecutiveSlots: yup.number().required("Slot length is required"),
    }) as yup.ObjectSchema<Partial<ICreateAdvertisementFormValues>>,
  ];

  const createAdvertisementFormMethods =
    useForm<ICreateAdvertisementFormValues>({
      resolver: yupResolver(validationSchemas[currentStep]) as any,
      defaultValues: {
        title: "",
        advertiserId: "",
        startDate: "",
        endDate: "",
        targets: 0 || [],
        mediaId: "",
        mediaUrl: "",
        zoneIds: [],
        radiusLocations: [],
        customPolygonLocations: [],
        budgetType: 1,
        budgetAmount: 0,
        consecutiveSlots: 10,
      },
    });

  useEffect(() => {
    if (draftAdvertisement) {
      createAdvertisementFormMethods.reset({
        title: draftAdvertisement.title,
        advertiserId: draftAdvertisement.accountId,
        startDate: draftAdvertisement.startDate,
        endDate: draftAdvertisement.endDate,
        targets: draftAdvertisement.targets,
        mediaId: draftAdvertisement.mediaId,
        mediaUrl: draftAdvertisement.mediaUrl,
        zoneIds: draftAdvertisement.geofenceZoneIds,
        budgetType: 1,
        budgetAmount: draftAdvertisement.budgetTotal,
        consecutiveSlots: draftAdvertisement.displayTime,
      });
    }
  }, [draftAdvertisement, createAdvertisementFormMethods.reset]);

  const [isFormFilled, setFormFilled] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [selectedLocationsList, setSelectedLocationsList] = useState<ILocation[]>([]);

  return (
    <CreateAdvertisementFormContext.Provider
      value={{
        createAdvertisementFormMethods,
        currentStep,
        setCurrentStep,
        isFormFilled,
        setFormFilled,
        selectedImageUrl,
        setSelectedImageUrl,
        accounts,
        profile,
        advertisementTargets,
        draftAdvertisement,
        selectedLocationsList,
        setSelectedLocationsList,
      }}
    >
      {children}
    </CreateAdvertisementFormContext.Provider>
  );
};

export const useCreateAdvertisementForm = () => {
  const context = useContext(CreateAdvertisementFormContext);
  if (!context) {
    throw new Error(
      "useCreateAdvertisementForm must be used within a CreateAdvertisementFormProvider"
    );
  }
  return context;
};
