import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { IAccount } from "@client.types/account";
import { IUser } from "@client.types/user";

import Button from "@client.core.components/Button";
import Dropdown from "@client.components/core/Dropdown";
import IconRenderer from "@client.components/FA/IconRenderer";

import MinimizeIcon from "./MinimizeIcon";
import { IDashboardMapFilter } from "..";
import { TOOLTIP_ID } from "@client.constants";

interface IProps {
  accounts: IAccount[];
  users: IUser[];
  mapFilter: IDashboardMapFilter;
  setMapFilter: Dispatch<SetStateAction<IDashboardMapFilter>>;
  onMinimize: () => void;
}

const SearchByAccountsAndUsers = ({ accounts, users, mapFilter, setMapFilter, onMinimize }: IProps) => {
  const [selectedAccount, setSelectedAccount] = useState(mapFilter.accountId);
  const [selectedUser, setSelectedUser] = useState(mapFilter.userId);

  useEffect(() => {
    if (mapFilter.accountId) {
      setSelectedAccount(mapFilter.accountId)
    }
    if (mapFilter.userId) {
      setSelectedUser(mapFilter.userId)
    }
  }, [mapFilter]);

  useEffect(() => {
    if (selectedAccount && selectedUser) {
      setSelectedUser("");
    }
  }, [selectedAccount])

  useEffect(() => {
    if (selectedAccount && selectedUser) {
      setSelectedAccount("");
    }
  }, [selectedUser])

  const usersOptions = users.map((user) => ({
    ...user,
    fullName: `${user.firstName} ${user.lastName}`
  }))

  return (
    <div className="search-by-accounts-and-users-modal">
      <div className="modal-title-container">
        <div className="label-tooltip">
          <p className="modal-title">Search by Accounts</p>
          <IconRenderer
            iconName="FaCircleInfo"
            size={17}
            className="info-icon"
            data-tooltip-id={TOOLTIP_ID}
            data-tooltip-content="Select an account or user to see where devices or advertisements are currently running."
          />
        </div>
        <MinimizeIcon onClick={onMinimize} />
      </div>
      <div className="search-by-accounts-and-users-content">
        <div className="search-by-accounts-and-users-form">
          <div className="search-by-accounts-and-users-dropdowns">
            <Dropdown
              items={accounts}
              defaultValue={selectedAccount}
              className="search-by-accounts-and-users-dropdown"
              itemClassName="search-by-accounts-and-users-dropdown-item"
              label="Accounts"
              valueKey="Id"
              labelKey="companyName"
              onSelect={setSelectedAccount}
            />
            <Dropdown
              items={usersOptions}
              defaultValue={selectedUser}
              className="search-by-accounts-and-users-dropdown"
              itemClassName="search-by-accounts-and-users-dropdown-item"
              label="Users"
              valueKey="Id"
              labelKey="fullName"
              onSelect={setSelectedUser}
            />
          </div>
          <Button
            text="Search"
            className="search-by-accounts-and-users-button"
            onClick={() => setMapFilter((prev) => ({
              ...prev,
              accountId: selectedAccount,
              userId: selectedUser,
            }))}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchByAccountsAndUsers;
