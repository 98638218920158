import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { get } from "lodash";

import ServiceProvider from "@client.services/provider";

import useToast from "@client.hooks/useToast";
import { IAccount } from "@client.types/account";
import { IUser } from "@client.types/user";
import { IAccountMap } from "@client.types/accountMap";

import Loader from "@client.core.components/Loader";
import Map from "@client.components/Map";
import Card from "@client.components/Card";

import AccountMapFilter from "./AccountMapFilter";

import "./styles.scss";
import LabelValue from "@client.components/LabelValue";
import { getAccountDetails } from "./const";
import { MdOutlineEdit } from "react-icons/md";
import Drawer from "@client.components/Drawer";
import Button from "@client.components/core/Button";
import UpdateAccountDetailsForm from "./UpdateAccountDetailsForm";
import useUpdateAccountDetailsForm from "./useUpdateAccountDetailsForm";

interface IProps {
  selectedAccount: IAccount;
  accountUsers: IUser[];
}

const DetailsTab = ({ selectedAccount, accountUsers }: IProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const [accountOwnerInformation, setAccountOwnerInformation] = useState<IUser | null>(null);
  const [accountMapData, setAccountMapData] = useState<IAccountMap | null>(null);
  const [showUpdateAccountDetailsDrawer, setShowUpdateAccountDetailsDrawer] = useState(false);

  const [zones, setZones] = useState([]);

  const [showZonesFilter, setShowZonesFilter] = useState(false);

  const accountDetails = accountOwnerInformation ? getAccountDetails(t, selectedAccount, accountOwnerInformation) : [];

  const {
    register,
    control,
    getValues,
    setValue,
    trigger,
    errors,
    resetForm,
    shippingAddress,
    billingAddress,
    handleSubmitUpdateAccountDetailsForm,
  } = useUpdateAccountDetailsForm(
    selectedAccount,
    setShowUpdateAccountDetailsDrawer
  )

  useEffect(() => {
    const fetchAccountOwner = async () => {
      try {
        const response = await ServiceProvider.Account.getAccountOwner(selectedAccount.Id);
        if (response.data.accountOwner) {
          setAccountOwnerInformation(response.data.accountOwner)
        } else {
          showErrorToast("Can't get account information")
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get account information";
        showErrorToast(errorMessage)
      }
    }
    
    fetchAccountOwner();
  }, []);

  useEffect(() => {
    const fetchAccountMap = async () => {
      try {
        const response = await ServiceProvider.Account.getAccountMap({
          accountId: selectedAccount.Id,
          showCampaigns: true,
          showZones: showZonesFilter,
        });
        if (response.data.accountMap) {
          setAccountMapData(response.data.accountMap)
        } else {
          showErrorToast("Can't get account map information")
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get account map information";
        showErrorToast(errorMessage)
      }
    }
    
    fetchAccountMap();
  }, []);

  const advertisementMarkers = accountMapData?.campaigns.map(advertisement => ({
    lat: advertisement.latitude ?? 0,
    lng: advertisement.longitude ?? 0,
  })) || [];

  useEffect(() => {
    ServiceProvider.Zone.getZones()
      .then((resp) => {
        setZones(get(resp, "data.zones.results", []));
      })
  }, [selectedAccount]);

  const handleCloseUpdateAccountDetailsDrawer = () => {
    resetForm();
    setShowUpdateAccountDetailsDrawer(false);
  }

  if (!accountOwnerInformation || !accountUsers.length) {
    return (
      <div className="account-details-loading">
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div className="account-details-tab">
      <div className="details-content">
        <Card title={t("pages.Accounts.tabs.Details.accountDetails")}>
          <MdOutlineEdit
            onClick={() => setShowUpdateAccountDetailsDrawer(true)}
            title="Edit"
            className="update-details-icon"
          />
          <div className="details-grid">
            {accountDetails.map((v) => <LabelValue key={v.label} label={v.label} value={v.value} />)}
          </div>
        </Card>
      </div>
      <Map
        zoom={6}
        markers={advertisementMarkers}
        polymarks={[]}
        options={{
          mapTypeControl: false,
          disableDefaultUI: true,
          zoomControl: true
        }}
        className="account-details-map"
      >
        <AccountMapFilter
          showZonesFilter={showZonesFilter}
          setShowZonesFilter={setShowZonesFilter}
        />
      </Map>
      <Drawer
        isOpen={showUpdateAccountDetailsDrawer}
        onClose={handleCloseUpdateAccountDetailsDrawer}
        title="Update Account Details"
        footer={
          <Button
            text={t("button.Update")}
            onClick={handleSubmitUpdateAccountDetailsForm}
          />
        }
      >
        <UpdateAccountDetailsForm
          register={register}
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          errors={errors}
          shippingAddress={shippingAddress}
          billingAddress={billingAddress}
          accountUsers={accountUsers}
        />
      </Drawer>
    </div>
  );
};

export default DetailsTab;

