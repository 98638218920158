import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { VoidFunc } from "types";

import "./styles.scss";

interface IProps {
  hexColor: string;
  onChange: VoidFunc<string>;
  label?: string;
}

const ColorPicker = ({
  hexColor,
  onChange,
  label,
}: IProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState("#FFFFFF");

  useEffect(() => {
    if (hexColor) {
      setColor(hexColor);
    }
  }, [hexColor]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    onChange(newColor.hex);
    setColor(newColor.hex);
  };

  return (
    <div className="color-picker-container">
      <div className="color-picker">
        <label className="label">{label}</label>
        <div className="swatch" onClick={handleClick}>
          <div
            className="color"
            style={{
              background: color,
            }}
          />
        </div>
      </div>
      {displayColorPicker ? (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
