import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsAsync,
  selectAccountsData,
} from "@client.reducers/accounts";
import { AppDispatch } from "@client.store";

import AdvertisementStatuses from "@client.enums/advertisementStatuses";

import DateRangePicker from "@client.components/DateRangePicker";
import Dropdown from "@client.components/core/Dropdown";
import Input from "@client.core.components/Input";

import { AdvertisementsTabsEnum } from "./AdvertisementsTabs";
import { IAdvertisementsFilter } from ".";
import { TOOLTIP_ID } from "@client.constants";

interface IProps {
  filter: IAdvertisementsFilter;
  setFilter: Dispatch<SetStateAction<IAdvertisementsFilter>>;
  selectedTab: number;
  isAdvertiser: boolean;
}

const AdvertisementsFilter = ({
  filter,
  setFilter,
  selectedTab,
  isAdvertiser,
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const accounts = useSelector(selectAccountsData);

  const statusOptions = [
    { value: null, label: "All" },
    {
      value: AdvertisementStatuses.PendingApproval,
      label: t("Pages.Advertisement.status.pending"),
    },
    {
      value: AdvertisementStatuses.Queued,
      label: t("Pages.Advertisement.status.queued"),
    },
    {
      value: AdvertisementStatuses.Active,
      label: t("Pages.Advertisement.status.active"),
    },
    {
      value: AdvertisementStatuses.Paused,
      label: t("Pages.Advertisement.status.paused"),
    },
    {
      value: AdvertisementStatuses.Ended,
      label: t("Pages.Advertisement.status.ended"),
    },
  ];

  useEffect(() => {
    if (!accounts.length) {
      dispatch(getAccountsAsync());
    }
  }, [dispatch]);

  const handleStatusChange = (value) => {
    setFilter({ ...filter, status: value });
  };

  const handleAdvertiserChange = (value) => {
    setFilter({ ...filter, advertiserId: value });
  };

  return (
    <div className="filters-container">
      <Input
        label="Search"
        value={filter.search}
        onChange={(e) => setFilter({ ...filter, search: e })}
        data-tooltip-id={TOOLTIP_ID}
        data-tooltip-content={"Search Parameters\n - Full Name"}
      />
      <DateRangePicker
        dateFrom={filter.startDate}
        dateTo={filter.endDate}
        label={t("Pages.Advertisements.dateRange")}
        onChange={(range) =>
          setFilter({ ...filter, startDate: range[0], endDate: range[1] })
        }
      />
      {selectedTab === AdvertisementsTabsEnum.AllTheRest && (
        <Dropdown
          items={statusOptions}
          defaultValue={filter.status}
          label={t("Pages.Advertisements.status")}
          valueKey="value"
          labelKey="label"
          onSelect={handleStatusChange}
        />
      )}
      {!isAdvertiser ? (
        <Dropdown
          items={accounts}
          defaultValue={filter.advertiserId}
          label="Advertisers"
          valueKey="Id"
          labelKey="companyName"
          onSelect={handleAdvertiserChange}
        />
      ) : (
        false
      )}
    </div>
  );
};

export default AdvertisementsFilter;
