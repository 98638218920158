import React, { useEffect, useState } from "react";

import useStableParams from "@client.hooks/useStableParams";
import useToast from "@client.hooks/useToast";
import ServiceProvider from "@client.services/provider";
import { IAdvertisementInsights } from "@client.types/advertisementInsights";

import Loader from "@client.core.components/Loader";

import AdvertisementInsightsCards from "./AdvertisementinsightsCards";
import AdvertisementInsightsMap from "./AdvertisementInsightsMap";
import AdvertisementInsightsGenderAge from "./AdvertisementInsightsGenderAge";
import AdvertisementInsightsAgeRange from "./AdvertisementInsightsAgeRange";
import AdvertisementInsightsViews from "./AdvertisementInsightsViews";

const AdvertisementInsights = () => {
  const { showErrorToast } = useToast();
  const { id } = useStableParams();
  
  const [isLoading, setLoading] = useState(true);
  const [advertisementInsights, setAdvertisementInsights] = useState<IAdvertisementInsights | null>(null);

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchAdvertisementInsights = async () => {
      setLoading(true);
      try {
        const response = await ServiceProvider.Advertisement.getAdvertisementInsights({ id });
        
        if (response.data.campaignInsights) {
          setAdvertisementInsights(response.data.campaignInsights);
        } else {
          showErrorToast("Can't get advertisement insights information");
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get advertisement insights";
        showErrorToast(errorMessage)
      } finally {
        setLoading(false);
      }
    }

    fetchAdvertisementInsights();
  }, [id]);

  const maleNumber = advertisementInsights?.ageGraph.reduce((sum, { male }) => sum + male, 0) || 0;
  const femaleNumber = advertisementInsights?.ageGraph.reduce((sum, { female }) => sum + female, 0) || 0;
  const isGenderPieChartDataEmpty = !(maleNumber + femaleNumber);

  if (isLoading) {
    return <Loader loading={true} />;
  }

  return (
    <div className="advertisement-insights">
      <AdvertisementInsightsCards advertisementInsights={advertisementInsights} />
      <div className="advertisement-insights-row">
        <AdvertisementInsightsMap advertisementInsights={advertisementInsights} />
        {isGenderPieChartDataEmpty ? (
          <AdvertisementInsightsViews advertisementInsights={advertisementInsights} />
        ) : <AdvertisementInsightsGenderAge advertisementInsights={advertisementInsights} />}
      </div>
      {!isGenderPieChartDataEmpty ? (
        <div className="advertisement-insights-row">
          <AdvertisementInsightsAgeRange advertisementInsights={advertisementInsights} />
          <AdvertisementInsightsViews advertisementInsights={advertisementInsights} />
        </div>
      ) : false }
    </div>
  );
};

export default AdvertisementInsights;
