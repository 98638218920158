import React from "react";

import config from "@client.config";
import moment from "moment";
import { t } from "i18next";
import DeviceTypes from "@client.enums/deviceTypes";
import Badge from "@client.components/Badge";

export const getDateTimeGetter = (val: string | null) => {
  return val ? moment(val).format(config.dateTimeFormat) : "N/A";
};

export const getColumns = () => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1, // Adding 1 to start index from 1
    cellRenderer: (params) => (
      <div className="clickable-cell">{params.value}</div>
    ),
    maxWidth: 80,
  },

  {
    field: "assignedAccountName",
    headerName: t("pages.Devices.columns.assignedPartner"),
    cellRenderer: (params) => (
      <div className="clickable-cell">
        {params.data.assignedAccountName || "N/A"}
      </div>
    ),
  },

  {
    field: "deviceName",
    headerName: t("pages.Devices.columns.name"),
    cellRenderer: (params) => (
      <div className="clickable-cell">{params.data.deviceName}</div>
    ),
    tooltipField: "DeviceName",
  },

  {
    field: "deviceType",
    headerName: t("pages.Devices.columns.type"),
    cellClass: "data-table-cell",
    cellRenderer: (data) => {
      let label = "pages.Devices.na";
      if (data.data.deviceType) {
        label =
          data.data.deviceType === DeviceTypes.Dynamic
            ? "pages.Devices.type.dynamic"
            : "pages.Devices.type.static";
      }
      return <div className="clickable-cell">{t(label)}</div>;
    },
  },

  {
    field: "reatedAt",
    headerName: t("pages.Devices.columns.lastTelemetry"),
    cellClass: "data-table-cell",
    cellRenderer: (data) => {
      return (
        <div className="clickable-cell">
          {data.data && data.data.createdAt
            ? moment(data.data.createdAt).format(config.dateTimeFormat)
            : t("pages.Devices.na")}
        </div>
      );
    },
  },

  {
    field: "isOnline",
    headerName: t("pages.Devices.columns.deviceStatus"),
    cellClass: "data-table-cell clickable-cell",
    cellRenderer: (data) => {
      const isOnline = data?.data?.isOnline;
      return (
        <Badge
          text={t(
            isOnline
              ? "pages.Devices.status.online"
              : "pages.Devices.status.offline"
          )}
          className={isOnline ? "device-online-badge" : "device-offline-badge"}
        />
      );
    },
  },
];
