import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

import { IAdvertisement } from "@client.types/advertisement";
import ServiceProvider from "@client.services/provider";

interface IAdvertisementsSlice {
  data: IAdvertisement[];
  error: string | null;
  loading: boolean;
}

const initialState: IAdvertisementsSlice = {
  data: [],
  error: null,
  loading: false,
};

export const getAdvertisementsAsync = createAsyncThunk(
  "advertisements/getAdvertisements",
  async () => {
    const response = await ServiceProvider.Advertisement.getAdvertisements();
    return response;
  }
);

export const advertisementsSlice = createSlice({
  name: "advertisements",
  initialState,
  reducers: {
    clearAdvertisementError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertisementsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdvertisementsAsync.fulfilled, (state, action) => {
        const response = get(
          action,
          "payload.data.campaigns.results",
          []
        );
        state.data = response;
        state.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertisementsAsync.rejected, (state, action) => {
        state.data = [];
        state.error = getResponseError(action);
      });
  },
});

export const {
  clearAdvertisementError,
} = advertisementsSlice.actions;

export const selectAdvertisementsData = (state) => state.advertisements.data;
export const selectAdvertisementsError = (state) => state.advertisements.error;
export const selectAdvertisementsLoading = (state) => state.advertisements.loading;

export default advertisementsSlice.reducer;
