import React from "react";

import { useTranslation } from "react-i18next";

const AdvertisementsTabsEnum = Object.freeze({
  AllTheRest: 0,
  Archived: 1,
  Drafts: 2,
});

const AdvertisementsTabs = ({ value, onSelect }) => {
  const { t } = useTranslation();

  const tabs = [
    { id: AdvertisementsTabsEnum.AllTheRest, name: t("pages.campaings.tabs.allTheRest") },
    { id: AdvertisementsTabsEnum.Archived, name: t("pages.campaings.tabs.archived") },
    { id: AdvertisementsTabsEnum.Drafts, name: t("pages.campaings.tabs.drafts") },
  ];

  return (
    <div className="archived-advertisement-tabs">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`tab-button ${value === tab.id ? "active" : ""}`}
          onClick={() => onSelect(tab.id)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export { AdvertisementsTabsEnum };
export default AdvertisementsTabs;
