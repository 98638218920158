import React from "react";
import './styles.scss'

interface IProps {
	text: string;
  className?: string;
}

const Badge = ({ text, className }: IProps) => {
  return <span className={`badge ${className}`}>{text}</span>;
};

export default Badge;
