import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "@client.components/DataTable";
import TopBoardOverview from "@client.components/TopBoardOverview_Billing";

import "./styles.scss";
import ServiceProvider from "@client.services/provider";
import { IAccount } from "@client.types/account";
import useToast from "@client.hooks/useToast";
import { IInvoice } from "@client.types/invoice";
import { getColumns } from "./getColumns";

const ITEMS_PER_PAGE = 10;

interface IProps {
  selectedAccount: IAccount;
}

const BillingTab = ({ selectedAccount }: IProps) => {
  const { showErrorToast } = useToast();

  const { t } = useTranslation();

  const [accountCredit, setAccountCredit] = useState(0);
  const [accountPrimaryCard, setAccountPrimaryCard] = useState('');
  const [lastInvoiceAmount, setLastInvoiceAmount] = useState(0);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [isLoading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchAccountBilling = async () => {
      try {
        const offset = (currentPage - 1) * ITEMS_PER_PAGE;
        const response = await ServiceProvider.Account.getAccountBilling({
          pagination: {
            limit: ITEMS_PER_PAGE,
            offset,
          },
          accountId: selectedAccount.Id,
        });
        const accountBillingResponse = response.data.accountBilling;
        if (accountBillingResponse) {
          setInvoices(accountBillingResponse.invoices.results);
          setInvoicesCount(accountBillingResponse.invoices.count);
          setAccountCredit(accountBillingResponse.accountCredit);
          setAccountPrimaryCard(accountBillingResponse.primaryCard.cardLastFour);
          setLastInvoiceAmount(accountBillingResponse.lastInvoiceAmount);
        } else {
          showErrorToast("Can't get invoices");
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get invoices";
        showErrorToast(errorMessage)
      } finally {
        setLoading(false);
      }
    }
    
    fetchAccountBilling();
  }, []);

  return (
    <>
      <TopBoardOverview
        className="top-board-dashboard-overview"
        accountCredit={accountCredit}
        lastInvoiceAmount={lastInvoiceAmount}
        cardLastFour={accountPrimaryCard}
      />
      <div className="wallet-tab">
        <DataTable 
          columns={getColumns(t)} 
          data={invoices} 
          isLoading={isLoading}
          pagination={{
            total: invoicesCount,
            itemsPerPage: ITEMS_PER_PAGE,
            onChange: setCurrentPage,
          }}
        />
      </div>
    </>
  );
};

export default BillingTab;
