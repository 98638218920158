import { TOAST_EVENT } from "@client.core.components/Toast";
import ServiceProvider from "@client.services/provider";

const validateModel = (model, rules) => {
  const validation = model.validate(rules);
  const isModelValid = Object.keys(validation).length === 0;

  if (!isModelValid) {
    ServiceProvider.EventEmitter.emit(
      TOAST_EVENT,
      Object.keys(validation).map((m) => ({
        type: "warning",
        msg: validation[m][0],
      }))
    );
  }

  return isModelValid;
};

export { validateModel };
