import React from "react";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import SvgIcon from "@client.core.components/SvgIcon";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { NavigationSection } from "./NavigationSection";
import { useSelector } from "react-redux";
import "./styles.scss";
import AccountEntity from "@client.enums/accountEntity";
import { selectProfileData } from "@client.reducers/profile";
import { 
  FaMapLocationDot, 
  FaVideo,
  FaUserClock, 
  FaServer, 
  FaBuilding, 
  FaUserGroup, 
  FaCommentDots, 
  FaTv, 
  FaEarthAmericas,
} from "react-icons/fa6";

export const NavigationBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const profileAccount = useSelector(selectProfileData);

  const platformSections = [
    {
      title: t("components.NavigationBar.dashboard"),
      items: [
        {
          icon: <FaEarthAmericas />,
          url: Pages.Dashboard,
          name: t("components.NavigationBar.overview"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.deviceOperations"),
      items: [
        {
          icon: <FaTv />,
          url: Pages.Devices,
          name: t("components.NavigationBar.devices"),
        },
        {
          icon: <FaMapLocationDot />,
          url: Pages.Zones,
          name: t("components.NavigationBar.locations"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.advertisementManagement"),
      items: [
        {
          icon: <FaCommentDots />,
          url: Pages.AdvertisementApprovals,
          name: t("components.NavigationBar.advertisementApprovals"),
        },
        {
          icon: <FaVideo />,
          url: Pages.Advertisements,
          name: t("components.NavigationBar.advertisements"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.userAndAccountManagement"),
      items: [
        {
          icon: <FaBuilding />,
          url: Pages.Accounts,
          name: t("components.NavigationBar.accounts"),
        },
        {
          icon: <FaUserGroup />,
          url: Pages.Users,
          name: t("components.NavigationBar.Users"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.logsAndActivity"),
      items: [
        {
          icon: <FaUserClock />,
          url: Pages.UserLogs,
          name: t("components.NavigationBar.userLogs"),
        },
        {
          icon: <FaServer />,
          url: Pages.SystemLogs,
          name: t("components.NavigationBar.systemLogs"),
        },
      ],
    },
  ];

  const advertiserSections = [
    {
      title: t("components.NavigationBar.dashboard"),
      items: [
        {
          icon: "dashboard",
          url: Pages.Dashboard,
          name: t("components.NavigationBar.overview"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.advertisementManagement"),
      items: [
        {
          icon: <FaVideo />,
          url: Pages.Advertisements,
          name: t("components.NavigationBar.myAdvertisements"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.userManagement"),
      items: [
        {
          icon: "profile",
          url: Pages.Users,
          name: t("components.NavigationBar.myUsers"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.analyticsAndReporting"),
      items: [
        {
          icon: "reports",
          url: Pages.Reports,
          name: t("components.NavigationBar.reports"),
        },
      ],
    },
    // {
    //   title: t("components.NavigationBar.helpAndSupport"),
    //   items: [
    //     // {
    //     //   icon: "faqs",
    //     //   url: Pages.FAQs,
    //     //   name: t("components.NavigationBar.faqs"),
    //     // },
    //     // {
    //     //   icon: "tickets",
    //     //   url: Pages.SupportTickets,
    //     //   name: t("components.NavigationBar.supportTickets"),
    //     // },
    //     // {
    //     //   icon: "contact-support",
    //     //   url: Pages.ContactSupport,
    //     //   name: t("components.NavigationBar.contactSupport"),
    //     // },
    //   ],
    // },
  ];

  return (
    <div className="navigation-bar" data-testid="NavigationBar">
      <div className="nav-top-bar">
        <div className="logo-container">
          <SvgIcon
            name="logo"
            className="logo"
            onClick={() => {
              navigate(Pages.Dashboard);
            }}
          />
        </div>
        <Button
          icon="plus"
          text={t("components.NavigationBar.createAdvertisement")}
          onClick={() => {
            navigate(Pages.CreateAdvertisement);
          }}
        />
      </div>

      {profileAccount && profileAccount.entity === AccountEntity.Platform && (
        <div className="nav-main-content">
          {platformSections.map((section) => (
            <NavigationSection
              key={section.title}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      )}

      {profileAccount && profileAccount.entity === AccountEntity.Advertiser && (
        <div className="nav-main-content">
          {advertiserSections.map((section) => (
            <NavigationSection
              key={section.title}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
