import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@client.store";
import { createUserAsync } from "@client.reducers/users";
import { IGetUsersVariables } from "@client.types/queries/getUsers";

const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/;

const useCreateUserForm = (
  setShowCreateUserDrawer: Dispatch<SetStateAction<boolean>>,
  filter: IGetUsersVariables
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessfulToast, showErrorToast } = useToast();

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(nameRegex, "Name must contain only alphabetic characters, spaces, or hyphens")
      .required(t("components.Advertisers.validation.firstName")),
    lastName: yup
      .string()
      .matches(nameRegex, "Name must contain only alphabetic characters, spaces, or hyphens")
      .required(t("components.Advertisers.validation.lastName")),
    emailAddress: yup
      .string()
      .email(t("pages.Login.validation.invalidEmail"))
      .required(t("components.Advertisers.validation.emailAddress")),
    password: yup
      .string()
      .required(t("components.Advertisers.validation.password"))
      .min(6, 'Password must be at leeast 6 characters long')
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter"),
    phoneNumber: yup
      .string()
      .required(t("components.Advertisers.validation.phoneNumber"))
      .min(10, "Phone number must be at least 10 characters long")
      .max(15, "Phone number must be at most 15 characters long"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      password: "",
      phoneNumber: "",
    },
  });

  const onSubmit = async (userData) => {
    try {
      const resultAction = await dispatch(createUserAsync({
        createUserVariables: userData,
        getUsersVariables: filter
      }))

      if (createUserAsync.fulfilled.match(resultAction)) {
        showSuccessfulToast("User created");
        setShowCreateUserDrawer(false);
      } else if (createUserAsync.rejected.match(resultAction)) {
        showErrorToast(resultAction.payload || "An error occurred");
      }
    } catch {
      showErrorToast("Unexpected error occurred")
    }
  };

  const handleSubmitCreateUserForm = handleSubmit(onSubmit);

  return { register, errors, handleSubmitCreateUserForm }
}

export default useCreateUserForm;