import React from "react";
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from "recharts";

import variables from "../../../styles.scss";

const AdvertisementStatisticsChart = ({ advertisementMonitoring }) => {
  return (
    <div className="statistic-chart">
      <ResponsiveContainer width="90%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={
            advertisementMonitoring.graphData
          }
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="plays"
            stroke={variables.pink}
          />
          <Line
            type="monotone"
            dataKey="views"
            stroke={variables.yellow}
          />
          <Line type="monotone" dataKey="spent" stroke={variables.blue} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AdvertisementStatisticsChart;
