import { ICreateAccountFormValues } from "./useCreateAccountForm";

export const getCreateAccountFields = (
  createAccountFormValues: ICreateAccountFormValues,
) => {
  return {
    user: {
      firstName: createAccountFormValues.firstName,
      lastName: createAccountFormValues.lastName,
      emailAddress: createAccountFormValues.emailAddress,
      phoneNumber: createAccountFormValues.phoneNumber,
    },
    account: {
      companyName: createAccountFormValues.companyName,
      companyNumber: createAccountFormValues.companyRegistrationNumber,
      vatNumber: createAccountFormValues.companyVatNumber,
      entity: +createAccountFormValues.entity,
    },
    billingAddress: {
      addressLineOne: createAccountFormValues.billingAddress.addressLineOne,
      city: createAccountFormValues.billingAddress.city,
      postalCode: createAccountFormValues.billingAddress.postalCode,
      country: createAccountFormValues.billingAddress.country,
    },
    shippingAddress: {
      addressLineOne: createAccountFormValues.shippingAddress.addressLineOne,
      city: createAccountFormValues.shippingAddress.city,
      postalCode: createAccountFormValues.shippingAddress.postalCode,
      country: createAccountFormValues.shippingAddress.country,
    },
  }
}
