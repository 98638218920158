enum AdvertisementStatuses {
  Draft = 1,
  PendingApproval = 2,
  Queued = 3,
  Active = 4,
  Paused = 5,
  Archived = 6,
  Ended = 7,
  Rejected = 8,
}

export default AdvertisementStatuses;
