import React from "react";
import moment from "moment";
import LogActions from "@client.enums/logActions";
import LogTypes from "@client.enums/logTypes";
import config from "@client.config";
import EllipsisCellRenderer from "@client.components/DataTable/EllipsisCellRenderer";

export const getColumns = (t, onRowClick) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1,
    maxWidth: 80
  },
  {
    field: "createdAt",
    headerName: t("Created At"),
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format(config.dateTimeSecondsFormat)
        : "N/A";
    }
  },
  {
    field: "entityName",
    headerName: t("systemLogs.Related"),
    cellRenderer: (params) => {
      const entityPage = (() => {
        switch (params.data.entity) {
        case LogTypes.Device:
          return t("systemLogs.goToPage.Device");
        case LogTypes.User:
          return t("systemLogs.goToPage.User");
        case LogTypes.Accounts:
          return t("systemLogs.goToPage.Accounts");
        default:
          return t("N/A");
        }
      })();
      return (
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            onRowClick(entityPage, params.data.entityId);
          }}
        >
          {params.value || "N/A"}
        </a>
      );
    }
  },
  {
    field: "userFullName",
    headerName: t("Full Name")
  },
  {
    field: "type",
    headerName: t("Action"),
    valueGetter: (params) => {
      switch (params.data.type) {
      case LogActions.CREATE:
        return t("systemLogs.type.CREATE");
      case LogActions.UPDATE:
        return t("systemLogs.type.UPDATE");
      case LogActions.DELETE:
        return t("systemLogs.type.DELETE");
      case LogActions.LOGIN:
        return t("systemLogs.type.LOGIN");
      default:
        return t("N/A");
      }
    }
  },
  {
    field: "description",
    headerName: t("Description"),
    cellRenderer: EllipsisCellRenderer,
  },
  {
    field: "ipAddress",
    headerName: t("IP Address")
  }
];
