import React, { useState } from "react";

import TreeItem from "@client.components/Tree/TreeItem";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import classNames from "classnames";

const ZoneItem = ({
  zone,
  selectedZonesId,
  onSelectZone,
}) => {
  const [expanded, setExpanded] = useState(false);

  const isSelected = selectedZonesId.includes(zone.Id);

  const renderExpandCollapse = () => {
    if (!zone.items.length) {
      return null;
    }

    return expanded ? (
      <MdOutlineExpandLess onClick={() => setExpanded(prev => !prev)} />
    ) : (
      <MdOutlineExpandMore onClick={() => setExpanded(prev => !prev)} className="expand" />
    );
  };

  return (
    <li className="tree-item">
      <div 
        className={classNames("tree-item-title", {
          selected: isSelected,
        })}
      >
        {renderExpandCollapse()}
        <p onClick={() => onSelectZone(zone.Id)} className="tree-item-title-text">
          {zone.zoneName}
          <span className="item-type">({zone.category})</span>
        </p>
      </div>
      {expanded && (
        <div>
          {zone.items && (
            <ul>
              {zone.items.map((item) => (
                <ZoneItem
                  key={item.Id}
                  zone={item}
                  selectedZonesId={selectedZonesId}
                  onSelectZone={onSelectZone}
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </li>
  );
};

export default ZoneItem;
