import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeviceError,
  selectDevicesError,
} from "@client.reducers/devices";
import ServiceProvider from "@client.services/provider";
import { TOAST_EVENT } from "@client.core.components/Toast";
import { clearLoginError, selectLoginError } from "@client.reducers/login";
import {
  clearAdvertiserError,
  makeAdvertiserError,
} from "@client.reducers/Advertiser";
import {
  clearProfileError,
  makeProfileError,
} from "@client.reducers/profile";
import {
  clearZonesError,
  selectZonesError,
} from "@client.reducers/zones";

export const ErrorMessages = () => {
  const loginError = useSelector(selectLoginError);
  const devicesError = useSelector(selectDevicesError);
  const advertiserError = useSelector(makeAdvertiserError);
  const profileError = useSelector(makeProfileError);
  const zonesError = useSelector(selectZonesError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      devicesError ||
      loginError ||
      advertiserError ||
      profileError ||
      zonesError
    ) {
      const errors = [
        devicesError ||
          loginError ||
          advertiserError ||
          profileError ||
          zonesError
      ]
        .filter(Boolean)
        .map((e) => ({
          type: "error",
          msg: Array.isArray(e) ? e[0].message : e.message,
        }));
      ServiceProvider.EventEmitter.emit(TOAST_EVENT, errors);
    }
    if (devicesError) dispatch(clearDeviceError());
    if (loginError) dispatch(clearLoginError());
    if (advertiserError) dispatch(clearAdvertiserError());
    if (profileError) dispatch(clearProfileError());
    if (zonesError) dispatch(clearZonesError());
  }, [
    dispatch,
    devicesError,
    loginError,
    advertiserError,
    profileError,
    zonesError,
  ]);

  return null;
};
