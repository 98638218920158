import AccountEntity from "@client.enums/accountEntity";
import ZoneTypes from "@client.enums/zoneTypes";

import AdvertisementStatuses from "@client.enums/advertisementStatuses";
import DeviceStatuses from "@client.enums/deviceStatuses";

export const formatNumber = (num, fractionalPart = 2) => {
  const options = {
    minimumFractionDigits: fractionalPart,
    maximumFractionDigits: fractionalPart,
  };
  const formattedNumber = new Intl.NumberFormat("en-US", options).format(num);

  return formattedNumber;
};

export const getZoneTypeNameByCode = (zoneCode: number) => {
  switch (zoneCode) {
  case ZoneTypes.Country:
    return "Country";
  case ZoneTypes.State:
    return "State";
  case ZoneTypes.County:
    return "County";
  case ZoneTypes.LocalArea:
    return "Local Area";
  case ZoneTypes.Custom:
    return "Custom";
  default:
    return "N/A";
  }
};

export const getAccountEntityNameByCode = (entityCode: number) => {
  switch (entityCode) {
  case AccountEntity.Platform:
    return "Platform";
  case AccountEntity.Reseller:
    return "Reseller";
  case AccountEntity.Advertiser:
    return "Advertiser";
  case AccountEntity.Partner:
    return "Partner";
  }
};

export const getAdvertisementStatus = (statusCode: number) => {
  switch (statusCode) {
  case AdvertisementStatuses.Draft:
    return "Draft";
  case AdvertisementStatuses.PendingApproval:
    return "Pending Approval";
  case AdvertisementStatuses.Queued:
    return "Queued";
  case AdvertisementStatuses.Active:
    return "Active";
  case AdvertisementStatuses.Paused:
    return "Paused";
  case AdvertisementStatuses.Archived:
    return "Archived";
  case AdvertisementStatuses.Ended:
    return "Ended";
  case AdvertisementStatuses.Rejected:
    return "Rejected";
  default:
    return "Unknown";
  }
};

export const getAdvertisementBadgeClassName = (statusCode: number) => {
  switch (statusCode) {
  case AdvertisementStatuses.Draft:
    return "advertisement-draft-badge";
  case AdvertisementStatuses.PendingApproval:
    return "advertisement-pending-approval-badge";
  case AdvertisementStatuses.Queued:
    return "advertisement-queued-badge";
  case AdvertisementStatuses.Active:
    return "advertisement-active-badge";
  case AdvertisementStatuses.Paused:
    return "advertisement-paused-badge";
  case AdvertisementStatuses.Archived:
    return "advertisement-archived-badge";
  case AdvertisementStatuses.Ended:
    return "advertisement-ended-badge";
  case AdvertisementStatuses.Rejected:
    return "advertisement-rejected-badge";
  default:
    return "";
  }
};

export const getDeviceStatus = (statusCode: string | number) => {
  switch (statusCode) {
  case DeviceStatuses.All:
    return "";
  case DeviceStatuses.Online:
    return "Online";
  case DeviceStatuses.Offline:
    return "Offline";
  default:
    return "";
  }
}

export const getDeviceStatusClassName = (statusCode: string | number) => {
  switch (statusCode) {
  case DeviceStatuses.All:
    return "status-1";
  case DeviceStatuses.Online:
    return "status-3";
  case DeviceStatuses.Offline:
    return "status-2";
  default:
    return "status-1";
  }
}

export const countDaysDifference = (start: string, end: string): number => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const timeDifference = endDate.getTime() - startDate.getTime();

  const daysDifference = timeDifference / (1000 * 3600 * 24);

  return daysDifference;
};

export const convertDateAndTime = (inputDate: string): string => {
  const dateObj = new Date(inputDate);

  const formattedDate = dateObj.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return formattedDate;
};
