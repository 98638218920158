import { IGetMapVariables } from "@client.types/queries/mapQuery";
import { IDashboardMapFilter } from ".";

const getMapFilterVariables = (mapFilter: IDashboardMapFilter): IGetMapVariables => ({
  search: mapFilter.accountId || mapFilter.userId,
  showZones: mapFilter.showZones,
  showHeatmap: mapFilter.showHeatmap,
  deviceType: mapFilter.deviceType,
  deviceOnline: mapFilter.deviceOnline,
})

export default getMapFilterVariables;
