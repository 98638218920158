import BaseService from "@client.services/baseService";
import ServiceProvider from "./provider";
import LocalStorageKeys from "@client.enums/localStorageKeys";
import config from "@client.config";
import { IGetAccountBillingResponse, IGetAccountBillingVariables } from "@client.types/queries/getAccountBilling";

export default class AccountService extends BaseService {
  async getAccounts() {
    return super.post("", {
      query: `
        query Accounts($pagination: PaginationInputType) {
          accounts(pagination: $pagination) {
            count
            results {
              Id
              entity
              parentAccountId
              ownerUserId
              companyName
              companyRegistrationNumber
              companyVatNumber
              companyUrl
              logoUrl
              balance
              billingAddress {
                lineOne
                city
                postalCode
                country
              }
              shippingAddress {
                lineOne
                city
                postalCode
                country
              }
              createdAt
              updatedAt
              isActive
            }
          }
        }
      `,
    });
  }

  async getAccountById(accountId: string) {
    return super.post("", {
      query: `
        query GetAccountById($accountId: String!) {
          account(accountId: $accountId) {
            Id
            entity
            parentAccountId
            ownerUserId
            companyName
            companyRegistrationNumber
            companyVatNumber
            companyUrl
            logoUrl
            balance
            billingAddress {
              lineOne
              city
              postalCode
              country
            }
            shippingAddress {
              lineOne
              city
              postalCode
              country
            }
            createdAt
            updatedAt
            isActive
          }
        }
      `,
      variables: {
        accountId,
      },
    });
  }

  async getAccountOwner(accountId) {
    return super.post("", {
      query: `
        query AccountOwner($accountId: String) {
          accountOwner(accountId: $accountId) {
            Id
            accountId
            avatarUrl
            createdAt
            emailAddress
            firstName
            isActive
            jobTitle
            lastLoginTime
            lastName
            password
            role
            phoneNumber
            updatedAt
          }
        }
      `,
      variables: {
        accountId,
      },
    });
  }

  async getAccountMap({ accountId, showCampaigns, showZones }) {
    return super.post("", {
      query: `
        query AccountMap($accountId: String!, $showCampaigns: Boolean!, $showZones: Boolean!) {
          accountMap(accountId: $accountId, showCampaigns: $showCampaigns, showZones: $showZones) {
            campaigns {
              title
              startDate
              endDate
              accountId
              accountName
              userId
              userFullName
              targets
              mediaId
              mediaUrl
              budgetTotal
              budgetDaily
              geofenceRadiusLatitude
              geofenceRadiusLongitude
              geofenceRadiusRange
              geofenceZoneIds
              geofenceCustomPolygons
              totalViews
              totalSpent
              totalPlays
              displayTime
              status
              rejectionReason
              latitude
              longitude
              Id
              createdAt
              updatedAt
              isActive
            }
            zones {
              Id
              category
              color
              createdAt
              isActive
              parentZoneId
              polygon
              standardPpvRate
              primePpvRate
              updatedAt
              zoneName
            }
          }
        }
      `,
      variables: {
        accountId,
        showCampaigns,
        showZones,
      },
    });
  }

  async getAccountBilling(
    variables: IGetAccountBillingVariables = {}
  ): Promise<IGetAccountBillingResponse> {
    return super.post("", {
      query: `
        query($accountId: String!, $pagination: PaginationInputType) {
          accountBilling(accountId: $accountId, pagination: $pagination) {
            accountCredit
            invoices {
              count
              results {
                Id
                amount
                createdAt
                dueDate
                invoiceDate
                isActive
                status
                type
                updatedAt
                purpose
              }
            }
            lastInvoiceAmount
            primaryCard {
              cardLastFour
              cardType
            }
          }
        }`
      ,
      variables,
    });
  }

  async getAccountAdvertisements(
    accountId: string,
    pagination: {
      limit: number;
      offset: number;
      search?: string;
      searchOn?: string;
      startDate?: string;
      endDate?: string;
    }
  ) {
    return super.post("", {
      query:  `
        query AccountCampaigns($accountId: String!, $pagination: PaginationInputType) {
          accountCampaigns(accountId: $accountId, pagination: $pagination) {
            count
            results {
              title
              startDate
              endDate
              accountId
              accountName
              userId
              userFullName
              targets
              mediaId
              mediaUrl
              budgetTotal
              budgetDaily
              geofenceRadiusLatitude
              geofenceRadiusLongitude
              geofenceRadiusRange
              geofenceZoneIds
              geofenceCustomPolygons
              totalViews
              totalSpent
              totalPlays
              displayTime
              status
              rejectionReason
              latitude
              longitude
              Id
              createdAt
              updatedAt
              isActive
            }
          }
        }
      `,
      variables: {
        accountId,
        pagination,
      },
    });
  }

  async uploadAsset(file, accountId) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("kgn_account_id", accountId);

    const token = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Token);
    const authHeader = token ? { Authorization: `KGN ${token}` } : {};

    return super.upload(config.uploadUrl, formData, true, authHeader);
  }

  async getAccountAssets(accountId) {
    return super.post("", {
      query: `query Medias($accountId: String) {
        medias(accountId: $accountId) {
          count
          results {
            Id
            url
            accountId
            userId
            fileExtension
            fileSize
            isActive
            createdAt
            updatedAt
            mimeType
          }
        }
      }`,
      variables: {
        accountId,
      },
    });
  }

  async getAccountUsers(accountId) {
    return super.post("", {
      query: `
        query AccountUsers($accountId: String, $pagination: PaginationInputType){
          users(accountId: $accountId, pagination: $pagination){
            count
            results{
              Id
              avatarUrl
              firstName
              accountId
              lastName
              emailAddress
              role
              createdAt
              lastLoginTime
              isActive
              phoneNumber
            }
          }
        }
      `,
      variables: {
        accountId
      }
    });
  }

  async createAccount(variables) {
    return super.post("", {
      query: `
        mutation CreateAccount(
          $account: CreateAccountInputType, 
          $user: UserInputType,
          $shippingAddress: AddressInputType,
          $billingAddress: AddressInputType
          ) {
            createAccount(
              account: $account,
              user: $user,
              shippingAddress: $shippingAddress,
              billingAddress: $billingAddress
            ) {
              message
              user {
                Id
                emailAddress
                phoneNumber
                firstName
                lastName
                lastLoginTime
                avatarUrl
                role
                accountId
              }
              account {
                entity
                parentAccountId
                ownerUserId
                companyName
                companyRegistrationNumber
                companyVatNumber
                companyUrl
                logoUrl
                balance
                billingAddress {
                  lineOne
                  city
                  postalCode
                  country
                }
                shippingAddress {
                  lineOne
                  city
                  postalCode
                  country
                }
                Id
                createdAt
                updatedAt
                isActive
              }
            }
          }
        `,
      variables,
    });
  }

  async updateAccount(variables) {
    return super.post("", {
      query: `
        mutation UpdateAccount(
          $accountId: String,
          $ownerUserId: String,
          $account: CreateAccountInputType, 
          $shippingAddress: AddressInputType,
          $billingAddress: AddressInputType
          ) {
            updateAccount(
              accountId: $accountId,
              ownerUserId: $ownerUserId,
              account: $account, 
              shippingAddress: $shippingAddress,
              billingAddress: $billingAddress
            ) {
              message
              user {
                Id
                emailAddress
                phoneNumber
                firstName
                lastName
                lastLoginTime
                avatarUrl
                role
                accountId
              }
              account {
                entity
                parentAccountId
                ownerUserId
                companyName
                companyRegistrationNumber
                companyVatNumber
                companyUrl
                logoUrl
                balance
                billingAddress {
                  lineOne
                  city
                  postalCode
                  country
                }
                shippingAddress {
                  lineOne
                  city
                  postalCode
                  country
                }
                Id
                createdAt
                updatedAt
                isActive
              }
            }
          }
        `,
      variables,
    });
  }
}