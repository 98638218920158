import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { useDispatch } from "react-redux";
import "./AdvertisementApprovalSummary.scss";
import { updateAdvertiserAdvertisementStatusAsync } from "@client.reducers/Advertiser";
import { getZoneByIdAsync } from "@client.reducers/zones";
import {
  convertDateAndTime,
  countDaysDifference,
  getAdvertisementStatus,
} from "@client.utils/utils";
import AdvertisementStatuses from "@client.enums/advertisementStatuses";
import useToast from "@client.hooks/useToast";
import { Panel } from "@client.core.components/Panel";

const AdvertisementApprovalSummary = ({
  model,
  fetchAdvertisementApprovals,
}) => {
  const { t } = useTranslation();
  const { showErrorToast, showToastAfterRequest } = useToast();
  const dispatch = useDispatch();
  const [locationName, setLocationName] = useState("");
  const [isOpenReasonField, setIsOpenReasonField] = useState(false);
  const [reason, setReason] = useState("");

  const fetchLocationNames = async () => {
    if (model.zoneId) {
      const data = await dispatch(getZoneByIdAsync(model.zoneId));
      setLocationName(data?.payload?.data?.zone?.zoneName);
    } else {
      setLocationName("N/A");
    }
  };

  useEffect(() => {
    fetchLocationNames();
    setReason("");
    setIsOpenReasonField(false);
  }, [dispatch, model.zoneId]);

  const handleApprove = () => {
    updateAdvertisementStatus(AdvertisementStatuses.Queued);
  };

  const handleDeny = () => {
    if (isOpenReasonField) {
      updateAdvertisementStatus(AdvertisementStatuses.Rejected);
    } else {
      setIsOpenReasonField(true);
    }
  };

  const updateAdvertisementStatus = (type) => {
    dispatch(
      updateAdvertiserAdvertisementStatusAsync({
        advertisement: {
          id: model.Id,
          status: type,
          rejectionReason:
            type === AdvertisementStatuses.Rejected ? reason : "",
        },
        filters: { status: type },
      })
    )
      .then((res) => {
        showToastAfterRequest(
          "",
          res.payload.data.advertisementApproval.message
        );
        fetchAdvertisementApprovals();
        setReason("");
      })
      .catch(() => {
        showErrorToast(
          "",
          type === AdvertisementStatuses.Rejected
            ? t("Pages.AdvertisementApproval.denied")
            : t("Pages.AdvertisementApproval.errorApprove")
        );
      });
  };

  const advertisementLabels = useMemo(
    () => [
      {
        title: t("Pages.CreateAdvertisement.advertisementName"),
        value: model.title || "N/A",
      },
      {
        title: t("Pages.CreateAdvertisement.startDate"),
        value: convertDateAndTime(model.startDate) || "N/A",
      },
      {
        title: t("Pages.CreateAdvertisement.endDate"),
        value: convertDateAndTime(model.endDate) || "N/A",
      },
      {
        title: t("Pages.CreateAdvertisement.durationTime"),
        value: model.budgetDaily * 3600,
      },
      {
        title: t("Pages.CreateAdvertisement.pricePerDay"),
        value: `$ ${
          (model.budgetTotal &&
            (
              model.budgetTotal /
              countDaysDifference(model.startDate, model.endDate)
            ).toFixed(2)) ||
          "N/A"
        }`,
      },
      {
        title: t("Pages.CreateAdvertisement.priceTotal"),
        value: `$ ${model.budgetTotal.toFixed(2) || "N/A"}`,
      },
      {
        title: t("Pages.CreateAdvertisement.location"),
        value: locationName || "N/A",
      },
    ],
    [model, locationName]
  );

  return (
    <div className="advertisement-approval-summary panel-default-width">
      <Panel
        title={model.title}
        status={model.status}
        data={advertisementLabels}
        image={model.mediaUrl}
        isShowImage
        statusClassNameFunc={getAdvertisementStatus}
        statusViewFunc={getAdvertisementStatus}
      >
        <div className="approval-section">
          {isOpenReasonField && (
            <textarea
              className={`reason-textbox ${isOpenReasonField ? "open" : null}`}
              placeholder={t("Pages.AdvertisementApproval.enterReason")}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          )}
          <div className="approval-buttons">
            <Button
              text={t("Pages.AdvertisementApproval.deny")}
              className="small-button deny alternative"
              onClick={handleDeny}
              disabled={isOpenReasonField && !reason.trim().length}
            />
            <Button
              text={t("Pages.AdvertisementApproval.approve")}
              className="small-button button"
              onClick={handleApprove}
            />
          </div>
        </div>
      </Panel>
    </div>
  );
};

AdvertisementApprovalSummary.propTypes = {
  model: PropTypes.any,
  estimation: PropTypes.any,
  fetchAdvertisementApprovals: PropTypes.func,
};

export default AdvertisementApprovalSummary;
