import React, { useEffect, useState } from "react";
import { VoidFunc } from "types";

import "./styles.scss";

interface IProps {
  onChange: VoidFunc<boolean>;
  value: boolean;
  isDisabled?: boolean;
}

const ToggleSwitch = ({
  onChange,
  value,
  isDisabled,
}: IProps) => {
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    setIsOn(value);
  }, [value]);

  const handleToggle = () => {
    setIsOn(!isOn);
    onChange(!isOn);
  };

  return (
    <div
      className={`toggle-switch-component ${(isOn && !isDisabled) ? "on" : "off"}`}
      onClick={isDisabled ? () => null : handleToggle}
    >
      {!isDisabled && (
        <div className={`toggle-knob ${(isOn && !isDisabled) ? "on" : "off"}`} />
      )}
    </div>
  );
};

export default ToggleSwitch;
