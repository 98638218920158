import React from "react";

import { FieldErrors, UseFormRegister } from "react-hook-form";

import { useTranslation } from "react-i18next";
import TextInput from "@client.components/form/TextInput";

export interface IFormValues {
  firstName: string,
  lastName: string,
  emailAddress: string,
  password: string,
  phoneNumber: string,
}

interface IProps {
  register: UseFormRegister<IFormValues>;
  errors: FieldErrors<IFormValues>;
}

const CreateUserForm = ({ register, errors }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="create-user-form">
      <TextInput
        id="firstName"
        name="firstName"
        label={t("pages.Users.columns.firstName")}
        placeholder={t("pages.Users.columns.firstName")}
        register={register}
        error={errors.firstName}
      />
      <TextInput
        id="lastName"
        name="lastName"
        label={t("pages.Users.columns.lastName")}
        placeholder={t("pages.Users.columns.lastName")}
        register={register}
        error={errors.lastName}
      />
      <TextInput
        id="password"
        name="password"
        type="password"
        label={t("pages.Users.columns.password")}
        placeholder={t("pages.Users.columns.password")}
        register={register}
        error={errors.lastName}
      />
      <TextInput
        id="emailAddress"
        name="emailAddress"
        label={t("pages.Users.columns.email")}
        placeholder={t("pages.Users.columns.email")}
        register={register}
        error={errors.emailAddress}
      />
      <TextInput
        id="phoneNumber"
        name="phoneNumber"
        type="tel"
        label={t("pages.Users.columns.phone")}
        placeholder={t("pages.Users.columns.phone")}
        register={register}
        error={errors.phoneNumber}
      />
    </div>
  );
};

export default CreateUserForm;
