import React from "react";
import { IAdvertisement } from "@client.types/advertisement";
import {
  getAdvertisementBadgeClassName,
  getAdvertisementStatus,
} from "@client.utils/utils";
import { Panel } from "@client.components/core/Panel";
import { advertisementData } from "./constant";
import AdvertisementProgressBar from "./AdvertisementProgressBar";

interface IProps {
  advertisement: IAdvertisement;
}

const AdvertisementCard = ({ advertisement }: IProps) => {
  return (
    <div className="advertisement-card">
      <Panel
        title={advertisement.title}
        data={advertisementData(advertisement)}
        image={advertisement.mediaUrl}
        isShowImage
        status={advertisement.status}
        statusClassNameFunc={getAdvertisementBadgeClassName}
        statusViewFunc={getAdvertisementStatus}
      >
        <div className="advertisement-progress-bar">
          <AdvertisementProgressBar
            startDate={advertisement.startDate}
            endDate={advertisement.endDate}
          />
        </div>
      </Panel>
    </div>
  );
};

export default AdvertisementCard;
