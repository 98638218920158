import React from "react";
import classNames from "classnames";

import { useCreateAdvertisementForm } from "./CreateAdvertisementFormContext";
import CreateAdvertisementSummary from "./CreateAdvertisementSummary";
import CreateAdvertisementForm from "./CreateAdvertisementForm";

const CreateAdvertisementPage = () => {
  const {
    isFormFilled
  } = useCreateAdvertisementForm();

  return (
    <div className={classNames("create-advertisement-container", { "with-sidebar": !isFormFilled })}>
      <CreateAdvertisementForm />
      {!isFormFilled ? (
        <CreateAdvertisementSummary />
      ) : false}
    </div>
  )
}

export default CreateAdvertisementPage;
