import React, { useEffect } from "react";

import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import TextInput from "@client.components/form/TextInput";
import FormSelect from "@client.components/form/FormSelect";
import LogTypes from "@client.enums/logTypes";
import LogActions from "@client.enums/logActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getDevicesAsync,
  selectDevicesData,
} from "../../reducers/devices";
import {
  getAllUsersAsync,
  selectUsersData,
} from "@client.reducers/users";
import { IDevice } from "types/device";
import { IUser } from "types/user";
import { selectAccountsData, getAccountsAsync } from "@client.reducers/accounts";
import { AppDispatch } from "@client.store";

export interface IFormValues {
  entity: string,
  entityId: string,
  type: string,
  description: string,
}

interface IProps {
  register: UseFormRegister<IFormValues>;
  control: Control<IFormValues>;
  selectedEntity: string;
  errors: FieldErrors<IFormValues>;
}

const CreateSystemLogForm = ({ register, control, selectedEntity, errors }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const devices: IDevice[] = useSelector(selectDevicesData);
  const users: IUser[] = useSelector(selectUsersData);
  const accounts = useSelector(selectAccountsData);

  useEffect(() => {
    dispatch(
      getDevicesAsync()
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUsersAsync({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccountsAsync());
  }, [dispatch]);

  const getOptions = () => {
    switch (selectedEntity) {
    case '1':
      return devices.map((device) => ({ value: device.Id, label: device.deviceName }))
    case '2':
      return users.map((user) => ({ value: user.Id, label: user.firstName }))
    case '3':
      return accounts.map((account) => ({ value: account.Id, label: account.companyName }))
    default:
      return [];
    }
  }

  return (
    <div className="create-system-log-form">
      <FormSelect 
        name="entity"
        label="Entity"
        control={control}
        options={[
          {
            value: LogTypes.Device,
            label: t("systemLogs.goToPage.Device")
          },
          {
            value: LogTypes.User,
            label: t("systemLogs.goToPage.User")
          },
          {
            value: LogTypes.Accounts,
            label: t("systemLogs.goToPage.Accounts")
          },
        ]}
        error={errors.entity?.message}
      />
      <FormSelect 
        name="entityId"
        label="Entity ID"
        control={control}
        options={getOptions()}
        error={errors.entityId?.message}
      />
      <FormSelect
        name="type"
        label="Type"
        control={control}
        options={[
          {
            value: LogActions.CREATE,
            label: t("systemLogs.type.CREATE"),
          },
          {
            value: LogActions.UPDATE,
            label: t("systemLogs.type.UPDATE"),
          },
          {
            value: LogActions.DELETE,
            label: t("systemLogs.type.DELETE"),
          },
          {
            value: LogActions.LOGIN,
            label: t("systemLogs.type.LOGIN"),
          },
        ]}
        error={errors.type?.message}
      />
      <TextInput
        id="description"
        name="description"
        label="Description"
        placeholder="Description"
        register={register}
        error={errors.description}
      />
    </div>
  );
};

export default CreateSystemLogForm;
