import React from "react";
 
import Loader from "@client.core.components/Loader";
import CircleIcon from "@client.components/CircleIcon";
import { IUser } from "@client.types/user";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@client.store";
import { setSelectedUser } from "@client.reducers/users";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
 
 interface IProps {
   users: IUser[],
   selectedUser: IUser | null,
   usersLoading: boolean;
 }
 
const UsersList = ({
  users,
  selectedUser,
  usersLoading,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
 
  if (usersLoading) {
    return (
      <div className="users-loading">
        <Loader loading={true} />
      </div>
    );
  }
 
  return (
    <div className="users-list-container">
       
      {users?.length > 0 ? (
        users.map((user, index) => (
          <div
            key={user.Id}
            className={`list-item ${
              selectedUser?.Id === user.Id
                ? "list-item--selected"
                : ""
            }`}
            onClick={() => navigate(`${Pages.Users}/${user.Id}/general`)}
          >
            {user.avatarUrl ? (
              <img
                src={user.avatarUrl}
                alt="Avatar"
                className="avatar"
              />
            ) : (
              <CircleIcon
                name={`${user.firstName} ${user.lastName}`}
                index={index}
              />
            )}
            <span>{`${user.firstName} ${user.lastName}`}</span>
          </div>
        ))
      ) : (
        <p className="no-data">No Data Available</p>
      )}
    </div>
  );
};
 
export default UsersList;