import React from "react";
import { useTranslation } from "react-i18next";

const ChartTooltip = ({ active, payload }) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    const { name, qty, value } = payload[0].payload;
    return (
      <div className="pie-chart-tooltip">
        <b>{name}</b>
        <p>{t("pages.DataAnalyzer.quantity")}: {qty}</p>
        <p>{t("pages.DataAnalyzer.percentage")}: {value}%</p>
      </div>
    );
  }
  return null;
};

export default ChartTooltip;
