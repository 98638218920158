import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import {
  getSystemLogsAsync,
  selectSystemLogsData,
  selectSystemLogsLoading
} from "@client.reducers/systemLogs";

import Pages from "@client.enums/pages";

import DataTable from "@client.components/DataTable";
import Button from "@client.core.components/Button";
import Input from "@client.core.components/Input";
import Title from "@client.components/Title";
import DateRangePicker from "@client.components/DateRangePicker";
import Drawer from '@client.components/Drawer';

import CreateSystemLogForm from "./CreateSystemLogForm";
import useCreateSystemLogForm from "./useCreateSystemLogForm";
import { getColumns } from "./consts";
import "./styles.scss";
import { AppDispatch } from "@client.store";
import { TOOLTIP_ID } from "@client.constants";

const ITEMS_PER_PAGE = 10;

const SystemLogsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const systemLogs = useSelector(selectSystemLogsData);
  const systemLogsLoading = useSelector(selectSystemLogsLoading);

  const tableRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: ""
  });
  const [showCreateSystemLogDrawer, setShowCreateSystemLogDrawer] = useState(false);

  const {
    register,
    errors,
    control,
    resetForm,
    handleSubmitCreateSystemLogForm,
    selectedEntity,
  } = useCreateSystemLogForm(setShowCreateSystemLogDrawer, filter);

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getSystemLogsAsync({
        ...filter,
        offset,
        limit: ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "description,user_full_name",
        startDate: filter.startDate,
        endDate: filter.endDate
      })
    );
  }, [filter, currentPage, dispatch]);

  const handleCloseCreateSystemLogDrawer = () => {
    resetForm();
    setShowCreateSystemLogDrawer(false);
  };

  const onRowClick = useCallback(
    (page, entityId) => {
      switch (page) {
      case "Device":
        navigate(`${Pages.Devices}/${entityId}`);
        break;
      case "User":
        navigate(`${Pages.Users}`);
        break;
      case "Accounts":
        navigate(`${Pages.Accounts}/${entityId}`);
        break;
      default:
        console.warn("Unknown page type");
        break;
      }
    },
    [navigate]
  );

  const createTxt = t("systemLogs.title.create");

  return (
    <div className="system-logs">
      <Title text="components.NavigationBar.SystemLogs">
        <Button
          icon="plus"
          text={createTxt}
          onClick={() => setShowCreateSystemLogDrawer(true)}
        />
      </Title>
      <div className="filters-container">
        <Input
          label="Search"
          value={filter.query}
          onChange={(e) => {
            setFilter({
              ...filter,
              query: e
            });
          }}
          data-tooltip-id={TOOLTIP_ID}
          data-tooltip-content={
            "Search Parameters\n- Description\n- User Full Name"
          }
        />
        <DateRangePicker
          dateFrom={filter.startDate}
          dateTo={filter.endDate}
          label="Date Range"
          onChange={([startDate, endDate]) => {
            setFilter({
              ...filter,
              startDate: startDate
                ? moment(startDate).toISOString()
                : "",
              endDate: endDate
                ? moment(endDate).toISOString()
                : ""
            });
          }}
        />
      </div>
      <div className="table-wrapper">
        <DataTable
          ref={tableRef}
          columns={getColumns(t, onRowClick)}
          data={systemLogs}
          isLoading={systemLogsLoading}
          pagination={{
            total: systemLogs.length,
            itemsPerPage: ITEMS_PER_PAGE,
            currentPage,
            onChange: setCurrentPage
          }}
        />
      </div>
      <Drawer
        isOpen={showCreateSystemLogDrawer}
        onClose={handleCloseCreateSystemLogDrawer}
        title={createTxt}
        footer={
          <Button
            text={t("components.Advertisers.create")}
            onClick={handleSubmitCreateSystemLogForm}
          />
        }
      >
        <CreateSystemLogForm
          register={register}
          control={control}
          selectedEntity={selectedEntity}
          errors={errors}
        />
      </Drawer>
    </div>
  );
};

export default SystemLogsPage;
