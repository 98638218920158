import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./AdvertisementProgressBar.scss";

interface IProps {
  startDate: string;
  endDate: string;
}

const AdvertisementProgressBar = ({ startDate, endDate }: IProps) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (startDate && endDate) {
      const now = new Date();
      const start = new Date(startDate);
      const end = new Date(endDate);

      const totalDuration = end.getTime() - start.getTime();
      const elapsed = now.getTime() - start.getTime();

      const percentage = Math.min(100, Math.max(0, (elapsed / totalDuration) * 100));
      setProgress(percentage);
    }
  }, [startDate, endDate]);

  return (
    <div className="advertisement-progress-container">
      <div className="progress-label">{t("Pages.Advertisement.Overview.component.progressbar")}</div>
      <div className="progress-percentage">{Math.floor(progress)}%</div>
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default AdvertisementProgressBar;
