import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "@client.hooks/useAuth";
import Pages from "@client.enums/pages";

const RequireAuth = () => {
  const { user, setLocationToNavigate } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      setLocationToNavigate(
        location.pathname === Pages.Logout ? Pages.Dashboard : location.pathname
      );
    }
  }, [user, setLocationToNavigate, location.pathname]);

  if (!user) {
    return <Navigate to={Pages.Login} />;
  }

  return <Outlet />;
};

export default RequireAuth;
