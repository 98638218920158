import React from "react";
import Pagination from "rc-pagination";
import { useTranslation } from "react-i18next";

const ITEMS_PER_PAGE = 10;

const DataTablePagination = ({
  total,
  onChange,
  itemsPerPage = ITEMS_PER_PAGE,
}) => {
  const { t } = useTranslation();

  if (total <= itemsPerPage) {
    return null;
  }

  return (
    <Pagination
      showTitle={false}
      onChange={onChange}
      showTotal={(total, range) =>
        `${t("components.Pagination.TotalFormat", {
          from: range[0],
          to: range[1],
          total: total,
        })}`
      }
      pageSize={itemsPerPage}
      total={total}
    />
  );
};

export default DataTablePagination;
