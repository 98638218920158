import React, { useEffect, useState } from "react";
import { isNull } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";
import RCDropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import Icon from "@client.core.components/Icon";

import "./styles.scss";

interface IProps {
  items: any,
  defaultValue?: any,
  label?: string,
  onSelect: (value: any) => void,
  valueKey?: string,
  labelKey?: string,
  error?: string | null,
  descriptionKey?: any,
  onPrepareValue?: any,
  placeholder?: any,
  disabled?: any,
  payment?: any,
  labelClassName?: any,
  nameData?: any,
  className?: string,
  itemClassName?: string,
  labelBackgroundColor?: string
}

const Dropdown = ({
  items,
  defaultValue,
  label,
  onSelect,
  valueKey = "value",
  labelKey = "label",
  error = null,
  descriptionKey,
  onPrepareValue,
  placeholder,
  disabled,
  payment,
  labelClassName,
  nameData,
  className,
  itemClassName,
  labelBackgroundColor = ''
}: IProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const renderTextWithDots = (text) => {
    if (!text) return "";
    const visiblePart = text.slice(-4);
    const hiddenPart = text.slice(0, -4).replace(/./g, "●");

    const addSpaces = (str) => str.match(/.{1,4}/g).join(" ");

    return addSpaces(hiddenPart) + " " + visiblePart;
  };

  const renderOverlay = () => (
    <Menu
      onSelect={({ key }) => {
        const value = onPrepareValue ? onPrepareValue(key) : key;
        setSelectedValue(value);
        if (onSelect) {
          onSelect(value);
        }
      }}
      className="dropdown-menu-rc"
    >
      {items.map((item) =>
        payment ? (
          <MenuItem key={item[valueKey]} className={itemClassName}>
            {renderTextWithDots(item[labelKey])}
            {descriptionKey && item[descriptionKey] && (
              <span>{item[descriptionKey]}</span>
            )}
          </MenuItem>
        ) : (
          <MenuItem
            key={item[valueKey]}
            className={classNames(itemClassName, { "with-description": !!descriptionKey })}
          >
            {item[labelKey]}
            {descriptionKey && item[descriptionKey] && (
              <span className="description">{item[descriptionKey]}</span>
            )}
          </MenuItem>
        )
      )}
    </Menu>
  );

  const selectedItem =
    items && items.find((i) => i[valueKey] === selectedValue);

  return (
    <div className="dropdown-container">
      {label && (
        <span style={{backgroundColor: labelBackgroundColor}} className={classNames(`label`, labelClassName)}>{label}</span>
      )}
      <RCDropdown
        trigger={["click"]}
        overlay={renderOverlay}
        animation="slide-up"
      >
        <button
          disabled={disabled}
          className={classNames("dropdown", { "is-invalid": !isNull(error) }, className)}
        >
          {selectedItem === undefined ? (
            <span
              className={
                nameData ? "placeholder placeholder-black" : "placeholder"
              }
            >
              {placeholder}
            </span>
          ) : payment ? (
            renderTextWithDots(selectedItem[labelKey])
          ) : (
            selectedItem[labelKey]
          )}
          <Icon name="chevron" />
        </button>
      </RCDropdown>
    </div>
  );
};

export default Dropdown;
