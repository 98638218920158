import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import loginReducer from "reducers/login";
import advertiserReducer from "@client.reducers/Advertiser";
import advertisementReducer from "@client.reducers/advertisements";
import devicesReducer from "@client.reducers/devices";
import deviceRegistrationsReducer from "@client.reducers/deviceRegistrations";
import profileReducer from "reducers/profile";
import usersReducer from "@client.reducers/users";
import userLogsReducer from "@client.reducers/userLogs";
import systemLogsReducer from "@client.reducers/systemLogs";
import accountsReducer from "@client.reducers/accounts";
import invoiceReducer from "@client.reducers/Invoice";
import zonesReducer from "@client.reducers/zones";

const rootReducer = combineReducers({
  login: loginReducer,
  advertisement: advertisementReducer,
  advertiser: advertiserReducer,
  devices: devicesReducer,
  deviceRegistrations: deviceRegistrationsReducer,
  profile: profileReducer,
  users: usersReducer,
  userLogs: userLogsReducer,
  systemLogs: systemLogsReducer,
  accounts: accountsReducer,
  invoice: invoiceReducer,
  zones: zonesReducer,
});

export const getStore = (initialState = null) => {
  const optionalSettings: Record<string, unknown> = {};
  if (initialState) {
    optionalSettings.preloadedState = initialState;
  }
  const logger = createLogger({
    collapsed: true,
    diff: true
  });
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    ...optionalSettings
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof getStore>["dispatch"];
