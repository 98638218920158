import React from "react";

import { CreateAdvertisementFormProvider } from "./CreateAdvertisementFormContext";
import CreateAdvertisementContent from "./CreateAdvertisementContent";

import './styles.scss'

const CreateAdvertisementPage = () => {
  return (
    <CreateAdvertisementFormProvider>
      <CreateAdvertisementContent />
    </CreateAdvertisementFormProvider>
  )
}

export default CreateAdvertisementPage;
