import { IUpdateAccountDetailsFormValues } from "./useUpdateAccountDetailsForm";

export const getUpdateAccountFields = (
  accountId,
  updateAccountDetailsFormValues: IUpdateAccountDetailsFormValues,
) => {
  return {
    accountId,
    ownerUserId: updateAccountDetailsFormValues.ownerUserId,
    account: {
      companyName: updateAccountDetailsFormValues.companyName,
      companyNumber: updateAccountDetailsFormValues.companyNumber,
      vatNumber: updateAccountDetailsFormValues.vatNumber,
      companyUrl: updateAccountDetailsFormValues.companyUrl,
    },
    billingAddress: {
      addressLineOne: updateAccountDetailsFormValues.billingAddress.addressLineOne,
      city: updateAccountDetailsFormValues.billingAddress.city,
      postalCode: updateAccountDetailsFormValues.billingAddress.postalCode,
      country: updateAccountDetailsFormValues.billingAddress.country,
    },
    shippingAddress: {
      addressLineOne: updateAccountDetailsFormValues.shippingAddress.addressLineOne,
      city: updateAccountDetailsFormValues.shippingAddress.city,
      postalCode: updateAccountDetailsFormValues.shippingAddress.postalCode,
      country: updateAccountDetailsFormValues.shippingAddress.country,
    },
  }
}
