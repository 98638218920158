import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ServiceProvider from "@client.services/provider";
import { IZonePolygon } from "@client.types/zonePolygon";
import { IMapLocation } from "@client.types/zone";
import useToast from "@client.hooks/useToast";
import ZoneTypes from "@client.enums/zoneTypes";

import Input from "@client.core.components/Input";
import Button from "@client.core.components/Button";
import ToggleButton from "@client.core.components/ToggleButton";
import ColorPicker from "@client.core.components/ColorPicker";

import ZonesMap from "./ZonesMap";
import "./styles.scss"

interface IProps {
  selectedZone: IMapLocation | null,
  isCreatingMode?: boolean,
  isUpdatingMode?: boolean,
  onSuccess?: () => void,
}

const ZoneMapForm = ({
  selectedZone,
  isCreatingMode = false,
  isUpdatingMode = false,
  onSuccess,
}: IProps) => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();

  const [selectedPolygonData, setSelectedPolygonData] = useState<IZonePolygon | null>(null);

  const [isDrawingActive, setDrawingActive] = useState(false);
  const [name, setName] = useState('');
  const [color, setColor] = useState('');
  const [drawingManagerKey, setDrawingManagerKey] = useState(
    new Date().getTime().toString()
  );

  useEffect(() => {
    if (isUpdatingMode && selectedZone) {
      setName(selectedZone.zoneName || '')
      setColor(selectedZone.color || '')
    }
  }, [isUpdatingMode])

  useEffect(() => {
    if (selectedZone && selectedZone.polygon) {
      setSelectedPolygonData({
        color: selectedZone.color || '',
        data: selectedZone.polygon.map((data) => ({
          lat: data[1],
          lng: data[0],
        })),
      });
    } else {
      setSelectedPolygonData(null);
    }
  }, [selectedZone])

  const changeDrawingMode = useCallback(() => {
    if (isDrawingActive) {
      setSelectedPolygonData(null);
      setDrawingManagerKey(new Date().getTime().toString());
    }
    setDrawingActive((prev) => !prev);
  }, [isDrawingActive]);

  const handleCreateZoneClick = async () => {
    if (!onSuccess || !selectedPolygonData) {
      return;
    }

    const zoneFields = {
      zoneName: name,
      primePpvRate: 0,
      color,
      category: ZoneTypes.Custom,
      polygon: JSON.stringify(
        selectedPolygonData.data.map((d) => [d.lng, d.lat])
      ),
    }

    try {
      const response = await ServiceProvider.Zone.create(zoneFields);
      await showToastAfterRequest(response, "pages.Locations.components.ZonesTree.modal.create.success")
      setDrawingActive(false);
      setName('');
      setColor('');
      onSuccess();
    } catch (e) {
      console.log(e)
    }
  }

  const handleUpdateZoneClick = async () => {
    if (!onSuccess || !selectedPolygonData || !selectedZone) {
      return;
    }

    const zoneFields = {
      zoneName: name,
      primePpvRate: 0,
      color,
      category: ZoneTypes.Custom,
      polygon: JSON.stringify(
        selectedPolygonData.data.map((d) => [d.lng, d.lat])
      ),
    }

    try {
      const response = await ServiceProvider.Zone.update(zoneFields, selectedZone.Id);
      await showToastAfterRequest(response, "pages.Locations.components.ZonesTree.modal.update.success")
      setDrawingActive(false);
      setName('');
      setColor('');
      onSuccess();
    } catch (e) {
      console.log(e)
    }
  }

  const handleCancelClick = () => {
    setDrawingActive(false);
    setSelectedPolygonData(null);
    setName('');
    setDrawingManagerKey(new Date().getTime().toString());
  }

  return (
    <div className="create-zone-form">
      <ZonesMap
        selectedPolygonData={selectedPolygonData ? [selectedPolygonData] : []}
        isDrawingActive={isDrawingActive}
        drawingManagerKey={drawingManagerKey}
        onDrawingManagerPolygonComplete={(coordinates) => {
          setSelectedPolygonData({
            color: selectedPolygonData?.color || "red",
            data: coordinates,
          });
        }}
      >
        {(isCreatingMode || isUpdatingMode) && (
          <div className="drawing-button">
            <ToggleButton
              text={t(
                isDrawingActive
                  ? "pages.Locations.stopDrawing"
                  : "pages.Locations.startDrawing"
              )}
              value={isDrawingActive}
              onChange={changeDrawingMode}
            />
          </div>
        )}
      </ZonesMap>
      {(isCreatingMode || isUpdatingMode) && (
        <div className="management-controls">
          <Input
            value={name}
            onChange={setName}
            label={t("pages.Zones.zoneName")}
          />
          <ColorPicker
            hexColor={color}
            onChange={(v) => {
              setColor(v);
              setSelectedPolygonData((prev) => prev ? ({
                ...prev,
                color: v,
              }) : prev)
            }}
          />
          <Button
            text={isCreatingMode ? t("pages.Locations.create") : t("pages.Locations.update")}
            disabled={!name}
            onClick={isCreatingMode ? handleCreateZoneClick : handleUpdateZoneClick}
          />
          <Button
            text={t("pages.Locations.cancel")}
            disabled={!name}
            onClick={handleCancelClick}
          />
        </div>
      )}
    </div>
  );
};

export default ZoneMapForm;
