import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Title from "@client.components/Title";
import Button from "@client.components/core/Button";
import Drawer from "@client.components/Drawer";

import UserInformation from "./UserInformation";
import UsersList from "./UsersList";
import CreateUserForm from "./CreateUserForm";
import useCreateUserForm from "./useCreateUserForm";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAsync, selectSelectedUser, selectUsersData, selectUsersLoading, setSelectedUser } from "@client.reducers/users";
import { AppDispatch } from "@client.store";
import { useNavigate, useParams } from "react-router-dom";
import { userInfo } from "os";

const ITEMS_PER_PAGE = 10;

const UsersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
 
  const users = useSelector(selectUsersData);
  const usersLoading = useSelector(selectUsersLoading);
  const selectedUser = useSelector(selectSelectedUser);
 
  const [currentPage, setCurrentPage] = useState(1);
  const [showCreateUserDrawer, setShowCreateUserDrawer] = useState(false);

  const getUsersFilter = {
    offset: (currentPage - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  }
 
  const {
    register,
    errors,
    handleSubmitCreateUserForm,
  } = useCreateUserForm(setShowCreateUserDrawer, {
    pagination: getUsersFilter
  });
 
  useEffect(() => {
    if (!users.length) {
      dispatch(getAllUsersAsync({
        pagination: getUsersFilter
      }))
    }
  }, [dispatch]);

  useEffect(() => {
    if (id && users) {
      dispatch(setSelectedUser(users.find((user) => user.Id === id)));
    }
  }, [id, users, dispatch]);

  return (
    <div className="users-page">
      <Title text="components.NavigationBar.Users">
        <Button
          icon="plus"
          text={t("pages.Users.createNewUser")}
          onClick={() => setShowCreateUserDrawer(true)}
        />
      </Title>

      <div className="users-content">
        <UsersList
          users={users}
          usersLoading={usersLoading}
          selectedUser={selectedUser}
        />
        <UserInformation
          selectedUser={selectedUser}
          getUsersVariables={{
            pagination: getUsersFilter
          }}
        />
      </div>
      <Drawer
        isOpen={showCreateUserDrawer}
        onClose={() => setShowCreateUserDrawer(false)}
        title={t("pages.Users.createNewUser")}
        footer={
          <Button
            text={t("components.Advertisers.create")}
            onClick={handleSubmitCreateUserForm}
          />
        }
      >
        <CreateUserForm register={register} errors={errors} />
      </Drawer>
    </div>
  );
};

export default UsersPage;
