import React, { LegacyRef, forwardRef } from "react";

import { AgGridReact } from "ag-grid-react";
import Loader from "@client.core.components/Loader";
import "ag-grid-community/styles/ag-grid.css";
import variables from "./styles.scss";
import DataTablePagination from "./DataTablePagination";
import "./styles.scss";

interface IProps {
  columns: any[];
  data: any[];
  isLoading: boolean;
  onSelectionChanged?: any;
  pagination?: any;
  isMinified?: boolean;
}

const DataTable = forwardRef<AgGridReact<any>, IProps>(
  ({ columns, data, isLoading, onSelectionChanged, pagination, isMinified }, ref) => {
    const headerHeight = parseInt(variables.cellHeight.replace("px", ""));

    return (
      <div className="data-table ag-theme-alpine-light">
        {isLoading && (
          <div className="data-table-loader">
            <Loader loading={true} />
          </div>
        )}
        <AgGridReact
          ref={ref}
          rowData={data}
          columnDefs={columns.map((col) => ({
            ...col,
          }))}
          gridOptions={{
            onCellClicked: onSelectionChanged,
            headerHeight: headerHeight,
            rowSelection: onSelectionChanged ? "single" : undefined,
            getRowHeight: () => {
              return headerHeight;
            },
          }}
          defaultColDef={{
            sortable: true,
            flex: 1,
            headerClass: isMinified ? "data-table-header-min" : "data-table-header",
            cellClass: isMinified ? "data-table-cell-min" : "data-table-cell",
          }}
        />
        {pagination && <DataTablePagination {...pagination} />}
      </div>
    );
  }
);

DataTable.displayName = "DataTable";

export default DataTable;
