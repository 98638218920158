import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectProfileData } from "@client.reducers/profile";
import * as Popover from "@radix-ui/react-popover";
import Pages from "@client.enums/pages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRightFromBracket,
  faUserGroup,
  faBuilding,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { MediaFile } from "@client.components/MediaFile/MediaFile_TopBar";
import ProfileMenuKeys from "@client.enums/profileMenuKeys";
import ProfileMenuDrawer from "@client.components/ProfileMenu/ProfileMenuDrawer";

import "./styles.scss";
import AccountEntity from "@client.enums/accountEntity";

const ProfileMenu = () => {
  const { t } = useTranslation();

  const profile = useSelector(selectProfileData);

  const links = [
    {
      url: `${Pages.Users}/${profile.userId}/balance`,
      name: "Balance",
      icon: faChartLine,
      key: ProfileMenuKeys.MyAccount,
    },
    {
      url: `${Pages.Users}/${profile.userId}/general`,
      name: "My Profile",
      icon: faUser,
      key: ProfileMenuKeys.MyAccount,
    },
    {
      url: `${Pages.Accounts}/${profile.accountId}/details`,
      name: t("components.ProfileMenu.myaccount"),
      icon: faBuilding,
      key: ProfileMenuKeys.MyAccount,
    },
    {
      url: "#",
      name: "Send Invitations",
      icon: faUserGroup,
      key: ProfileMenuKeys.InviteUser,
    },
  ];

  const [role, setRole] = useState('');
  const [open, setOpen] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    const renderRoles = () => {
      switch (profile.entity) {
      case AccountEntity.Platform:
        return "Platform";
      case AccountEntity.Reseller:
        return "Reseller";
      case AccountEntity.Advertiser:
        return "Advertiser";
      case AccountEntity.Partner:
        return "Partner";
      default:
        return "Unknown";
      }
    };
    setRole(renderRoles());
  }, [profile.entity]);

  const handleClosePopover = () => {
    setOpen(false);
  };

  const handleOpenDrawer = (key) => {
    if (key === ProfileMenuKeys.InviteUser) setIsOpenDrawer(true);
  };

  const renderCustomContent = () => {
    return (
      <MediaFile
        src={profile.avatarUrl}
        className="avatar"
        fallbackElement={() => (
          <div className="profile-menu">
            {profile.firstName.charAt(0)}
            {profile.lastName.charAt(0)}
          </div>
        )}
      />
    );
  };

  return (
    <div className="profile-menu-container">
      <Popover.Root open={open} onOpenChange={(newOpen) => setOpen(newOpen)}>
        <Popover.Trigger asChild>
          <button className="popover-button">{renderCustomContent()}</button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="profile-popover-content"
            sideOffset={10}
            alignOffset={110}
          >
            <div className="profile-menu-popover-content">
              <div className="profile-information">
                {renderCustomContent()}
                <div className="profile-info">
                  <div className="profile-full-name">{`${profile.firstName} ${profile.lastName}`}</div>
                  <div>{profile.accountName}</div>
                  <div>{role}</div>
                </div>
              </div>
              <div className="profile-pages">
                {links.map((item) => (
                  <NavLink
                    key={item.key}
                    to={item.url}
                    title={item.name}
                    onClick={() => {
                      handleClosePopover();
                      handleOpenDrawer(item.key);
                    }}
                  >
                    <FontAwesomeIcon icon={item.icon} />
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="divider" />
              <div className="profile-pages profile-logout">
                <NavLink
                  title={t("components.ProfileMenu.logout")}
                  to={Pages.Logout}
                >
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  <span>{t("components.ProfileMenu.logout")}</span>
                </NavLink>
              </div>
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <ProfileMenuDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
      />
    </div>
  );
};

export default ProfileMenu;
