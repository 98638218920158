import React, { useEffect, useState } from "react";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import { IZone } from "@client.types/zone";
import MinimizeIcon from "./MinimizeIcon";
import { getZoneTypeNameByCode } from "@client.utils/utils";
import LabelValue from "@client.components/LabelValue";

const ZonesInfo = ({
  zones,
  onClose,
}: {
  zones: IZone[];
  onClose: VoidFunction;
}) => {
  const [selectedZone, setSelectedZone] = useState<IZone>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (zones.length === 1) {
      setSelectedZone(zones[0]);
    }
  }, [zones]);

  return (
    <div className="info-table">
      <div className="minimize-icon-absolute">
        <MinimizeIcon onClick={onClose} />
      </div>
      <div className="info-header">
        <div className="info">
          <span>Zones Info</span>
        </div>
      </div>
      <div className="info-table-content">
        {zones.length > 1 ? (
          <ul className="info-table-list">
            {zones.map((zone) => (
              <li
                key={zone.Id}
                className={zone.Id === selectedZone?.Id ? "active" : undefined}
                onClick={() => setSelectedZone(zone)}
              >
                {zone.zoneName}
              </li>
            ))}
          </ul>) : false
        }
        {(!loading && !selectedZone) ? (
          <div className="info-table-nodata-wrapper">
            <NoDataPlaceholder />
          </div>) : (
          <div className="info-table-grid-wrapper">
            <div className="info-table-grid">
              {[
                { title: "Category", value: selectedZone ? getZoneTypeNameByCode(selectedZone.category) : "N/A" },
                { title: "PPV Price", value: selectedZone ? `${selectedZone.standardPpvRate}` : "N/A" },
                { title: "Prime Price", value: selectedZone ? `${selectedZone.primePpvRate}` : "N/A" },
              ].map((v) => (
                <LabelValue key={v.title} label={v.title} value={v.value || "N/A"} isLoading={loading} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZonesInfo;
