import React from "react";

import ReactModernDrawer from "react-modern-drawer";

import Icon from "@client.core.components/Icon";

import "react-modern-drawer/dist/index.css";
import "./styles.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const Drawer = ({ isOpen, onClose, title, children, footer }: IProps) => {
  return (
    <ReactModernDrawer
      open={isOpen}
      onClose={onClose}
      direction="right"
      className="drawer-container"
    >
      {title ? (
        <div className="drawer-title">
          {title}
          <Icon
            name="close"
            className="modal-component-close-button"
            onClick={onClose}
          />
        </div>
      ) : (
        false
      )}
      <div className="drawer-content-wrapper">
        <div className="drawer-content">{children}</div>
        {footer ? <div className="drawer-footer">{footer}</div> : false}
      </div>
    </ReactModernDrawer>
  );
};

export default Drawer;
