import React from "react";

import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from "react-hook-form";

import { useTranslation } from "react-i18next";
import TextInput from "@client.components/form/TextInput";
import FormSelect from "@client.components/form/FormSelect";
import AccountEntity from "@client.enums/accountEntity";
import { IUpdateAccountDetailsFormValues } from "./useUpdateAccountDetailsForm";
import FormPlacesInput from "@client.components/form/FormPlacesInput";
import FormCheckbox from "@client.components/form/FormCheckbox";
import { IAddress } from "@client.types/address";
import { IUser } from "@client.types/user";

interface IProps {
  register: UseFormRegister<IUpdateAccountDetailsFormValues>;
  control: Control<IUpdateAccountDetailsFormValues>;
  getValues: UseFormGetValues<IUpdateAccountDetailsFormValues>;
  setValue: UseFormSetValue<IUpdateAccountDetailsFormValues>;
  trigger: UseFormTrigger<IUpdateAccountDetailsFormValues>;
  errors: FieldErrors<IUpdateAccountDetailsFormValues>;
  shippingAddress: IAddress;
  billingAddress: IAddress;
  accountUsers: IUser[];
}

const UpdateAccountDetailsForm = ({ 
  register, 
  control, 
  getValues,
  setValue,
  trigger,
  errors,
  shippingAddress,
  billingAddress,
  accountUsers
}: IProps) => {
  const { t } = useTranslation();

  const accountOwnerOptions = accountUsers.map((user) => ({
    value: user.Id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  return (
    <div className="account-form">
      <FormSelect 
        name="ownerUserId"
        label="Account Owner"
        control={control}
        options={accountOwnerOptions}
        error={errors.ownerUserId?.message}
      />
      <TextInput
        id="companyName"
        name="companyName"
        label={t("pages.Accounts.tabs.Details.companyName")}
        placeholder={t("pages.Accounts.tabs.Details.companyName")}
        register={register}
        error={errors.companyName}
      />
      <TextInput
        id="companyNumber"
        name="companyNumber"
        label={t("pages.Accounts.tabs.Details.companyNumber")}
        placeholder={t("pages.Accounts.tabs.Details.companyNumber")}
        register={register}
        error={errors.companyNumber}
      />
      <TextInput
        id="vatNumber"
        name="vatNumber"
        label={t("pages.Accounts.tabs.Details.vatNumber")}
        placeholder={t("pages.Accounts.tabs.Details.vatNumber")}
        register={register}
        error={errors.vatNumber}
      />
      <TextInput
        id="companyUrl"
        name="companyUrl"
        label="Company URL"
        placeholder="Company URL"
        register={register}
        error={errors.companyUrl}
      />
      <FormPlacesInput
        value={shippingAddress}
        setValue={setValue}
        trigger={trigger}
        addressObjectName="shippingAddress"
        label="Shipping Address"
        error={errors.shippingAddress?.addressLineOne?.message}
      />
      <FormCheckbox
        id="sameAddress"
        name="sameAddress"
        label="Billing Address is the same"
        control={control}
        error={errors.sameAddress}
      />
      <FormPlacesInput
        value={billingAddress}
        setValue={setValue}
        trigger={trigger}
        addressObjectName="billingAddress"
        label="Billing Address"
        disabled={getValues("sameAddress")}
        error={errors.billingAddress?.addressLineOne?.message}
      />
    </div>
  );
};

export default UpdateAccountDetailsForm;
