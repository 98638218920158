import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceProvider from "@client.services/provider";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import { IDevice } from "@client.types/device";
import MinimizeIcon from "./MinimizeIcon";
import LabelValue from "@client.components/LabelValue";
import Card from "@client.components/Card";

const DevicesInfo = ({
  devices,
  onClose,
}: {
  devices: IDevice[];
  onClose: VoidFunction;
}) => {
  const { t } = useTranslation();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState<IDevice>();

  const deviceDetailsFields = [
    { title: "Machine ID", value: device?.machineId },
    { title: "Device Name", value: device?.deviceName },
    { title: "Timezone", value: device?.timezone },
    {
      title: "Hardware Version",
      value: device?.hardwareVersion,
    },
    {
      title: "Software Version",
      value: device?.softwareVersion,
    },
  ]

  const deviceTelemetryFields = [
    { title: "Latitude", value: device? `${device.latitude}` : "N/A" },
    { title: "Longitude", value: device? `${device.longitude}` : "N/A" },
    { title: "Up Time", value: "N/A" },
    {
      title: "Screen temperature",
      value: "N/A",
    },
    {
      title: "Battery A",
      value: "N/A",
    },
    {
      title: "Battery B",
      value: "N/A",
    },
  ]

  useEffect(() => {
    if (devices.length === 1) {
      setSelectedDeviceId(devices[0].Id);
    }
  }, [devices]);

  useEffect(() => {
    if (selectedDeviceId !== undefined) {
      setLoading(true);
      ServiceProvider.Device.getDeviceById(selectedDeviceId)
        .then((resp) => {
          setDevice(resp?.data.device);
        })
        .finally(() => {
          setTimeout(() => setLoading(false), 200);
        });
    }
  }, [selectedDeviceId]);

  return (
    <div className="info-table">
      <div className="minimize-icon-absolute">
        <MinimizeIcon onClick={onClose} />
      </div>
      <div className="info-header">
        <div className="info">
          <span>{t("components.Dashboard.MapFilter.Header")}</span>
          <div className="statuses">
            <div className="status-label">
              <span className="status-online"></span>
              {t("components.Dashboard.MapFilter.online", {
                val: devices.filter((d) => d.isOnline).length,
              })}
            </div>
            <div className="status-label">
              <span className="status-offline"></span>
              {t("components.Dashboard.MapFilter.offline", {
                val: devices.filter((d) => !d.isOnline).length,
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="info-table-content">
        {devices.length > 1 ? (
          <ul className="info-table-list">
            {devices.map((d) => (
              <li
                key={d.Id}
                className={d.Id === selectedDeviceId ? "active" : undefined}
                onClick={() => setSelectedDeviceId(d.Id)}
              >
                <div className="status-label">
                  <span
                    className={d.isOnline ? "status-online" : "status-offline"}
                  ></span>
                </div>
                {d.deviceName}
              </li>
            ))}
          </ul>
        ) : false}
        {(!loading && !device) ? (
          <div className="info-table-nodata-wrapper">
            <NoDataPlaceholder />
          </div>) : (
          <div className="info-table-grid-wrapper">
            <div className="device-info-grid">
              <Card title="Details" className="device-details-card">
                {deviceDetailsFields.map((v) => (
                  <LabelValue key={v.title} label={v.title} value={v.value || "N/A"} isLoading={loading} />
                ))}
              </Card>
              <Card title="Telemetry">
                <div className="device-telemetry-card">
                  {deviceTelemetryFields.map((v) => (
                    <LabelValue key={v.title} label={v.title} value={v.value || "N/A"} isLoading={loading} />
                  ))}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevicesInfo;
