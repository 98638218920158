import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import { IUser } from "@client.types/user";
import {
  ICreateUserFields,
  ICreateUserResponse,
} from "@client.types/queries/createUser";
import {
  IUpdateUserFields,
  IUpdateUserResponse,
} from "@client.types/queries/updateUser";
import {
  IGetUsersResponse,
  IGetUsersVariables,
} from "@client.types/queries/getUsers";
import {
  ICreateUserRegisterResponse,
  ICreateUserRegisterVariables,
} from "@client.types/queries/createUserMutation";

interface IUsersSlice {
  data: IUser[];
  error: string | null;
  loading: boolean;
  selectedUser: IUser | null;
}

const initialState: IUsersSlice = {
  data: [],
  error: null,
  loading: false,
  selectedUser: null,
};

export const getAllUsersAsync = createAsyncThunk<
  IGetUsersResponse,
  IGetUsersVariables
>("users/getAllUsers", async (variables) => {
  return await ServiceProvider.User.getUsers(variables);
});

export const createUserAsync = createAsyncThunk<
  ICreateUserResponse,
  ICreateUserFields
>(
  "users/create",
  async ({ createUserVariables, getUsersVariables }, thunkAPI) => {
    const response = await ServiceProvider.User.createUser(createUserVariables);
    if (!response.errors) {
      thunkAPI.dispatch(getAllUsersAsync(getUsersVariables));
    }
    return response;
  }
);

export const createUserRegisterAsync = createAsyncThunk<
  ICreateUserRegisterResponse,
  ICreateUserRegisterVariables
>("users/register", async (data: ICreateUserRegisterVariables) => {
  try {
    const response = await ServiceProvider.User.userRegistration(data);
    return response;
  } catch (error) {
    throw getGraphqlResponseError(error);
  }
});

export const updateUserAsync = createAsyncThunk<
  IUpdateUserResponse,
  IUpdateUserFields
>(
  "users/update",
  async ({ updateUserVariables, getUsersVariables }, thunkAPI) => {
    const response = await ServiceProvider.User.updateUser(updateUserVariables);
    if (!response.errors) {
      thunkAPI.dispatch(getAllUsersAsync(getUsersVariables));
    }
    return response;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = get(action, "payload", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        const response = get(action, "payload.data.users");
        state.data = response.results;
        state.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllUsersAsync.rejected, (state, action) => {
        state.data = [];
        state.error = getResponseError(action);
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        const updatedUser = get(action, "payload.data.updateUser.user", null);
        if (updatedUser) {
          state.selectedUser = updatedUser;
        }
      });
  },
});

export const { setSelectedUser } = usersSlice.actions;

export const selectUsersData = (state) => state.users.data;
export const selectUsersLoading = (state) => state.users.loading;
export const selectSelectedUser = (state) => state.users.selectedUser;

export default usersSlice.reducer;
