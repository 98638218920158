import React from "react";

import "./styles.scss";
import { IOption } from "@client.types/option";
import classNames from "classnames";

interface IProps {
  groupName: string;
  items: IOption[];
  value: string | null;
  onChange: (value: string) => void;
  className?: string;
}

const RadioGroup = ({ items, groupName, onChange, value, className }: IProps) => {
  return (
    <div className={classNames("radio-group", className)}>
      {items.map((item) => {
        return (
          <label key={item.value}>
            <input
              type="radio"
              className="input-radio"
              name={groupName}
              value={item.value}
              checked={item.value === value}
              onChange={() => onChange(item.value)}
            />
            {item.label}
          </label>
        );
      })}
    </div>
  );
};

export default RadioGroup;
