import React from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import config from "@client.config";
import classNames from "classnames";

import useToast from "@client.hooks/useToast";

import { IFile } from "@client.types/file";

import SvgIcon from "@client.core.components/SvgIcon";

import "./styles.scss";
import FileUploaderPlaceholder from "@client.components/FileUploaderPlaceholder";

interface IProps {
  onChange: (file: IFile) => void,
  placeholderUrl?: string | undefined,
  isButton?: boolean,
  className?: string,
}

const Uploader = ({ onChange, placeholderUrl, isButton = false, className }: IProps) => {
  const maxSize = config.maxFileSizeInMb ? +config.maxFileSizeInMb * 1024 * 1024 : 0;
  const minSize = 100 * 1024; // 100KB minimum size
  const { t } = useTranslation();
  const { showToastWarning } = useToast();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles: IFile[], rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        const rejectedFile = rejectedFiles[0];
        if (rejectedFile.file.size > maxSize) {
          showToastWarning("components.Uploader.fileSizeExceeds", {
            value: config.maxFileSizeInMb,
          });
        }
      } else {
        const file = acceptedFiles[0];
        if (file.size < minSize) {
          showToastWarning("components.Uploader.fileSizeBigger", {
            value: "500",
          });
          return;
        }

        if (file.type.startsWith("image/")) {
          const img = new Image();
          img.src = URL.createObjectURL(file as unknown as Blob);
          img.onload = () => {
            if (img.width < 1280 || img.height < 720) {
              showToastWarning("components.Uploader.imageResolution", {
                value: "1280x720",
              });
              return;
            } else {
              onChange(acceptedFiles[0]);
            }
          };
        } else {
          onChange(acceptedFiles[0]);
        }
      }
    },
    maxSize: maxSize,
  });

  if (isButton) {
    return (
      <button
        {...getRootProps({
          className: classNames("dropzone button", className),
        })}
      >
        <input {...getInputProps()} />
        <SvgIcon name="plus" />
        <span>{t("pages.MediaLibrary.Assets.Upload")}</span>
      </button>
    );
  } else {
    return (
      <section className="uploader">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <FileUploaderPlaceholder
            url={placeholderUrl}
            className="uploader-rectangle"
            description={t("components.Uploader.maxSize", {
              value: config.maxFileSizeInMb,
            })}
          />
        </div>
      </section>
    );
  }
};

export default Uploader;
