import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Step,
  useCreateAdvertisementForm,
} from "./CreateAdvertisementFormContext";
import Button from "@client.components/core/Button";
import ServiceProvider from "@client.services/provider";
import getCreateAdvertisementFields from "./getCreateAdvertisementFields";
import useToast from "@client.hooks/useToast";
import Pages from "@client.enums/pages";
import { convertDateAndTime } from "@client.utils/utils";
import { Panel } from "@client.components/core/Panel";

const CreateAdvertisementSummary = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();

  const {
    createAdvertisementFormMethods,
    selectedImageUrl,
    currentStep,
    draftAdvertisement,
    isFormFilled,
    accounts,
    advertisementTargets,
    selectedLocationsList,
  } = useCreateAdvertisementForm();

  const {
    title,
    advertiserId,
    startDate,
    endDate,
    targets,
    consecutiveSlots,
    budgetAmount,
    mediaUrl,
  } = createAdvertisementFormMethods.getValues();

  const advertisementDetails = [
    {
      title: t("Pages.CreateAdvertisement.advertisementName"),
      value: title || "N/A",
    },
    {
      title: "Advertiser",
      value: accounts.find((account) => account.Id === advertiserId)?.companyName || "N/A",
    },
    {
      title: t("Pages.CreateAdvertisement.startDate"),
      value: startDate ? convertDateAndTime(startDate) : "N/A",
    },
    {
      title: t("Pages.CreateAdvertisement.endDate"),
      value: endDate ? convertDateAndTime(endDate) : "N/A",
    },
    {
      title: "Targets",
      value: advertisementTargets
        .filter((target) => targets.includes(target.id))
        .map((target) => target.name).join(", ") || "N/A",
    },
    {
      title: t("Pages.CreateAdvertisement.location"),
      value: selectedLocationsList.length > 0 ? selectedLocationsList.map((location) => location.title).join(", ") : "N/A",
    },
    {
      title: t("Pages.CreateAdvertisement.priceTotal"),
      value: `$${budgetAmount || 0}`,
    },
    {
      title: t("Pages.CreateAdvertisement.durationTime"),
      value: consecutiveSlots,
    },
  ];

  const handleLaunchAdvertisement = async (isDraft: boolean = false) => {
    const createAdvertisementFormValues =
      createAdvertisementFormMethods.getValues();
    try {
      const advertisementFields = getCreateAdvertisementFields(
        createAdvertisementFormValues
      );
      if (draftAdvertisement) {
        const response =
          await ServiceProvider.Advertisement.updateAdvertisement({
            fields: {
              ...advertisementFields,
              isDraft: isDraft || false,
            },
            id: draftAdvertisement.Id,
          });
        if (response.data.updateAdvertisement.advertisement) {
          navigate(`${Pages.Advertisements}`);
        } else {
          showErrorToast("Can't create advertisement");
        }
      } else {
        const response =
          await ServiceProvider.Advertisement.createAdvertisement({
            fields: {
              ...advertisementFields,
              isDraft: isDraft || false,
            },
          });
        if (response.data.createAdvertisement.advertisement) {
          navigate(`${Pages.Advertisements}`);
        } else {
          showErrorToast("Can't create advertisement");
        }
      }
    } catch (e) {
      console.log(e);
      const errorMessage =
        e instanceof Error ? e.message : "Can't create advertisement";
      showErrorToast(errorMessage);
    }
  };

  if (isFormFilled) {
    return null;
  }

  return (
    <div className="create-advertisement-summary">
      <Panel
        title={title}
        data={advertisementDetails}
        image={selectedImageUrl || mediaUrl}
        isShowImage
      >
        <div className="advertisement-buttons">
          {currentStep !== Step.GeneralInformation ? (
            <Button
              text={t("Pages.CreateAdvertisement.saveDraft")}
              onClick={() => handleLaunchAdvertisement(true)}
            />
          ) : (
            false
          )}
          {currentStep === Step.Summary ? (
            <Button
              text={t("Pages.CreateAdvertisement.scheduledAdvertisement")}
              onClick={() => handleLaunchAdvertisement()}
            />
          ) : (
            false
          )}
        </div>
      </Panel>
    </div>
  );
};

export default CreateAdvertisementSummary;
