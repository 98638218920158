import React from "react";

import CircleIcon from "@client.components/CircleIcon";

import "./styles.scss";

const AdvertiserCircleName = ({ advertiser }) => {
  return (
    advertiser && (
      <div className="advertiser-circle-name">
        <CircleIcon name={advertiser.fullName} index={advertiser.index || 0} />
        {advertiser.fullName}
      </div>
    )
  );
};

export default React.memo(AdvertiserCircleName);
