import React from "react";

const AccountsEntitiesLegend = () => {
  return (
    <div className="accounts-entities-legend">
      <div className="account-entity-indicator entity-platform" />
      <span>Platform</span>
      <div className="account-entity-indicator entity-reseller" />
      <span>Reseller</span>
      <div className="account-entity-indicator entity-advertiser" />
      <span>Advertiser</span>
      <div className="account-entity-indicator entity-partner" />
      <span>Partner</span>
    </div>
  );
};

export default AccountsEntitiesLegend;
