import React, { Dispatch, SetStateAction, useEffect } from "react";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Input from "@client.core.components/Input";
import "./styles.scss";

interface IProps {
  location: string;
  label: string;
  setLocation?: (location: any, address: string) => void;
  onChange: Dispatch<SetStateAction<string>>;
}

const PlacesInput = ({ 
  location,
  label,
  setLocation,
  onChange,
}: IProps) => {
  const {
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    onChange(address);
    clearSuggestions();

    if (setLocation) {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setLocation({ lat, lng }, address);
    }
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      clearSuggestions();
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  });

  return (
    <div className="places-autocomplete">
      <Input
        value={location}
        label={label}
        onChange={(v) => {
          setValue(v);
          onChange(v);
        }}
      />
      {data.length > 0 && (
        <ul className="suggestions-list">
          {data.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onClick={() => handleSelect(suggestion.description)}
              className="suggestion-item"
            >
              {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PlacesInput;
