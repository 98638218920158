import React, { useState } from "react";

import RadioGroup from "@client.components/RadioGroup";

import NewCreditCardForm from "./NewCreditCardForm";
import Button from "@client.components/core/Button";
import Slider from "@client.components/Slider";

const CREDIT_CARDS = [
  { value: '1', label: "**** **** **** 1234" },
  { value: '2', label: "**** **** **** 2345" },
  { value: '3', label: "Another Card" },
]

const DepositFunds = () => {
  const [selectedCreditCard, setSelectedCreditCard] = useState<string | null>(null);
  const [depositAmount, setDepositAmount] = useState('100');

  return (
    <div className="deposit-funds-container">
      <div>
        <strong>Choose Credit Card</strong>
        <RadioGroup 
          groupName="credit-card"
          items={CREDIT_CARDS}
          value={selectedCreditCard}
          onChange={setSelectedCreditCard}
          className="credit-card-options"
        />
      </div>
      {selectedCreditCard === "3" ? (
        <NewCreditCardForm />
      ) : false}
      {selectedCreditCard ? (
        <Slider
          min={1} 
          max={5000} 
          value={depositAmount} 
          onChange={setDepositAmount}
          isMoney
        />
      ) : false }
      <Button 
        text="Deposit"
      />
    </div>
  );
};

export default DepositFunds;
