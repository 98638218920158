import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "@client.core.components/Loader";
import DataTable from "@client.components/DataTable";

import { getColumns } from "./getColumns";
import { IDevice } from "@client.types/device";
import { useDispatch, useSelector } from "react-redux";
import {
  getDevicesAsync,
  selectDevicesData,
  selectDevicesLoading,
  selectDevicesCount,
} from "@client.reducers/devices";
import { AppDispatch } from "@client.store";
import Input from "@client.components/core/Input";
import DateRangePicker from "@client.components/DateRangePicker";
import Dropdown from "@client.components/core/Dropdown";
import DeviceStatuses from "@client.enums/deviceStatuses";
import moment from "moment";
import { TOOLTIP_ID } from "@client.constants";

const ITEMS_PER_PAGE = 10;

interface IProps {
  onSelectDevice: (device: IDevice | null) => void;
}

const DevicesList = ({ onSelectDevice }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const statuses = [
    {
      value: '',
      label: t("pages.Devices.status.all"),
    },
    {
      value: DeviceStatuses.Online,
      label: t("pages.Devices.status.online"),
    },
    {
      value: DeviceStatuses.Offline,
      label: t("pages.Devices.status.offline"),
    },
  ];

  const devices = useSelector(selectDevicesData);
  const devicesCount = useSelector(selectDevicesCount);
  const devicesLoading = useSelector(selectDevicesLoading);

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    search: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  useEffect(() => {
    dispatch(
      getDevicesAsync({
        limit: ITEMS_PER_PAGE,
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        startDate: filter.startDate,
        endDate: filter.endDate,
        search: filter.search,
      })
    );
  }, [dispatch, filter, currentPage]);

  const tableColumns = getColumns();

  const handleRowClick = (rowData: any) => {
    onSelectDevice(rowData?.data || null);
  };

  const handleFilterChange = (values) => {
    setFilter((prev) => ({
      ...prev,
      ...values,
    }));
  };

  return (
    <div className="devices-list-container">
      <div className="filters-container">
        <Input
          label="Search"
          value={filter.search}
          onChange={(v) => {
            handleFilterChange({
              search: v,
            });
          }}
          data-tooltip-id={TOOLTIP_ID}
          data-tooltip-content={
            "Search Parameters\n- Name"
          }
        />
        <DateRangePicker
          dateFrom={filter.startDate}
          dateTo={filter.endDate}
          label={t("pages.Devices.dateRange")}
          onChange={([startDate, endDate]) => {
            handleFilterChange({
              startDate: startDate ? moment(startDate).toISOString() : "",
              endDate: endDate ? moment(endDate).toISOString() : "",
            });
          }}
        />
        <Dropdown
          items={statuses}
          defaultValue={filter.status}
          label={t("pages.Devices.status")}
          valueKey="value"
          labelKey="label"
          onSelect={(v) => {
            handleFilterChange({
              status: v,
            });
          }}
        />
      </div>
      <div className="table-wrapper">
        <DataTable
          columns={tableColumns}
          data={devices}
          isLoading={devicesLoading}
          onSelectionChanged={handleRowClick}
          pagination={{
            total: devicesCount,
            itemsPerPage: ITEMS_PER_PAGE,
            currentPage,
            onChange: setCurrentPage,
          }}
        />
      </div>
    </div>
  );
};

export default DevicesList;
