import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsAsync,
  selectAccountsData,
  selectAccountsHierarchy,
  selectAccountsLoading,
} from "@client.reducers/accounts";
import { AppDispatch } from "@client.store";

import { IAccount } from "@client.types/account";

import Title from "@client.components/Title";
import Drawer from "@client.components/Drawer";
import Button from "@client.components/core/Button";

import AccountsList from "./AccountsList";
import AccountInformation from "./AccountInformation";
import CreateAccountForm from "./CreateAccountForm";
import useCreateAccountForm from "./useCreateAccountForm";
import "./styles.scss";
import { useParams } from "react-router-dom";

const AccountsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  const accountsData = useSelector(selectAccountsData);
  const accountsTree: IAccount[] = useSelector(selectAccountsHierarchy);
  const accountsLoading = useSelector(selectAccountsLoading);

  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [showCreateAccountDrawer, setShowCreateAccountDrawer] = useState(false);

  const {
    register,
    control,
    getValues,
    setValue,
    trigger,
    errors,
    resetForm,
    shippingAddress,
    billingAddress,
    handleSubmitCreateAccountForm,
  } = useCreateAccountForm(setShowCreateAccountDrawer);

  const handleCloseCreateAccountDrawer = () => {
    resetForm();
    setShowCreateAccountDrawer(false);
  };

  useEffect(() => {
    if (!accountsTree.length) {
      dispatch(getAccountsAsync());
    }
  }, [dispatch]);

  useEffect(() => {
    if (accountsData && selectedAccount) {
      setSelectedAccount(
        accountsData.find((account) => account.Id === selectedAccount.Id) ||
          null
      );
    }
  }, [accountsData]);

  useEffect(() => {
    if (id && accountsData) {
      setSelectedAccount(
        accountsData.find((account) => account.Id === id) || null
      );
    }
  }, [id, accountsData, dispatch]);

  useEffect(() => {
    setValue("parentAccountId", selectedAccount?.Id || "");
  }, [selectedAccount]);

  return (
    <div className="accounts-page">
      <Title text="pages.AccountsPage.title">
        <Button
          icon="plus"
          text={t("components.Advertisers.createAccount")}
          onClick={() => setShowCreateAccountDrawer(true)}
        />
      </Title>

      <div className="accounts-content">
        <AccountsList
          accounts={accountsTree || []}
          selectedAccount={selectedAccount}
          onSelectAccount={setSelectedAccount}
          accountsLoading={accountsLoading}
        />
        <AccountInformation selectedAccount={selectedAccount} />
      </div>
      <Drawer
        isOpen={showCreateAccountDrawer}
        onClose={handleCloseCreateAccountDrawer}
        title={t("components.Advertisers.createAccount")}
        footer={
          <Button
            text={t("button.Create")}
            onClick={handleSubmitCreateAccountForm}
          />
        }
      >
        <CreateAccountForm
          register={register}
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          errors={errors}
          shippingAddress={shippingAddress}
          billingAddress={billingAddress}
        />
      </Drawer>
    </div>
  );
};

export default AccountsPage;
