import React from 'react';
import { Controller } from "react-hook-form";
import ReactSlider from "react-slider";

import Input from '@client.components/core/Input';

import "./styles.scss"

interface IProps {
  min: number;
  max: number;
  control: any;
  name: string;
  isMoney?: boolean;
}

const FormSlider = ({ min, max, control, name, isMoney }: IProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="form-slider">
          <div className="value">
            {isMoney ? '$' : ''}
            <Input
              value={value || 0}
              onChange={(v) => onChange(Number(v))}
              type="number"
              optional={{
                min,
                max,
                step: 1,
              }}
            />
          </div>

          <ReactSlider
            min={min}
            max={max}
            className="horizontal-slider"
            thumbClassName="thumb"
            trackClassName="track"
            value={value || 0}
            onChange={onChange}
            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          />
        </div>
      )}
    />
  );
};

export default FormSlider;