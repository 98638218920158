import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import { createSystemLogAsync } from "@client.reducers/systemLogs";
import { AppDispatch } from "@client.store";

const DEFAULT_VALUES = {
  entity: "",
  entityId: "",
  type: "",
  description: "",
};

const useCreateSystemLogForm = (
  setShowCreateUserDrawer: Dispatch<SetStateAction<boolean>>,
  filter: any
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showToastAfterRequest } = useToast();

  const validationSchema = yup.object({
    entity: yup
      .string()
      .required("Entity is required"),
    entityId: yup
      .string()
      .required("Entity ID is required"),
    type: yup
      .string()
      .required("Type is required"),
    description: yup
      .string()
      .required("Description is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const resetForm = () => {
    reset(DEFAULT_VALUES);
  }

  const onSubmit = async (data) => {
    const systemLogData = {
      ...data,
      entity: parseInt(data.entity),
      type: parseInt(data.type)
    }
    try {
      const response = await dispatch(createSystemLogAsync({ systemLogData, filter}));
      showToastAfterRequest(
        response,
        "System log created"
      )
      resetForm();
      setShowCreateUserDrawer(false);
    } catch (e) {
      console.log(e)
    }
  };

  const selectedEntity = watch("entity");

  const handleSubmitCreateSystemLogForm = handleSubmit(onSubmit);

  return { register, errors, control, resetForm, handleSubmitCreateSystemLogForm, selectedEntity }
}

export default useCreateSystemLogForm;