import React from "react";

import AdvertisementStatisticsCards from "./AdvertisementStatisticsCards";
import AdvertisementStatisticsChart from "./AdvertisementStatisticsChart";

const AdvertisementStatistics = ({ advertisementMonitoring }) => {
  return (
    <div className="advertisement-statistics">
      <AdvertisementStatisticsCards advertisementMonitoring={advertisementMonitoring} />
      <AdvertisementStatisticsChart advertisementMonitoring={advertisementMonitoring} />
    </div>
  );
};

export default AdvertisementStatistics;
