export const TOOLTIP_ID = "TooltipID";

export const STATUS_TYPES = {
  1: "Pending",
  2: "Declined",
  3: "Approved",
  4: "Suspended",
  5: "Upgraded",
  6: "Terminated",
  7: "Live",
  8: "Hidden",
};

export const STATUS_CLASS = {
  1: "status-1", // Pending
  2: "status-2", // Declined
  3: "status-3", // approved
  4: "status-4", // suspended
  5: "status-5", // upgraded
  6: "status-6", // terminated
  7: "status-7", // live
  8: "status-8", // hidden
};
