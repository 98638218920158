import React from 'react';
import { Controller } from "react-hook-form";

import "./styles.scss";

const FormRadioGroup = ({ items, groupName, control }) => {
  return (
    <Controller
      name={groupName}
      control={control}
      render={({ field }) => (
        <div className="radio-group">
          {items.map((item) => (
            <label key={item.value}>
              <input
                type="radio"
                className="input-radio"
                {...field}
                value={item.value}
                checked={field.value === item.value}
                onChange={() => field.onChange(item.value)}
              />
              {item.label}
            </label>
          ))}
        </div>
      )}
    />
  );
};

export default FormRadioGroup;