import React from "react";
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';

import './styles.scss'
import { TOOLTIP_ID } from "@client.constants";

interface IProps {
  place?: PlacesType,
}

const Tooltip = ({ place="top" }: IProps) => {
  return (<ReactTooltip 
    id={TOOLTIP_ID}
    place={place}
    className="tooltip-container"
    border="2px solid #e91e63"
  />)
}

export default Tooltip;