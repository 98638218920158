import React from "react";
import AuthLeftPanel from "../components/AuthLeftPanel";
import UnauthorizedPageCard from "../components/UnauthorizedPageCard/UnauthorizedPageCard";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@client.components/core/Button";
import { useTranslation } from "react-i18next";
import TextInput from "@client.components/form/TextInput";
import "./ResetPassword.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@client.store";
import { resetPasswordAsync } from "@client.reducers/login";
import { useNavigate, useParams } from "react-router-dom";

function ResetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigation = useNavigate();

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number"),
    repeatedPassword: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      password: "",
      repeatedPassword: "",
    },
  });

  const onSubmit = async (data) => {
    if (params.id) {
      await dispatch(
        resetPasswordAsync({ token: params.id, password: data.password })
      );
      navigation("/");
    }
  };

  return (
    <div className="reset-page">
      <AuthLeftPanel />
      <UnauthorizedPageCard isLoading={false}>
        <div className="title">{t("pages.reset.title")}</div>
        <TextInput
          id="password"
          name="password"
          type="password"
          label={t("pages.Login.fieldset.password")}
          placeholder={t("pages.Login.placeholder.password")}
          register={register}
          error={errors.password}
        />
        <TextInput
          id="repeatedPassword"
          name="repeatedPassword"
          type="password"
          label={t("pages.Login.fieldset.repeatedPassword")}
          placeholder={t("pages.Login.placeholder.repeatedPassword")}
          register={register}
          error={errors.repeatedPassword}
        />
        <div className="btn">
          <Button text="Send" onClick={handleSubmit(onSubmit)} />
        </div>
      </UnauthorizedPageCard>
    </div>
  );
}

export default ResetPassword;
