import React from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import TextInput from "@client.components/form/TextInput";
import FormCheckbox from "@client.components/form/FormCheckbox";

import "./styles.scss";
import { validateExpirationDate } from "./utils";

const NewCreditCardForm = () => {
  const validationSchema = yup.object({
    cardNumber: yup
      .string()
      .required("Card number must be 16 digits")
      .matches(/^\d{16}$/, "Card number must be 16 digits"),
    cardholderName: yup
      .string()
      .required("Cardholder name is required")
      .matches(/^[A-Za-z\s]+$/, "Cardholder name can only contain letters and spaces"),
    expiryDate: yup
      .string()
      .required("Expiration date must be in MM/YY format")
      .matches(/^\d{2}\/\d{2}$/, "Expiration date must be in MM/YY format")
      .test(
        "is-valid-expiry",
        "Invalid expiration date",
        validateExpirationDate
      ),
    cvv: yup
      .string()
      .required("CVC must be 3 or 4 digits")
      .matches(/^\d{3,4}$/, "CVC must be 3 or 4 digits"),
    saveCard: yup
      .boolean(),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cardNumber: "",
      cardholderName: "",
      expiryDate: "",
      cvv: "",
      saveCard: false,
    },
  });

  return (
    <div className="new-credit-card-form">
      <TextInput
        id="cardNumber"
        name="cardNumber"
        placeholder="1234 1234 1234 1234"
        error={errors.cardNumber}
        register={register}
      />
      <TextInput
        id="cardholderName"
        name="cardholderName"
        placeholder="Cardholder Name"
        error={errors.cardholderName}
        register={register}
      />
      <div className="inputs-row">
        <TextInput
          id="expiryDate"
          name="expiryDate"
          placeholder="MM/YY"
          error={errors.expiryDate}
          register={register}
        />
        <TextInput
          id="cvv"
          name="cvv"
          placeholder="CVC"
          error={errors.cvv}
          register={register}
        />
      </div>
      <FormCheckbox 
        id="saveCard"
        name="saveCard"
        control={control}
        label="Save card for future use"
      />
    </div>
  );
};

export default NewCreditCardForm;
