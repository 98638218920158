import React, { useEffect, useRef, useState } from "react";
import SvgIcon from "@client.core.components/SvgIcon";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

export const MediaFile = (params) => {
  const [isImageNotFound, setIsImageNotFound] = useState(false);
  const {
    fallbackElement,
    isNotFound,
    onLoad,
    showThumbnail,
    src,
    ...mediaProps
  } = params;
  // const videoRef = useRef(null);
  const videoRef = useRef(null);
  const getFileExtension = (url) => {
    if (!url) {
      return null;
    }

    const urlParts = url.split(".");
    const extension = urlParts[urlParts.length - 1].toLowerCase();

    return extension;
  };

  const isVideoFile = (url) => ["mp4"].includes(getFileExtension(url));

  useEffect(() => {
    if (isVideoFile(src) && videoRef.current && !showThumbnail) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [src]);

  const renderMediaFile = (mediaParams) => {
    if (getFileExtension(src) === "avi") {
      if (mediaParams.onError) mediaParams.onError();
      return <NoDataPlaceholder description="AVI format is not supported" />;
    }
    return isVideoFile(src) ? (
      <video
        // ref={videoRef}
        ref={videoRef}
        muted
        autoPlay={!showThumbnail}
        loop={!showThumbnail}
        onLoadedMetadata={(e) => {
          if (onLoad) {
            onLoad({
              width: e.nativeEvent.target.videoWidth,
              height: e.nativeEvent.target.videoHeight,
            });
          }
        }}
        onError={() => {
          setIsImageNotFound(true);
          if (params.onError) params.onError();
        }}
        {...mediaParams}
      >
        <source src={showThumbnail ? `${src}#t=1` : src} type={`video/mp4`} />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        src={src}
        {...mediaParams}
        onLoad={(e) => {
          if (onLoad) {
            onLoad({
              width: e.target.naturalWidth,
              height: e.target.naturalHeight,
            });
          }
        }}
        onError={() => {
          setIsImageNotFound(true);
          if (params.onError) params.onError();
        }}
      />
    );
  };

  return isNotFound || isImageNotFound ? (
    fallbackElement ? (
      fallbackElement()
    ) : (
      <SvgIcon name="file-not-found" className={params.className} />
    )
  ) : (
    renderMediaFile(mediaProps)
  );
};
