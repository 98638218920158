import React from "react";
import Tag from "@client.components/Tag";
import "./styles.scss";

interface IItem {
  [key: string]: number | string;
}

interface IProps {
  items: IItem[];
  selected: number | number[];
  onSelect: (value) => void;
  nameKey?: keyof IItem;
  valueKey?: keyof IItem;
  isMultiSelect?: boolean;
}

const TagList = ({
  items,
  selected,
  onSelect,
  nameKey = "name",
  valueKey = "id",
  isMultiSelect = false,
}: IProps) => {
  const handleTagClick = (value: number) => {
    if (isMultiSelect) {
      if (Array.isArray(selected)) {
        if (selected.includes(value)) {
          onSelect(selected.filter((selectedValue) => selectedValue !== value));
        } else {
          onSelect([...selected, value]);
        }
      } else {
        onSelect([selected, value]);
      }
    } else {
      onSelect(value);
    }
  };

  const isSelected = (value: number) => {
    if (isMultiSelect && Array.isArray(selected)) {
      return selected.includes(value);
    }
    return selected === value;
  };

  return (
    <div className="tag-list">
      {items.map((t, index) => {
        const itemValue = t[valueKey];
        const itemName = t[nameKey];

        return (
          <Tag
            key={itemValue}
            value={Number(itemValue)}
            name={itemName || `${index + 1}`}
            active={isSelected(Number(itemValue))}
            onSelect={() => handleTagClick(Number(itemValue))}
          />
        );
      })}
    </div>
  );
};

export default TagList;
