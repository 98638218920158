import React, { useState } from "react";
import classNames from "classnames";

import {
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";
import { IAccount, IAccountHierarchyItem } from "@client.types/account";
import { getAccountEntityNameByCode } from "@client.utils/utils";

interface IProps {
  account: IAccountHierarchyItem;
  selectedAccountId: string | null;
  onSelectAccount: (account: IAccount | null) => void;
}

const AccountTreeItem = ({
  account,
  selectedAccountId,
  onSelectAccount,
}: IProps) => {
  const [expanded, setExpanded] = useState(false);

  const renderExpandCollapse = () => {
    if (!account.children.length) {
      return null;
    }
    return expanded ? (
      <MdOutlineExpandLess onClick={() => setExpanded(prev => !prev)} />
    ) : (
      <MdOutlineExpandMore onClick={() => setExpanded(prev => !prev)} className="expand" />
    );
  };

  return (
    <li className="tree-item">
      <div
        className={classNames("tree-item-title", {
          selected: account.Id === selectedAccountId,
        })}
      >
        {renderExpandCollapse()}
        <p onClick={() => onSelectAccount(account)} className="tree-item-title-text">
          <div
            className={`account-entity-indicator entity-${getAccountEntityNameByCode(account.entity)?.toLowerCase()}`} 
          />
          {account.companyName}
        </p>
      </div>
      {expanded && (
        <div>
          {account.children && (
            <ul>
              {account.children.map((item) => (
                <AccountTreeItem
                  key={item.Id}
                  account={item}
                  selectedAccountId={selectedAccountId}
                  onSelectAccount={onSelectAccount}
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </li>
  );
};

export default AccountTreeItem;
