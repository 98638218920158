import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import config from "@client.config";
import classNames from "classnames";

import ServiceProvider from "@client.services/provider";
import { IAccount } from "@client.types/account";
import useToast from "@client.hooks/useToast";
import { getAdvertisementStatus } from "@client.utils/utils";

import DataTable from "@client.components/DataTable";

import "./styles.scss";

interface IProps {
  selectedAccount: IAccount;
}

const AdvertisementsTab = ({ selectedAccount }: IProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [accountAdvertisements, setAccountAdvertisements] = useState([]);

  const COLUMNS = [
    {
      field: "title",
      headerName: "Name",
      cellClass: "data-table-cell clickable-cell",
    },
    {
      field: "totalViews",
      headerName: "Views",
      cellClass: "data-table-cell clickable-cell",
    },
    {
      field: "totalPlays",
      headerName: "Plays",
      cellClass: "data-table-cell clickable-cell",
    },
    {
      field: "budgetDaily",
      headerName: "Daily Budget",
      cellClass: "data-table-cell clickable-cell",
    },
    {
      field: "budgetTotal",
      headerName: "Budget",
      cellClass: "data-table-cell clickable-cell",
    },
    {
      field: 'endDate',
      headerName: "Ends",
      valueGetter: (params)=> moment(params.data.endDate).format(config.dateTimeFormat),
      cellClass: "data-table-cell clickable-cell",
    },
    {
      field: "status",
      cellRenderer: (params) => {
        const status = getAdvertisementStatus(params.value)

        return (
          <div className="status">
            <span className={classNames("badge")}>
              {t(status)}
            </span>
          </div>
        )
      },
      cellClass: "data-table-cell clickable-cell",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const fetchAccountAdvertisements = async () => {
      try {
        const response = await ServiceProvider.Account.getAccountAdvertisements(
          selectedAccount.Id,
          {
            limit: 10,
            offset: 0,
            search: "",
            searchOn: "",
            startDate: "",
            endDate: "",
          },
        );
        if (response.data.accountCampaigns.results) {
          setAccountAdvertisements(response.data.accountCampaigns.results)
        } else {
          showErrorToast("Can't get account advertisements")
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get account advertisements";
        showErrorToast(errorMessage)
      } finally {
        setLoading(false);
      }
    }
    
    fetchAccountAdvertisements();
  }, []);

  return (
    <div className="account-advertisements-tab">
      <DataTable 
        columns={COLUMNS}
        data={accountAdvertisements}
        isLoading={loading}
      />
    </div>
  );
};

export default AdvertisementsTab;
