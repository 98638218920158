import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { IAdvertisementInsights } from "@client.types/advertisementInsights";

interface IProps {
  advertisementInsights: IAdvertisementInsights | null;
}

const AdvertisementInsightsViews = ({ advertisementInsights }: IProps) => {
  const { t } = useTranslation();

  const viewsChartData = advertisementInsights?.ivpGraph.map((entry) => ({
    date: moment(entry.datetime).format("HH:mm:ss"),
    impressions: entry.impressions,
    qty: entry.peopleDetected,
    views: entry.views,
  })) || [];

  return (
    <div>
      <p className="title">{t("pages.DataAnalyzer.viewsChart.title")}</p>
      <ResponsiveContainer width="100%" height={320}>
        <LineChart
          data={viewsChartData}
          margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="impressions"
            stroke="#2D99FF"
            activeDot={{ r: 8 }}
            name="Impressions"
          />
          <Line type="monotone" dataKey="views" stroke="#2cd9c5" name="Views" />
          <Line type="monotone" dataKey="qty" stroke="#ff6c40" name="People Around" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AdvertisementInsightsViews;
