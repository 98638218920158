import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import AdvertisementStatuses from "@client.enums/advertisementStatuses";
import useStableParams from "@client.hooks/useStableParams";
import { 
  selectDefaultAdvertisementsData, 
  selectDefaultAdvertisementsError, 
  selectDefaultAdvertisementsLoading, 
  getDefaultAdvertisementsByZoneIdAsync, 
  clearDefaultAdvertisements 
} from "@client.reducers/zones";
import Loader from "@client.components/core/Loader";
import { Link } from "react-router-dom";
import Pages from "@client.enums/pages";
import { AppDispatch } from "@client.store";
import { IAdvertisement } from "@client.types/advertisement";

const ZoneDefaultAdvertisementsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { zoneId } = useStableParams();

  const defaultAdvertisements: IAdvertisement[] = useSelector(selectDefaultAdvertisementsData);
  const defaultAdvertisementsError = useSelector(selectDefaultAdvertisementsError);
  const defaultAdvertisementsLoading = useSelector(selectDefaultAdvertisementsLoading);
  
  useEffect(() => {
    if (!zoneId) {
      return;
    }

    dispatch(
      getDefaultAdvertisementsByZoneIdAsync(zoneId)
    )
  
    return () => {
      dispatch(clearDefaultAdvertisements())
    }
  }, []);
  
  const getStatus = (status: number) => {
    switch (status) {
    case AdvertisementStatuses.Paused:
      return { term: "Pages.Advertisement.status.paused", class: "paused" };
    case AdvertisementStatuses.Archived:
      return { term: "Pages.Advertisement.status.archived", class: "archived" };
    case AdvertisementStatuses.Queued:
      return { term: "Pages.Advertisement.status.queued", class: "queued" };
    case AdvertisementStatuses.PendingApproval:
      return { term: "Pages.Advertisement.status.pending", class: "pending" };
    case AdvertisementStatuses.Draft:
      return { term: "Pages.Advertisement.status.draft", class: "draft" };
    default:
      return { term: "Pages.Advertisement.status.live", class: "live" };
    }
  };
  
  if (defaultAdvertisementsLoading) {
    return (
      <div className="default-advertisements-list-loader">
        <Loader loading={true} />
      </div>
    )
  }
  
  return (
    <div className="default-advertisements-list">
      {defaultAdvertisements.map((advertisement) => (
        <div className="default-advertisements-list-item" key={advertisement.Id}>
          <Link 
            className="default-advertisements-list-item-name" 
            to={`${Pages.Advertisement}/${advertisement.Id}`}
          >
            {advertisement.title}
          </Link>
          <span className={classNames("badge", getStatus(advertisement.status).class)}>
            {t(getStatus(advertisement.status).term)}
          </span>
          <div className="default-advertisements-list-item-date">
            <p className="default-advertisements-list-item-date-line">Start date: {moment(advertisement.startDate).format("YYYY-MM-DD")}</p>
            <p className="default-advertisements-list-item-date-line">End date: {moment(advertisement.endDate).format("YYYY-MM-DD")}</p>
          </div>
        </div>
      ))}
    </div>
  )
};

export default ZoneDefaultAdvertisementsPage;
