import React, { useEffect, useState } from "react";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import Icon from "@client.core.components/Icon";

import './styles.scss'

interface IProps {
  name: string;
  label: string;
  control: any;
  options: { value: string | number; label: string }[];
  placeholder?: string;
  error?: string;
}

const FormSelect: React.FC<IProps> = ({ name, label, control, options, placeholder, error }) => {
  const [visible, setVisible] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        useEffect(() => {
          if (value) {
            const selectedOption = options.find((opt) => opt.value == value);
            setSelectedLabel(selectedOption ? selectedOption.label : placeholder || "Select an option");
          } else {
            setSelectedLabel(placeholder || "Select an option");
          }
        }, [value, options, placeholder]);
        
        const handleSelect = (selectedValue: string) => {
          const selectedOption = options.find((opt) => opt.value == selectedValue);
          setSelectedLabel(selectedOption ? selectedOption.label : placeholder || "Select an option");
          onChange(selectedValue);
          setVisible(false);
        };

        const menu = (
          <Menu
            onSelect={({ key }) => handleSelect(key)}
            className="form-select-menu"
          >
            {options.map((option) => (
              <MenuItem className="form-select-menu-item" key={option.value}>{option.label}</MenuItem>
            ))}
          </Menu>
        );

        return (
          <div className="form-select-container">
            <label className="label">{label}</label>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              animation="slide-up"
              visible={visible}
              onVisibleChange={(v) => setVisible(v)}
            >
              <button
                type="button"
                className={classNames("form-select-button", { "select-error": error })}
              >
                {selectedLabel || placeholder || "Select an option"}
                <Icon name="chevron" />
              </button>
            </Dropdown>
            {error && <span className="error-message">{error}</span>}
          </div>
        );
      }}
    />
  );
};

export default FormSelect;
