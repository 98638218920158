import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IAdvertisement } from "@client.types/advertisement";

import Tabs from "@client.components/Tabs";

import AdvertisementOverview from "./AdvertisementOverview";
import AdvertisementInsights from "./AdvertisementInsights";

export enum AdvertisementTabsEnum {
  Overview = 0,
  Insights = 1,
}

interface IProps {
  advertisement: IAdvertisement;
}

const AdvertisementDetails = ({ advertisement }: IProps) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<AdvertisementTabsEnum>(AdvertisementTabsEnum.Overview);

  const renderTabContent = () => {
    switch (selectedTab) {
    case AdvertisementTabsEnum.Overview:
      return <AdvertisementOverview advertisement={advertisement} />;
    case AdvertisementTabsEnum.Insights:
      return <AdvertisementInsights />;
    default:
      return null;
    }
  };

  const tabs = [
    {
      id: AdvertisementTabsEnum.Overview,
      name: t("pages.campaings.tabs.overview"),
    },
    {
      id: AdvertisementTabsEnum.Insights,
      name: t("pages.campaings.tabs.insights"),
    }
  ];

  return (
    <div className="advertisement-details">
      <>
        <Tabs
          tabs={tabs}
          value={selectedTab}
          onSelect={setSelectedTab}
        />
        {renderTabContent()}
      </>
    </div>
  );
};

export default AdvertisementDetails;
