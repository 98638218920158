import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import ServiceProvider from '@client.services/provider';

import Slider from '@client.components/Slider';
import Button from '@client.components/core/Button';
import PlacesInput from '@client.components/PlacesInput';
import LocationTypes from '@client.enums/locationTypes';
import { IZonePolygon } from '@client.types/zonePolygon';
import { useCreateAdvertisementForm } from '@client.pages/CreateAdvertisement/CreateAdvertisementFormContext';

interface IProps {
  mapLocations: IZonePolygon[];
  setMapLocations: Dispatch<SetStateAction<IZonePolygon[]>>;
}

const RadiusTab = ({ mapLocations, setMapLocations }: IProps) => {
  const [radiusLocationPolygon, setRadiusLocationPolygon] = useState<number[][]>([]);
  const [radius, setRadius] = useState('1');
  const [locationName, setLocationName] = useState('');
  const [locationCoords, setLocationCoords] = useState({
    lat: 0,
    lng: 0,
  });

  const { 
    createAdvertisementFormMethods,
    setSelectedLocationsList,
  } = useCreateAdvertisementForm();

  const {
    setValue,
    watch,
  } = createAdvertisementFormMethods;

  const radiusLocations = watch("radiusLocations");

  useEffect(() => {
    if (radiusLocationPolygon.length) {
      const locationId = `temporary`;
      if (mapLocations.find((location) => location.id === locationId)) {
        setMapLocations((prev) =>
          prev.map((location) =>
            location.id === locationId
              ? {
                ...location,
                color: "red",
                polygon: radiusLocationPolygon.map((point) => ({
                  lat: point[1],
                  lng: point[0],
                })),
              }
              : location
          )
        );
      } else {
        setMapLocations((prev) => [...prev, {
          color: 'red',
          data: radiusLocationPolygon.map((point) => ({
            lat: point[1],
            lng: point[0],
          })),
          id: locationId,
        }])
      }
    }
  }, [radiusLocationPolygon]);
  
  useEffect(() => {
    const handler = setTimeout(async () => {
      if (location) {
        const polygon = await ServiceProvider.Zone.getPolygon(locationName, +radius);
        if (polygon) {
          setRadiusLocationPolygon(polygon);
        }
      }
    }, 500);
    
    return () => clearTimeout(handler);
  }, [location, radius]);
  
  const handleChooseLocation = (location: any, address: string) => {
    setLocationCoords(location);
    setLocationName(address)
  }

  const handleConfirmRadiusZone = () => {
    const locationId = `${locationName} + ${radius}mi`
    setSelectedLocationsList((prev) => [...prev, {
      title: locationId,
      id: locationId,
      type: LocationTypes.Radius
    }]);
    setMapLocations((prev) => [...prev.filter((mapLocation) => mapLocation.id !== 'temporary'), {
      color: 'red',
      data: radiusLocationPolygon.map((point) => ({
        lat: point[1],
        lng: point[0],
      })),
      id: locationId,
    }]);
    setValue("radiusLocations", [...radiusLocations, {
      id: locationId,
      geofenceRadiusLatitude: locationCoords.lat,
      geofenceRadiusLongitude: locationCoords.lng,
      geofenceRadiusRange: +radius,
    }]);
    setLocationName('');
    setRadius('1');
    setRadiusLocationPolygon([]);
  };
  
  return (
    <>
      <PlacesInput
        label="Location Address"
        location={locationName}
        onChange={setLocationName}
        setLocation={handleChooseLocation}
      />
      <Slider
        min={1}
        max={100}
        value={`${radius}`}
        onChange={setRadius}
      />
      <Button text="Confirm" onClick={handleConfirmRadiusZone} />
    </>
  )
};

export default RadiusTab;
