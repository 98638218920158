import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "@client.components/form/TextInput";
import FormSelect from "@client.components/form/FormSelect";
import TagList from "@client.components/TagList";
import SectionDescription from "../../../../components/SectionTitleDescription";
import moment from "moment";
import Button from "@client.components/core/Button";
import ServiceProvider from "@client.services/provider";
import { IAsset } from "@client.types/asset";
import classNames from "classnames";
import MediaFile from "@client.components/MediaFile";
import DateRangePicker from "@client.components/DateRangePicker";
import {
  Step,
  useCreateAdvertisementForm,
} from "@client.pages/CreateAdvertisement/CreateAdvertisementFormContext";
import AccountEntity from "@client.enums/accountEntity";
import ImageEditor from "@client.components/ImageEditor";
import Modal from "@client.components/core/Modal";
import Uploader from "@client.components/Uploader";

export interface IFormValues {
  title: string;
  advertiserId: string;
  startDate: string;
  endDate: string;
  target: string;
}

interface IProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const GeneralInformationStep = ({ currentStep, setCurrentStep }: IProps) => {
  const { t } = useTranslation();

  const {
    createAdvertisementFormMethods,
    setFormFilled,
    setSelectedImageUrl,
    accounts,
    profile,
    advertisementTargets,
  } = useCreateAdvertisementForm();

  const isAdvertiser = profile.entity === AccountEntity.Advertiser;

  const advertisersOptions =
    accounts?.map((advertiser) => ({
      value: advertiser.Id,
      label: advertiser.companyName,
    })) || [];

  const [accountAssets, setAccountAssets] = useState<IAsset[]>([]);
  const [image, setImage] = useState<File | undefined>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = createAdvertisementFormMethods;

  const handleSubmitGeneralInformationStepForm = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        setCurrentStep((prev) => prev + 1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const advertiserId = watch("advertiserId");
  const target = watch("targets");
  const mediaId = watch("mediaId");

  useEffect(() => {
    if (accounts && profile) {
      setValue("advertiserId", profile.accountId);
    }
  }, [accounts, profile]);

  useEffect(() => {
    if (advertiserId) {
      getAccountAssets();
    }
  }, [advertiserId, accounts, profile]);

  const getAccountAssets = async () => {
    if (getValues().advertiserId) {
      const resp = await ServiceProvider.Account.getAccountAssets(advertiserId);
      if (resp.data.medias) {
        setAccountAssets(resp.data.medias.results);
        setIsOpenModal(false);
      }
    }
  };

  const handleUploadImage = async (file) => {
    try {
      const resp = await ServiceProvider.Account.uploadAsset(
        file,
        advertiserId
      );
      if (resp.id) {
        getAccountAssets();
      }
      handleCloseModal();
    } catch (e) {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const getSuccessData = (data) => {
    setIsOpenModal(true);
    setImage(data);
  };

  const handleSelectedTargets = () => {
    if (typeof target !== "number") return target.map(Number);
    return target;
  };

  return (
    <div className="general-information-container">
      <div className="step-title-container">
        <div className="step-title">General Information</div>
        {currentStep !== Step.GeneralInformation ? (
          <Button
            icon="edit"
            text={t("Pages.CreateAdvertisement.edit")}
            className="wizard"
            onClick={() => {
              setFormFilled(false);
              setCurrentStep(Step.GeneralInformation);
            }}
          />
        ) : (
          false
        )}
      </div>
      {currentStep === Step.GeneralInformation ? (
        <>
          <div className="form-row">
            <TextInput
              id="title"
              name="title"
              label={t("Pages.CreateAdvertisement.title")}
              register={register}
              error={errors.title}
            />
            {!isAdvertiser ? (
              <FormSelect
                name="advertiserId"
                label="Advertisers"
                control={control}
                options={advertisersOptions}
                error={errors.advertiserId?.message}
              />
            ) : (
              false
            )}
          </div>
          <div>
            <SectionDescription
              title="Pages.CreateAdvertisement.advertisementDates"
              description="Pages.CreateAdvertisement.advertisementDates.description"
            />
            <DateRangePicker
              dateFrom={getValues().startDate}
              dateTo={getValues().endDate}
              onChange={(selectedDates) => {
                const [startDate, endDate] = selectedDates;
                setValue(
                  "startDate",
                  startDate ? moment(startDate).toISOString() : ""
                );
                setValue(
                  "endDate",
                  endDate ? moment(endDate).toISOString() : ""
                );
              }}
              twoFieldsView
              labelStart={t("Pages.CreateAdvertisement.startDate")}
              labelEnd={t("Pages.CreateAdvertisement.endDate")}
              withTime
            />
          </div>
          <div>
            <SectionDescription
              title="Pages.CreateAdvertisement.advertisementTarget"
              description="Pages.CreateAdvertisement.advertisementTarget.description"
            />
            <TagList
              items={advertisementTargets}
              selected={handleSelectedTargets()}
              valueKey="id"
              nameKey="name"
              onSelect={(values) => setValue("targets", values)}
              isMultiSelect={true}
            />
          </div>
          <div>
            <SectionDescription
              title="Pages.CreateAdvertisement.uploadAdvertisementImage"
              description="Pages.CreateAdvertisement.uploadAdvertisementImage.description"
            />
            <div className="upload-image-container">
              <Uploader onChange={getSuccessData} />
              <div className="gallery">
                {accountAssets.map((asset) => (
                  <div
                    key={asset.Id}
                    className={classNames("gallery-item", {
                      selected: mediaId === asset.Id,
                    })}
                    onClick={() => {
                      setValue("mediaId", asset.Id);
                      setSelectedImageUrl(asset.url);
                    }}
                  >
                    <MediaFile src={asset.url} />
                    {mediaId === asset.Id && (
                      <div className="selected-label">Selected</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Button
            text={t("Pages.CreateAdvertisement.next")}
            className="btn-next wizard"
            onClick={handleSubmitGeneralInformationStepForm}
          />
          <Modal
            show={isOpenModal}
            title="Image Editor"
            closeModal={handleCloseModal}
          >
            <ImageEditor image={image} onSuccess={handleUploadImage} />
          </Modal>
        </>
      ) : (
        false
      )}
    </div>
  );
};

export default GeneralInformationStep;
