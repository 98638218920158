import React from "react";
import { useTranslation } from "react-i18next";

import { IAdvertisementInsights } from "@client.types/advertisementInsights";

import OverviewCard from "@client.components/OverviewCard";

interface IProps {
  advertisementInsights: IAdvertisementInsights | null;
}

const AdvertisementInsightsCards = ({ advertisementInsights }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="advertisement-insights-cards-container">
      <OverviewCard
        value={advertisementInsights?.peopleDetected || 0}
        text={t("pages.DataAnalyzer.peopleArea")}
      />
      <OverviewCard
        value={advertisementInsights?.views || 0}
        text={t("pages.DataAnalyzer.viewers")}
        color="yellow"
      />
      <OverviewCard
        value={advertisementInsights?.impressions || 0}
        text={t("pages.DataAnalyzer.interact")}
        color="blue"
      />
      <OverviewCard
        value={`${advertisementInsights?.ageRangeMaleLow || 0} - ${advertisementInsights?.ageRangeMaleHigh || 0}`}
        text={t("pages.DataAnalyzer.gender.male.ageRange")}
        color="red"
      />
      <OverviewCard
        value={`${advertisementInsights?.ageRangeFemaleLow || 0} - ${advertisementInsights?.ageRangeFemaleHigh || 0}`}
        text={t("pages.DataAnalyzer.gender.female.ageRange")}
        color="green"
      />
    </div>
  );
};

export default AdvertisementInsightsCards;
