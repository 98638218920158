import React from "react";

import useAvailableColors from "@client.hooks/useAvailableColors";
import "./styles.scss";

const CircleIcon = ({ name, index }) => {
  const { getSubsequentColor } = useAvailableColors();

  if (!name) {
    return null;
  }

  return (
    <div className="circle-icon">
      <div
        style={{
          background: getSubsequentColor(index),
        }}
        className="icon"
      >
        {name.charAt(0)}
      </div>
    </div>
  );
};

export default CircleIcon;
