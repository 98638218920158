import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import { createAccountAsync } from "@client.reducers/accounts";
import { AppDispatch } from "@client.store";
import { getCreateAccountFields } from "./getCreateAccountFields";
import { IAddress } from "@client.types/address";
import { ICreateAccountResponse } from "@client.types/queries/createAccountMutation";

export interface ICreateAccountFormValues {
  parentAccountId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  entity: string;
  companyName: string;
  companyRegistrationNumber: string;
  companyVatNumber: string;
  shippingAddress: IAddress;
  billingAddress: IAddress;
  sameAddress?: boolean;
}

const DEFAULT_VALUES = {
  parentAccountId: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  entity: "",
  companyName: "",
  companyRegistrationNumber: "",
  companyVatNumber: "",
  shippingAddress: {
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  },
  billingAddress: {
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  },
  sameAddress: false,
};

const useCreateAccountForm = (
  setShowCreateAccountDrawer: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { showToastAfterRequest, showErrorToast } = useToast();

  const validationSchema = yup.object({
    parentAccountId: yup.string().required("Parent account is required"),
    firstName: yup.string().required("firstName is required"),
    lastName: yup.string().required("lastName is required"),
    emailAddress: yup
      .string()
      .email(t("pages.Login.validation.invalidEmail"))
      .required(t("components.Advertisers.validation.emailAddress")),
    phoneNumber: yup
      .string()
      .required(t("components.Advertisers.validation.phoneNumber")),
    entity: yup.string().required(t("components.Advertisers.validation.role")),
    companyName: yup.string().required("Company name is required"),
    companyRegistrationNumber: yup
      .string()
      .required("Company registration number is required"),
    companyVatNumber: yup.string().required("Company VAT number is required"),
    shippingAddress: yup
      .object()
      .shape({
        addressLineOne: yup.string().required("Shipping address is required"),
        city: yup.string().required("City is required"),
        country: yup.string().required("Country is required"),
        postalCode: yup.string().required("Postal code is required"),
      })
      .required("Shipping address is required"),
    billingAddress: yup
      .object()
      .shape({
        addressLineOne: yup.string().required("Billing address is required"),
        city: yup.string().required("City is required"),
        country: yup.string().required("Country is required"),
        postalCode: yup.string().required("Postal code is required"),
      })
      .required("Billing address is required"),
    sameAddress: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const shippingAddress = watch("shippingAddress");
  const billingAddress = watch("billingAddress");
  const sameAddress = watch("sameAddress");

  useEffect(() => {
    if (sameAddress) {
      setValue("billingAddress", shippingAddress);
      trigger("billingAddress");
    }
  }, [shippingAddress, sameAddress]);

  const resetForm = () => {
    reset(DEFAULT_VALUES);
  };

  const onSubmit = async (formValues) => {
    try {
      const fields = getCreateAccountFields(formValues);
      const response = await dispatch(createAccountAsync(fields));
      if ((response.payload as ICreateAccountResponse).errors) {
        showErrorToast("Can't create account");
      } else {
        showToastAfterRequest(response, "Account created");
        resetForm();
        setShowCreateAccountDrawer(false);
      }
    } catch (e) {
      showErrorToast("Can't create account");
    }
  };

  const handleSubmitCreateAccountForm = handleSubmit(onSubmit);

  return {
    register,
    control,
    getValues,
    setValue,
    trigger,
    errors,
    resetForm,
    shippingAddress,
    billingAddress,
    handleSubmitCreateAccountForm,
  };
};

export default useCreateAccountForm;
