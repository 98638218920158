const getMarkerIcon = () => ({
  url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
          <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="14" fill="#ff0066" stroke="white" stroke-width="2"/>
            <text x="50%" y="50%" font-size="14" text-anchor="middle" dy=".35em" fill="white" font-weight="400" font-family="sans-serif">1</text>
          </svg>
        `),
  scaledSize: new window.google.maps.Size(40, 40),
  anchor: new window.google.maps.Point(20, 20),
});

export default getMarkerIcon;