import * as turf from "@turf/turf";

import BaseService from "@client.services/baseService";

export default class ZoneService extends BaseService {
  async getZones() {
    const response = await super.post("", {
      query: `
        query (
          $accountId: String
        ) {
          zones(accountId: $accountId) {
            results {
              color
              polygon
              primePpvRate
              updatedAt
              zoneName
              parentZoneId
              standardPpvRate
              isActive
              createdAt
              category
              Id
            }
          }
        }
      `,
    });

    return response;
  }

  async getById(id) {
    return super.post("", {
      query: `
        query($id: String){
          zone(id: $id){
            Id
            zoneName
            category
            polygon
            color
            standardPpvRate
            primePpvRate
            parentZoneId
          }
        }
      `,
      variables: {
        id,
      },
    });
  }
  
  async create(model) {
    return super.post("", {
      query: `
        mutation($fields: ZoneInputType){
          createZone(fields: $fields){
            message
            zone {
              Id
              zoneName
              category
              polygon
              standardPpvRate
              primePpvRate
              parentZoneId
              polygon
            }
          }
        }
      `,
      variables: {
        fields: model,
      },
    });
  }

  async update(fields, id) {
    return super.post("", {
      query: `mutation($id: String, $fields: ZoneInputType){
        updateZone(id: $id, fields: $fields){
          message
          zone{
            Id
            zoneName
            category
            polygon
            color
            standardPpvRate
            primePpvRate
          }
        }
      }`,
      variables: {
        fields,
        id,
      },
    });
  }

  async delete(id) {
    return super.post("", {
      query: `mutation($id: String){
        deleteZone(id: $id)
      }`,
      variables: {
        id,
      },
    });
  }

  async getPolygon(zone, radius: number | null = null) {
    let results: any[] = [];
    try {
      results = await this.api(
        `https://nominatim.openstreetmap.org/search.php?q=${zone}&polygon_geojson=1&format=jsonv2`,
        {},
        true
      );
    } catch (e) {
      console.log(e)
    }
    
    if (!results.length) return null; 
    const result = results[0];

    if (result.geojson.type === "Point") { 
      const [lng, lat] = result.geojson.coordinates;
      if (radius) {
        return this.createCircularPolygon(lat, lng, radius);
      }
      return [[lng, lat]];
    }

    else if (result.geojson.type === "LineString") { 
      return radius ? this.expandPolygon(result.geojson, radius) : result.geojson.coordinates;
    }

    else if (result.geojson.type === "Polygon") { 
      return radius ? this.expandPolygon(result.geojson, radius) : this.samplePolygon(result.geojson.coordinates[0]);
    }

    else if (result.geojson.type === "MultiPolygon") {
      const largestPolygon = result.geojson.coordinates.reduce((largest, current) =>
        current[0].length > largest[0].length ? current : largest
      );
      return radius ? this.expandPolygon({ type: "Polygon", coordinates: largestPolygon }, radius) : this.samplePolygon(largestPolygon[0]);
    }
  }

  createCircularPolygon(lat, lng, radius) {
    const center = turf.point([lng, lat]);
    const options = { steps: 64, units: "miles" as turf.Units };
    const circle = turf.circle(center, radius, options);
  
    return circle.geometry.coordinates[0];
  }

  expandPolygon(geojson, radius) {
    const buffered = turf.buffer(geojson, radius, { units: "miles" });
  
    if (!buffered || !buffered.geometry || !buffered.geometry.coordinates) {
      console.error("Buffering failed: Invalid geometry", geojson);
      return null;
    }
  
    return buffered.geometry.coordinates[0];
  }

  samplePolygon(polygon, sampleRate = 3) {
    return polygon.filter((_, index) => index % sampleRate === 0);
  }

  getDefaultAdvertisementsByZoneId(zoneId) {
    return super.post("", {
      query: `
        query($zoneId: String){
        ZoneDefaultAdvertisements(zoneId: $zoneId){
          results {
            title
            startDate
            endDate
            accountId
            accountName
            userId
            userFullName
            targets
            mediaId
            mediaUrl
            budgetTotal
            budgetDaily
            geofenceRadiusLatitude
            geofenceRadiusLongitude
            geofenceRadiusRange
            geofenceZoneIds
            geofenceCustomPolygons
            totalViews
            totalSpent
            totalPlays
            displayTime
            status
            rejectionReason
            latitude
            longitude
            Id
            createdAt
            updatedAt
            isActive
          }
          count
        }
      }`,
      variables: {
        zoneId,
      },
    });
  }
  
}








