import React from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ViewsChart = ({ data }) => {
  const { t } = useTranslation();

  if (!data || !data.ivpGraph || !Array.isArray(data.ivpGraph) || data.ivpGraph.length === 0) {
    return (
      <div className="data-analyzer-chart">
        <div className="chart-title">
          {t("pages.DataAnalyzer.viewsChart.title")}
        </div>
        <p>{t("pages.DataAnalyzer.noData")}</p>
      </div>
    );
  }

  // Transform `ivpGraph` for the chart
  const dataToShow = data.ivpGraph.map((entry) => ({
    date: moment(entry.datetime).format("HH:mm:ss"),
    impressions: entry.impressions || 0,
    qty: entry.peopleDetected || 0,
    views: entry.views || 0,
  }));

  return (
    <div className="data-analyzer-chart">
      <div className="chart-title">
        {t("pages.DataAnalyzer.viewsChart.title")}
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={dataToShow}
          margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="impressions"
            stroke="#2D99FF"
            activeDot={{ r: 8 }}
            name={t("pages.DataAnalyzer.viewsChart.impressions")}
          />
          <Line
            type="monotone"
            dataKey="views"
            stroke="#2cd9c5"
            name={t("pages.DataAnalyzer.viewsChart.views")}
          />
          <Line
            type="monotone"
            dataKey="qty"
            stroke="#ff6c40"
            name={t("pages.DataAnalyzer.viewsChart.peopleDetected")}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

ViewsChart.propTypes = {
  data: PropTypes.shape({
    ivpGraph: PropTypes.arrayOf(
      PropTypes.shape({
        datetime: PropTypes.string.isRequired,
        impressions: PropTypes.number,
        peopleDetected: PropTypes.number,
        views: PropTypes.number,
      })
    ),
  }),
};

export default ViewsChart;
