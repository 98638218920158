import React from 'react';
import { UseFormRegister, FieldValues, FieldError, Path } from "react-hook-form";

import "./index.scss";

interface IProps<T extends FieldValues> {
  id: string;
  name: Path<T>;
  type?: string;
  placeholder?: string;
  label?: string;
  register: UseFormRegister<T>;
  error?: FieldError;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextInput = <T extends FieldValues>({
  id,
  name,
  type = "text",
  placeholder = "",
  label = "",
  register,
  error,
  handleKeyPress,
}: IProps<T>) => {
  return (
    <div className="text-input-container">
      {label && <span className="label">{label}</span>}
      <input
        type={type}
        id={id}
        className="input"
        autoComplete="off"
        placeholder={placeholder}
        onKeyDown={handleKeyPress}
        {...register(name)}
      />
      {error && <span className="error-message">{error.message}</span>}
    </div>
  );
};

export default TextInput;