import React from "react";
import { useSelector } from "react-redux";

import { makeDeviceAccountInfo  } from "@client.reducers/devices";

import Title from "@client.components/Title";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import Loader from "@client.core.components/Loader";


import './index.scss'

const PartnerTab = () => {
  const {data, loading} = useSelector(makeDeviceAccountInfo);

  if (loading) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }

  if (!data) {
    return <NoDataPlaceholder description="No partner data available" />;
  }

  const displayedData = [{
    title: "Registration number",
    value: data?.companyRegistrationNumber || "N/A",
  },
  {
    title: "Link",
    value: data?.companyUrl || "N/A",
  },
  {
    title: "Status",
    value: data?.isActive? 'Active' : 'Inactive',
  }]

  return (
    <div className="partner-tab">
      <Title text={data?.companyName} />
      {displayedData.map((v) => (
        <div className="list-item" key={v.title}>
          <div className="title">{v.title}:</div>
          <div
            className="value"
            title={v.value}
          >
            {v.value}
          </div>
        </div>
      ))}
    </div>
  )
}

export default PartnerTab;