import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import LocalStorageKeys from "@client.enums/localStorageKeys";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import { getProfileAsync } from "./profile";

interface IProcessLoginPayload {
  password: string;
  emailAddress: string;
}

interface IProcessForgotPasswordPayload {
  email: string;
}

interface IResetPasswordPayload {
  password: string;
  token: string;
}

const initialState = {
  error: null,
  loading: false,
};

export const processLoginAsync = createAsyncThunk(
  "login/login",
  async ({ password, emailAddress }: IProcessLoginPayload, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ServiceProvider.User.login(emailAddress, password);
      const login = get(response, "data.login", null);
      if (login) {
        ServiceProvider.LocalStorage.setItem(
          LocalStorageKeys.Token,
          login.token
        );
        await thunkAPI.dispatch(getProfileAsync());
      }
      return response;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const processForgotPasswordAsync = createAsyncThunk(
  "login/forgotPassword",
  async ({ email }: IProcessForgotPasswordPayload, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      await ServiceProvider.User.forgotPassword(email);
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "login/forgotPassword",
  async ({ token, password }: IResetPasswordPayload, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ServiceProvider.User.resetPassword(
        password,
        token
      );
      ServiceProvider.LocalStorage.setItem(
        LocalStorageKeys.Token,
        response.data.verifyAccount.token
      );
      return response;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const verifyAccountAsync = createAsyncThunk(
  "login/verifyAccount",
  async ({ token }: { token: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await ServiceProvider.User.verifyAccount(token);
      ServiceProvider.LocalStorage.setItem(
        LocalStorageKeys.Token,
        response.data.verifyAccount.token
      );
      return response;
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearLoginError: (state) => {
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processLoginAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(processLoginAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(processForgotPasswordAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(processForgotPasswordAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const { setLoading, clearLoginError } = loginSlice.actions;

export const selectLoginError = (state) => state.login.error;
export const selectLoginLoading = (state) => state.login.loading;

export default loginSlice.reducer;
