import BaseService from "@client.services/baseService";

export default class DeviceService extends BaseService {
  async getDevices(pagination) {
    return super.post("", {
      query: `
        query($accountId: String, $pagination: PaginationInputType, $isOnline: Boolean){
	      devices(accountId: $accountId, pagination: $pagination, isOnline: $isOnline){
            count
            results{
              deviceName
              machineId
              deviceType
              hardwareVersion
              softwareVersion
              timezone
              assignedAccountId
              assignedAccountName
              createdAccountName
              createdAccountId
              vpnId
              vpnLocalIp
              lastMessageDate
              isOnline
              latitude
              longitude
              status
              lastTelemetry {
                campaignId
                createdAt
                machineId
                network {
                  connectionTime
                  localIp
                  remoteIp
                }
                sensors {
                  accelerometer {
                    xValue
                    yValue
                    zValue
                  }
                  cpuTemperature
                  fan {
                    dutyA
                    dutyB
                  }
                  gnss {
                    latitude
                    longitude
                  }
                  gyroscope {
                    xValue
                    yValue
                    zValue
                  }
                  lightSensor
                  screen {
                    brightness
                    temperature
                  }
                  utilization {
                    core
                    disk
                    memory
                  }
                }
                uptime
              }
              Id
              createdAt
              updatedAt
              isActive
            }
          }
        }`,
      variables: {
        pagination,
      },
    });
  }

  async getApprovals() {
    const response = await super.post("", {
      query: `
        query{
          fetchPendingDevices{
            lookupId
            createdAt
            machineId
            verificationCode
            country
            timezoneCode
          }
        }
    `,
    });
    return response;
  }

  async approveDevice(lookupId) {
    return super.post("", {
      query: `mutation configureDeviceApproval($lookupId: String){
      configureDeviceApproval(lookupId: $lookupId){
      message
      }
    } `,
      variables: {
        lookupId: lookupId,
      },
    });
  }

  async getDeviceById(id) {
    return super.post("", {
      query: `query($id: String){
        device(id: $id){
    Id
    assignedAccountId
    createdAccountId
    createdAt
    deviceType
    deviceName
    hardwareVersion
    isActive
    isOnline
    lastTelemetry {
      campaignId
      createdAt
      machineId
      network {
        connectionTime
        localIp
        remoteIp
      }
      sensors {
        accelerometer {
          xValue
          yValue
          zValue
        }
        cpuTemperature
        fan {
          dutyA
          dutyB
        }
        gnss {
          latitude
          longitude
        }
        gyroscope {
          xValue
          yValue
          zValue
        }
        lightSensor
        screen {
          brightness
          temperature
        }
        utilization {
          core
          disk
          memory
        }
      }
      uptime
    }
    lastMessageDate
    latitude
    longitude
    machineId
    softwareVersion
    timezone
    status
    updatedAt
    vpnId
    vpnLocalIp
  }
}`,
      variables: {
        id: id,
      },
    });
  }

  async getDeviceCaptures({ advertisementId, deviceId, startDate, endDate }) {
    return super.post("", {
      query: `
        query (
          $campaignId: String
          $deviceId: String
          $startDate: String
          $endDate: String
        ) {
          captures(
            campaignId: $campaignId
            deviceId: $deviceId
            startDate: $startDate
            endDate: $endDate
          ) {
            count
            results {
              Id
              endDate
              afterMediaUrl
              startDate
              status
            }
          }
        }
      `,
      variables: {
        campaignId: advertisementId,
        deviceId,
        startDate,
        endDate,
      },
    });
  }

  async fetchDeviceTelemetry(id) {
    return super.post("", {
      query: `query($id: String) {
        telemetries(id: $id) {
    count
    results {
      Id
      campaignId
      createdAt
      isActive
      machineId
      network {
        connectionTime
        localIp
        remoteIp
      }
      sensors {
        accelerometer {
          xValue
          yValue
          zValue
        }
        cpuTemperature
        gnss {
          latitude
          longitude
        }
        gyroscope {
          xValue
          yValue
          zValue
        }
        screen {
          brightness
          temperature
        }
        lightSensor
        fan {
          dutyA
          dutyB
        }
        utilization {
          core
          disk
          memory
        }
        battery {
          percentage
          temperatureA
          temperatureB
        }
      }
      updatedAt
      uptime
    }
  }
}
      `,
      variables: { id },
    });
  }

  async updateDeviceMedia(lookupId, defaultMedia) {
    return super.post("", {
      query: `mutation updateDevice($id: String, $fields: DeviceInputType) {
        updateDevice(id: $id, fields: $fields) {
          message
        }
      }`,
      variables: {
        id: lookupId,
        fields: {
          defaultMedia: defaultMedia,
        },
      },
    });
  }

  async flickrSignal(macAddress, signal) {
    return super.post("", {
      query: `mutation CloudFlicker($macAddress: String, $signal: String) {
        cloudFlicker(macAddress: $macAddress, signal: $signal) {
          message
        }
      }`,
      variables: {
        macAddress,
        signal,
      },
    });
  }

  async deviceRegistrationsJudgement({ id, status }) {
    return this.post("", {
      query: `
        mutation ($id: String, $status: Int) {
          deviceRegistrationsJudgement(id: $id, status: $status)
        }
      `,
      variables: { id, status },
    });
  }

  async fetchDeviceRegistrationFlagStatus() {
    return super.post("", {
      query: `
        query {
          deviceRegistrationFlagStatus
        }
      `,
    });
  }

  async modifyDeviceRegistrationFlags({ autoRegistration }) {
    return super.post("", {
      query: `
        mutation ($autoRegistration: Boolean) {
          modifyDeviceRegistrationFlags(autoRegistration: $autoRegistration)
        }
      `,
      variables: { autoRegistration },
    });
  }
}
