import React, { Dispatch, SetStateAction } from 'react';

import { IZonePolygon, IZonePolygonData } from '@client.types/zonePolygon';
import Button from '@client.components/core/Button';
import LocationTypes from '@client.enums/locationTypes';
import { useCreateAdvertisementForm } from '@client.pages/CreateAdvertisement/CreateAdvertisementFormContext';

interface IProps {
  drawnPolygon: IZonePolygonData[];
  setDrawnPolygon: Dispatch<SetStateAction<IZonePolygonData[]>>;
  setMapLocations: Dispatch<SetStateAction<IZonePolygon[]>>;
}

const CustomTab = ({ drawnPolygon, setDrawnPolygon, setMapLocations }: IProps) => {
  const { 
    createAdvertisementFormMethods,
    setSelectedLocationsList,
  } = useCreateAdvertisementForm();

  const {
    setValue,
    watch,
  } = createAdvertisementFormMethods;

  const customPolygonLocations = watch("customPolygonLocations");

  const handleConfirmCustomLocation = () => {
    const locationId = `Custom location (${drawnPolygon.map((point) => `${point.lat.toFixed(2)} ${point.lng.toFixed(2)}`).join(", ")})`;
    setSelectedLocationsList(
      (prev) => 
        [...prev, {
          title: `Custom location (${drawnPolygon.map((point) => `${point.lat.toFixed(2)} ${point.lng.toFixed(2)}`).join(", ")})`,
          id: locationId,
          type: LocationTypes.Custom
        }]
    );
    setMapLocations((prev) => [...prev.filter((mapLocation) => mapLocation.id !== 'temporary'), {
      color: 'red',
      data: drawnPolygon,
      id: locationId,
    }])
    setValue("customPolygonLocations", [...customPolygonLocations, {
      id: locationId,
      data: JSON.stringify(drawnPolygon),
    }])
    setDrawnPolygon([]);
  }

  if (!drawnPolygon.length) {
    return (
      <p>Please draw a custom zone</p>
    )
  }

  return (
    <div className="custom-zone">
      {drawnPolygon.map((point, index) => (
        <div className="point-row" key={index}>
          <p className="point-label">Point {index + 1}:</p>
          <div className="point-value">
            <p className="point-value-text">Latitude: {point.lat.toFixed(5)}</p>
            <p className="point-value-text">Longitude: {point.lng.toFixed(5)}</p>
          </div>
        </div>
      ))}
      <Button text="Confirm" onClick={handleConfirmCustomLocation} />
    </div>
  )
};

export default CustomTab;
