import React, { useEffect, useRef, useState } from "react";

import SvgIcon from "@client.core.components/SvgIcon";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

interface IProps {
  src: string;
  showThumbnail?: boolean;
  isNotFound?: boolean;
  fallbackElement?: () => React.ReactNode;
  loop?: boolean;
  controls?: boolean;
  onLoad?: (e: any) => void;
  onError?: () => void;
  onEnded?: () => void;
}

const MediaFile = ({ 
  src, 
  showThumbnail, 
  isNotFound, 
  fallbackElement, 
  onLoad, 
  onError 
}: IProps): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [isImageNotFound, setIsImageNotFound] = useState(false);
  const [isVideo, setVideo] = useState(false);

  const getFileExtension = (url) => {
    if (!url) {
      return null;
    }

    const urlParts = url.split(".");
    const extension = urlParts[urlParts.length - 1].toLowerCase();

    return extension;
  };

  const isVideoFile = (url) => ["mp4"].includes(getFileExtension(url));

  useEffect(() => {
    if (isVideoFile(src) && videoRef.current && !showThumbnail) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [src]);

  const renderMediaFile = () => {
    if (getFileExtension(src) === "avi") {
      if (onError) onError();
      return <NoDataPlaceholder description="AVI format is not supported" />;
    }

    return isVideo ? (
      <video
        ref={videoRef}
        muted
        autoPlay={!showThumbnail}
        loop={!showThumbnail}
        onLoadedMetadata={(e) => {
          const target = e.nativeEvent.target as HTMLVideoElement;
          if (onLoad) {
            onLoad({
              width: target.videoWidth,
              height: target.videoHeight,
            });
          }
        }}
        onError={() => {
          setIsImageNotFound(true);
          if (onError) onError();
        }}
      >
        <source src={showThumbnail ? `${src}#t=1` : src} type={`video/mp4`} />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        src={src}
        onLoad={(e) => {
          const target = e.target as HTMLImageElement;
          if (onLoad) {
            onLoad({
              width: target.naturalWidth,
              height: target.naturalHeight,
            });
          }
        }}
        onError={() => {
          setVideo(true);
        }}
      />
    );
  };

  if (isNotFound || isImageNotFound) {
    return fallbackElement ? fallbackElement() as JSX.Element : <SvgIcon name="file-not-found" /> 
  }

  return renderMediaFile();
};

export default MediaFile;