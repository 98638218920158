import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import useToast from "@client.hooks/useToast";
import useStableParams from "@client.hooks/useStableParams";
import { IOutletContext } from "@client.types/outletContext";
import { IAdvertisement } from "@client.types/advertisement";
import ServiceProvider from "@client.services/provider";

import Loader from "@client.core.components/Loader";

import AdvertisementDetails from "./AdvertisementDetails";
import AdvertisementCard from "./AdvertisementCard";
import "./styles.scss";

const AdvertisementPage = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const context = useOutletContext<IOutletContext>();

  const { id } = useStableParams();

  const [advertisement, setAdvertisement] = useState<IAdvertisement | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    context.setShowAdvertiser(true);

    return () => {
      context.setShowAdvertiser(false);
      context.setNavigationLink(null);
    };
  }, [context]);

  useEffect(() => {
    const fetchAdvertisement = async () => {
      setLoading(true);
      try {
        const response = await ServiceProvider.Advertisement.getAdvertisementById(id);
        if (response.data.campaign) {
          setAdvertisement(response.data.campaign)
        } else {
          showErrorToast("Can't get advertisement information")
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get advertisement information";
        showErrorToast(errorMessage)
      } finally {
        setLoading(false);
      }
    }

    fetchAdvertisement();
  }, [id]);

  if (isLoading) {
    return (
      <div className="advertisement-page page-loading">
        <Loader loading={true} />
      </div>
    );
  }

  if (!advertisement) {
    return (
      <div className="advertisement-page not-found">
        {t("Pages.Advertisement.advertisementNotFound")}
      </div>
    );
  }

  return (
    <div className="advertisement-page">
      <AdvertisementCard advertisement={advertisement} />
      <AdvertisementDetails advertisement={advertisement} />
    </div>
  );
};

export default AdvertisementPage;
