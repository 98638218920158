import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import config from "@client.config";

import useToast from "@client.hooks/useToast";

import { IFile } from "@client.types/file";

import FileUploaderPlaceholder from "@client.components/FileUploaderPlaceholder";

import "./styles.scss";

interface IProps {
  onChange: (file: IFile) => void,
  placeholderUrl?: string | undefined,
}

const FileUploader = ({ onChange, placeholderUrl }: IProps) => {
  const maxSize = config.maxFileSizeInMb ? +config.maxFileSizeInMb * 1024 * 1024 : 0;
  const { t } = useTranslation();
  const { showToastWarning } = useToast();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": []
    },
    onDrop: (acceptedFiles: IFile[], rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        const rejectedFile = rejectedFiles[0];
        if (rejectedFile.file.size > maxSize) {
          showToastWarning("components.Uploader.fileSizeExceeds", {
            value: config.maxFileSizeInMb,
          });
        }
      } else {
        const file = acceptedFiles[0];

        if (file.type.startsWith("image/")) {
          const img = new Image();
          img.src = URL.createObjectURL(file as unknown as Blob);
          img.onload = () => {
            onChange(file);
          };
        } else {
          onChange(file);
        }
      }
    },
    maxSize: maxSize,
  });

  return (
    <section className="file-uploader">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <FileUploaderPlaceholder
          url={placeholderUrl}
          className="uploader-rectangle"
          description={t("components.Uploader.maxSize", {
            value: config.maxFileSizeInMb,
          })}
        />
      </div>
    </section>
  );
};

export default FileUploader;
