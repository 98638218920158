import React from "react";
import classNames from "classnames";

import "./styles.scss";

interface IProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
}

const Card = ({ title, children, className }: IProps) => {
  return (
    <div className={classNames("card-container", className)}>
      {title && <div className="card-title">{title}</div>}
      {children}
    </div>
  );
};

export default Card;
