import Loader from "@client.core.components/Loader";
import IndividualInvoicePage from "@client.pages/IndividualInvoice";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceByIdAsync, makeInvoiceInfo } from "../../reducers/Invoice";

const InvoiceDetails = ({ id, onView, onDelete }) => {
  const dispatch = useDispatch();
  const selectedInvoice = useSelector(makeInvoiceInfo);

  useEffect(() => {
    dispatch(getInvoiceByIdAsync(id));
  }, [dispatch, id]);

  const { invoice } = selectedInvoice;
  if (invoice === null) {
    return null;
  }

  return (
    <>
      {selectedInvoice.loading ? (
        <div className="invoice-details invoice-details-loading">
          <Loader loading={true} />
        </div>
      ) : (
        <IndividualInvoicePage invoice={selectedInvoice.invoice} />
      )}
    </>
  );
};

InvoiceDetails.defaultProps = {
  showMap: true,
};

InvoiceDetails.propTypes = {
  id: PropTypes.any,
  showMap: PropTypes.bool,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
};

export default InvoiceDetails;
