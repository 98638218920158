import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IUser } from "@client.types/user";
import { IGetUsersVariables } from "@client.types/queries/getUsers";

import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import Tabs from "@client.components/Tabs";
import { FaChartLine, FaKey, FaUser } from "react-icons/fa";

import GeneralTab from "./tabs/General";
import BalanceTab from "./tabs/Balance";
import ChangePasswordTab from "./tabs/ChangePassword";
import { useNavigate, useParams } from "react-router-dom";

enum UserTabs {
  General = 0,
  Balance = 1,
  ChangePassword = 2,
}

const UserTabsNames = {
  General: 'general',
  Balance: 'balance',
  ChangePassword: 'change-password',
}

const getTabNumberFromString = (tabName: string) => {
  switch (tabName) {
  case UserTabsNames.General: 
    return UserTabs.General;
  case UserTabsNames.Balance: 
    return UserTabs.Balance;
  case UserTabsNames.ChangePassword: 
    return UserTabs.ChangePassword;
  default: 
    return UserTabs.General;
  }
}

const getTabStringFromNumber = (tabNumber: UserTabs) => {
  switch (tabNumber) {
  case UserTabs.General: 
    return UserTabsNames.General;
  case UserTabs.Balance: 
    return UserTabsNames.Balance;
  case UserTabs.ChangePassword: 
    return UserTabsNames.ChangePassword;
  default: 
    return UserTabsNames.General;
  }
}

interface IProps {
  selectedUser: IUser | null;
  getUsersVariables: IGetUsersVariables
}

const UserInformation = ({ selectedUser, getUsersVariables }: IProps) => {
  const { t } = useTranslation();
  const { id, tab } = useParams();
  const navigate = useNavigate();

  const selectedTab = tab ? getTabNumberFromString(tab) : UserTabs.General;

  const tabs = [
    {
      id: UserTabs.General,
      name: t("components.ProfileTabs.general"),
      icon: <FaUser />,
    },
    {
      id: UserTabs.Balance,
      name: t("components.ProfileTabs.balance"),
      icon: <FaChartLine />,
    },
    {
      id: UserTabs.ChangePassword,
      name: t("components.ProfileTabs.changePassword"),
      icon: <FaKey />,
    },
  ];

  const renderTabContent = () => {
    if (!selectedUser) {
      return null
    }

    switch (selectedTab) {
    case UserTabs.General:
      return <GeneralTab selectedUser={selectedUser} getUsersVariables={getUsersVariables} />;
    case UserTabs.Balance:
      return <BalanceTab selectedUser={selectedUser} />;
    case UserTabs.ChangePassword:
      return <ChangePasswordTab selectedUser={selectedUser} />;
    default:
      return null;
    }
  };

  const handleTabChange = (newTab) => {
    navigate(`/users/${id}/${getTabStringFromNumber(newTab)}`);
  };

  return (
    <div className="user-information">
      {selectedUser ? (
        <>
          <Tabs
            tabs={tabs}
            className="user-tabs"
            value={selectedTab}
            onSelect={handleTabChange}
          />
          {renderTabContent()}
        </>
      ) : (
        <div className="no-data-wrapper">
          <NoDataPlaceholder description={t("pages.Accounts.noData")} />
        </div>
      )}
    </div>
  );
};

export default UserInformation;
