import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceProvider from "@client.services/provider";
import { IUserLog } from "@client.types/userLog";
import { IGetUserLogsData, IGetUserLogsVariables } from "@client.types/queries/getUserLogs";

interface IUserLogsSlice {
  data: IUserLog[];
  count: number;
  error: string | null;
  loading: boolean;
}

const initialState: IUserLogsSlice = {
  data: [],
  count: 0,
  error: null,
  loading: false,
};

export const getUserLogsAsync = createAsyncThunk<
  IGetUserLogsData,
  IGetUserLogsVariables
>(
  "userLogs/getUserLogs",
  async (params) => {
    const response = await ServiceProvider.UserLog.getUserLogs(params);
    return response.data.userLogs;
  }
);

const userLogsSlice = createSlice({
  name: "userLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserLogsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserLogsAsync.fulfilled, (state, action) => {
        state.data = action.payload.results;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(getUserLogsAsync.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const selectUserLogsData = (state) => state.userLogs.data;

export default userLogsSlice.reducer;
