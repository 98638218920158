import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polygon,
  HeatmapLayer,
  DrawingManager,
  MarkerClusterer,
} from "@react-google-maps/api";

import useAvailableColors from "@client.hooks/useAvailableColors";

import { IZonePolygon } from "@client.types/zonePolygon";
import { IHeatmap } from "@client.types/heatmap";

import getMarkerIcon from "./getMarkerIcon";
import "./styles.scss";
import { LatLng } from "use-places-autocomplete";

const NEW_YORK_CENTER = { lat: 40.7128, lng: -74.0060 };

interface IProps {
  className?: string,
  options: any,
  zoom?: number,
  markers?: LatLng[],
  polygons?: IZonePolygon[],
  polymarks?: any,
  heatmapData?: IHeatmap[],
  recenterMapTimestamp?: any,
  drawingManagerKey?: string,
  drawingManagerRef?: any,
  onDrawingManagerPolygonComplete?: any,
  onClusterClick?: any,
  onMarkerClick?: any,
  onZoneClick?: any,
  children?: React.ReactNode,
}

const Map = ({
  className,
  options,
  zoom,
  markers,
  polygons,
  polymarks,
  heatmapData,
  recenterMapTimestamp,
  drawingManagerKey,
  drawingManagerRef,
  onDrawingManagerPolygonComplete,
  onClusterClick,
  onMarkerClick,
  onZoneClick,
  children,
}: IProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { getSubsequentColor } = useAvailableColors();

  const formattedData = heatmapData?.map(({ latitude, longitude }) => ({
    lat: latitude,
    lng: longitude
  })) || [];

  useEffect(() => {
    if (!map || !recenterMapTimestamp || !markers) return;

    const bounds = new window.google.maps.LatLngBounds();

    markers.forEach((marker) => {
      bounds.extend(marker);
    });

    map.fitBounds(bounds);
  }, [map, markers, recenterMapTimestamp]);

  // LEAVE IT FOR FUTURE USE, TO PUT MAP POSITION WHERE THE MARKERS ARE
  // useEffect(() => {
  //   if (!markers ||!markers.length || !map) return;

  //   const bounds = new window.google.maps.LatLngBounds();

  //   markers.forEach((marker) => {
  //     bounds.extend(marker);
  //   });

  //   const fitMapBounds = () => {
  //     map.fitBounds(bounds);
  //     if (markers.length === 1) {
  //       map.setZoom(12);
  //     }
  //   };

  //   fitMapBounds();
  // }, [map]);

  useEffect(() => {
    if (!map) return;
    map.setCenter(NEW_YORK_CENTER);
    map.setZoom(10);
  }, [map]);

  const onPolygonComplete = (polygon) => {
    if (!map) {
      return;
    }

    const paths = polygon
      .getPath()
      .getArray()
      .map((point) => {
        return { lat: point.lat(), lng: point.lng() };
      });
    const bounds = new window.google.maps.LatLngBounds();

    paths.forEach(({ lat, lng }) => {
      bounds.extend(new window.google.maps.LatLng(lat, lng));
    });
    map.fitBounds(bounds);

    onDrawingManagerPolygonComplete(paths);
  };

  useEffect(() => {
    if (map && polygons && polygons.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      polygons.forEach((polygon) => {
        polygon?.data?.forEach(({ lat, lng }) =>
          bounds.extend(new window.google.maps.LatLng(lat, lng))
        );
      });
      map.fitBounds(bounds);
    }
  }, [map, polygons]);

  const onLoad = useCallback((m: google.maps.Map) => {
    setMap(m);
  }, []);

  const handleClusterClick = useCallback(
    (cluster) => {
      const markerData = cluster.getMarkers().map((marker) => marker.metaInfo);

      if (onClusterClick) {
        onClusterClick(markerData);
      }
    },
    [onClusterClick, markers]
  );

  const onLoadMarker = (marker, data) => {
    marker.metaInfo = data;
  };

  return (
    <div className="map-container">
      <GoogleMap
        center={NEW_YORK_CENTER || location}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={() => setMap(null)}
        options={options}
        mapContainerClassName={className}
      >
        {children}
        {markers ? (
          <MarkerClusterer
            averageCenter
            clusterClass="map-cluster"
            enableRetinaIcons
            gridSize={60}
            zoomOnClick={false}
            onClick={handleClusterClick}
          >
            {(clusterer) => (
              <>
                {
                  markers.map((location, index) => (
                    <Marker
                      key={index}
                      position={location}
                      icon={getMarkerIcon()}
                      clusterer={clusterer}
                      onClick={() => onMarkerClick && onMarkerClick(location)}
                      onLoad={(markerInstance) =>
                        onLoadMarker(markerInstance, location)
                      }
                    />
                  ))
                }
              </>)}
          </MarkerClusterer>
        ) : false}

        {polygons &&
          polygons.map((polygon, index) => (
            <Polygon
              key={index}
              paths={polygon.data}
              options={{
                fillColor: polygon.color,
                fillOpacity: 0.35,
                strokeColor: polygon.color,
                strokeOpacity: 1,
                strokeWeight: 2
              }}
            />
          ))}
        {polymarks &&
          polymarks.map((polygonData, index) => {
            const polygon = JSON.parse(polygonData.polygon);
            const color = polygonData.color;
            const paths = polygon.map((coord) => ({
              lat: parseFloat(coord[1]),
              lng: parseFloat(coord[0])
            }));
            return (
              <Polygon
                onClick={() => onZoneClick(polygonData)}
                key={index}
                paths={paths}
                options={{
                  fillColor: color,
                  fillOpacity: 0.35,
                  strokeColor: color,
                  strokeOpacity: 1,
                  strokeWeight: 2
                }}
              />
            );
          })}

        {formattedData.length > 0 && (
          <HeatmapLayer
            data={formattedData.map(
              (d) => new window.google.maps.LatLng(d.lat, d.lng)
            )}
          />
        )}

        {drawingManagerRef && (
          <DrawingManager
            key={drawingManagerKey}
            onPolygonComplete={onPolygonComplete}
            onOverlayComplete={(e) => {
              e.overlay?.setMap(null);
            }}
            onLoad={(ref) => {
              drawingManagerRef(ref);
            }}
            options={{
              drawingControlOptions: {
                drawingModes: ['polygon'] as any
              },
              polygonOptions: {
                editable: true,
                draggable: true,
                fillColor: getSubsequentColor(1),
                strokeColor: getSubsequentColor(1),
                zIndex: 2
              }
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default React.memo(Map);
