import React, { useCallback, useEffect, useState } from "react";
import Map from "@client.components/Map";
import DashboardMapFilter from "./DashboardMapFilter";
import DeviceStatuses from "@client.enums/deviceStatuses";
import DeviceTypes from "@client.enums/deviceTypes";
import { IDevice } from "@client.types/device";
import { IZone } from "@client.types/zone";
import { IHeatmap } from "@client.types/heatmap";
import { IZonePolygon } from "@client.types/zonePolygon";
import useToast from "@client.hooks/useToast";
import ServiceProvider from "@client.services/provider";
import getMapFilterVariables from "./getMapFilterVariables";
import { IDevicesStatistics } from "@client.types/devicesStatistics";
import { FaCompress, FaExpand } from "react-icons/fa";
import classNames from "classnames";
import SvgIcon from "@client.components/core/SvgIcon";
import { IDashboardBoxes } from "@client.types/dashboardBoxes";
import OverviewBadge from "./OverviewBadge";

export interface IDashboardMapFilter {
  accountId: string;
  userId: string;
  showHeatmap: boolean;
  showZones: boolean;
  deviceType: number[];
  deviceOnline: boolean | null;
}

interface IProps {
  dashboardBoxes: IDashboardBoxes | null;
}

const DashboardMap = ({ dashboardBoxes }: IProps) => {
  const { showErrorToast } = useToast();

  const [isFullscreen, setFullScreen] = useState(false);
  const [mapFilter, setMapFilter] = useState<IDashboardMapFilter>({
    accountId: '',
    userId: '',
    showZones: false,
    showHeatmap: false,
    deviceType: [DeviceTypes.Unknown, DeviceTypes.Dynamic, DeviceTypes.Static],
    deviceOnline: false,
  });
  const [mapDevices, setMapDevices] = useState<IDevice[]>([]);
  const [mapHeatmap, setMapHeatmap] = useState<IHeatmap[]>([]);
  const [mapZones, setMapZones] = useState<IDevice[]>([]);
  const [mapDevicesStatistics, setMapDevicesStatistics] = useState<IDevicesStatistics | null>(null);
  const [heatmapSearchStartDate, setHeatmapSearchStartDate] = useState("");
  const [heatmapSearchEndDate, setHeatmapSearchEndDate] = useState("");

  const [isDrawingActive, setDrawingActive] = useState(false);
  const [recenterMapTimestamp, setRecenterMapTimestamp] = useState<number>();
  const [selectedClusterMarkers, setSelectedClusterMarkers] = useState<
    IDevice[]
  >([]);
  const [selectedZones, setSelectedZones] = useState<
    IZone[]
  >([]);
  const [drawingManagerKey, setDrawingManagerKey] = useState(
    new Date().getTime().toString()
  );
  const [polygonsData, setPolygonsData] = useState<IZonePolygon[]>([]);

  const fetchDashboardMap = async () => {
    try {
      const variables = getMapFilterVariables(mapFilter)
      const response = await ServiceProvider.Dashboard.getMap(variables);
      if (response.data.map) {
        setMapDevices(response.data.map.devices.results);
        setMapHeatmap(response.data.map.heatmap.results);
        setMapZones(response.data.map.zones.results);
        setMapDevicesStatistics(response.data.map.deviceStatistics);
      } else {
        showErrorToast("Can't get map information")
      }
    } catch (e) {
      const errorMessage =
    e instanceof Error ? e.message : "Can't get dashboard information";
      showErrorToast(errorMessage)
    }
  }

  useEffect(() => {
    fetchDashboardMap();
  
    const interval = setInterval(() => {
      fetchDashboardMap();
    }, 2000);

    return () => clearInterval(interval);
  }, [mapFilter])

  const markerPositions = mapDevices.map((device) => ({
    lat: device.latitude,
    lng: device.longitude,
    deviceId: device.Id,
    status: device.isOnline
      ? DeviceStatuses.Online
      : DeviceStatuses.Offline
  }));

  const changeDrawingMode = useCallback(() => {
    if (isDrawingActive) {
      setPolygonsData([]);
      setDrawingManagerKey(new Date().getTime().toString());
    }
    setDrawingActive((prev) => !prev);
  }, [isDrawingActive]);

  return (
    <>
      <div className={classNames("devices-map", isFullscreen ? "devices-map--fullscreen" : "")}>
        <Map
          className="dashboard-map"
          options={{
            mapTypeControl: false,
            disableDefaultUI: true,
            zoomControl: true,
            fullscreenControl: false,
          }}
          zoom={12}
          markers={markerPositions}
          polymarks={mapFilter.showZones ? mapZones : []}
          heatmapData={mapFilter.showHeatmap ? mapHeatmap : []}
          recenterMapTimestamp={recenterMapTimestamp}
          polygons={polygonsData}
          drawingManagerKey={drawingManagerKey}
          drawingManagerRef={
            isDrawingActive
              ? (v) => {
                if (v) {
                  v.setDrawingMode("polygon");
                }
              }
              : null
          }
          onDrawingManagerPolygonComplete={(coordinates) => {
            setPolygonsData((prev) => [
              ...prev,
              {
                color: "red",
                data: coordinates,
              }])}
          }
          onClusterClick={(markersData) => {
            const deviceIds: string[] = markersData.map((d) => d.deviceId);
            setSelectedClusterMarkers(
              mapDevices.filter((d) => deviceIds.includes(d.Id))
            );
          }}
          onMarkerClick={(marker) => {
            setSelectedClusterMarkers(
              mapDevices.filter((d) => d.Id === marker.deviceId)
            );
          }}
          onZoneClick={(zone) => {
            setSelectedZones([zone])
          }}
        >
          <>
            <button 
              title="Toggle fullscreen view" 
              type="button" 
              className="gm-control-active gm-fullscreen-control expand-button"
              onClick={() => setFullScreen(prev => !prev)}
            >
              {isFullscreen ? (
                <FaCompress />
              ) : (
                <FaExpand />
              )}
            </button>
            {isFullscreen ? (
              <>
                <div className="logo-container">
                  <SvgIcon
                    name="logo"
                    className="logo"
                  />
                </div>
                {dashboardBoxes ? (
                  <div className="overview-badges">
                    <OverviewBadge
                      value={dashboardBoxes.dailyBudgetSpent || 0}
                      icon="FaCoins"
                      info="This represents the total amount of budget spent on ads today. If the value is zero, no budget has been used yet."
                    />
                    <OverviewBadge
                      value={dashboardBoxes.dailyAdPlays || 0}
                      icon="FaPlay"
                      info="This indicates the duration ads have been played today. A zero value means no ads have been played so far."
                      color="yellow"
                    />
                    <OverviewBadge
                      value={0}
                      icon="FaEye"
                      info="This shows the number of times a person has been detected within the operating range today. If this is zero, no impressions have been recorded yet."
                      color="blue"
                    />
                    <OverviewBadge
                      value={dashboardBoxes.dailyAdViews || 0}
                      icon="FaUsers"
                      info="This shows the number of times users have viewed ads today. If this is zero, it means no ads have been watched by users yet."
                      color="red"
                    />
                    <OverviewBadge
                      value={dashboardBoxes.activeCampaigns || 0}
                      icon="FaVideo"
                      info="This displays the number of currently active ads. An active ad is one that is running and live."
                      color="green"
                    />
                  </div>
                ) : false}
              </>
            ) : false}
            <DashboardMapFilter
              onRecenterClick={() =>
                setRecenterMapTimestamp(new Date().getTime())
              }
              devicesStatistics={mapDevicesStatistics}
              selectedDeviceInfos={selectedClusterMarkers}
              selectedZones={selectedZones}
              isDrawingActive={isDrawingActive}
              changeDrawingMode={changeDrawingMode}
              mapFilter={mapFilter}
              setMapFilter={setMapFilter}
              heatmapSearchStartDate={heatmapSearchStartDate}
              setHeatmapSearchStartDate={setHeatmapSearchStartDate}
              heatmapSearchEndDate={heatmapSearchEndDate}
              setHeatmapSearchEndDate={setHeatmapSearchEndDate}
              polygonsData={polygonsData}
              setPolygonsData={setPolygonsData}
            />
          </>
        </Map>
      </div>
    </>
  );
};

export default DashboardMap;
