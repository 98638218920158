import React, { useState, createContext, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import { selectProfileData, setProfile } from "@client.reducers/profile";
import { IUser } from "@client.types/user";

interface IAuthContextType {
  user: IUser,
  login: (data: any) => void,
  logout: () => void,
  locationToNavigate: string,
  setLocationToNavigate: (location: string) => void,
}

const AuthContext = createContext<IAuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectProfileData);

  const [locationToNavigate, setLocationToNavigate] = useState(Pages.Dashboard as string);

  const value = useMemo(() => {
    const login = async (data) => {
      dispatch(setProfile(data));
      navigate(Pages.Dashboard);
    };

    const logout = () => {
      dispatch(setProfile(null));
      navigate(Pages.Login, { replace: true });
    };

    return {
      user,
      login,
      logout,
      locationToNavigate,
      setLocationToNavigate,
    };
  }, [user, locationToNavigate, dispatch, navigate]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
