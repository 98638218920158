import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface Props {
  text: string;
  children?: React.ReactElement;
  isLoading?: boolean;
}
const Title = ({ text, children, isLoading = false }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="page-title">
      {isLoading ? (
        <p className="loading-text" />
      ) : (
        <p>{text ? t(text) : ""}</p>
      )}
      {children}
    </div>
  );
};

export default Title;
