import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { TOOLTIP_ID } from "@client.constants";

import Input from "@client.core.components/Input";
import DateRangePicker from "@client.components/DateRangePicker";

import { IUserLogsFilter } from ".";
import "./styles.scss";

interface IProps {
  filter: IUserLogsFilter;
  setFilter: Dispatch<SetStateAction<IUserLogsFilter>>;
}

const UserLogsFilter = ({ filter, setFilter }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="filters-container">
      <Input
        label="Search"
        value={filter.search}
        onChange={(e) => setFilter({ ...filter, search: e })}
        data-tooltip-id={TOOLTIP_ID}
        data-tooltip-content={
          "Search Parameters\n- Description\n- User Full Name"
        }
      />
      <DateRangePicker
        dateFrom={filter.startDate}
        dateTo={filter.endDate}
        label={t("Pages.Advertisements.dateRange")}
        onChange={(range) =>
          setFilter({ ...filter, startDate: range[0], endDate: range[1] })
        }
      />
    </div>
  );
};

export default UserLogsFilter;
