const enum ZoneTypes {
  NA = 0,
  Country = 1,
  State = 2,
  County = 3,
  LocalArea = 4,
  Custom = 5
}

export default ZoneTypes;
