import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PasswordInput } from "@client.components/PasswordInput";

import "./styles.scss";

const PasswordValidator = ({ id, onChange, placeholder, onKeyDown, value }) => {
  const { t } = useTranslation();

  const [criteria, setCriteria] = useState({
    length: false,
    uppercase: false,
    number: false,
  });

  const validatePassword = (pswrd) => {
    const length = pswrd.length >= 8 && pswrd.length <= 32;
    const uppercase = /[A-Z]/.test(pswrd);
    const number = /\d/.test(pswrd);

    setCriteria({
      length,
      uppercase,
      number,
    });
  };

  const handlePasswordChange = (newPassword) => {
    onChange(newPassword);
    validatePassword(newPassword);
  };

  return (
    <div className="password-validator">
      <PasswordInput
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handlePasswordChange}
        onKeyDown={onKeyDown}
      />
      <div className="password-description">
        {t("components.PasswordValidator.mustHave")}
      </div>
      <ul>
        <li className={criteria.length ? "valid" : "invalid"}>
          {criteria.length ? "✔" : "✘"}{" "}
          {t("components.PasswordValidator.validation.length")}
        </li>
        <li className={criteria.uppercase ? "valid" : "invalid"}>
          {criteria.uppercase ? "✔" : "✘"}{" "}
          {t("components.PasswordValidator.validation.uppercase")}
        </li>
        <li className={criteria.number ? "valid" : "invalid"}>
          {criteria.number ? "✔" : "✘"}{" "}
          {t("components.PasswordValidator.validation.number")}
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidator;
