import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import Title from "@client.components/Title";
import ServiceProvider from "@client.services/provider";
import "./AdvertisementApprovals.scss";
import AdvertisementStatuses from "@client.enums/advertisementStatuses";
import {
  getAccountsAsync,
  selectAccountsHierarchy,
  setSelectedAccount,
} from "@client.reducers/accounts";
import { useDispatch, useSelector } from "react-redux";
import SvgIcon from "@client.core.components/SvgIcon";
import AdvertisementApprovalSummary from "./AdvertisementApprovalSummary";

const ITEMS_PER_PAGE = 10;

const AdvertisementApprovals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedAdvertisement, setselectedAdvertisement] = useState(null);

  useEffect(() => {
    dispatch(getAccountsAsync());
  }, [dispatch]);

  const accounts = useSelector(selectAccountsHierarchy);
  const enrichAdvertisementsWithAccountName = (advertisements, accounts) => {
    const accountMap = accounts?.reduce((acc, account) => {
      acc[account.Id] = account.companyName;
      return acc;
    }, {});

    return advertisements.map((advertisement) => ({
      ...advertisement,
      companyName:
        accountMap[advertisement.advertiserId] || "Unknown Advertiser",
    }));
  };

  const fetchAdvertisementApprovals = () => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    setIsLoading(true);
    ServiceProvider.Advertisement.getAdvertisements({
      limit: ITEMS_PER_PAGE,
      offset: offset,
      status: AdvertisementStatuses.PendingApproval,
    })
      .then((res) => {
        const enrichedAdvertisements = enrichAdvertisementsWithAccountName(
          res.data.campaigns.results,
          accounts
        );
        setData(enrichedAdvertisements);
        setTotalItems(res.data.campaigns.count);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAdvertisementApprovals();
  }, [currentPage, accounts]);

  // Handle row selection in DataTable
  const onSelectionChanged = (d) => {
    setselectedAdvertisement(d.data);
    const advertiserId = d.data.advertiserId;
    const selectedAcc = accounts.find((acc) => acc.Id === advertiserId);

    if (selectedAcc) {
      dispatch(setSelectedAccount(selectedAcc));
    }
  };

  return (
    <div className="advertisements-approvals">
      <div className="content">
        <div className="content-grid">
          <Title text="components.NavigationBar.advertisementApprovals" />
          <div className="advertisements-approvals-table-container">
            <div className="title-table-container">
              <DataTable
                columns={getColumns(t)}
                data={data}
                isLoading={isLoading}
                onSelectionChanged={onSelectionChanged}
                pagination={{
                  total: totalItems,
                  itemsPerPage: ITEMS_PER_PAGE,
                  onChange: setCurrentPage,
                }}
              />
            </div>
            {selectedAdvertisement ? (
              <AdvertisementApprovalSummary
                model={selectedAdvertisement}
                approve={true}
                fetchAdvertisementApprovals={fetchAdvertisementApprovals}
              />
            ) : (
              <div className="no-selection panel-default-width">
                <SvgIcon name="no-address" />
                <span>{t("pages.Accounts.noData")}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisementApprovals;
