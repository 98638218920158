import React from "react"

import { IInvoiceData } from "./types";
import { convertDateAndTime } from "@client.utils/utils";
import "./Invoice.scss";

function getOverdueDays(dueDateStr: string) {
  const dueDate = new Date(dueDateStr);
  const today = new Date();

  dueDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const diffMs = today.getTime() - dueDate.getTime();
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

  return diffDays > 0 ? diffDays : 0;
}

export function InvoiceInfo({ invoice: data }: { invoice: IInvoiceData }) {

  const { invoice, payments } = data

  const overdueDaysCount = getOverdueDays(invoice.dueDate)

  return (
    <div className="info">
      {invoice.status === 3 && <div className="date">
        <div className="date-overdue">
          <span>Overdue</span>
        </div>
        <span className="date-dalay">{overdueDaysCount} {overdueDaysCount > 1 ? 'days' : 'day'} delay</span>
      </div>}
      <div className="info">
        <div>
          <h3>Total Amount</h3>
          <span className="highlighed">$ {invoice.amount}</span>
        </div>
        <div>
          <h3>Open Amount</h3>
          <span>$ {invoice.status === 2 ? "0" : invoice.amount}</span>
        </div>
        <div>
          <h3>VAT Amount</h3>
          <span>$ 0</span>
        </div>
        <div>
          <h3>Due Date</h3>
          <span>{convertDateAndTime(invoice.dueDate)}</span>
        </div>
        <div>
          <h3>Paid On</h3>
          <span>{invoice.status === 2 ? convertDateAndTime(payments[0].createdAt) : '-'}</span>
        </div>
        <div>
          <h3>Customer Av. Delay</h3>
          <span>0 Days</span>
        </div>
      </div>
      <div className="payment">
        <div>
          <h2>Payments</h2>
        </div>
        <div className="payment-content">
          {payments.map(p => <div key={p.Id}>
            <div className="time-column">
              <span>$ {p.amount}</span>
              <span>{p.type === 1 ? 'Paid' : 'Credited'} on {convertDateAndTime(p.paymentDate)}</span>
            </div>
            <div>
              <span>Type</span>
              <span>{p.type === 1 ? 'Debit' : 'Credit'} &mdash; {p.method === 1 ? 'Account Balance' : 'Card'}</span>
            </div>
          </div>)}

        </div>
      </div>
    </div>
  )
}