const enum Pages {
  RegisterUser = "/register",
  InviteUser = "/user/invite",
  Login = "/login",
  Logout = "/logout",
  ForgotPassword = "/forgot-password",
  Verify = "/verify/:id",
  ResetPassword = "/reset/:id",

  // ProfileGeneral = "/profile",
  // ProfileBalance = "/profile/balance",
  // ProfileChangePassword = "/profile/changePassword",

  CreateAdvertisement = "/advertisements/create",
  Dashboard = "/dashboard",
  Devices = "/devices",
  DeviceRegistrations = "/device-registrations",
  Zones = "/zones",
  DefaultAdvertisements = "/default-advertisements",
  AdvertisementApprovals = "advertisements/approvals",
  Advertisements = "/advertisements",
  Advertisement = "/advertisement",
  Accounts = "/accounts",
  Users = "/users",
  Invoices = "/invoices",
  UserLogs = "/user-logs",
  SystemLogs = "/system-logs",

  FlickrDemo = "/flickrDemo",
  Reports = "/reports",

  DataAnalyzer = "/dataAnalyzer",
  DataAnalyzerBlob = "/dataAnalyzerBlob",
  DataAnalyzerDemo = "/dataAnalyzerDemo",
}

export default Pages;
