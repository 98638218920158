import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import {
  ICreateZoneResponse,
  createZoneAsync,
} from "@client.reducers/zones";
import ZoneTypes from "@client.enums/zoneTypes";
import { AppDispatch } from "@client.store";
import { IZone } from "@client.types/zone";

const DEFAULT_VALUES = {
  zoneName: "",
  primePpvRate: 0,
  standardPpvRate: 0,
  color: "",
  polygon: [],
};

const useCreateZoneForm = (
  setShowCreateZoneDrawer: Dispatch<SetStateAction<boolean>>,
  setFormZoneCategory: Dispatch<SetStateAction<ZoneTypes>>,
  onSelectZone: (zone: IZone) => void,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();

  const [parentZoneId, setParentZoneId] = useState(null);
  const [zoneCategory, setZoneCategory] = useState(null);

  const validationSchema = yup.object({
    zoneName: yup
      .string()
      .required(t("pages.Locations.components.ZoneManagement.validation.zoneName")),
    primePpvRate: yup
      .number()
      .required(t("pages.Locations.components.ZoneManagement.validation.primeRate")),
    standardPpvRate: yup
      .number()
      .required(t("pages.Locations.components.ZoneManagement.validation.ppvRate")),
    color: yup
      .string()
      .required(t("pages.Locations.components.ZoneManagement.validation.color")),
    polygon: yup
      .array()
      .of(yup.array().required()
        .of(yup.number().required()))
      .required()
      .min(1, 'Location is not correct'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const zoneName = watch('zoneName');

  const handleCreateChildZone = (parentZoneId, category) => {
    setParentZoneId(parentZoneId);
    setZoneCategory(category);
    setFormZoneCategory(category);
    setShowCreateZoneDrawer(true);
  }

  const resetForm = () => {
    reset(DEFAULT_VALUES);
  }

  const onSubmit = async (data) => {
    const zoneFields = {
      ...data,
      polygon: JSON.stringify(data.polygon),
      category: zoneCategory || ZoneTypes.Country,
      parentZoneId: parentZoneId || "",
    }
    try {
      const response = await dispatch(createZoneAsync(zoneFields));
      const createdZone = (response.payload as ICreateZoneResponse).data.createZone.zone;
      showToastAfterRequest(
        response,
        "pages.Locations.components.ZonesTree.modal.create.success"
      );
      resetForm();
      onSelectZone({
        ...createdZone,
        polygon: createdZone.polygon ? JSON.parse(createdZone.polygon) : [],
      });
      setShowCreateZoneDrawer(false);
    } catch (e) {
      console.log(e)
    }
  };

  const handleSubmitCreateZoneForm = handleSubmit(onSubmit);

  return { 
    register, 
    setValue,
    getValues, 
    resetForm, 
    errors,
    zoneName,
    handleSubmitCreateZoneForm, 
    handleCreateChildZone 
  }
}

export default useCreateZoneForm;