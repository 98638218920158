import React from "react";

import { convertDateAndTime } from "@client.utils/utils";
import Badge from "@client.components/Badge";

export const getColumns = (t) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1,
    cellRenderer: (params) => (
      <div className="clickable-cell">{params.value}</div>
    ),
    maxWidth: 80
  },
  {
    field: "Name",
    cellRenderer: (params) => (
      <div className="clickable-cell">
        {params.data.purpose}
      </div>
    ),
    tooltipField: "InvoiceName",
    headerName: t("pages.Invoices.columns.title")
  },
  {
    field: "Invocie Date",
    cellRenderer: (params) => (
      <div className="clickable-cell">
        {convertDateAndTime(params.data.createdAt)}
      </div>
    ),
    tooltipField: "InvoiceDate",
    headerName: t("pages.Invoices.columns.createdAt")
  },
  {
    field: "Due Date",
    cellRenderer: (params) => (
      <div className="clickable-cell">
        {convertDateAndTime(params.data.dueDate)}
      </div>
    ),
    tooltipField: "InvoiceDate",
    headerName: t("pages.Invoices.columns.dueDate")
  },
  {
    field: "status",
    headerName: t("pages.Invoices.columns.status"),
    cellClass: "data-table-cell cell-status clickable-cell",
    cellRenderer: (data) => {
      let statusClassName = "Unknown";
      let statusText = "Unknown";
      
      switch (data.data.status) {
      case 1:
        statusClassName = "Unpaid";
        statusText = t("pages.Invoices.status.unpaid");
        break;
      case 2:
        statusClassName = "Paid";
        statusText = t("pages.Invoices.status.paid");
        break;
      default:
        statusClassName = "Overdue";
        statusText = t("pages.Invoices.status.overdue");
      }
  
      return (
        <Badge
          text={statusText}
          className={statusClassName}
        />
      )
    },
  },
  {
    field: "amount",
    cellRenderer: (params) => (
      <div className="clickable-cell">$ {params.data.amount.toFixed(2)}</div>
    ),
    tooltipField: "InvoiceAmount",
    headerName: t("pages.Invoices.columns.amount")
  }
];
