import BaseService from "@client.services/baseService";

export default class DeviceRegistrationService extends BaseService {
  async getDeviceRegistrations(pagination) {
    return super.post("", {
      query: `
        query($pagination: PaginationInputType) {
          deviceRegistrations(pagination: $pagination) {
            count
            results{
              deviceName
              machineId
              deviceType
              hardwareVersion
              softwareVersion
              timezone
              assignedAccountId
              createdAccountId
              vpnId
              vpnLocalIp
              lastMessageDate
              isOnline
              latitude
              longitude
              status
              lastTelemetry {
                campaignId
                createdAt
                machineId
                network {
                  connectionTime
                  localIp
                  remoteIp
                }
                sensors {
                  accelerometer {
                    xValue
                    yValue
                    zValue
                  }
                  cpuTemperature
                  fan {
                    dutyA
                    dutyB
                  }
                  gnss {
                    latitude
                    longitude
                  }
                  gyroscope {
                    xValue
                    yValue
                    zValue
                  }
                  lightSensor
                  screen {
                    brightness
                    temperature
                  }
                  utilization {
                    core
                    disk
                    memory
                  }
                }
                uptime
              }
              Id
              createdAt
              updatedAt
              isActive
            }
          }
        }
      `,
      variables: {
        pagination,
      },
    });
  }

  async deviceRegistrationsJudgement({ id, status }) {
    return this.post("", {
      query: `
        mutation ($id: String, $status: Int) {
          deviceRegistrationsJudgement(id: $id, status: $status)
        }
      `,
      variables: { id, status },
    });
  }


  async getDeviceRegistrationFlagStatus() {
    return super.post("", {
      query: `
        query {
          deviceRegistrationFlagStatus
        }
      `,
    });
  }
  
  async modifyDeviceRegistrationFlags(autoRegistration) {
    return super.post("", {
      query: `
        mutation ($autoRegistration: Boolean) {
          modifyDeviceRegistrationFlags(autoRegistration: $autoRegistration)
        }
      `,
      variables: { autoRegistration },
    });
  }
}