import React, { useState, useEffect } from "react";

import CalendarView from "./CalendarView";
import "./styles.scss";
import moment from "moment";
import config from "@client.config";

interface IProps {
  dateFrom: string,
  dateTo: string,
  onChange: (startEndDates: string[]) => void,
  label?: string,
  placeholder?: string,
  twoFieldsView?: boolean,
  labelStart?: string,
  labelEnd?: string,
  withTime?: boolean,
  isColumn?: boolean,
}

const DateRangePicker = ({
  dateFrom,
  dateTo,
  onChange,
  label,
  placeholder = "Select a range",
  twoFieldsView,
  labelStart,
  labelEnd,
  withTime,
  isColumn,
}: IProps) => {
  const [startDate, setStartDate] = useState<string>(dateFrom);
  const [endDate, setEndDate] = useState<string>(dateTo);
  const [showCalendarView, setShowCalendarView] = useState(false);

  useEffect(() => {
    setStartDate(dateFrom);
    setEndDate(dateTo);
  }, [dateFrom, dateTo]);

  const toggleCalendarView = () => {
    setShowCalendarView((prev) => !prev);
  };

  const renderStartDateValue = () => {
    if (twoFieldsView) {
      return startDate ? moment(startDate).format(withTime ? config.dateTimeFormat : config.dateFormat) : ''
    }
    return startDate 
      ?`${moment(startDate).format(withTime ? config.dateTimeFormat : config.dateFormat)}${endDate ? ` - ${moment(endDate).format(withTime ? config.dateTimeFormat : config.dateFormat)}` : ""}`
      : ''
  }

  const renderContainerClasses = () => {
    if (twoFieldsView && !isColumn) {
      return `daterangepicker-container two-fields-container`
    }
    return "daterangepicker-container"
  }

  return (
    <div className={renderContainerClasses()}>
      <div className="daterangepicker-input-container">
        {label
          ? <span className="daterangepicker-input-label">{label}</span> 
          : <span className="daterangepicker-input-label">{labelStart}</span>}
        <input
          type="text"
          placeholder={placeholder}
          readOnly
          value={renderStartDateValue()}
          onClick={toggleCalendarView}
          className="daterangepicker-input"
        />
      </div>
      {twoFieldsView ? (
        <div className="daterangepicker-input-container">
          {labelEnd && <span className="daterangepicker-input-label">{labelEnd}</span>}
          <input
            type="text"
            placeholder={placeholder}
            readOnly
            value={endDate ? moment(endDate).format(withTime ? config.dateTimeFormat : config.dateFormat) : ''}
            onClick={toggleCalendarView}
            className="daterangepicker-input"
          />
        </div>
      ) : false}
      {showCalendarView && (
        <CalendarView
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          toggleCalendarView={toggleCalendarView}
          onChange={onChange}
          withTime={withTime}
        />
      )}
    </div>
  );
};

export default React.memo(DateRangePicker);
