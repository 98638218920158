import React from "react";

import IconRenderer from "@client.components/FA/IconRenderer";

interface IProps {
  onClick: () => void;
}

const MinimizeIcon = ({ onClick }: IProps) => {
  return (
    <IconRenderer iconName="FaMinimize" size={15} onClick={onClick} />
  );
};

export default MinimizeIcon;
