import variables from "../variables.scss";

export const allColors = {
  ...variables,
};

const useAvailableColors = () => {
  const colors = [
    variables.color1,
    variables.color2,
    variables.color3,
    variables.color4,
    variables.color5,
  ];

  const getRandomColor = (colorArray = colors) => {
    const randomIndex = Math.floor(Math.random() * colorArray.length);
    return colorArray[randomIndex];
  };

  const getSubsequentColor = (index: number) => {
    return colors[index % colors.length];
  };

  return { getSubsequentColor, getRandomColor };
};

export default useAvailableColors;
