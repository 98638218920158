import BaseService from "@client.services/baseService";
import { IGetInvoicesResponse, IGetInvoicesVariables } from "@client.types/queries/getInvoices";

export default class InvoiceService extends BaseService {
  async getInvoices(
    variables: IGetInvoicesVariables = {}
  ): Promise<IGetInvoicesResponse> {
    return super.post("", {
      query: `
        query GetInvoices($pagination: PaginationInputType, $accountId: String) {
          invoices(pagination: $pagination, accountId: $accountId) {
            count
            results {
              Id
              amount
              createdAt
              dueDate
              invoiceDate
              isActive
              status
              type
              updatedAt
              purpose
            }
          }
        }
      `,
      variables,
    });
  }

  async getInvoiceById(id) {
    return super.post("", {
      query: `
      query ($id: String) {
        invoice(id: $id) {
          billFrom {
            companyName
            billingAddress {
              city
              country
              lineOne
              postalCode
            }
          }
          billTo {
            companyName
            billingAddress {
              city
              country
              lineOne
              postalCode
            }
          }
          
          invoice {
            amount
            createdAt
            dueDate
            Id
            invoiceDate
            isActive
            purpose
            status
            type
            updatedAt
          }
          payments {
            amount
            createdAt
            Id
            invoiceId
            isActive
            method
            paymentDate
            tranzillaHash
            type
            updatedAt
          }
        }
      }`,
      variables: {
        id,
      },
    });
  }

  async createInvoice(invoice) {
    return super.post("", {
      query: `mutation($fields: InvoiceInputType){
        createInvoice(fields: $fields){
          message
          invoice{
            Id
            invoiceDate
            dueDate
            amount
            status
          }
        }
      }`,
      variables: {
        fields: {
          invoiceDate: invoice.invoiceDate,
          dueDate: invoice.dueDate,
          amount: invoice.amount,
          status: invoice.status,
        },
      },
    });
  }

  async updateInvoice(model) {
    return super.post("", {
      query: `mutation($id: String, $fields: InvoiceInputType) {
        updateInvoice(id: $id, fields: $fields) {
          message
          invoice {
            Id
            invoiceDate
            dueDate
            amount
            status
          }
        }
      }`,
      variables: {
        id: model.Id,
        fields: {
          invoiceDate: model.invoiceDate || "",
          dueDate: model.dueDate || "",
          amount: model.amount || "",
          status: model.status || "",
        },
      },
    });
  }
}
