import BaseService from "@client.services/baseService";
import {
  ICreateAdvertisementResponse,
  ICreateAdvertisementVariables,
} from "@client.types/queries/createAdvertisement";
import {
  IGetAdvertisementHeatmapResponse,
  IGetAdvertisementHeatmapVariables,
} from "@client.types/queries/getAdvertisementHeatmap";
import {
  IGetAdvertisementInsightsResponse,
  IGetAdvertisementInsightsVariables,
} from "@client.types/queries/getAdvertisementInsights";
import {
  IGetAdvertisementMonitoringResponse,
  IGetAdvertisementMonitoringVariables,
} from "@client.types/queries/getAdvertisementMonitoring";
import {
  IGetAdvertisementPlacesResponse,
  IGetAdvertisementPlacesVariables,
} from "@client.types/queries/getAdvertisementPlaces";
import {
  IGetAdvertisementsResponse,
  IGetAdvertisementsVariables,
} from "@client.types/queries/getAdvertisements";
import {
  IUpdateAdvertisementResponse,
  IUpdateAdvertisementVariables,
} from "@client.types/queries/updateAdvertisement";

export default class AdvertisementService extends BaseService {
  async getAdvertisements(
    variables: IGetAdvertisementsVariables = {}
  ): Promise<IGetAdvertisementsResponse> {
    return super.post("", {
      query: `
        query Advertisements($pagination: PaginationInputType, $status: Int, $advertiserId: String){
          campaigns(pagination: $pagination, status: $status, advertiserId: $advertiserId){
            count
            results{
              title
              startDate
              endDate
              accountId
              accountName
              userId
              userFullName
              targets
              mediaId
              mediaUrl
              budgetTotal
              budgetDaily
              geofenceRadiusLatitude
              geofenceRadiusLongitude
              geofenceRadiusRange
              geofenceZoneIds
              geofenceCustomPolygons
              totalViews
              totalSpent
              totalPlays
              displayTime
              status
              rejectionReason
              latitude
              longitude
              Id
              createdAt
              updatedAt
              isActive
            }
          }
        }
      `,
      variables,
    });
  }

  async getAdvertisementById(id) {
    return super.post("", {
      query: `
        query GetAdvertisementById($id: String){
          campaign(id: $id){
            title
            startDate
            endDate
            accountId
            accountName
            userId
            userFullName
            targets
            mediaId
            mediaUrl
            budgetTotal
            budgetDaily
            geofenceRadiusLatitude
            geofenceRadiusLongitude
            geofenceRadiusRange
            geofenceZoneIds
            geofenceCustomPolygons
            totalViews
            totalSpent
            totalPlays
            displayTime
            status
            rejectionReason
            latitude
            longitude
            Id
            createdAt
            updatedAt
            isActive
          }
        }
      `,
      variables: {
        id,
      },
    });
  }

  async createAdvertisement(
    variables: ICreateAdvertisementVariables
  ): Promise<ICreateAdvertisementResponse> {
    return super.post("", {
      query: `
        mutation CreateAdvertisement($fields: AdvertismentInputType) {
          createAdvertisement(fields: $fields) {
            message
            advertisement {
              title
              startDate
              endDate
              accountId
              accountName
              userId
              userFullName
              targets
              mediaId
              mediaUrl
              budgetTotal
              budgetDaily
              geofenceRadiusLatitude
              geofenceRadiusLongitude
              geofenceRadiusRange
              geofenceZoneIds
              geofenceCustomPolygons
              totalViews
              totalSpent
              totalPlays
              displayTime
              status
              rejectionReason
              latitude
              longitude
              Id
              createdAt
              updatedAt
              isActive
            }
          }
        }
      `,
      variables,
    });
  }

  async updateAdvertisement(
    variables: IUpdateAdvertisementVariables
  ): Promise<IUpdateAdvertisementResponse> {
    return super.post("", {
      query: `
        mutation updateCampaign($id: String, $fields: CampaignInputType){
          updateCampaign(id: $id, fields: $fields){
            message
            campaign{
              Id
              status
              startDate
              endDate
            }
          }
        }
      `,
      variables,
    });
  }

  async updateAdvertisementStatus(
    variables: { id: string; status: number; rejectionReason?: string }
  ): Promise<IUpdateAdvertisementResponse> {
    return super.post("", {
      query: `
        mutation advertisementApproval($id: String!, $status: Int!, $rejectionReason: String) {
          advertisementApproval(id: $id, status: $status, rejectionReason: $rejectionReason) {
            message
            advertisement {
              Id
              status
              startDate
              endDate
            }
          }
        }
      `,
      variables,
    });
  }

  async getAdvertisementMonitoring(
    variables: IGetAdvertisementMonitoringVariables
  ): Promise<IGetAdvertisementMonitoringResponse> {
    return this.post("", {
      query: `
        query AdvertisementMonitoring($id: String, $startDate: String, $endDate: String) {
          campaignMonitoring(id: $id, startDate: $startDate, endDate: $endDate) {
            graphData {
              date
              plays
              spent
              views
            }
            totalPlays
            totalSpent
            totalViews
          }
        }
      `,
      variables,
    });
  }

  async getAdvertisementPlaces(
    variables: IGetAdvertisementPlacesVariables
  ): Promise<IGetAdvertisementPlacesResponse> {
    return super.post("", {
      query: `
        query AdvertisementPlaces($id: String, $deviceId: String) {
          campaignPlaces(id: $id, deviceId: $deviceId) {
            count
            results {
              deviceId
              deviceName
              latitude
              longitude
            }
          }
        }
      `,
      variables,
    });
  }

  async getAdvertisementHeatmap(
    variables: IGetAdvertisementHeatmapVariables
  ): Promise<IGetAdvertisementHeatmapResponse> {
    return super.post("", {
      query: `
        query AdvertisementHeatmap($id: String, $zoneId: String) {
          campaignHeatmap(id: $id, zoneId: $zoneId)  {
            count
            results{ 
              intensity
              latitude
              longitude
              name
              zoneId
            }
          }
        }
      `,
      variables,
    });
  }

  async getAdvertisementInsights(
    variables: IGetAdvertisementInsightsVariables
  ): Promise<IGetAdvertisementInsightsResponse> {
    return super.post("", {
      query: `
        query($id: String){
          campaignInsights(id: $id) {
            ageGraph {
              age
              female
              male
            }
            ageRangeFemaleHigh
            ageRangeFemaleLow
            ageRangeMaleHigh
            ageRangeMaleLow
            impressions
            ivpGraph {
              datetime
              impressions
              peopleDetected
              views
            }
            peopleAgeCount {
              age
              quantity
            }
            peopleDetected
            peopleFemale
            peopleMale
            playLocations {
              latitude
              longitude
            }
            views
          }
        }
      `,
      variables,
    });
  }

  async getPerformance(id) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchCampaignCaptures(lookupId: $lookupId) {
          createdAt
          latitude
          longitude
          views
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async getStatisticOverview(id, dateFrom, dateTo) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            date: "11/15/2023",
            plays: 100,
            views: 230,
            spent: 20,
          },
          {
            date: "11/16/2023",
            plays: 111,
            views: 240,
            spent: 23,
          },
          {
            date: "11/17/2023",
            plays: 50,
            views: 100,
            spent: 10,
          },
          {
            date: "11/18/2023",
            plays: 500,
            views: 800,
            spent: 40,
          },
        ]);
      }, 500);
    });
  }

  // eslint-disable-next-line no-unused-vars
  async getEstimation(data) {
    return new Promise((resolve) => {
      resolve({
        plays: 1000,
        pricePerView: 0.3,
        price: 300,
      });
    });
  }

  async getTargets(): Promise<{ id: number; name: string; }[]> {
    return Promise.resolve([
      {
        id: 1,
        name: "Brand Awareness",
      },
      {
        id: 2,
        name: "Lead Generation",
      },
      {
        id: 3,
        name: "Website Traffic",
      },
      {
        id: 4,
        name: "Sales Conversion",
      },
      {
        id: 5,
        name: "App Installs",
      },
      {
        id: 6,
        name: "Engagement",
      },
      {
        id: 7,
        name: "Retargeting",
      },
      {
        id: 8,
        name: "Attract to Location",
      },
      {
        id: 9,
        name: "Video Views",
      },
      { id: 10, name: "Google Search" },
      {
        id: 99,
        name: "Other",
      },
    ]);
  }

  async getTimeSlots() {
    return Promise.resolve([
      {
        id: "1",
        name: "All Day",
      },
      {
        id: "2",
        name: "Morning - 7am- 11am",
      },
      {
        id: "3",
        name: "Lunch - 11am- 2pm",
      },
      {
        id: "4",
        name: "Afternoon - 2pm - 5pm",
      },
      {
        id: "5",
        name: "Dinner - 5pm - 8pm",
      },
      {
        id: "6",
        name: "Evening - 8pm - 10pm",
      },
      {
        id: "7",
        name: "Night - 10pm - 7am",
      },
    ]);
  }

  async getLocations() {
    return super.post("", {
      query: `query {
        fetchLocations{
          lookupId
          createdAt
          isActive
          polygon
          pricePerView
          displayName
        }
      }`,
    });
  }

  async getAdvertisementDetails(id) {
    return this.post("", {
      query: `
        query($id: String) {
          campaignDetails(id: $id) {
            budgetDaily
            budgetSpent
            budgetTotal
            title
            endDate
            estimationPricePerPlay
            peopleReached
            pricePerDay
            pricePerPlay
            pricePerSlot
            startDate
            status
            locations {
              zoneName
            }
          }
        }
      `,
      variables: { id },
    });
  }
}
