import React from "react";

import { TOOLTIP_ID } from "@client.constants";

import IconRenderer from "@client.components/FA/IconRenderer";

import './styles.scss';
import classNames from "classnames";

interface IProps {
  value: number | string;
  text: string;
  icon?: string;
  info?: string;
  color?: string;
}

const OverviewCard = ({
  value,
  text,
  icon,
  info,
  color,
}: IProps) => {
  return (
    <div className={classNames("overview-card", color)}>
      {info ? (
        <IconRenderer
          iconName="FaCircleInfo"
          size={17}
          className="overview-card-info-icon"
          data-tooltip-id={TOOLTIP_ID}
          data-tooltip-content={info}
        />
      ) : false}
      {icon ? (
        <div className="overview-icon">
          <IconRenderer iconName={icon} size={23} />
        </div>
      ) : false}

      <div className="overview-info">
        <div className="overview-value">
          {value}
        </div>
        <div className="overview-text">{text}</div>
      </div>
    </div>
  );
};

export default OverviewCard;
