import config from "@client.config";
import { IDevice } from "@client.types/device";
import { ITelemetry } from "@client.types/telemetry";
import moment from "moment";

export const getDeviceDetails = (device: IDevice, t: (key: string) => string) => [
  {
    title: t("pages.Devices.Details.id"),
    value: device.Id || 'N/A'
  },
  {
    title: t("pages.Devices.Details.swVersion"),
    value: device.softwareVersion || 'N/A'
  },
  {
    title: t("pages.Devices.Details.hwVersion"),
    value: device.hardwareVersion || 'N/A'
  },
  {
    title: t("pages.Devices.Details.macId"),
    value: device.machineId || 'N/A'
  },
  {
    title: t("pages.Devices.Details.timeZoneCode"),
    value: device.timezone || 'N/A'
  },
  {
    title: t("pages.Devices.Details.status"),
    value: t(
      device.isOnline
        ? "pages.Devices.status.online"
        : "pages.Devices.status.offline"
    )
  },
  {
    title: t("pages.Devices.Details.update"),
    value: moment(device.updatedAt).format(config.dateTimeFormat)
  },
];

export const getDeviceLastTelemetry = (deviceTelemetry: ITelemetry, t: (key: string) => string) => [
  {
    label: t("pages.Device.telemetry.RecievedAt"),
    value: moment(deviceTelemetry.createdAt).format(config.dateTimeSecondsFormat)
  },
  {
    label: t("pages.Device.telemetry.CoreUtilization"),
    value: `${deviceTelemetry.sensors?.utilization?.core || "N/A"}`
  },
  {
    label: t("pages.Device.telemetry.MemoryUtilization"),
    value: `${deviceTelemetry.sensors?.utilization?.memory || "N/A"}`
  },
  {
    label: t("pages.Device.telemetry.DiskUtilization"),
    value: `${deviceTelemetry.sensors?.utilization?.disk || "N/A"}`
  },
  {
    label: t("pages.Device.telemetry.DeviceUptime"),
    value: `${deviceTelemetry.uptime}`
  },
  {
    label: t("pages.Device.telemetry.AvaliableLight"),
    value: `${deviceTelemetry.sensors?.lightSensor || "N/A"}`
  },
  {
    label: t("pages.Device.telemetry.Gyroscope"),
    value: `X: ${deviceTelemetry.sensors?.gyroscope.xValue || "N/A"} Y: ${
      deviceTelemetry.sensors?.gyroscope.yValue || "N/A"
    } Z: ${deviceTelemetry.sensors?.gyroscope.zValue || "N/A"}`
  },
  {
    label: t("pages.Device.telemetry.Acceleration"),
    value: `X: ${deviceTelemetry.sensors?.accelerometer.xValue || "N/A"} Y: ${
      deviceTelemetry.sensors?.accelerometer.yValue || "N/A"
    } Z: ${deviceTelemetry.sensors?.accelerometer.zValue || "N/A"}`
  },
]