import React from "react";
import classNames from "classnames";

import "./styles.scss";

interface IProps {
  tabs: { id: number; name: string; icon?: React.ReactNode }[];
  value: number;
  onSelect: (value: number) => void;
  className?: string;
}

const Tabs = ({ tabs, value, onSelect, className }: IProps) => {
  return (
    <div className={classNames("tabs", className)}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={classNames("tab", { active: tab.id === value })}
          onClick={() => {
            if (tab.id !== value) {
              onSelect(tab.id);
            }
          }}
        >
          {tab.icon}
          {tab.name}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
