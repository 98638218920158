import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import SvgIcon from "@client.core.components/SvgIcon";
import MediaFile from "@client.components/MediaFile";

import "./styles.scss";

interface IProps {
  onClick?: () => void,
  className: string,
  description: string, 
  url: string | undefined,
}

const FileUploaderPlaceholder = ({ onClick, className, description, url }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={classNames("uploader-placeholder", className)}
        onClick={onClick}
      >
        {url ? (
          <MediaFile src={url} showThumbnail />
        ) : (
          <div className="drop-area-inner">
            {<SvgIcon name="photo" />}
            {t("Pages.CreateAdvertisement.uploadPhoto")}
          </div>
        )}
      </div>
      {description && (
        <div className="footer">
          <div className="title">{t("components.Uploader.uploadPhoto")}</div>
          <div className="description">{description}</div>
        </div>
      )}
    </>
  );
};

export default React.memo(FileUploaderPlaceholder);
