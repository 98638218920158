import React, { useCallback, useEffect, useState } from "react";

import useStableParams from "@client.hooks/useStableParams";

import Title from "@client.components/Title";
import Card from "@client.components/Card";

import "./styles.scss";
import DepositFunds from "./DepositFunds";
import useToast from "@client.hooks/useToast";
import ServiceProvider from "@client.services/provider";
import { IUser } from "@client.types/user";

interface IProps {
  selectedUser: IUser;
}

const BalanceTab = ({ selectedUser }: IProps) => {
  const { showErrorToast } = useToast();
  const { userId } = useStableParams();

  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState<IUser | null>(null);

  const fetchUser = useCallback(async () => {
    if (!userId) {
      return;
    }
    setLoading(true);
    try {
      const response = await ServiceProvider.User.getUserById(userId);
      if (response.data.user) {
        setUserData(response.data.user)
      } else {
        showErrorToast("Can't get user information")
      }
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : "Can't get user information";
      showErrorToast(errorMessage)
    } finally {
      setLoading(false);
    }
  }, [userId])

  useEffect(() => {
    fetchUser();
  }, [userId]);

  return (
    <div className="user-balance">
      <div className="user-balance-grid">
        <Card title="Your balance"><p className="balance-amount">$3100</p></Card>
        <Card title="Deposit Funds">
          <DepositFunds />
        </Card>
        <Card title="Withdraw Funds">Withdraw</Card>
      </div>
    </div>
  );
};

export default BalanceTab;
