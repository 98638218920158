import React from "react";

import MinimizeIcon from "./MinimizeIcon";
import Card from "@client.components/Card";
import LabelValue from "@client.components/LabelValue";

interface IProps {
  onMinimize: () => void;
}

const HeatmapSummary = ({ onMinimize }: IProps) => {
  const heatmapDetailsFields = [
    { title: "People Detected", value: '100' },
    { title: "Impressions", value: '70', items: [
      { title: "Male", value: '40' },
      { title: "Female", value: '30' },
    ] },
    { title: "Views", value: '30', items: [
      { title: "Male", value: '10' },
      { title: "Female", value: '20' },
    ] },
  ]

  const heatmapObjectsFields = [
    { title: "Dogs", value: '1' },
    { title: "Cats", value: '3' },
    { title: "Bicycles", value: '2' },
    { title: "Cars", value: '8' },
    { title: "Trucks", value: '2' },
    { title: "Motorcycles", value: '4' },
    { title: "Buses", value: '1' },
    { title: "Backpacks", value: '36' },
    { title: "Handbags", value: '22' },
  ]

  return (
    <div className="heatmap-summary-modal">
      <div className="minimize-icon-absolute">
        <MinimizeIcon onClick={onMinimize} />
      </div>
      <div className="info-header">
        <div className="info">
          <span>Heatmap Summary</span>
        </div>
      </div>
      <div className="info-table-content">
        <div className="info-table-grid-wrapper">
          <div className="heatmap-summary-details">
            {heatmapDetailsFields.map((v) => (
              <div key={v.title}>
                <div className="heatmap-summary-details-row">
                  <p>{v.title}</p>
                  <p className="heatmap-summary-details-value">{v.value || "N/A"}</p>
                </div>
                {v.items?.map((item) => (
                  <div key={item.title} className="heatmap-summary-details-row heatmap-summary-details-row--secondary">
                    <p>{item.title}</p>
                    <p className="heatmap-summary-details-value">{item.value || "N/A"}</p>
                  </div>
                ))}
              </div>
            ))}
            <div className="heatmap-summary-divider" />
            {heatmapObjectsFields.map((v) => (
              <div key={v.title} className="heatmap-summary-details-row"> 
                <p>{v.title}</p>
                <p className="heatmap-summary-details-value">{v.value || "N/A"}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeatmapSummary;
