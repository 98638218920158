import React from "react";
import { useTranslation } from "react-i18next";

import OverviewCard from "@client.components/OverviewCard";

const AdvertisementStatisticsCards = ({ advertisementMonitoring }) => {
  const { t } = useTranslation();

  return (
    <div className="advertisement-cards-container">
      <OverviewCard
        value={advertisementMonitoring.totalPlays}
        text={t("Pages.Advertisement.Overview.plays")}
      />
      <OverviewCard
        value={advertisementMonitoring.totalViews}
        text={t("Pages.Advertisement.Overview.views")}
        color="yellow"
      />
      <OverviewCard
        value={advertisementMonitoring.totalSpent}
        text={t("Pages.Advertisement.Overview.spent")}
        color="blue"
      />
    </div>
  );
};

export default AdvertisementStatisticsCards;
