import React from "react";

import DeviceRegistrationsStatuses from "@client.enums/deviceRegistrationsStatuses";

export const getColumns = (t: (key: string) => string, handleDeviceApproval: (id: string, status: number) => void) => [
  {
    field: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1,
    maxWidth: 50,
  },
  {
    field: "createdAt",
    headerName: "Received At",
    cellRenderer: (params) =>
      new Date(params.data.createdAt).toLocaleString(),
  },
  {
    field: "machineId",
    headerName: "Machine ID",
    minWidth: 290,
  },
  {
    field: "lastTelemetry.uptime",
    headerName: "Device Uptime",
    cellRenderer: (params) =>
      params.data?.lastTelemetry?.uptime || "N/A",
    maxWidth: 120,
  },
  {
    field: "status",
    headerName: "Status",
    cellRenderer: (params: any) =>
      t(DeviceRegistrationsStatuses[params.data.status] || "Unknown"),
  },
  {
    colId: "actions",
    headerName: "",
    cellRenderer: ({ data }) =>
      (data.status === 1) ? (
        <div className="action-buttons">
          <button
            className="confirm-button"
            onClick={() => handleDeviceApproval(data.Id, 2)}
          >
            Approve
          </button>
          <button
            className="cancel-button"
            onClick={() => handleDeviceApproval(data.Id, 1)}
          >
            Reject
          </button>
        </div>
      ) : null,
  },
];
