import React from "react";

import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";

import { useTranslation } from "react-i18next";
import ColorPicker from "@client.core.components/ColorPicker";
import TextInput from "@client.components/form/TextInput";
import FormPlacesInput from "@client.components/form/FormPlacesInput";
import ZoneTypes from "@client.enums/zoneTypes";

const getRequestType = (zoneCode: number) => {
  switch (zoneCode) {
  case ZoneTypes.Country:
    return "country";
  case ZoneTypes.State:
    return "administrative_area_level_1";
  case ZoneTypes.County:
    return "administrative_area_level_2";
  case ZoneTypes.LocalArea:
    return "";
  default:
    return ""
  }
}

export interface IFormValues {
  zoneName: string,
  primePpvRate: number,
  standardPpvRate: number,
  color: string,
  polygon: number[][],
}

interface IProps {
  register: UseFormRegister<IFormValues>;
  getValues: UseFormGetValues<IFormValues>;
  setValue: UseFormSetValue<IFormValues>;
  errors: FieldErrors<IFormValues>;
  zoneNameValue: string;
  zoneCategory: ZoneTypes;
}

const ZoneForm = ({ register, getValues, setValue, errors, zoneNameValue, zoneCategory }: IProps) => {
  const { t } = useTranslation();

  const renderZoneNameFieldError = () => {
    if (errors.zoneName) {
      return errors.zoneName.message
    }
    if (errors.polygon) {
      return errors.polygon.message
    }
    return '';
  }

  return (
    <div className="zone-form">
      <FormPlacesInput
        addressStringName="zoneName"
        coordinatesName="polygon"
        label={t("pages.Locations.components.ZoneManagement.name")}
        value={zoneNameValue}
        setValue={setValue}
        error={renderZoneNameFieldError()}
        placeType={getRequestType(zoneCategory)}
      />
      <TextInput
        id="standardPpvRate"
        name="standardPpvRate"
        type="number"
        label={t("pages.Locations.components.ZoneManagement.ppvRate")}
        placeholder={t("pages.Locations.components.ZoneManagement.ppvRate")}
        register={register}
        error={errors.standardPpvRate}
      />
      <TextInput
        id="primePpvRate"
        name="primePpvRate"
        type="number"
        label={t("pages.Locations.components.ZoneManagement.primeRate")}
        placeholder={t("pages.Locations.components.ZoneManagement.primeRate")}
        register={register}
        error={errors.primePpvRate}
      />
      <ColorPicker
        hexColor={getValues().color}
        onChange={(v) => setValue("color", v)}
        label={t("pages.Locations.components.ZoneManagement.color")}
      />
    </div>
  );
};

export default ZoneForm;
