import React, { useEffect, useState } from "react";

import ServiceProvider from "@client.services/provider";

import useToast from "@client.hooks/useToast";
import useStableParams from "@client.hooks/useStableParams";

import { IAdvertisementMonitoring } from "@client.types/advertisementMonitoring";
import { IAdvertisementPlace } from "@client.types/advertisementPlace";
import { IHeatmap } from "@client.types/heatmap";

import Loader from "@client.core.components/Loader";

import AdvertisementStatistics from "./AdvertisementStatistics";
import AdvertisementMaps from "./AdvertisementMaps";

const AdvertisementOverview = ({ advertisement }) => {
  const { showErrorToast } = useToast();
  const { id } = useStableParams();

  const [isLoading, setLoading] = useState(true);
  const [advertisementMonitoring, setAdvertisementMonitoring] = useState<IAdvertisementMonitoring | null>(null);
  const [advertisementPlaces, setAdvertisementPlaces] = useState<IAdvertisementPlace[]>([]);
  const [advertisementHeatmap, setAdvertisementHeatmap] = useState<IHeatmap[]>([]);

  const startDate = advertisement.startDate;
  const endDate = advertisement.endDate;

  useEffect(() => {
    if (!id) {
      return;
    }
  
    const fetchAdvertisementOverview = async () => {
      setLoading(true);
      try {
        const [
          advertisementMonitoringResponse, 
          advertisementPlacesResponse,
          advertisementHeatmapResponse,
        ] = await Promise.all([
          ServiceProvider.Advertisement.getAdvertisementMonitoring({ id, startDate, endDate }),
          ServiceProvider.Advertisement.getAdvertisementPlaces({ id, deviceId: "" }),
          ServiceProvider.Advertisement.getAdvertisementHeatmap({ id, zoneId: "" }),
        ]);
        
        if (advertisementMonitoringResponse.data.campaignMonitoring) {
          setAdvertisementMonitoring(advertisementMonitoringResponse.data.campaignMonitoring);
        } else {
          showErrorToast("Can't get advertisement monitoring information");
        }

        if (advertisementPlacesResponse.data.campaignPlaces) {
          setAdvertisementPlaces(advertisementPlacesResponse.data.campaignPlaces.results);
        } else {
          showErrorToast("Can't get advertisement places information");
        }

        if (advertisementHeatmapResponse.data.campaignHeatmap) {
          setAdvertisementHeatmap(advertisementHeatmapResponse.data.campaignHeatmap.results);
        } else {
          showErrorToast("Can't get advertisement heatmap information");
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get advertisement overview";
        showErrorToast(errorMessage)
      } finally {
        setLoading(false);
      }
    }

    fetchAdvertisementOverview();
  }, [id]);

  if (isLoading) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div className="advertisement-overview">
      <AdvertisementStatistics
        advertisementMonitoring={advertisementMonitoring}
      />
      <AdvertisementMaps 
        advertisementPlaces={advertisementPlaces}
        advertisementHeatmap={advertisementHeatmap}
      />
    </div>
  );
};

export default AdvertisementOverview;
