import React from "react";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

import { IAdvertisementInsights } from "@client.types/advertisementInsights";
import ChartTooltip from "./ChartTooltip";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#AF19FF",
  "#FF6392",
  "#FF3D1F",
  "#9FE6B9",
  "#FFBF00",
  "#6A0572",
];

interface IProps {
  advertisementInsights: IAdvertisementInsights | null;
}

const AdvertisementInsightsGenderAge = ({ advertisementInsights }: IProps) => {
  const { t } = useTranslation();

  const maleNumber = advertisementInsights?.ageGraph.reduce((sum, { male }) => sum + male, 0) || 0;
  const femaleNumber = advertisementInsights?.ageGraph.reduce((sum, { female }) => sum + female, 0) || 0;
  const totalViewers = maleNumber + femaleNumber || 1;

  const genderPieChartData = [
    ...(parseFloat(((maleNumber / totalViewers) * 100).toFixed(2))
      ? [
        {
          name: t("pages.DataAnalyzer.gender.male"),
          qty: maleNumber,
          value: parseFloat(((maleNumber / totalViewers) * 100).toFixed(2)),
        },
      ]
      : []),
    ...(parseFloat(((femaleNumber / totalViewers) * 100).toFixed(2))
      ? [
        {
          name: t("pages.DataAnalyzer.gender.female"),
          qty: femaleNumber,
          value: parseFloat(((femaleNumber / totalViewers) * 100).toFixed(2)),
        },
      ]
      : []),
  ];

  const ageRangePieData = advertisementInsights?.ageGraph.map(({ age, male, female }) => {
    const qty = (male || 0) + (female || 0);
    return {
      name: `Age ${age}`,
      qty,
      value: parseFloat(((qty / advertisementInsights.peopleDetected) * 100).toFixed(2)) || 0,
    };
  }) || [];

  return (
    <div>
      <p className="title">{t("pages.DataAnalyzer.demographics")}</p>
      <div className="demographics-charts">
        <ResponsiveContainer width="50%" height={320}>
          <PieChart>
            <Pie
              data={genderPieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={(entry) => entry.name}
            >
              {genderPieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={({ active, payload }) => <ChartTooltip active={active} payload={payload} />} />
          </PieChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="50%" height={320}>
          <PieChart>
            <Pie
              data={ageRangePieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={(entry) => entry.name}
            >
              {ageRangePieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={({ active, payload }) => <ChartTooltip active={active} payload={payload} />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
};

export default AdvertisementInsightsGenderAge;
