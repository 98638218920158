import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceProvider from "@client.services/provider";
import { ISystemLog } from "@client.types/systemLog";
import { IGetSystemLogsResponse, IGetSystemLogsVariables } from "@client.types/queries/getSystemLogs";

interface ICreateSystemLogPayload {
  systemLogData: any;
  filter: IGetSystemLogsVariables;
}

interface ISystemLogsSlice {
  data: ISystemLog[];
  count: number;
  error: string | null;
  loading: boolean;
}

const initialState: ISystemLogsSlice = {
  data: [],
  count: 0,
  error: null,
  loading: false,
};

export const getSystemLogsAsync = createAsyncThunk<
  IGetSystemLogsResponse,
  IGetSystemLogsVariables
>(
  "systemLogs/getAll",
  async (filter) => {
    const response = await ServiceProvider.SystemLogService.getSystemLogs(filter);
    return response.data.systemLogs || [];
  }
);

export const createSystemLogAsync = createAsyncThunk(
  "systemLogs/create",
  async ({ systemLogData, filter }: ICreateSystemLogPayload, thunkAPI) => {
    const response = await ServiceProvider.SystemLogService.createSystemLog(systemLogData);

    if (!response.errors) {
      thunkAPI.dispatch(getSystemLogsAsync(filter));
    }

    return response.data.createSystemLog;
  }
);

const systemLogsSlice = createSlice({
  name: "systemLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSystemLogsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSystemLogsAsync.fulfilled, (state, action) => {
        state.data = action.payload.results;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(getSystemLogsAsync.rejected, (state, action) => {
        state.data = [];
        state.count = 0;
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(createSystemLogAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSystemLogAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSystemLogAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      });
  }
});

export const selectSystemLogsData = (state) => state.systemLogs.data;
export const selectSystemLogsCount = (state) => state.systemLogs.count;
export const selectSystemLogsLoading = (state) => state.systemLogs.loading;
export const selectSystemLogsError = (state) => state.systemLogs.error;

export default systemLogsSlice.reducer;