import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import { IGetDevicesResponse, IGetDevicesVariables } from "@client.types/queries/getDevices";
import { IDevice } from "@client.types/device";

const DEFAULT_SELECTED_DEVICE = { device: null, loading: false };
const DEFAULT_APPROVAL_DEVICE = { device: null, loading: false };
const DEFAULT_DEVICE_CAPTURES = { results: [], loading: false, count: 0 };
const INITIAL_DEVICE_ACCOUNT = { data: null, loading: false };

const DEFAULT_DEVICES_FILTER = {
  startDate: "",
  endDate: "",
  limit: 10,
  offset: 0,
  search: "",
  searchOn: "",
}

interface IDevicesSlice {
  data: IDevice[];
  count: number;
  error: string | null;
  loading: boolean;

  dataAnalyzer: { data: IDevice[] | null, loading: boolean },
  approval: { device: IDevice | null, loading: boolean },
  selected: { device: IDevice | null, loading: boolean },
  deviceCaptures: { results: IDevice[], loading: boolean, count: number },
  deviceAccount: { data: IDevice | null, loading: boolean },
  defaultMedia: null;
}

const initialState: IDevicesSlice = {
  data: [],
  count: 0,
  error: null,
  loading: false,

  dataAnalyzer: { data: null, loading: false },
  approval: DEFAULT_APPROVAL_DEVICE,
  selected: DEFAULT_SELECTED_DEVICE,
  deviceCaptures: DEFAULT_DEVICE_CAPTURES,
  deviceAccount: INITIAL_DEVICE_ACCOUNT,
  defaultMedia: null,
};

export const getDevicesAsync = createAsyncThunk<
  IGetDevicesResponse,
  IGetDevicesVariables | undefined
>(
  "devices/getDevices",
  async (variables) => {
    const response = await ServiceProvider.Device.getDevices(variables);
    return response.data.devices;
  }
);

export const getDeviceByIdAsync = createAsyncThunk<
  IDevice,
  string
>(
  "devices/getById",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      const response = await ServiceProvider.Device.getDeviceById(id);
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

export const fetchDeviceCapturesAsync = createAsyncThunk(
  "devices/fetchCaptures",
  async (filter, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      const response = await ServiceProvider.DataAnalyzer.getDeviceCaptures(
        filter
      );
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

export const updateDeviceDefaultMediaAsync = createAsyncThunk(
  "devices/updateDefaultMedia",
  async ({ lookupId, defaultMedia }: any, thunkAPI) => {
    try {
      const response = await ServiceProvider.Device.updateDeviceMedia(
        lookupId,
        defaultMedia
      );
      if (!response.errors) {
        thunkAPI.dispatch(getDevicesAsync(DEFAULT_DEVICES_FILTER));
      }
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const processFlickrSignalAsync = createAsyncThunk(
  "devices/flickrSignal",
  async ({ macAddress, signal }: any, thunkAPI) => {
    const response = await ServiceProvider.Device.flickrSignal(
      macAddress,
      signal
    );
    return response;
  }
);

export const fetchDeviceTelemetryAsync = createAsyncThunk(
  "devices/telemetries",
  async (id, thunkAPI) => {
    const response = await ServiceProvider.Device.fetchDeviceTelemetry(id);
    return response;
  }
);

export const fetchDeviceAccountInfo = createAsyncThunk(
  "devices/getAccountInfo",
  async (accountId: string, thunkAPI) => {
    thunkAPI.dispatch(setAccountInfoLoading(true));
    try {
      const response = await ServiceProvider.Account.getAccountById(accountId);
      return response;
    } finally {
      thunkAPI.dispatch(setAccountInfoLoading(false));
    }
  }
);

export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setSelectedLoading: (state, action) => {
      state.selected.loading = get(action, "payload", false);
    },
    setAccountInfoLoading: (state, action) => {
      state.deviceAccount.loading = get(action, "payload", false);
    },
    setAllApprovalsLoading: (state, action) => {
      state.approval.loading = get(action, "payload", false);
    },
    clearDeviceError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDevicesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDevicesAsync.fulfilled, (state, action) => {
        state.data = action.payload.results;
        state.count = action.payload.count;
        state.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getDevicesAsync.rejected, (state, action) => {
        state.data = [];
        state.count = 0;
        state.error = getResponseError(action);
      })
      .addCase(getDeviceByIdAsync.fulfilled, (state, action) => {
        state.selected.device = get(action, "payload.data.device", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getDeviceByIdAsync.rejected, (state, action) => {
        state.selected = {
          ...DEFAULT_SELECTED_DEVICE,
        };
        state.error = getResponseError(action);
      })
      .addCase(fetchDeviceCapturesAsync.rejected, (state, action) => {
        state.deviceCaptures.loading = false;
        state.deviceCaptures.results = [];
        state.error = getResponseError(action);
      })
      .addCase(updateDeviceDefaultMediaAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateDeviceDefaultMediaAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(processFlickrSignalAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(processFlickrSignalAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const {
  setAllApprovalsLoading,
  setSelectedLoading,
  clearDeviceError,
  setAccountInfoLoading
} = devicesSlice.actions;

export const makeSelectedDeviceTelemetry = (state) =>
  state.devices.selected.telemetry;
export const makeDataAnalyzer = (state) =>
  state.advertisement.selected.analyzer || { data: null, loading: false };

export const makeApprovals = (state) => state.devices.approval;
export const makeDeviceInfo = (state) => state.devices.selected;
export const makeCaptures = (state) => state.devices.deviceCaptures;
export const selectDevicesError = (state) => state.devices.error;
export const makeDeviceAccountInfo = (state) => state.devices.deviceAccount;

export const selectDevicesData = (state) => state.devices.data;
export const selectDevicesCount = (state) => state.devices.count;
export const selectDevicesLoading = (state) => state.devices.loading;

export default devicesSlice.reducer;
