import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import LocalStorageKeys from "@client.enums/localStorageKeys";
import { IUpdateUserResponse, IUpdateUserVariables } from "@client.types/queries/updateUser";
import { RootState } from "@client.store";
import { IProfile } from "@client.types/profile";

interface IChangeUserPasswordPayload {
  password: string;
  userId: string;
}

interface IProfileSlice {
  data: IProfile | null;
  error: string | null;
  loading: boolean;
}

const initialState: IProfileSlice = {
  data: null,
  error: null,
  loading: false,
};

export const getProfileAsync = createAsyncThunk("profile/get", async () => {
  try {
    const response = await ServiceProvider.User.profile();
    if (response.errors) {
      ServiceProvider.LocalStorage.removeItem(LocalStorageKeys.Token);
    }
    return response;
  } catch (e) {
    return e;
  }
});

export const changeUserPasswordAsync = createAsyncThunk(
  "profile/changePassword",
  async ({ password, userId }: IChangeUserPasswordPayload, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      await ServiceProvider.User.changePassword(userId, password);
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.data = get(action, "payload", null);
    },
    clearProfileError: (state) => {
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.data = get(action, "payload.data.accountInformation", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getProfileAsync.rejected, (state, action) => {
        state.data = null;
        state.error = getResponseError(action);
      })
      .addCase(changeUserPasswordAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(changeUserPasswordAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
  },
});

export const { setProfile, setLoading, clearProfileError } = profile.actions;

export const selectProfileData = (state) => state.profile.data;
export const makeProfileError = (state) => state.profile.error;
export const makeProfileMessage = (state) => state.profile.message;

export default profile.reducer;
