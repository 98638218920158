
import Button from "@client.core.components/Button";
import DateRangePicker from "@client.components/DateRangePicker";
import Dropdown from "@client.core.components/Dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDevicesAsync, selectDevicesData } from "@client.reducers/devices";
import {
  getAdvertiserAdvertisementsAsync,
  makeSelectedAdvertisements,
} from "@client.reducers/Advertiser";

const FilterBar = ({ onFilterChange, dataAnalyzer = false }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [advertisementId, setAdvertisementId] = useState("");

  const devices = useSelector(selectDevicesData);
  const advertisements = useSelector(makeSelectedAdvertisements);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDevicesAsync());
    dispatch(getAdvertiserAdvertisementsAsync());
  }, [dispatch]);

  return (
    <div className="search-bar">
      {!dataAnalyzer ? (
        <>
          <div className="search-inputs">
            <Dropdown
              items={devices.results}
              valueKey="lookupId"
              labelKey="deviceName"
              placeholder={t("pages.DataAnalyzer.selectDevice")}
              label={t("pages.DataAnalyzer.devices")}
              defaultValue={deviceId}
              onSelect={setDeviceId}
            />
            <Dropdown
              items={advertisements.campaigns}
              valueKey="lookupId"
              labelKey="displayName"
              placeholder={t("pages.DataAnalyzer.selectAdvertisement")}
              label={t("pages.DataAnalyzer.Advertisements")}
              defaultValue={advertisementId}
              onSelect={setAdvertisementId}
            />
            <DateRangePicker
              onChange={(startDate, endDate) => {
                setStartDate(startDate || "");
                setEndDate(endDate || "");
              }}
            />
          </div>
          <Button
            text={t("pages.Devices.DeviceCaptures.submit")}
            className="search-submit"
           
            onClick={() => {
              onFilterChange({
                deviceId,
                advertisementId,
                endDate,
                startDate,
              });
            }}
          />
        </>
      ) : (
        <div 
          style={{ width: "100%" }}>
          <Button
            text={"Load"}
            className="search-submit"
            onClick={() => {
              onFilterChange({
                deviceId,
                advertisementId,
                endDate,
                startDate,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

FilterBar.propTypes = {
  onFilterChange: PropTypes.func,
  dataAnalyzer: PropTypes.bool,
};

export default FilterBar;
