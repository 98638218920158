import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceProvider from "@client.services/provider";
import { get } from "lodash";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_INVOICES = { results: [], loading: false, count: 0 };
const DEFAULT_SELECTED_INVOICE = { invoice: null, loading: false };

const initialState = {
  invoices: DEFAULT_INVOICES,
  selected: DEFAULT_SELECTED_INVOICE,
  error: null,
  loading: false,
  filter: {
    accountLookupId: "",
    startDate: "",
    endDate: "",
    limit: 10,
    offset: 0
  }
};

export const getInvoiceByIdAsync = createAsyncThunk(
  "invoices/getById",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      const response = await ServiceProvider.Invoice.getInvoiceById(id);
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setSelectedLoading: (state, action) => {
      state.selected.loading = get(action, "payload", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceByIdAsync.fulfilled, (state, action) => {
        state.selected.invoice = get(action, "payload.data.invoice", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getInvoiceByIdAsync.rejected, (state, action) => {
        state.selected = {
          ...DEFAULT_SELECTED_INVOICE
        };
        state.error = getResponseError(action);
      })
  }
});

export const {
  setSelectedLoading,
} = invoiceSlice.actions;

export const makeInvoiceInfo = (state) => state.invoice.selected;

export default invoiceSlice.reducer;
