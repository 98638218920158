import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@client.core.components/Button";
import TextInput from "@client.components/form/TextInput";
import AuthLeftPanel from "../components/AuthLeftPanel";
import UnauthorizedPageCard from "../components/UnauthorizedPageCard/UnauthorizedPageCard";
import PasswordValidator from "@client.components/PasswordValidator";
import AccountEntity from "@client.enums/accountEntity";
import FormSelect from "@client.components/form/FormSelect";
import { Link } from "react-router-dom";
import Pages from "@client.enums/pages";
import FormPlacesInput from "@client.components/form/FormPlacesInput";
import FormCheckbox from "@client.components/form/FormCheckbox";
import useRegisterForm from "./useRegisterForm";
import "./RegisterUser.scss";

const RegisterUser = () => {
  const { t } = useTranslation();

  const {
    register,
    control,
    getValues,
    setValue,
    trigger,
    errors,
    shippingAddress,
    billingAddress,
    password,
    handleSubmitRegisterForm,
  } = useRegisterForm();

  return (
    <div className="user-creation">
      <AuthLeftPanel />
      <UnauthorizedPageCard isLoading={false}>
        <div className="title">{t("pages.RegisterUser.header")}</div>
        <div className="title subtitle">
          {t("pages.InviteUser.getStarted")},&nbsp;
          <Link to={Pages.Login} className="signin">
            {t("pages.InviteUser.login")}
          </Link>
          &nbsp;
          {t("pages.InviteUser.createAccount")}
        </div>
        <div className="registration-form">
          <div className="row">
            <TextInput
              id="firstName"
              name="firstName"
              label={t("pages.InviteUser.firstName")}
              placeholder={t("pages.InviteUser.firstName")}
              register={register}
              error={errors.firstName}
            />
            <TextInput
              id="lastName"
              name="lastName"
              label={t("pages.InviteUser.lastName")}
              placeholder={t("pages.InviteUser.lastName")}
              register={register}
              error={errors.lastName}
            />
          </div>
          <div className="row">
            <TextInput
              id="emailAddress"
              name="emailAddress"
              label={t("pages.InviteUser.email")}
              placeholder={t("pages.InviteUser.email")}
              register={register}
              error={errors.emailAddress}
            />
            <TextInput
              id="phoneNumber"
              name="phoneNumber"
              label={t("pages.InviteUser.phone")}
              placeholder={t("pages.InviteUser.phone")}
              register={register}
              error={errors.phoneNumber}
            />
          </div>
          <div className="row">
            <FormSelect
              name="entity"
              label="Entity"
              control={control}
              options={[
                {
                  value: AccountEntity.Advertiser,
                  label: t("components.Advertisers.customerType.advertiser"),
                },
                {
                  value: AccountEntity.Partner,
                  label: t("components.Advertisers.customerType.partner"),
                },
              ]}
              error={errors.entity?.message}
            />
          </div>
          <div className="row">
            <TextInput
              id="companyName"
              name="companyName"
              label={t("pages.RegisterUser.companyName")}
              placeholder={t("pages.RegisterUser.companyName")}
              register={register}
              error={errors.companyName}
            />
            <TextInput
              id="companyNumber"
              name="companyNumber"
              label={t("pages.RegisterUser.companyNumber")}
              placeholder={t("pages.RegisterUser.companyNumber")}
              register={register}
              error={errors.companyNumber}
            />
          </div>
          <div className="row">
            <FormPlacesInput
              value={shippingAddress}
              setValue={setValue}
              trigger={trigger}
              addressObjectName="shippingAddress"
              label="Shipping Address"
              error={errors.shippingAddress?.addressLineOne?.message}
            />
            <TextInput
              id="vatNumber"
              name="vatNumber"
              label={t("pages.RegisterUser.vatNumber")}
              placeholder={t("pages.RegisterUser.vatNumber")}
              register={register}
              error={errors.vatNumber}
            />
          </div>
          <div className="checkbox-row">
            <FormCheckbox
              id="sameAddress"
              name="sameAddress"
              label="Billing Address is the same"
              control={control}
              error={errors.sameAddress}
            />
          </div>
          {!getValues("sameAddress") && (
            <FormPlacesInput
              value={billingAddress}
              setValue={setValue}
              trigger={trigger}
              addressObjectName="billingAddress"
              label="Billing Address"
              error={errors.billingAddress?.addressLineOne?.message}
            />
          )}
          <PasswordValidator
            id="password"
            label={t("pages.InviteUser.password")}
            placeholder={t("pages.InviteUser.password")}
            onChange={(v) => setValue("password", v)}
            value={password}
            error={errors.password}
          />
          <div className="btn">
            <Button text={t("pages.InviteUser.signinForFree")} onClick={handleSubmitRegisterForm} />
          </div>
        </div>
      </UnauthorizedPageCard>
    </div>
  );
};

export default RegisterUser;
