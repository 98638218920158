import React, { FC } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import SvgIcon from "@client.core.components/SvgIcon";
import MediaFile from "@client.components/MediaFile";

interface IPanelProps {
  title: string;
  status?: number;
  data: {
    title: string;
    value: string | number | string[];
  }[];
  image?: string;
  children?: React.ReactNode;
  isShowImage?: boolean;
  statusClassNameFunc?: (arg: number) => string;
  statusViewFunc?: (arg: number) => string;
  onClick?: () => void;
}

export const Panel: FC<IPanelProps> = ({
  children,
  status,
  title,
  data,
  image,
  isShowImage = false,
  statusClassNameFunc,
  statusViewFunc,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="panel-container">
      <div className="panel-sub-container">
        <div className="panel-inner">
          {title && (
            <div className="panel-heading">
              <h2 className="panel-title">{title}</h2>
              {(status === 0 || status) &&
                statusClassNameFunc &&
                statusViewFunc && (
                <span
                  className={`status ${statusClassNameFunc(
                    status
                  ).toLowerCase()}`}
                >
                  {statusViewFunc(status)}
                </span>
              )}
            </div>
          )}
          {isShowImage && (
            <div className="panel-image">
              <MediaFile
                key={image}
                src={`${image}`}
                fallbackElement={() => (
                  <SvgIcon
                    name="file-not-found"
                    className="file-not-found-icon"
                    width={120}
                    height={120}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="panel-content">
          <div className="panel-content-heading">
            <h2>{t("pages.Accounts.details")}</h2>
            {onClick && (
              <button className="content-button" onClick={onClick}>
                {t("pages.Devices.columns.view")}
              </button>
            )}
          </div>
          {data.map((item) => (
            <div key={item.title} className="panel-details">
              <p className="panel-content-title">{t(item.title)}</p>
              <p className="panel-content-description">{item.value}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="panel-child">{children}</div>
    </div>
  );
};
