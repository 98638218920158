import React from "react";

import { IAccount } from "@client.types/account";

import Loader from "@client.core.components/Loader";
import Tree from "@client.components/Tree";

import AccountsTreeItem from "./AccountsTreeItem";
import AccountsEntitiesLegend from "./AccountsEntitiesLegend";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";

interface IProps {
  accounts: IAccount[],
  selectedAccount: IAccount | null,
  onSelectAccount: (account: IAccount | null) => void,
  accountsLoading: boolean,
}

const AccountsList = ({
  accounts,
  selectedAccount,
  onSelectAccount,
  accountsLoading,
}: IProps) => {
  const navigate = useNavigate();

  if (accountsLoading) {
    return (
      <div className="accounts-loading">
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div className="accounts-list-container">
      <Tree
        items={accounts}
        renderItem={(account) => (
          <AccountsTreeItem
            key={account.Id}
            account={account}
            selectedAccountId={selectedAccount?.Id || null}
            onSelectAccount={(account) => navigate(`${Pages.Accounts}/${account?.Id}/details`)}
          />
        )}
        Footer={AccountsEntitiesLegend}
      />
    </div>
  );
};

export default AccountsList;
