import React from "react";

import "./styles.scss";

interface IProps {
  label: string;
  value: string;
  isLoading?: boolean;
}

const LabelValue = ({ label, value, isLoading = false }: IProps) => {
  return (
    <div className="label-value-container">
      <strong>{label}</strong>
      {isLoading ? (
        <p className="loading-value" />
      ) : (
        <p>{value || "N/A"}</p>
      )}
    </div>
  );
};

export default LabelValue;
