import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IAdvertisementInsights } from "@client.types/advertisementInsights";

import Map from "@client.components/Map";
import Loader from "@client.components/core/Loader";

interface IProps {
  advertisementInsights: IAdvertisementInsights | null;
}

const AdvertisementInsightsMap = ({ advertisementInsights }: IProps) => {
  const { t } = useTranslation();

  const insightsMap = advertisementInsights?.playLocations.map((item) => ({
    lat: item.latitude,
    lng: item.longitude,
  })) || [];

  return (
    <div>
      <p className="title">{t("pages.DataAnalyzer.map.title")}</p>
      {advertisementInsights ? (
        <Map
          zoom={12}
          markers={insightsMap}
          options={{
            mapTypeControl: false,
            disableDefaultUI: true,
            zoomControl: true
          }}
          className="insights-map"
        />
      ) : <Loader loading={true} />}
    </div>
  );
};

export default AdvertisementInsightsMap;
