import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import Title from "@client.components/Title";

import "./styles.scss";
import DevicesList from "./DevicesList";
import DeviceInformation from "./DeviceInformation";
import { IDevice } from "@client.types/device";

const DevicesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);

  return (
    <div className="devices-page">
      <Title text="components.NavigationBar.devices">
        <Button
          text={t("pages.Devices.deviceApproval")}
          onClick={() => navigate(`${Pages.DeviceRegistrations}`)}
        />
      </Title>
      <div className="devices-content">
        <DevicesList
          onSelectDevice={setSelectedDevice}
        />
        <DeviceInformation selectedDevice={selectedDevice} />
      </div>
    </div>
  );
};

export default DevicesPage;
