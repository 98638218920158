import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import ServiceProvider from "@client.services/provider";
import DataTable from "@client.components/DataTable";
import Badge from '@client.components/Badge'
import DeviceTypes from "@client.enums/deviceTypes";
import { IAccount } from "@client.types/account";
import './styles.scss';

interface IProps {
  selectedAccount: IAccount;
}

const DevicesTab = ({ selectedAccount }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    if (selectedAccount.Id) {
      setLoading(true);
      ServiceProvider.Device.getDevices(selectedAccount.Id)
        .then((resp) => {
          setDevices(get(resp, "data.devices.results", []));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedAccount.Id]);

  const COLUMNS = [
    {
      field: "deviceName",
      tooltipField: "DeviceName",
      headerName: t("pages.Devices.columns.name"),
    },
    {
      field: "deviceName",
      tooltipField: "DeviceName",
      headerName: t("pages.Devices.columns.name"),
    },
    {
      field: "deviceType",
      headerName: t("pages.Devices.columns.type"),
      cellClass: "data-table-cell",
      // headerClass: "align-center", // Optional, align header text
      cellRenderer: (data) => {
        let label = "pages.Devices.na";
        if (data.data.type) {
          label =
            data.data.type === DeviceTypes.Dynamic
              ? "pages.Devices.type.dynamic"
              : "pages.Devices.type.static";
        }
        return t(label);
      },
    },
    {
      field: "isOnline",
      headerName: t("pages.Devices.columns.status"),
      cellClass: "data-table-cell",
      cellRenderer: (data) => {
        const isOnline = data?.data?.isOnline;
        return <Badge 
          text={t(
            isOnline ? "pages.Devices.status.online" : "pages.Devices.status.offline"
          )}
          className={isOnline ? "device-online-badge" : "device-offline-badge"}
        />
      },
    },
  ];

  return (
    <div className="account-devices-tab">
      <DataTable 
        columns={COLUMNS}
        data={devices}
        isLoading={loading} 
      />
    </div>
  );
};

DevicesTab.propTypes = {};

export default DevicesTab;
