import React from "react";

import moment from "moment";
import config from "@client.config";
import AdvertisementStatuses from "@client.enums/advertisementStatuses";

export const getStatus = (t, status) => {
  switch (parseInt(status)) {
  case AdvertisementStatuses.Queued:
    return t("AdvertisementStatuses.Queued");
  case AdvertisementStatuses.PendingApproval:
    return t("AdvertisementStatuses.PendingApproval");
  case AdvertisementStatuses.Draft:
    return t("AdvertisementStatuses.Draft");
  case AdvertisementStatuses.Paused:
    return t("AdvertisementStatuses.Paused");
  case AdvertisementStatuses.Active:
    return t("AdvertisementStatuses.Active");
  case AdvertisementStatuses.Archive:
    return t("AdvertisementStatuses.Archive");
  }
};

export const getColumns = (t) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1,
    cellRenderer: (params) => (
      <div className="clickable-cell">{params.value}</div>
    ),
    maxWidth: 80,
  },
  {
    field: "title",
    tooltipField: "title",
    headerName: t("Pages.AdvertisementApproval.columns.name"),
    cellRenderer: (params) => (
      <div className="clickable-cell">{params.value}</div>
    ),
  },
  {
    field: "companyName", // Updated to use companyName instead of advertiserId
    tooltipField: "companyName", // Tooltip also shows the companyName
    headerName: t("Pages.AdvertisementApproval.columns.createdBy"),
    cellRenderer: (params) => (
      <div className="clickable-cell">{params.value}</div>
    ),
  },
  {
    field: "createdAt",
    headerName: t("Pages.AdvertisementApproval.columns.createdAt"),
    tooltipValueGetter: (params) =>
      moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
    cellRenderer: (params) => (
      <div className="clickable-cell">
        {moment(params.data.createdAt).format(config.dateTimeSecondsFormat)}
      </div>
    ),
  },
  {
    field: "status",
    headerName: t("Pages.AdvertisementApproval.columns.status"),
    tooltipValueGetter: (params) => getStatus(t, params.data.status),
    cellRenderer: (params) => (
      <div className="clickable-cell">{getStatus(t, params.data.status)}</div>
    ),
  },
];
