import React from "react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "@client.utils/utils";

import OverviewCard from "@client.components/OverviewCard";

const ReportsOverview = () => {
  const { t } = useTranslation();

  return (
    <div className="reports-overview">
      <OverviewCard
        value={formatNumber(1000, 0)}
        icon="FaEye"
        text={t("pages.Reports.Overview.totalView")}
      />
      <OverviewCard
        value={`$${formatNumber(0.5)}`}
        icon="FaCoins"
        text={t("pages.Reports.Overview.perView")}
        color="yellow"
      />
      <OverviewCard
        value={`$${formatNumber(500)}`}
        icon="FaCoins"
        text={t("pages.Reports.Overview.spent")}
        color="blue"
      />
    </div>
  );
};

export default ReportsOverview;
