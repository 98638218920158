import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import MapFilter from "@client.components/MapFilter";

enum PopoverType {
  None = 0,
}

export interface IProps {
  showZonesFilter: boolean;
  setShowZonesFilter: Dispatch<SetStateAction<boolean>>;
}

const AccountMapFilter = ({
  showZonesFilter,
  setShowZonesFilter,
}: IProps) => {
  const [popoverType, setPopoverType] = useState(PopoverType.None);
  
  const handleMapLocationsClick = () => setShowZonesFilter((prev) => !prev)

  const mapFilterItems = [
    {
      icon: "IoIosPin",
      title: "components.Dashboard.MapFilter.ShowLocations",
      onClick: handleMapLocationsClick,
      enabled: showZonesFilter,
    },
  ];

  return (
    <>
      <MapFilter items={mapFilterItems} />
    </>
  );
};

export default AccountMapFilter;
