import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectLoginLoading,
  processForgotPasswordAsync,
} from "@client.reducers/login";
import Button from "@client.core.components/Button";
import Input from "@client.core.components/Input";
import { Link } from "react-router-dom";
import Pages from "@client.enums/pages";
import "./ForgotPassword.scss";
import AuthLeftPanel from "../components/AuthLeftPanel";
import UnauthorizedPageCard from "../components/UnauthorizedPageCard/UnauthorizedPageCard";

const ForgotPasswordPage = () => {
  const loginLoading = useSelector(selectLoginLoading);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState("");

  return (
    <div className="forgot-password">
      <div className="forgot-password-panel">
        <AuthLeftPanel />
        <div className="forgot-password-container">
          <UnauthorizedPageCard isLoading={false}>
            <div className="forgot-password-form">
              <div className="title">{t("pages.ForgotPassword.title")}</div>
              <div className="navigate-login-title">
                {t("pages.InviteUser.getStarted")},&nbsp;
                <Link to={Pages.Login} className="signin">
                  {t("pages.InviteUser.login")}
                </Link>
                &nbsp;
                {t("pages.InviteUser.createAccount")}
              </div>
              <div className="subtitle">
                {t("pages.ForgotPassword.subTitle")}
              </div>
              <Input
                id="forgot-password-email"
                placeholder={t("pages.ForgotPassword.button.resetPassword")}
                label={"Email Address"}
                value={emailAddress}
                onChange={(v) => setEmailAddress(v)}
              />
              <div className="btn">
                {!loginLoading && (
                  <Button
                    text={t("pages.ForgotPassword.button.resetPassword")}
                    onClick={() => {
                      dispatch(processForgotPasswordAsync({ email: emailAddress }));
                    }}
                  />
                )}
              </div>
            </div>
            <div className="forgot-password-footer"></div>
          </UnauthorizedPageCard>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
