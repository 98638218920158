import React from "react";
import moment from "moment";

import config from "@client.config";

import { getAdvertisementBadgeClassName, getAdvertisementStatus } from '../../utils/utils';
import Badge from "@client.components/Badge";

export const getColumns = (t) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1,
    cellRenderer: (params) => <div className="clickable-cell">{params.value}</div>,
    maxWidth: 80
  },
  {
    field: "title",
    headerName: "Name",
    cellClass: "data-table-cell clickable-cell",
  },
  {
    field: "status",
    cellRenderer: (params) => {
      const statusText = getAdvertisementStatus(params.value);
      const className = getAdvertisementBadgeClassName(params.value)

      return (
        <Badge 
          text={t(statusText)}
          className={className}
        />
      )
    },
    cellClass: "data-table-cell clickable-cell",
  },
  {
    field: "totalPlays",
    headerName: "Plays",
    cellClass: "data-table-cell clickable-cell",
  },
  {
    // field: "totalPlays",
    headerName: "Impressions",
    cellClass: "data-table-cell clickable-cell",
  },
  {
    field: "totalViews",
    headerName: "Views",
    cellClass: "data-table-cell clickable-cell",
  },
  {
    field: "budgetTotal",
    headerName: "Amount Spent",
    cellClass: "data-table-cell clickable-cell",
  },
  {
    field: 'endDate',
    headerName: "End Date",
    valueGetter: (params)=> moment(params.data.endDate).format(config.dateTimeFormat),
    cellClass: "data-table-cell clickable-cell",
  },
];
