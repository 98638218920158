import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import IconRenderer from "@client.components/FA/IconRenderer";
import { TOOLTIP_ID } from "@client.constants";

export const OverviewBlock = ({
  value,
  text,
  icon,
  className,
  description,
  currency,
  info,
  color=""
}) => {
  return (
    <div className={classNames("overview-block", className)}>
      <IconRenderer
        iconName="FaCircleInfo"
        size={17}
        className="info"
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "1rem",
          right: "1rem",
        }}
        color="gray"
        title={description || text}
        data-tooltip-id={TOOLTIP_ID}
        data-tooltip-content={info}
      />
      <div className="overview-icon">
        <IconRenderer iconName={icon} size={22} color={color} />
      </div>

      <div className="overview-info">
        <div className="overview-value">
          {currency} {value}
        </div>
        <div className="overview-text">{text}</div>
      </div>
    </div>
  );
};

OverviewBlock.defaultProps = {};

OverviewBlock.propTypes = {
  value: PropTypes.number,
  icon: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  currency: PropTypes.string,
  info: PropTypes.string,
  color: PropTypes.string,
};
