import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { AppDispatch } from "@client.store";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import { IUser } from "@client.types/user";
import ServiceProvider from "@client.services/provider";

const DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
};

const useUpdateUserForm = (userData: IUser | null) => {
  const { t } = useTranslation();
  const { showSuccessfulToast, showErrorToast } = useToast();

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t("pages.ProfileGeneral.validation.firstName")),
    lastName: yup
      .string()
      .required(t("pages.ProfileGeneral.validation.lastName")),
    emailAddress: yup
      .string()
      .required(t("pages.ProfileGeneral.validation.emailAddress")),
    phoneNumber: yup
      .string()
      .required(t("pages.profileGeneral.validation.phoneNumber")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: userData ? {
      firstName: userData.firstName,
      lastName: userData.lastName,
      emailAddress: userData.emailAddress,
      phoneNumber: userData.phoneNumber,
    } : DEFAULT_VALUES,
  });

  useEffect(() => {
    if (userData) {
      reset({
        firstName: userData.firstName,
        lastName: userData.lastName,
        emailAddress: userData.emailAddress,
        phoneNumber: userData.phoneNumber,
      });
    }
  }, [userData, reset]);

  const resetForm = () => {
    reset(DEFAULT_VALUES);
  }

  const onSubmit = async (data) => {
    if (!userData) {
      return;
    }

    try {
      const response = await ServiceProvider.User.updateUser({
        id: userData.Id,
        fields: {
          firstName: data.firstName,
          lastName: data.lastName,
          emailAddress: data.emailAddress,
          phoneNumber: data.phoneNumber,
        }
      });
      if (response.data.updateUser.user) {
        showSuccessfulToast("User updated")
      } else {
        showErrorToast("Can't update user")
      }
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : "Can't update user";
      showErrorToast(errorMessage)
    }
  };

  const handleSubmitUpdateUserForm = handleSubmit(onSubmit);

  return { 
    register, 
    setValue, 
    getValues, 
    resetForm, 
    errors,
    handleSubmitUpdateUserForm 
  }
}

export default useUpdateUserForm;