import React, { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ServiceProvider from "@client.services/provider";

import { AppDispatch } from "@client.store";
import { getZonesAsync } from "@client.reducers/zones";

import useToast from "@client.hooks/useToast";

import Dialog from "@client.components/Dialog";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  zoneId: string | null;
  setZoneIdToDelete: Dispatch<SetStateAction<string | null>>;
}

const DeleteZoneConfirmation = ({ isOpen, onClose, zoneId, setZoneIdToDelete }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showToastAfterRequest } = useToast();

  const handleDeleteZone = async () => {
    if (!zoneId) {
      return null;
    }

    try {
      await ServiceProvider.Zone.delete(zoneId);
      const response = await dispatch(getZonesAsync());
      showToastAfterRequest(response, "pages.Locations.components.ZonesTree.modal.remove.success");
      setZoneIdToDelete(null);
      onClose();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("pages.Locations.components.ZonesTree.modal.title")}
      text={t("pages.Locations.components.ZonesTree.modal.confirmation")}
      onConfirm={handleDeleteZone}
    />
  )
};

export default DeleteZoneConfirmation;
