import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceProvider from "@client.services/provider";
import { ISystemLog } from "@client.types/systemLog";
import { IGetSystemLogsResponse, IGetSystemLogsVariables } from "@client.types/queries/getSystemLogs";
import { IDevice } from "@client.types/device";
import { IGetDeviceRegistrationsResponse, IGetDeviceRegistrationsVariables } from "@client.types/queries/getDeviceRegistrations";

interface IDeviceRegistrationsSlice {
  data: IDevice[];
  count: number;
  error: string | null;
  loading: boolean;
}

const initialState: IDeviceRegistrationsSlice = {
  data: [],
  count: 0,
  error: null,
  loading: false,
};

export const getDeviceRegistrationsAsync = createAsyncThunk<
  IGetDeviceRegistrationsResponse,
  IGetDeviceRegistrationsVariables
>(
  "deviceRegistrations/getDeviceRegistrations",
  async (filter) => {
    const response = await ServiceProvider.DeviceRegistration.getDeviceRegistrations(filter);
    return response.data.deviceRegistrations || [];
  }
);

const deviceRegistrationsSlice = createSlice({
  name: "deviceRegistrations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceRegistrationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeviceRegistrationsAsync.fulfilled, (state, action) => {
        state.data = action.payload.results;
        state.count = action.payload.count;
        state.loading = false;
      })
      .addCase(getDeviceRegistrationsAsync.rejected, (state, action) => {
        state.data = [];
        state.count = 0;
        state.loading = false;
        state.error = action.error.message ?? null;
      })
  }
});

export const selectDeviceRegistrationsData = (state) => state.deviceRegistrations.data;
export const selectDeviceRegistrationsCount = (state) => state.deviceRegistrations.count;
export const selectDeviceRegistrationsLoading = (state) => state.deviceRegistrations.loading;
export const selectDeviceRegistrationsError = (state) => state.deviceRegistrations.error;

export default deviceRegistrationsSlice.reducer;