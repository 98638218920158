import React from "react";
import Button from "@client.core.components/Button";
import Icon from "@client.core.components/Icon";
import "./styles.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  text: string;
  onConfirm: () => void;
}

export const Dialog = ({ isOpen, onClose, title, text, onConfirm }: IProps) => {

  if (!isOpen) {
    return null
  }

  return (
    <div className="dialog-container">
      <div className="dialog-backdrop"></div>
      <div
        className="dialog-content"
      >
        <div className="dialog-header">
          {title}
          <Icon
            name="close"
            className="dialog-close-button"
            onClick={onClose}
          />
        </div>
        <div className="dialog-body">
          <div className="dialog-text">{text}</div>
          <div className="dialog-buttons">
            <Button
              text="Delete"
              onClick={onConfirm}
            />
            <Button
              text="Cancel"
              onClick={onClose}
              className="alternative"
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Dialog;