import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { makeSelectedAdvertiser } from "@client.reducers/Advertiser";

import ProfileMenu from "@client.components/ProfileMenu";
import AdvertiserCircleName from "@client.components/AdvertiserCircleName";
import Icon from "@client.core.components/Icon";

import "./styles.scss";

const TopBar = ({ showAdvertiser, navigationLink }) => {
  const { t } = useTranslation();

  const selectedAdvertiser: any = useSelector(makeSelectedAdvertiser);
  const { advertiser } = selectedAdvertiser;

  return (
    <div className="top-bar" data-testid="TopBar">
      {showAdvertiser && advertiser ? (
        <AdvertiserCircleName advertiser={advertiser} />
      ) : <div />}
      <div className="right-btns">
        {navigationLink && (
          <NavLink
            to={navigationLink.to}
            className="link"
            onClick={navigationLink.onClick}
          >
            <Icon name="back" />
            <span>{t(navigationLink.name)}</span>
          </NavLink>
        )}
        <ProfileMenu />
      </div>
    </div>
  );
};

export default TopBar;