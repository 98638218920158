import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import { IResponseZone, IZone } from "@client.types/zone";
import { IAdvertisement } from "@client.types/advertisement";
import ZoneTypes from "@client.enums/zoneTypes";

interface ICreateZonePayload {
  fields: any;
}

interface IUpdateZonePayload {
  fields: any;
  id: string;
}

export interface ICreateZoneResponse {
  data: {
    createZone: {
      zone: IResponseZone,
      message: string,
    }
  }
}

interface IUpdateZoneResponse {
  data: {
    updateZone: {
      zone: IResponseZone,
      message: string,
    }
  }
}

interface IZonesSlice {
  data: IZone[],
  error: string | null,
  loading: boolean,
  selectedZone: {
    data: IZone | null,
    defaultAdvertisements: {
      data: IAdvertisement[],
      loading: boolean,
      error: string | null,
    }
  },
}

const INITIAL_DEFAULT_ADVERTISEMENTS = { data: [], loading: false, error: null }

const initialState: IZonesSlice = {
  data: [],
  error: null,
  loading: false,
  selectedZone: {
    data: null,
    defaultAdvertisements: INITIAL_DEFAULT_ADVERTISEMENTS
  },
};

export const getZonesAsync = createAsyncThunk(
  "zones/getZones",
  async () => {
    const response = await ServiceProvider.Zone.getZones();
    if (response.data.zones?.results) {
      return response.data.zones.results.map((zone) => ({
        ...zone,
        polygon: zone.polygon ? JSON.parse(zone.polygon) : [],
      }))
    }
    return response.data.zones.results || [];
  }
);

export const getZoneByIdAsync = createAsyncThunk(
  "zones/getById",
  async (id) => {
    return ServiceProvider.Zone.getById(id);
  }
);

export const createZoneAsync = createAsyncThunk<
  ICreateZoneResponse,
  ICreateZonePayload
>(
  "zones/create",
  async (zone, thunkAPI) => {
    const resp = await ServiceProvider.Zone.create(zone);
    if (!resp.errors) {
      thunkAPI.dispatch(getZonesAsync());
    }
    return resp;
  }
);

export const updateZoneAsync = createAsyncThunk<
  IUpdateZoneResponse,
  IUpdateZonePayload
>(
  "zones/update",
  async ({ fields, id }, thunkAPI) => {
    const resp = await ServiceProvider.Zone.update(fields, id);
    if (!resp.errors) {
      thunkAPI.dispatch(getZonesAsync());
    }
    return resp;
  }
);

export const getDefaultAdvertisementsByZoneIdAsync = createAsyncThunk<
  IAdvertisement[],
  string
>(
  "locations/getDefaultAdvertisementsByZoneId",
  async (zoneId) => {
    const response = await ServiceProvider.Zone.getDefaultAdvertisementsByZoneId(zoneId);
    return response.data.ZoneDefaultAdvertisements.results || [];
  }
);

export const zonesSlice = createSlice({
  name: "zones",
  initialState,
  reducers: {
    clearZonesError: (state) => {
      state.error = null;
    },
    setSelectedZone: (state, action) => {
      state.selectedZone.data = get(action, "payload", null);
    },
    clearDefaultAdvertisements: (state) => {
      state.selectedZone.defaultAdvertisements = INITIAL_DEFAULT_ADVERTISEMENTS;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getZonesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getZonesAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getZonesAsync.rejected, (state, action) => {
        state.data = [];
        state.loading = false;
        state.error = getResponseError(action);
      })
      .addCase(createZoneAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createZoneAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateZoneAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateZoneAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(getDefaultAdvertisementsByZoneIdAsync.pending, (state) => {
        state.selectedZone.defaultAdvertisements.loading = true;
      })
      .addCase(getDefaultAdvertisementsByZoneIdAsync.fulfilled, (state, action) => {
        state.selectedZone.defaultAdvertisements.loading = false;
        state.selectedZone.defaultAdvertisements.data = action.payload;
        state.selectedZone.defaultAdvertisements.error = getGraphqlResponseError(action);
      })
  },
});

export const {
  clearZonesError,
  setSelectedZone,
  clearDefaultAdvertisements
} = zonesSlice.actions;

export const selectZonesData = (state) => state.zones.data;
export const selectZonesTree = (state) => {
  const zones = [...state.zones.data];

  const zoneMap = {};
  const hierarchy: IZone[] = [];

  zones.forEach((zone) => {
    zoneMap[zone.Id] = { ...zone, items: [] };
  });

  zones.forEach((zone) => {
    if (zone.parentZoneId) {
      if (zoneMap[zone.parentZoneId]) {
        zoneMap[zone.parentZoneId].items.push(zoneMap[zone.Id]);
      }
    } else {
      hierarchy.push(zoneMap[zone.Id]);
    }
  });
  return hierarchy.sort((a, b) => b.category - a.category);
};

export const selectCustomZones = createSelector(
  [selectZonesTree],
  (zones) => zones.filter((zone) => zone.category === ZoneTypes.Custom)
);

export const selectNonCustomZones = createSelector(
  [selectZonesTree],
  (zones) => zones.filter((zone) => zone.category !== ZoneTypes.Custom)
);

export const selectZonesError = (state) => state.zones.error;
export const selectDefaultAdvertisementZones = (state) => state.zones.data;
export const selectZonesLoading = (state) => state.zones.loading;
export const selectSelectedZone = (state) => state.zones.selectedZone.data;
export const selectDefaultAdvertisementsData = (state) => state.zones.selectedZone.defaultAdvertisements.data;
export const selectDefaultAdvertisementsError = (state) => state.zones.selectedZone.defaultAdvertisements.error;
export const selectDefaultAdvertisementsLoading = (state) => state.zones.selectedZone.defaultAdvertisements.loading;

export default zonesSlice.reducer;
