import moment from "moment";
import config from "@client.config";

export const advertisementData = (advertisement) => {
  return [
    {
      title: "Total Budget",
      value: `$ ${advertisement.budgetTotal.toFixed(2)}`,
    },
    {
      title: "Daily Budget",
      value: `$ ${advertisement.budgetDaily.toFixed(2)}`,
    },
    {
      title: "Total Spent",
      value: `$ ${advertisement.totalSpent.toFixed(2)}`,
    },
    {
      title: "Start date",
      value: `${moment(advertisement.startDate).format(config.dateTimeFormat)}`,
    },
    {
      title: "End date",
      value: `${moment(advertisement.endDate).format(config.dateTimeFormat)}`,
    },
    {
      title: "Display Time",
      value: `${advertisement.consecutiveSlots} seconds`,
    },
    {
      title: "People Detected",
      value: `0`,
    },
    {
      title: "Total Impressions",
      value: `0`,
    },
    {
      title: "Total Views",
      value: `${advertisement.totalViews}`,
    },
    {
      title: "Total Plays",
      value: `${advertisement.totalPlays}`,
    },
  ];
};
