import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { changeUserPasswordAsync } from "@client.reducers/profile";
import Pages from "@client.enums/pages";
import useToast from "@client.hooks/useToast";
import useStableParams from "@client.hooks/useStableParams";

import "./styles.scss";
import { AppDispatch } from "@client.store";
import TextInput from "@client.components/form/TextInput";
import { IUser } from "@client.types/user";
import ServiceProvider from "@client.services/provider";

interface IProps {
  selectedUser: IUser;
}

const ChangePasswordTab = ({ selectedUser }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const { userId } = useStableParams();

  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState<IUser | null>(null);

  const fetchUser = useCallback(async () => {
    if (!userId) {
      return;
    }
    setLoading(true);
    try {
      const response = await ServiceProvider.User.getUserById(userId);
      if (response.data.user) {
        setUserData(response.data.user)
      } else {
        showErrorToast("Can't get user information")
      }
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : "Can't get user information";
      showErrorToast(errorMessage)
    } finally {
      setLoading(false);
    }
  }, [userId])

  useEffect(() => {
    fetchUser();
  }, [userId]);

  const [validationRules, setValidationRules] = useState([false, false, false]);

  const validationSchema = yup.object({
    oldPassword: yup.string().required(t("pages.ProfileChangePassword.validation.oldPassword")),
    newPassword: yup.string().required(t("pages.ProfileChangePassword.validation.password")),
    confirmNewPassword: yup.string().required(t("pages.ProfileChangePassword.validation.password")),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const newPassword = watch("newPassword");

  const validatePasswordRules = (password) => {
    setValidationRules([
      password.length >= 8,
      /[A-Z]/.test(password),
      /\d/.test(password),
    ]);
  };

  useEffect(() => {
    validatePasswordRules(newPassword);
  }, [newPassword]);

  const onSubmit = async (data) => {
    if (!userId) {
      return;
    }
    await dispatch(changeUserPasswordAsync({
      userId,
      password: data.newPassword,
    }));
    navigate(Pages.Logout);
  }

  return (
    <div className="profile-change-password-page">
      <div className="profile-change-password-content">
        <TextInput
          id="oldPassword"
          name="oldPassword"
          type="password"
          label={t("pages.ProfileChangePasswordPage.oldPassword")}
          placeholder={t("pages.ProfileChangePasswordPage.oldPassword")}
          register={register}
          error={errors.oldPassword}
        />
        <TextInput
          id="newPassword"
          name="newPassword"
          type="password"
          label={t("pages.ProfileChangePasswordPage.newPassword")}
          placeholder={t("pages.ProfileChangePasswordPage.newPassword")}
          register={register}
          error={errors.newPassword}
        />
        <TextInput
          id="confirmNewPassword"
          name="confirmNewPassword"
          type="password"
          label={t("pages.ProfileChangePasswordPage.confirmNewPassword")}
          placeholder={t("pages.ProfileChangePasswordPage.confirmNewPassword")}
          register={register}
          error={errors.confirmNewPassword}
        />
        <div className="password-must">
          {t("pages.ProfileChangePasswordPage.passwordMust")}
        </div>
        <ul className="rules">
          <li className={classNames({ valid: validationRules[0] })}>
            {t("pages.ProfileChangePasswordPage.rules.passwordLength")}
          </li>
          <li className={classNames({ valid: validationRules[1] })}>
            {t("pages.ProfileChangePasswordPage.rules.passwordUpperCase")}
          </li>
          <li className={classNames({ valid: validationRules[2] })}>
            {t("pages.ProfileChangePasswordPage.rules.passwordNumber")}
          </li>
        </ul>
        <Button
          text={t("pages.ProfileChangePasswordPage.save")}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

export default ChangePasswordTab;
