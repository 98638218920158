import React, { useCallback, useEffect, useState } from "react";
import Drawer from "@client.components/Drawer";
import Button from "@client.core.components/Button";
import Input from "@client.core.components/Input";
import SvgIcon from "@client.core.components/SvgIcon";
import Icon from "@client.core.components/Icon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ServiceProvider from "@client.services/provider";
import { TOAST_EVENT } from "@client.core.components/Toast";
import { makeProfileMessage } from "@client.reducers/profile";
import { AppDispatch } from "@client.store";

const fullPagePath = window.location.href;

const ProfileMenuDrawer = ({ isOpenDrawer, setIsOpenDrawer }) => {
  const [input, setInput] = useState<any>({ 0: "" });
  const [counter, setCounter] = useState(1);
  const [urlValue, setUrlValue] = useState(fullPagePath);

  const message = useSelector(makeProfileMessage);

  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const handleAddNewField = () => {
    setCounter((prevCount) => prevCount + 1);
    setInput((prevInput) => ({ ...prevInput, [counter.toString()]: "" }));
  };

  const handleDeleteField = (key) => {
    setInput((prevInput) => {
      const { [key]: deleted, ...rest } = prevInput;
      return rest;
    });
    setCounter((prevCount) => prevCount - 1);
  };

  const showSuccessToast = () => {
    ServiceProvider.EventEmitter.emit(TOAST_EVENT, [
      {
        type: "success",
        msg: message,
      },
    ]);
  };

  useEffect(() => {
    if (message) showSuccessToast();
  }, [message]);

  const inviteUsers = async () => {
    await ServiceProvider.User.inviteProfileByEmail(Object.values(input))
  }

  return (
    <Drawer
      isOpen={isOpenDrawer}
      title={t("components.Advertisers.invite")}
      footer={
        <Button
          text={t("button.Invite")}
          onClick={inviteUsers}
        />
      }
      onClose={() => setIsOpenDrawer(false)}
    >
      <div className="profile-menu-drawer-panel">
        {Object.keys(input).map((key) => (
          <React.Fragment key={key}>
            <div className="email-field">
              <Input
                label="Email"
                value={input[key]}
                placeholder="Email address"
                onChange={(value) =>
                  setInput((prev) => ({ ...prev, [key]: value }))
                }
              />
              <div className="close-icon-container">
                {Number(key) > 0 ? (
                  <Icon
                    name="close"
                    className="close-icon"
                    onClick={() => handleDeleteField(key)}
                  />
                ) : null}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="plus-icon-container">
        <SvgIcon
          name="plus"
          className="plus-icon"
          onClick={handleAddNewField}
        />
      </div>
      <div className="url-input-container">
        <Input value={urlValue} label="URL" onChange={setUrlValue} />
        <div className="empty-element" />
      </div>
    </Drawer>
  );
};

export default ProfileMenuDrawer;
