import React, { useMemo } from "react";

import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";

import { useTranslation } from "react-i18next";
import TextInput from "@client.components/form/TextInput";
import FormSelect from "@client.components/form/FormSelect";
import AccountEntity from "@client.enums/accountEntity";
import { ICreateAccountFormValues } from "./useCreateAccountForm";
import FormPlacesInput from "@client.components/form/FormPlacesInput";
import FormCheckbox from "@client.components/form/FormCheckbox";
import { IAddress } from "@client.types/address";
import { IAccount } from "@client.types/account";
import { useSelector } from "react-redux";
import { selectAccountsData } from "@client.reducers/accounts";

interface IProps {
  register: UseFormRegister<ICreateAccountFormValues>;
  control: Control<ICreateAccountFormValues>;
  getValues: UseFormGetValues<ICreateAccountFormValues>;
  setValue: UseFormSetValue<ICreateAccountFormValues>;
  trigger: UseFormTrigger<ICreateAccountFormValues>;
  errors: FieldErrors<ICreateAccountFormValues>;
  shippingAddress: IAddress;
  billingAddress: IAddress;
}

const CreateAccountForm = ({
  register,
  control,
  getValues,
  setValue,
  trigger,
  errors,
  shippingAddress,
  billingAddress,
}: IProps) => {
  const { t } = useTranslation();
  const accounts: IAccount[] = useSelector(selectAccountsData);

  const options: { value: string | number; label: string }[] = useMemo(() => (accounts?.map((data) => ({
    value: data.Id,
    label: data.companyName,
  }))), [accounts]);

  return (
    <div className="account-form">
      <FormSelect
        name="parentAccountId"
        label="Parent Account"
        control={control}
        options={options}
        error={errors.entity?.message}
      />
      <TextInput
        id="firstName"
        name="firstName"
        label={t("components.Advertisers.firstName")}
        placeholder={t("components.Advertisers.firstName")}
        register={register}
        error={errors.firstName}
      />
      <TextInput
        id="lastName"
        name="lastName"
        label={t("components.Advertisers.lastName")}
        placeholder={t("components.Advertisers.lastName")}
        register={register}
        error={errors.lastName}
      />
      <TextInput
        id="emailAddress"
        name="emailAddress"
        label={t("components.Advertisers.emailAddress")}
        placeholder={t("components.Advertisers.emailAddress")}
        register={register}
        error={errors.emailAddress}
      />
      <TextInput
        id="phoneNumber"
        name="phoneNumber"
        label={t("components.Advertisers.phoneNumber")}
        placeholder={t("components.Advertisers.phoneNumber")}
        register={register}
        error={errors.phoneNumber}
      />
      <FormSelect
        name="entity"
        label="Entity"
        control={control}
        options={[
          {
            value: AccountEntity.Reseller,
            label: t("components.Advertisers.customerType.reseller"),
          },
          {
            value: AccountEntity.Advertiser,
            label: t("components.Advertisers.customerType.advertiser"),
          },
          {
            value: AccountEntity.Partner,
            label: t("components.Advertisers.customerType.partner"),
          },
        ]}
        error={errors.entity?.message}
      />
      <TextInput
        id="companyName"
        name="companyName"
        label={t("pages.Accounts.tabs.Details.companyName")}
        placeholder={t("pages.Accounts.tabs.Details.companyName")}
        register={register}
        error={errors.companyName}
      />
      <TextInput
        id="companyRegistrationNumber"
        name="companyRegistrationNumber"
        label={t("pages.Accounts.tabs.Details.companyNumber")}
        placeholder={t("pages.Accounts.tabs.Details.companyNumber")}
        register={register}
        error={errors.companyRegistrationNumber}
      />
      <TextInput
        id="companyVatNumber"
        name="companyVatNumber"
        label={t("pages.Accounts.tabs.Details.vatNumber")}
        placeholder={t("pages.Accounts.tabs.Details.vatNumber")}
        register={register}
        error={errors.companyVatNumber}
      />
      <FormPlacesInput
        value={shippingAddress}
        setValue={setValue}
        trigger={trigger}
        addressObjectName="shippingAddress"
        label="Shipping Address"
        error={errors.shippingAddress?.addressLineOne?.message}
      />
      <FormCheckbox
        id="sameAddress"
        name="sameAddress"
        label="Billing Address is the same"
        control={control}
        error={errors.sameAddress}
      />
      <FormPlacesInput
        value={billingAddress}
        setValue={setValue}
        trigger={trigger}
        addressObjectName="billingAddress"
        label="Billing Address"
        disabled={getValues("sameAddress")}
        error={errors.billingAddress?.addressLineOne?.message}
      />
    </div>
  );
};

export default CreateAccountForm;
