import React from "react";

import Map from "@client.components/Map";

interface IZonePolygon {
  color: string;
  data: {
    lat: number;
    lng: number;
  }[];
}

interface IProps {
  children: React.ReactNode,
  isDrawingActive?: boolean,
  drawingManagerKey: string,
  selectedPolygonData: IZonePolygon[],
  onDrawingManagerPolygonComplete: (coordinates: any) => void,
}

const ZonesMap = ({
  children,
  isDrawingActive = false,
  drawingManagerKey,
  selectedPolygonData,
  onDrawingManagerPolygonComplete,
}: IProps) => {
  return (
    <Map
      zoom={2}
      options={{
        mapTypeControl: false,
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: false,
      }}
      polygons={selectedPolygonData}
      drawingManagerKey={drawingManagerKey}
      drawingManagerRef={
        isDrawingActive
          ? (v) => {
            if (v) {
              v.setDrawingMode("polygon");
            }
          }
          : null
      }
      onDrawingManagerPolygonComplete={onDrawingManagerPolygonComplete}
      className="zones-map"
    >
      {children}
    </Map>
  );
};

export default ZonesMap;
