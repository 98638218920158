import React, { SVGProps } from "react";
import useSvgComponent from "@client.hooks/useSvgComponent";

interface IProps extends SVGProps<SVGSVGElement> {
  name: string;
}

const SvgIcon = ({ name, ...rest }: IProps) => {
  const Svg = useSvgComponent(name);

  if (Svg) {
    return <Svg {...rest} />;
  }
  return null;
};

export default SvgIcon;
