import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DataTable from "@client.components/DataTable";
import { getColumns } from "./getColumns";

import Title from "@client.components/Title";

import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import { IUserLog } from "@client.types/userLog";
import ServiceProvider from "@client.services/provider";
import useToast from "@client.hooks/useToast";
import UserLogsFilter from "./UserLogsFilter";
import "./styles.scss";

const ITEMS_PER_PAGE = 10;

export interface IUserLogsFilter {
  search: string,
  startDate: string,
  endDate: string,
}

const UserLogsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  const [userLogs, setUserLogs] = useState<IUserLog[]>([]);
  const [userLogsCount, setUserLogsCount] = useState<number>(0);
  const [isLoading, setLoading] = useState(true);

  const [filter, setFilter] = useState({
    search: "",
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    const fetchUserLogs = async () => {
      try {
        const offset = (currentPage - 1) * ITEMS_PER_PAGE;
        const response = await ServiceProvider.UserLog.getUserLogs({
          pagination: {
            limit: ITEMS_PER_PAGE,
            offset,
            search: filter.search,
            searchOn: "",
            startDate: filter.startDate, 
            endDate: filter.endDate,
          },
        });
        const userLogsResponse = response.data.userLogs;
        if (userLogsResponse) {
          setUserLogs(userLogsResponse.results);
          setUserLogsCount(userLogsResponse.count);
        } else {
          showErrorToast("Can't get user logs");
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get user logs";
        showErrorToast(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchUserLogs();
  }, [filter, currentPage]);

  const handleTableRowClick = useCallback(
    (id) => {
      navigate(`${Pages.Users}/${id}/general`);
    },
    [navigate]
  );

  return (
    <div className="user-logs">
      <Title text="components.NavigationBar.UserLogs" />
      <UserLogsFilter
        filter={filter}
        setFilter={setFilter}
      />
      <div className="user-logs-list">
        <DataTable
          columns={getColumns(t)}
          data={userLogs}
          isLoading={isLoading}
          onSelectionChanged={handleTableRowClick}
          pagination={{
            currentPage,
            total: userLogsCount,
            itemsPerPage: ITEMS_PER_PAGE,
            onChange: setCurrentPage,
          }}
        />
      </div>
    </div>
  );
};

export default UserLogsPage;
