import React from "react";

import SvgIcon from "@client.core.components/SvgIcon";

import GeneralInformation from "./steps/GeneralInformationStep";
import SlotCalculator from "./steps/SlotCalculatorStep";
import Locations from "./steps/LocationsStep";
import CreateAdvertisementFormSummary from "./CreateAdvertisementFormSummary";
import { Step, useCreateAdvertisementForm } from "../CreateAdvertisementFormContext";
import PaymentStep from "./steps/PaymentStep";
import LocationTypes from "@client.enums/locationTypes";

export interface ILocation {
  title: string;
  id: string;
  type: LocationTypes;
}

const CreateAdvertisementForm= () => {
  const { currentStep, setCurrentStep, isFormFilled } = useCreateAdvertisementForm();

  const steps = [
    {
      id: Step.GeneralInformation,
      component: (
        <GeneralInformation currentStep={currentStep} setCurrentStep={setCurrentStep}/>
      )
    },
    {
      id: Step.Locations,
      component: (
        <Locations currentStep={currentStep} setCurrentStep={setCurrentStep}/>
      )
    },
    {
      id: Step.SlotCalculator,
      component: (
        <SlotCalculator currentStep={currentStep} setCurrentStep={setCurrentStep}/>
      )
    },
    {
      id: Step.Payment,
      component: (
        <PaymentStep currentStep={currentStep} setCurrentStep={setCurrentStep}/>
      )
    }
  ]

  return (
    <div className="create-advertisement-form">
      {steps.map((step, index) => (
        <div className="step" key={index}>
          {currentStep > index && (
            <div className="identification">
              <SvgIcon name={"step-marker-active"} />
              <div className="connection" />
            </div>
          )}
          {currentStep <= index && (
            <div className="identification">
              <SvgIcon name={"step-marker"} />
              {index !== steps.length - 1 && (
                <div className="connection" />
              )}
            </div>
          )}
          <div className="content">
            {step.component}
          </div>
        </div>
      ))}
      { isFormFilled ? (
        <CreateAdvertisementFormSummary />
      ) : false }
    </div>
  )
}

export default CreateAdvertisementForm;