import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IAccount } from "@client.types/account";
import AccountEntity from "@client.enums/accountEntity";
import useToast from "@client.hooks/useToast";

import Tabs from "@client.components/Tabs";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";

import DetailsTab from "./tabs/Details";
import DevicesTab from "./tabs/Devices";
import BillingTab from "./tabs/Billing";
import AdvertisementTab from "./tabs/Advertisements";
import ServiceProvider from "@client.services/provider";
import { IUser } from "@client.types/user";
import { FaBuilding, FaFileInvoice, FaTv, FaUserGroup, FaVideo } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

enum AccountTabsEnum {
  Details = 0,
  Billing = 1,
  Devices = 2,
  Advertisements = 3,
  Users = 4,
}

const AccountTabsNames = {
  Details: 'details',
  Billing: 'billing',
  Devices: 'devices',
  Advertisements: 'advertisements',
  Users: 'users',
}

const getTabNumberFromString = (tabName: string) => {
  switch (tabName) {
  case AccountTabsNames.Details: 
    return AccountTabsEnum.Details;
  case AccountTabsNames.Billing: 
    return AccountTabsEnum.Billing;
  case AccountTabsNames.Devices: 
    return AccountTabsEnum.Devices;
  case AccountTabsNames.Advertisements: 
    return AccountTabsEnum.Advertisements;
  case AccountTabsNames.Users: 
    return AccountTabsEnum.Users;
  default: 
    return AccountTabsEnum.Details;
  }
}

const getTabStringFromNumber = (tabNumber: AccountTabsEnum) => {
  switch (tabNumber) {
  case AccountTabsEnum.Details: 
    return AccountTabsNames.Details;
  case AccountTabsEnum.Billing: 
    return AccountTabsNames.Billing;
  case AccountTabsEnum.Devices: 
    return AccountTabsNames.Devices;
  case AccountTabsEnum.Advertisements: 
    return AccountTabsNames.Advertisements;
  case AccountTabsEnum.Users: 
    return AccountTabsNames.Users;
  default: 
    return AccountTabsNames.Details;
  }
}

interface IProps {
  selectedAccount: IAccount | null;
}

const AccountInformation = ({ selectedAccount }: IProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const { id, tab } = useParams();
  const navigate = useNavigate();

  const [accountUsers, setAccountUsers] = useState<IUser[]>([]);

  const selectedTab = tab ? getTabNumberFromString(tab) : AccountTabsEnum.Details;

  useEffect(() => {
    if (selectedAccount) {
      const fetchAccountUsers = async () => {
        try {
          const response = await ServiceProvider.Account.getAccountUsers(selectedAccount.Id);
          if (response.data.users.results) {
            setAccountUsers(response.data.users.results)
          } else {
            showErrorToast("Can't get account users")
          }
        } catch (e) {
          const errorMessage =
          e instanceof Error ? e.message : "Can't get account users";
          showErrorToast(errorMessage)
        }
      }
  
      fetchAccountUsers();
    }
  }, [selectedAccount])

  const renderTabContent = () => {
    if (!selectedAccount) {
      return null
    }

    switch (selectedTab) {
    case AccountTabsEnum.Details:
      return <DetailsTab selectedAccount={selectedAccount} accountUsers={accountUsers} />;
    case AccountTabsEnum.Billing:
      return <BillingTab selectedAccount={selectedAccount} />;
    case AccountTabsEnum.Devices:
      return <DevicesTab selectedAccount={selectedAccount} />;
    case AccountTabsEnum.Advertisements:
      return <AdvertisementTab selectedAccount={selectedAccount} />;
    default:
      return null;
    }
  };

  const tabsPlatform = [
    {
      id: AccountTabsEnum.Details,
      name: t("pages.Accounts.details"),
      icon: <FaBuilding />,
    },
    {
      id: AccountTabsEnum.Billing,
      name: t("pages.Accounts.billing"),
      icon: <FaFileInvoice />,
    },
    {
      id: AccountTabsEnum.Devices,
      name: t("pages.Accounts.devices"),
      icon: <FaTv />,
    },
    {
      id: AccountTabsEnum.Advertisements,
      name: t("pages.Accounts.Advertisements"),
      icon: <FaVideo />,
    },
    {
      id: AccountTabsEnum.Users,
      name: "Users",
      icon: <FaUserGroup />,
    },
  ];

  const tabsPartner = [
    {
      id: AccountTabsEnum.Details,
      name: t("pages.Accounts.details"),
      icon: <FaBuilding />,
    },
    {
      id: AccountTabsEnum.Billing,
      name: t("pages.Accounts.billing"),
      icon: <FaFileInvoice />,
    },
    {
      id: AccountTabsEnum.Devices,
      name: t("pages.Accounts.devices"),
      icon: <FaTv />,
    },
  ];

  const tabsResellerOrAdvertiser = [
    {
      id: AccountTabsEnum.Details,
      name: t("pages.Accounts.details"),
      icon: <FaBuilding />,
    },
    {
      id: AccountTabsEnum.Billing,
      name: t("pages.Accounts.billing"),
      icon: <FaFileInvoice />,
    },
    {
      id: AccountTabsEnum.Advertisements,
      name: t("pages.Accounts.Advertisements"),
      icon: <FaVideo />,
    },
    {
      id: AccountTabsEnum.Users,
      name: "Users",
      icon: <FaUserGroup />,
    },
  ];

  const getTabs = () => {
    switch (selectedAccount?.entity) {
    case AccountEntity.Platform:
      return tabsPlatform;
    case AccountEntity.Partner:
      return tabsPartner;
    default:
      return tabsResellerOrAdvertiser;
    }
  }

  const handleTabChange = (newTab) => {
    navigate(`/accounts/${id}/${getTabStringFromNumber(newTab)}`);
  };

  return (
    <div className="account-information">
      {selectedAccount ? (
        <>
          <Tabs
            tabs={getTabs()}
            className="account-tabs"
            value={selectedTab}
            onSelect={handleTabChange}
          />
          {renderTabContent()}
        </>
      ) : (
        <div className="no-data-wrapper">
          <NoDataPlaceholder description={t("pages.Accounts.noData")} />
        </div>
      )}
    </div>
  );
};

export default AccountInformation;
