import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { OverviewBlock } from "./OverviewBlock";
import { selectProfileData } from "@client.reducers/profile";
import "./TopBoardOverview.scss";
import classNames from "classnames";

export const TopBoardOverview = ({
  budgetSpent,
  accountCredit,
  cardLastFour,
  lastInvoiceAmount,
  className
}) => {
  const { t } = useTranslation();
  const profile = useSelector(selectProfileData);

  return (
    <div className={classNames("top-board-overview", className)}>
      
      <OverviewBlock
        value={accountCredit}
        // icon="adPlays"
        icon="FaWallet"
        text={t("components.TopBoardOverview.accountCredit")}
        className="ad-plays"
        info="Ads plays info" // needs to include in I18N
        color="#04297A"
      />
      <OverviewBlock
        value={cardLastFour}
        // icon="cardLastFour"
        icon="FaRegCreditCard"
        text={t("components.TopBoardOverview.primeCard")}
        className="ad-views"
        info="Ads view info" // needs to include in I18N
        color="#7A4F01"
      />
      <OverviewBlock
        value={lastInvoiceAmount}
        // icon="lastInvoiceAmount"
        icon="FaFileInvoiceDollar"
        text={t("components.TopBoardOverview.lastInvoiceAmount")}
        className="active-advertisement"
        info="Your active advertisement info" // needs to include in I18N
        color="#08660D"
      />
    </div>
  );
};

TopBoardOverview.defaultProps = {
  budgetSpent: 0,
  accountCredit: 0,
  cardLastFour: 0,
  lastInvoiceAmount: 0,
  loading: false,
  info: null
};

TopBoardOverview.propTypes = {
  budgetSpent: PropTypes.number,
  accountCredit: PropTypes.number,
  cardLastFour: PropTypes.number,
  lastInvoiceAmount: PropTypes.number,
  loading: PropTypes.bool,
  className: PropTypes.string,
  info: PropTypes.string
};
