import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import {
  updateZoneAsync,
} from "@client.reducers/zones";
import { IZone } from "types/zone";
import { AppDispatch } from "@client.store";

const DEFAULT_VALUES = {
  zoneName: "",
  primePpvRate: 0,
  standardPpvRate: 0,
  color: "",
  polygon: [],
};

const useUpdateZoneForm = (
  zoneToUpdate: IZone | null,
  setShowUpdateZoneDrawer: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();

  const validationSchema = yup.object({
    zoneName: yup
      .string()
      .required(t("pages.Locations.components.ZoneManagement.validation.zoneName")),
    primePpvRate: yup
      .number()
      .required(t("pages.Locations.components.ZoneManagement.validation.primeRate")),
    standardPpvRate: yup
      .number()
      .required(t("pages.Locations.components.ZoneManagement.validation.ppvRate")),
    color: yup
      .string()
      .required(t("pages.Locations.components.ZoneManagement.validation.color")),
    polygon: yup
      .array()
      .of(yup.array().required()
        .of(yup.number().required()))
      .required()
      .min(1, 'Location is not correct'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: zoneToUpdate ? {
      zoneName: zoneToUpdate.zoneName,
      primePpvRate: zoneToUpdate.primePpvRate,
      standardPpvRate: zoneToUpdate.standardPpvRate,
      color: zoneToUpdate.color,
      polygon: zoneToUpdate.polygon,
    } : DEFAULT_VALUES,
  });

  useEffect(() => {
    if (zoneToUpdate) {
      reset({
        zoneName: zoneToUpdate.zoneName,
        primePpvRate: zoneToUpdate.primePpvRate,
        standardPpvRate: zoneToUpdate.standardPpvRate,
        color: zoneToUpdate.color,
        polygon: zoneToUpdate.polygon,
      });
    }
  }, [zoneToUpdate, reset]);

  const zoneName = watch('zoneName');

  const resetForm = () => {
    reset(DEFAULT_VALUES);
  }

  const onSubmit = async (data) => {
    if (!zoneToUpdate) {
      return;
    }

    const zoneFields = {
      ...data,
      polygon: JSON.stringify(data.polygon),
      category: zoneToUpdate.category,
      parentZoneId: zoneToUpdate.parentZoneId
    }
    try {
      const response = await dispatch(
        updateZoneAsync({ fields: zoneFields, id: zoneToUpdate.Id })
      ).unwrap();
      if (response.data.updateZone.zone) {
        showToastAfterRequest(
          response.data.updateZone.message,
          "pages.Locations.components.ZonesTree.modal.update.success"
        )
      }
      resetForm();
      setShowUpdateZoneDrawer(false);
    } catch (e) {
      console.log(e)
    }
  };

  const handleSubmitUpdateZoneForm = handleSubmit(onSubmit);

  return { 
    register, 
    setValue, 
    getValues, 
    resetForm, 
    errors,
    zoneName,
    handleSubmitUpdateZoneForm 
  }
}

export default useUpdateZoneForm;