import React from "react";

import "./Invoice.scss";
import { useParams } from "react-router-dom";
import InvoiceDetails from "./InvoiceDetails";

const Invoice = () => {
  const { id } = useParams();

  return (
    <div className="device-page">
      <div className="content">
        <InvoiceDetails id={id} />
      </div>
    </div>
  );
};

export default Invoice;
