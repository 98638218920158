import React from "react";

import { useCreateAdvertisementForm } from "../CreateAdvertisementFormContext";
import MediaFile from "@client.components/MediaFile";
import SvgIcon from "@client.components/core/SvgIcon";
import { useTranslation } from "react-i18next";
import { convertDateAndTime } from "@client.utils/utils";
import Button from "@client.components/core/Button";
import useToast from "@client.hooks/useToast";
import { useNavigate } from "react-router-dom";
import ServiceProvider from "@client.services/provider";
import Pages from "@client.enums/pages";
import getCreateAdvertisementFields from "../getCreateAdvertisementFields";

const CreateAdvertisementFormSummary = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();

  const { 
    createAdvertisementFormMethods, 
    selectedImageUrl, 
    draftAdvertisement,
    accounts,
    advertisementTargets,
    selectedLocationsList,
  } = useCreateAdvertisementForm();

  const {
    advertiserId,
    startDate,
    endDate,
    targets,
    consecutiveSlots,
    budgetAmount,
    title,
  } = createAdvertisementFormMethods.getValues();

  const advertisementSummaryGeneral = [
    {
      title: t("Pages.CreateAdvertisement.advertisementName"),
      value: title || "N/A",
    },
    {
      title: "Advertiser",
      value: accounts.find((account) => account.Id === advertiserId)?.companyName || "N/A",
    },
    {
      title: t("Pages.CreateAdvertisement.startDate"),
      value: convertDateAndTime(startDate) || "N/A",
    },
    {
      title: t("Pages.CreateAdvertisement.endDate"),
      value: convertDateAndTime(endDate) || "N/A",
    },
    {
      title: "Targets",
      value: advertisementTargets
        .filter((target) => targets.includes(target.id))
        .map((target) => target.name).join(", ") || "N/A",
    },
  ];

  const advertisementSummaryLocation = [
    {
      title: t("Pages.CreateAdvertisement.location"),
      value: selectedLocationsList.length > 0 ? selectedLocationsList.map((location) => location.title).join(", ") : "N/A",
    },
  ];

  const advertisementSummaryBudget = [
    {
      title: t("Pages.CreateAdvertisement.priceTotal"),
      value: `$${budgetAmount || 0}`,
    },
    {
      title: t("Pages.CreateAdvertisement.durationTime"),
      value: consecutiveSlots,
    },
  ];

  const handleLaunchAdvertisement = async (isDraft: boolean = false) => {
    const createAdvertisementFormValues =
      createAdvertisementFormMethods.getValues();
    try {
      const advertisementFields = getCreateAdvertisementFields(
        createAdvertisementFormValues
      );
      if (draftAdvertisement) {
        const response =
          await ServiceProvider.Advertisement.updateAdvertisement({
            fields: {
              ...advertisementFields,
              isDraft: isDraft || false,
            },
            id: draftAdvertisement.Id,
          });
        if (response.data.updateAdvertisement.advertisement) {
          navigate(`${Pages.Advertisements}`);
        } else {
          showErrorToast("Can't create advertisement");
        }
      } else {
        const response =
          await ServiceProvider.Advertisement.createAdvertisement({
            fields: {
              ...advertisementFields,
              isDraft: isDraft || false,
            },
          });
        if (response.data.createAdvertisement.advertisement) {
          navigate(`${Pages.Advertisements}`);
        } else {
          showErrorToast("Can't create advertisement");
        }
      }
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : "Can't create advertisement";
      showErrorToast(errorMessage);
    }
  };

  return (
    <div className="create-advertisement-form-summary">
      <p className="title">Summary</p>
      <div className="summary-container">
        <div className="summary-header">
          <h2 className="summary-title">{title}</h2>
          <div className="summary-image">
            <MediaFile
              src={`${selectedImageUrl}`}
              fallbackElement={() => (
                <SvgIcon
                  name="file-not-found"
                  className="file-not-found-icon"
                  width={120}
                  height={120}
                />
              )}
            />
          </div>
        </div>
        <div className="summary-content">
          {advertisementSummaryGeneral.map((item) => (
            <div key={item.title} className="summary-row">
              <p className="summary-title">{t(item.title)}</p>
              <p className="summary-value">{item.value}</p>
            </div>
          ))}
        </div>
        <div className="summary-content">
          {advertisementSummaryLocation.map((item) => (
            <div key={item.title} className="summary-row">
              <p className="summary-title">{t(item.title)}</p>
              <p className="summary-value">{item.value}</p>
            </div>
          ))}
        </div>
        <div className="summary-content">
          {advertisementSummaryBudget.map((item) => (
            <div key={item.title} className="summary-row">
              <p className="summary-title">{t(item.title)}</p>
              <p className="summary-value">{item.value}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="buttons-container">
        <Button
          text={t("Pages.CreateAdvertisement.scheduledAdvertisement")}
          onClick={() => handleLaunchAdvertisement()}
        />
        <Button
          text={t("Pages.CreateAdvertisement.saveDraft")}
          onClick={() => handleLaunchAdvertisement(true)}
        />
      </div>
    </div>
  );
};

export default CreateAdvertisementFormSummary;
