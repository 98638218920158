import React, { useMemo } from "react";
import { IInvoiceData } from "./types";
import SvgIcon from "@client.components/core/SvgIcon";
import { convertDateAndTime } from "@client.utils/utils";
import { useTranslation } from "react-i18next";

export function InvoicePreview({ invoice: data }: { invoice: IInvoiceData }) {
  const { billTo, billFrom, invoice, payments } = data;
  const { t } = useTranslation();
  const billToAddress = billTo.billingAddress;
  const billFromAddress = billFrom.billingAddress;

  const BillingFromAndBillingToData = useMemo(
    () => [
      {
        id: 1,
        companyName: billFrom.companyName,
        lineOne: billFromAddress.lineOne,
        city: `${billFromAddress.city}, ${billFromAddress.postalCode}`,
        country: billFromAddress.country,
      },
      {
        id: 2,
        companyName: billTo.companyName,
        lineOne: billToAddress.lineOne,
        city: `${billToAddress.city}, ${billToAddress.postalCode}`,
        country: billToAddress.country,
      },
    ],
    [billFromAddress, billToAddress]
  );

  const orderDetailsData = {
    head: [
      "pages.Invoice.item",
      "pages.Invoice.amount",
    ],
    body: [invoice.purpose, `$ ${invoice.amount.toFixed(2)}`],
  };

  return (
    <div className="preview">
      <div className="head">
        <div>
          <SvgIcon name="logo" className="logo" />
        </div>
        <div>
          <h3>{t("pages.Invoice.invoice")}</h3>
          <span>
            {t("pages.Invoice.invoiceNo")}: {invoice.Id}
          </span>
          <span>
            {t("pages.Invoice.dateIssued")} -{" "}
            {convertDateAndTime(invoice.createdAt)}
          </span>
        </div>
      </div>
      <div className="info">
        <span>{t("pages.Invoice.customer")}</span>
        <div className="content">
          {BillingFromAndBillingToData.map((data) => (
            <div key={data.id}>
              {data.id !== 1 ? (
                <>
                  <h3>{t("pages.Invoice.deliverTo")}</h3>
                  <h4>{data.companyName}</h4>
                </>
              ) : (
                <h3>{data.companyName}</h3>
              )}
              <div>{data.lineOne}</div>
              <div>{data.city}</div>
              <div>{data.country}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="details">
        <h3>{t("pages.Invoice.orderDetails")}</h3>
        <table className="order-details-table">
          <thead>
            <tr>
              {orderDetailsData.head.map((order) => (
                <th key={order}>
                  <span>{t(order)}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {orderDetailsData.body.map((order) => (
                <td key={order}>
                  <span>{order}</span>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <span>
          {t("pages.Invoices.Details.duedate")}:{" "}
          {convertDateAndTime(invoice.dueDate)}
        </span>
      </div>

      <div className="footer">
        <div>
          <span>
            {t("pages.Invoice.totalHT")}: $ {invoice.amount.toFixed(2)}
          </span>
          <span>{t("pages.Invoice.vat")}: $ 0.00</span>
          <span>
            {t("pages.Invoice.totalDue")}: $ {invoice.amount.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
}
