import validate from "validate.js";

interface PasswordConfirmationOptions {
  field: string;
  message?: string;
}

interface ValidationAttributes {
  [key: string]: any;
}

validate.validators.passwordConfirmation = function (
  value: string,
  options: PasswordConfirmationOptions,
  key: string,
  attributes: ValidationAttributes
): string | undefined {
  if (value !== attributes[options.field]) {
    return options.message;
  }
};

validate.validators.passwordComplexity = function (
  value: string,
  options: { message?: string }
): string | null {
  if (!value) {
    return options.message || "Password cannot be empty";
  }

  if (value.length < 8 || value.length > 32) {
    return options.message || "Password must be between 8 and 32 characters";
  }

  if (!/[A-Z]/.test(value)) {
    return options.message || "Password must include an uppercase character";
  }

  if (!/\d/.test(value)) {
    return options.message || "Password must include a number";
  }

  return null;
};

validate.extend(validate.validators.datetime, {
  // Parse converts the value into a JavaScript Date object
  parse: function (value) {
    return new Date(value).getTime(); // Convert to timestamp
  },
  // Format converts a timestamp back into a readable string
  format: function (value, options) {
    const date = new Date(value);
    return date.toISOString(); // Use ISO format for consistency
  },
});

class BaseModel {
  public validationMsgs: Record<string, string>;
  public validationRules: Record<string, any>;

  constructor(msg: Record<string, string>) {
    this.validationMsgs = msg;
    this.validationRules = {};
  }

  validate = (validationRules = {}) => {
    const rules =
      Object.keys(this.validationRules).length === 0
        ? validationRules
        : this.validationRules;
    const validation =
      Object.keys(rules).length === 0
        ? {}
        : validate(this, rules, {
          fullMessages: false,
        });

    return validation || {};
  };
}

export default BaseModel;
