import React, { useEffect, useState } from "react";

import TopBoardOverview from "./TopBoardOverview";
import DashboardMap from "./DashboardMap";
import ServiceProvider from "@client.services/provider";
import useToast from "@client.hooks/useToast";
import { IDashboardBoxes } from "@client.types/dashboardBoxes";

const PlatformDashboard = () => {
  const { showErrorToast } = useToast();

  const [dashboardBoxes, setDashboardBoxes] = useState<IDashboardBoxes | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchDashboardBoxes = async () => {
      try {
        const response = await ServiceProvider.Dashboard.getDashboardBoxes();
        if (response.data.dashboardBoxes) {
          setDashboardBoxes(response.data.dashboardBoxes)
        } else {
          showErrorToast("Can't get dashboard information")
        }
      } catch (e) {
        const errorMessage =
        e instanceof Error ? e.message : "Can't get dashboard information";
        showErrorToast(errorMessage)
      } finally {
        setLoading(false);
      }
    }

    fetchDashboardBoxes();
  }, [])
  
  return (
    <div className="dashboard">
      <TopBoardOverview dashboardBoxes={dashboardBoxes} isLoading={isLoading} />
      <DashboardMap dashboardBoxes={dashboardBoxes} />
    </div>
  );
};

export default PlatformDashboard;
