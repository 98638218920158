import React from 'react';
import { FieldValues, Path, FieldError, Controller, Control } from 'react-hook-form';

import "./index.scss";

interface IProps<T extends FieldValues> {
  id: string;
  name: Path<T>;
  label: string;
  control: Control<T>;
  error?: FieldError;
}

const FormCheckbox = <T extends FieldValues>({
  id,
  name,
  label,
  control,
  error,
}: IProps<T>) => {
  return (
    <div className="checkbox-container">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <label className="container">
            {label}
            <input
              type="checkbox"
              id={name}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
            <span className="checkmark"></span>
          </label>
        )}
      />
      {error && <span className="error-message">{error.message}</span>}
    </div>
  );
};

export default FormCheckbox;