import React from "react";
import ReactSlider from "react-slider";

import Input from "@client.core.components/Input";

import "./styles.scss";

interface IProps {
  min: number, 
  max: number, 
  value: string, 
  onChange: (value: string) => void, 
  isMoney?: boolean;
}

const Slider = ({ min, max, value, onChange, isMoney }: IProps) => {
  const numberValue = +value;
  return (
    <div className="slider">
      <div className="value">
        {!value ?
          <>
            {isMoney ? '$' : ''}
            <Input
              value="0"
              onChange={(v) => onChange(v)}
              type="number"
              optional={{
                min,
                max,
                step: 1,
              }}
            />
          </>
          :
          <>
            {isMoney ? '$' : ''}
            <Input
              value={value}
              onChange={(v) => onChange(v)}
              type={"number"}
              optional={{
                min,
                max,
                step: 1,
              }}
            />
          </>
        }
      </div>

      <ReactSlider
        min={min}
        max={max}
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        value={numberValue}
        onChange={(value) => {
          onChange(`${value}`);
        }}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      />
    </div>
  );
};

export default Slider;
