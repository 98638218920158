import * as FaIcons from "react-icons/fa"; // Font Awesome
import * as Fa6Icons from "react-icons/fa6"; // Font Awesome 6
import * as MdIcons from "react-icons/md"; // Material Design
import * as AiIcons from "react-icons/ai"; // Ant Design
import * as IoIcons from "react-icons/io"; // Ionicons
import * as BsIcons from "react-icons/bs"; // Bootstrap Icons
import * as TbIcons from "react-icons/tb"; // Tabler Icons
import * as FcIcons from "react-icons/fc"; // Flat Color Icons
import * as RiIcons from "react-icons/ri"; // Remix Icons
import * as GiIcons from "react-icons/gi"; // Game Icons
import * as CiIcons from "react-icons/ci";
import * as Io5Icons from "react-icons/io5";
const IconLibrary = {
  ...FaIcons, // Add all Font Awesome icons
  ...Fa6Icons, // Add all Font Awesome 6 icons
  ...MdIcons, // Add all Material Design icons
  ...AiIcons, // Add all Ant Design icons
  ...IoIcons, // Add all Ionicons
  ...BsIcons, // Add all Bootstrap Icons
  ...TbIcons, // Add all Tabler Icons
  ...FcIcons, // Add all Flat Color Icons
  ...RiIcons, // Add all Remix Icons
  ...GiIcons, // Add all Game Icons
  ...CiIcons, // Add all Crypto Icons
  ...Io5Icons, // Add all Ionicons 5 icons
};

export default IconLibrary;
