import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDashboardBoxes } from "@client.types/dashboardBoxes";
import Loader from "@client.components/core/Loader";
import useToast from "@client.hooks/useToast";
import ServiceProvider from "@client.services/provider";
import OverviewCard from "@client.components/OverviewCard";

import "./styles.scss";

interface IProps {
  dashboardBoxes: IDashboardBoxes | null;
  isLoading: boolean;
}

export const TopBoardOverview = ({ dashboardBoxes, isLoading }: IProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    <div className="top-board-dashboard-overview">
      <Loader loading={true} />
    </div>
  }

  if (!dashboardBoxes) {
    return null;
  }

  return (
    <div className="top-board-overview">
      <OverviewCard
        value={dashboardBoxes.dailyBudgetSpent || 0}
        icon="FaCoins"
        text={t("components.TopBoardOverview.budgetSpent")}
        info="This represents the total amount of budget spent on ads today. If the value is zero, no budget has been used yet."
      />
      <OverviewCard
        value={dashboardBoxes.dailyAdPlays || 0}
        icon="FaPlay"
        text={t("components.TopBoardOverview.adPlays")}
        info="This indicates the duration ads have been played today. A zero value means no ads have been played so far."
        color="yellow"
      />
      <OverviewCard
        value={0}
        icon="FaEye"
        text="Total Impressions Today"
        info="This shows the number of times a person has been detected within the operating range today. If this is zero, no impressions have been recorded yet."
        color="blue"
      />
      <OverviewCard
        value={dashboardBoxes.dailyAdViews || 0}
        icon="FaUsers"
        text={t("components.TopBoardOverview.adViews")}
        info="This shows the number of times users have viewed ads today. If this is zero, it means no ads have been watched by users yet."
        color="red"
      />
      <OverviewCard
        value={dashboardBoxes.activeCampaigns || 0}
        icon="FaVideo"
        text={t("components.TopBoardOverview.activeAdvertisements")}
        info="This displays the number of currently active ads. An active ad is one that is running and live."
        color="green"
      />
    </div>
  );
};

export default TopBoardOverview;
