import React from "react";
import { useTranslation } from "react-i18next";

import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import LabelValue from "@client.components/LabelValue";
import { IDevice } from "@client.types/device";
import { getDeviceDetails, getDeviceLastTelemetry } from "./utils";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import { Panel } from "@client.components/core/Panel";
import "./styles.scss";
import { getDeviceStatus, getDeviceStatusClassName } from "@client.utils/utils";
import DeviceStatuses from "@client.enums/deviceStatuses";

interface IProps {
  selectedDevice: IDevice | null;
}

const DeviceInformation = ({ selectedDevice }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deviceDetails = selectedDevice
    ? getDeviceDetails(selectedDevice, t)
    : [];
  const lastTelemetry = selectedDevice?.lastTelemetry
    ? getDeviceLastTelemetry(selectedDevice.lastTelemetry, t)
    : [];

  return (
    <div className="device-information">
      {selectedDevice ? (
        <>
          <Panel
            title={selectedDevice.deviceName}
            data={deviceDetails}
            status={
              selectedDevice.isOnline
                ? Number(DeviceStatuses.Online)
                : Number(DeviceStatuses.Offline)
            }
            statusClassNameFunc={getDeviceStatusClassName}
            statusViewFunc={getDeviceStatus}
            onClick={() => navigate(`${Pages.Devices}/${selectedDevice.Id}`)}
          >
            <div className="content">
              <h2>{t("pages.Devices.lastTelemetry")}</h2>
              <div className="details-grid">
                {lastTelemetry.map((item) => (
                  <LabelValue
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </div>
            </div>
          </Panel>
        </>
      ) : (
        <div className="no-data-wrapper">
          <NoDataPlaceholder description="Select a device from the left" />
        </div>
      )}
    </div>
  );
};

export default DeviceInformation;
