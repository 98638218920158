import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Map from "@client.components/Map";

const AdvertisementMaps = ({ advertisementPlaces, advertisementHeatmap }) => {
  const { t } = useTranslation();

  const deviceMap = useMemo(
    () =>
      advertisementPlaces.map((item) => ({
        Id: item.deviceId,
        deviceName: item.deviceName,
        lat: item.latitude,
        lng: item.longitude,
      })),
    [advertisementPlaces]
  );
  
  const heatmapData = useMemo(
    () =>
      advertisementHeatmap.map((item) => ({
        Id: item.zoneId,
        zoneName: item.name,
        lat: item.latitude,
        lng: item.longitude,
      })),
    [advertisementHeatmap]
  );

  return (
    <div className="advertisement-maps">
      <div>
        <p className="title">{t("Pages.Advertisement.ads")}</p>
        <Map
          zoom={12}
          markers={deviceMap}
          options={{
            mapTypeControl: false,
            disableDefaultUI: true,
            zoomControl: true
          }}
          className="devices-map"
        />
      </div>
      <div>
        <p className="title">{t("Pages.Advertisement.heatmap")}</p>
        <Map
          zoom={12}
          markers={heatmapData}
          options={{
            mapTypeControl: false,
            disableDefaultUI: true,
            zoomControl: true
          }}
          className="heatmaps-map"
        />
      </div>
    </div>
  );
};

export default AdvertisementMaps;
