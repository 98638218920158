import moment from "moment";
import config from "@client.config";

import { IAccount } from "@client.types/account";
import { IUser } from "@client.types/user";

export const getAccountDetails = (t: (key: string) => string, account: IAccount, accountOwner: IUser) => [{
  label: t("pages.Accounts.tabs.Details.companyName"),
  value: account.companyName
}, 
{
  label: t("pages.Accounts.tabs.Details.companyNumber"),
  value: account.companyRegistrationNumber
}, 
{
  label: t("pages.Accounts.tabs.Details.vatNumber"),
  value: account.companyVatNumber
}, {
  label: t("pages.Accounts.tabs.Details.fullName"),
  value: `${accountOwner.firstName || "N/A"} ${
    accountOwner.lastName || "N/A"
  }`
}, {
  label: t("pages.Accounts.tabs.Details.emailAddress"),
  value: accountOwner.emailAddress
}, {
  label: t("pages.Accounts.tabs.Details.phoneNumber"),
  value: accountOwner.phoneNumber
}, {
  label: t("pages.Accounts.tabs.Details.jobTitle"),
  value: accountOwner.jobTitle
}, {
  label: "Created At",
  value: moment(account.createdAt).format(
    config.dateTimeSecondsFormat
  ) || "N/A"
}, {
  label: "Last Login Time",
  value: accountOwner.lastLoginTime === "" ? "N/A" : moment(accountOwner.lastLoginTime).format(
    config.dateTimeSecondsFormat
  ) 
}, {
  label: "Updated At",
  value: moment(accountOwner.updatedAt).format(
    config.dateTimeSecondsFormat
  ) || "N/A"
}];
