import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Kpi from "./Kpi";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#AF19FF",
  "#FF6392",
  "#FF3D1F",
  "#9FE6B9",
  "#FFBF00",
  "#6A0572",
];

const Statistic = ({ data }) => {
  const { t } = useTranslation();

  if (!data) {
    return <div>{t("pages.DataAnalyzer.noDataAvailable")}</div>;
  }

  // Extracting data
  const {
    ageGraph = [],
    totalImpressions = 0,
    totalViews = 0,
    peopleDetected = 100, // Use the correct key from the data structure
  } = data;

  const peopleMale = ageGraph.reduce((sum, { male }) => sum + male, 0);
  const peopleFemale = ageGraph.reduce((sum, { female }) => sum + female, 0);
  const totalViewers = peopleMale + peopleFemale || 1; // Prevent division by zero

  // Gender Pie Chart Data
  const genderPieChartData = [
    {
      name: t("pages.DataAnalyzer.gender.male"),
      qty: peopleMale,
      value: parseFloat(((peopleMale / totalViewers) * 100).toFixed(2)),
    },
    {
      name: t("pages.DataAnalyzer.gender.female"),
      qty: peopleFemale,
      value: parseFloat(((peopleFemale / totalViewers) * 100).toFixed(2)),
    },
  ];

  // Age Range Pie Chart Data
  const ageRangePieData = ageGraph.map(({ age, male, female }) => {
    const qty = (male || 0) + (female || 0);
    return {
      name: `Age ${age}`,
      qty,
      value: parseFloat(((qty / peopleDetected) * 100).toFixed(2)) || 0,
    };
  });

  // Tooltip for Pie Charts
  const pieChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, qty, value } = payload[0].payload;
      return (
        <div className="pie-chart-tooltip">
          <p><b>{name}</b></p>
          <p>{t("pages.DataAnalyzer.quantity")}: {qty}</p>
          <p>{t("pages.DataAnalyzer.percentage")}: {value}%</p>
        </div>
      );
    }
    return null;
  };

  // Helper to get age range
  const getAgeRange = (isMale) => {
    const ages = ageGraph.reduce((acc, { age, male, female }) => {
      if (isMale && male > 0) acc.push(age);
      if (!isMale && female > 0) acc.push(age);
      return acc;
    }, []);
    if (ages.length === 0) return null; // Return `null` to avoid prop type error
    return `${Math.min(...ages)} - ${Math.max(...ages)}`;
  };

  return (
    <div className="data-analyzer-statistic">
      {/* KPIs */}
      <div className="kpis">
        <Kpi title={t("pages.DataAnalyzer.peopleArea")} value={peopleDetected} />
        <Kpi title={t("pages.DataAnalyzer.viewers")} value={totalViews} />
        <Kpi title={t("pages.DataAnalyzer.interact")} value={totalImpressions} />
        <Kpi
          title={t("pages.DataAnalyzer.gender.male.ageRange")}
          value={getAgeRange(true) || "N/A"}
        />
        <Kpi
          title={t("pages.DataAnalyzer.gender.female.ageRange")}
          value={getAgeRange(false) || "N/A"}
        />
      </div>

      {/* Charts */}
      <div className="data-analyzer-chart">
        <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
        <div className="demographics-charts">
          {/* Gender Pie Chart */}
          <ResponsiveContainer width="50%" height={400}>
            <PieChart>
              <Pie
                data={genderPieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label={(entry) => entry.name}
              >
                {genderPieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={pieChartTooltip} />
            </PieChart>
          </ResponsiveContainer>

          {/* Age Range Pie Chart */}
          <ResponsiveContainer width="50%" height={400}>
            <PieChart>
              <Pie
                data={ageRangePieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                label={(entry) => entry.name}
              >
                {ageRangePieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={pieChartTooltip} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

Statistic.propTypes = {
  data: PropTypes.shape({
    ageGraph: PropTypes.arrayOf(
      PropTypes.shape({
        age: PropTypes.number.isRequired,
        male: PropTypes.number,
        female: PropTypes.number,
      })
    ),
    totalImpressions: PropTypes.number,
    totalViews: PropTypes.number,
    peopleDetected: PropTypes.number,
  }),
};

export default Statistic;
