import React, { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

import ServiceProvider from "@client.services/provider";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@client.store";
import { 
  getDeviceRegistrationsAsync, 
  selectDeviceRegistrationsData, 
  selectDeviceRegistrationsLoading,
} from "@client.reducers/deviceRegistrations";

import useToast from "@client.hooks/useToast";

import DataTable from "@client.components/DataTable";
import Input from "@client.core.components/Input";
import Title from "@client.components/Title";
import Loader from "@client.components/core/Loader";

import { getColumns } from "./getColumns";

import "./styles.scss";

const ITEMS_PER_PAGE = 10;

const DeviceRegistrationsPage = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const dispatch = useDispatch<AppDispatch>();

  const tableRef = useRef(null);

  const deviceRegistrations = useSelector(selectDeviceRegistrationsData);
  const deviceRegistrationsLoading = useSelector(selectDeviceRegistrationsLoading);

  const [deviceRegistrationFlagStatus, setDeviceRegistrationFlagStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getDeviceRegistrationsAsync({
        offset,
        limit: ITEMS_PER_PAGE,
        search,
        searchOn: "device_name",
      })
    );
  }, [search, currentPage, dispatch]);

  const fetchDeviceRegistrationFlagStatus = async () => {
    setLoading(true);
    try {
      const response = await ServiceProvider.DeviceRegistration.getDeviceRegistrationFlagStatus();
      if (response.data.deviceRegistrationFlagStatus) {
        setDeviceRegistrationFlagStatus(response.data.deviceRegistrationFlagStatus)
      } else {
        showErrorToast("Can't get device registration status")
      }
    } catch (e) {
      const errorMessage =
      e instanceof Error ? e.message : "Can't get device registration status";
      showErrorToast(errorMessage)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDeviceRegistrationFlagStatus();
  }, []);

  const modifyDeviceRegistrationFlagStatus = async () => {
    try {
      await ServiceProvider.DeviceRegistration.modifyDeviceRegistrationFlags(!deviceRegistrationFlagStatus);
      await fetchDeviceRegistrationFlagStatus();
    } catch (e) {
      const errorMessage =
      e instanceof Error ? e.message : "Can't change device registration status";
      showErrorToast(errorMessage)
    }
  }

  const handleDeviceApproval = async (id: string, status: number) => {
    try {
      await ServiceProvider.DeviceRegistration.deviceRegistrationsJudgement({ id, status });
      dispatch(
        getDeviceRegistrationsAsync({
          offset: (currentPage - 1) * ITEMS_PER_PAGE,
          limit: ITEMS_PER_PAGE,
          search,
          searchOn: "device_name",
        })
      );
    } catch (e) {
      const errorMessage =
      e instanceof Error ? e.message : "Can't approve or reject this device";
      showErrorToast(errorMessage)
    }
  }

  if (isLoading) {
    <div>
      <Loader loading={true} />
    </div>
  }

  return (
    <div className="device-registrations-page">
      <Title text="Device Registrations" />
      <div className="filters-container">
        <Input
          label="Search by Machine ID"
          value={search}
          onChange={setSearch}
        />
        <Switch
          onChange={modifyDeviceRegistrationFlagStatus}
          checked={deviceRegistrationFlagStatus}
          onColor="#ff007a"
          offColor="#ccc"
          uncheckedIcon={false}
          checkedIcon={false}
        />
      </div>
      <div className="table-wrapper">
        <DataTable
          ref={tableRef}
          columns={getColumns(t, handleDeviceApproval)}
          data={deviceRegistrations}
          isLoading={deviceRegistrationsLoading}
          pagination={{
            total: deviceRegistrations.length,
            itemsPerPage: ITEMS_PER_PAGE,
            currentPage,
            onChange: setCurrentPage
          }}
        />
      </div>
    </div>
  );
};

export default DeviceRegistrationsPage;
