import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectProfileData } from "@client.reducers/profile";

import { IAdvertisement } from "@client.types/advertisement";
import { IProfile } from "@client.types/profile";
import useToast from "@client.hooks/useToast";
import ServiceProvider from "@client.services/provider";

import CircleIcon from "@client.components/CircleIcon";

import "./styles.scss";

const AdvertiserDashboard = () => {
  const { showErrorToast } = useToast();

  const [isLoading, setLoading] = useState(true);

  const profile: IProfile = useSelector(selectProfileData);

  const [advertisements, setAdvertisements] = useState<IAdvertisement[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchDashboardBoxes = async () => {
      try {
        const response = await ServiceProvider.Advertisement.getAdvertisements({
          advertiserId: profile.accountId
        });
        if (response.data.campaigns.results) {
          setAdvertisements(response.data.campaigns.results)
        } else {
          showErrorToast("Can't get advertisements")
        }
      } catch (e) {
        const errorMessage =
        e instanceof Error ? e.message : "Can't get advertisements";
        showErrorToast(errorMessage)
      } finally {
        setLoading(false);
      }
    }

    fetchDashboardBoxes();
  }, [])

  return (
    <div className='advertiser-dashboard'>
      {profile?.companyName && (
        <div className='account-name'>
          <CircleIcon name={profile.companyName} index={0} />
          {profile.companyName}
        </div>
      )}
      <div className='advertisements-title'>
        My Advertisements
      </div>
      {advertisements.map((advertisement) => (
        <div key={advertisement.Id}>{advertisement.Id}</div>
      ))}
    </div>
  );
};

export default AdvertiserDashboard;
