import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import AdvertisementStatuses from "@client.enums/advertisementStatuses";

const DEFAULT_SELECTED_ADVERTISER = { advertiser: null, loading: false };
const DEFAULT_SELECTED_ADVERTISEMENTS = { advertisements: [], loading: false, count: 0 };
const DEFAULT_SELECTED_ASSETS = { assets: [], loading: false };
const DEFAULT_SELECTED_DRAFT = { advertisement: null, loading: false };
const DEFAULT_SELECTED_OWNER = { owner: null, loading: false };
const DEFAULT_SELECTED_Details = { details: null, loading: false };
const DEFAULT_SELECTED_Plans_Details = { planDetails: null, loading: false };
const DEFAULT_SELECTED_Account_Map = {
  advertisements: [],
  zones: [],
  loading: false,
};
const DEFAULT_SELECTED_ACCOUNT_BILLING = {
  accountCredit: 0,
  invoices: { count: 0, results: [] },
  lastInvoiceAmount: 0,
  primaryCard: null,
  loading: false,
};
const DEFAULT_SELECTED_ACCOUNT_ADVERTISEMENTS = {
  advertisements: [],
  loading: false,
  count: 0,
};

const initialState = {
  advertisers: [],
  error: null,
  loading: false,
  filter: { status: AdvertisementStatuses.Active, advertiserId: null },
  selected: DEFAULT_SELECTED_ADVERTISER,
  selectedAdvertisements: DEFAULT_SELECTED_ADVERTISEMENTS,
  selectedDrafts: DEFAULT_SELECTED_ADVERTISEMENTS,
  selectedAssets: DEFAULT_SELECTED_ASSETS,
  selectedDraft: DEFAULT_SELECTED_DRAFT,
  selectedOwner: DEFAULT_SELECTED_OWNER,
  selectedDetails: DEFAULT_SELECTED_Details,
  selectedPlanDetails: DEFAULT_SELECTED_Plans_Details,
  selectedAccountMap: DEFAULT_SELECTED_Account_Map,
  selectedAccountBilling: DEFAULT_SELECTED_ACCOUNT_BILLING,
  selectedAccountAdvertisements: DEFAULT_SELECTED_ACCOUNT_ADVERTISEMENTS,
  filters: {
    search: "",
    searchOn: "",
    startDate: "",
    endDate: "",
  },
  total: 0,
};

export const getAdvertiserAdvertisementsAsync = createAsyncThunk(
  "advertiser/getAdvertisements",
  async ({
    status = null,
    advertiserId = null,
    startDate = "",
    endDate = "",
    limit = 1000,
    offset = 0,
    searchOn = "campaign_name",
    search = "",
  }) => {
    const response = await ServiceProvider.Advertisement.getAdvertisements({
      status,
      advertiserId,
      search,
      startDate,
      endDate,
      limit,
      offset,
      searchOn,
    });
    return response;
  }
);

export const updateAdvertiserAdvertisementStatusAsync = createAsyncThunk(
  "advertiser/updateAdvertisementStatus",
  async ({ advertisement, filters }, thunkAPI) => {
    const response =
      await ServiceProvider.Advertisement.updateAdvertisementStatus(
        advertisement
      );
    if (!response.errors) {
      thunkAPI.dispatch(getAdvertiserAdvertisementsAsync(filters));
    }
    return response;
  }
);

export const advertiserSlice = createSlice({
  name: "advertiser",
  initialState,
  reducers: {
    clearAdvertiserError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertiserAdvertisementsAsync.pending, (state) => {
        state.selectedAdvertisements.loading = true;
      })
      .addCase(getAdvertiserAdvertisementsAsync.fulfilled, (state, action) => {
        state.selectedAdvertisements.loading = false;
        state.selectedAdvertisements.advertisements = get(
          action,
          "payload.data.campaigns.results",
          []
        );
        state.selectedAdvertisements.count = get(
          action,
          "payload.data.campaigns.count",
          0
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAdvertiserAdvertisementsAsync.rejected, (state, action) => {
        state.selectedAdvertisements = {
          ...DEFAULT_SELECTED_ADVERTISEMENTS,
        };
        state.error = getResponseError(action);
      })
      .addCase(updateAdvertiserAdvertisementStatusAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateAdvertiserAdvertisementStatusAsync.fulfilled,
        (state, action) => {
          state.loading = false;
          const updatedAdvertisement = action.payload.data;
          const advertisementIndex =
            state.selectedAdvertisements.advertisements.findIndex(
              (c) => c.Id === updatedAdvertisement.Id
            );
          if (advertisementIndex >= 0) {
            state.selectedAdvertisements.advertisements[advertisementIndex] =
              updatedAdvertisement;
          }
        }
      )
      .addCase(updateAdvertiserAdvertisementStatusAsync.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const { clearAdvertiserError } = advertiserSlice.actions;

export const makeSelectedAdvertiser = (state) => state.advertiser.selected;
export const makeSelectedAdvertisements = (state) =>
  state.advertiser.selectedAdvertisements;
export const makeAdvertiserError = (state) => state.advertiser.error;

export default advertiserSlice.reducer;
