import { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import { AppDispatch } from "@client.store";
import { IAddress } from "@client.types/address";
import { getUpdateAccountFields } from "./getUpdateAccountFields";
import { updateAccountAsync } from "@client.reducers/accounts";
import { IAccount } from "@client.types/account";
import { IUpdateAccountResponse } from "@client.types/queries/updateAccountMutation";

export interface IUpdateAccountDetailsFormValues {
  ownerUserId: string,
  companyName: string,
  companyNumber: string,
  vatNumber: string,
  companyUrl: string,
  shippingAddress: IAddress,
  billingAddress: IAddress,
  sameAddress?: boolean,
}

const DEFAULT_VALUES = {
  ownerUserId: "",
  companyName: "",
  companyNumber: "",
  vatNumber: "",
  companyUrl: "",
  shippingAddress: {
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  },
  billingAddress: {
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  },
  sameAddress: false,
};

const useUpdateAccountDetailsForm = (
  selectedAccount: IAccount,
  setShowUpdateAccountDetailsDrawer: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showToastAfterRequest, showErrorToast } = useToast();

  const validationSchema = yup.object({
    ownerUserId: yup
      .string()
      .required("Account owner is required"),
    companyName: yup
      .string()
      .required("Company name is required"),
    companyNumber: yup
      .string()
      .required("Company registration number is required"),
    vatNumber: yup
      .string()
      .required("Company VAT number is required"),
    companyUrl: yup
      .string()
      .required("Company URL number is required"),
    shippingAddress: yup.object().shape({
      addressLineOne: yup.string().required("Shipping address is required"),
      city: yup.string().default(''),
      country: yup.string().default(''),
      postalCode: yup.string().default(''),
    }).required("Shipping address is required"),
    billingAddress: yup.object().shape({
      addressLineOne: yup.string().required("Billing address is required"),
      city: yup.string().default(''),
      country: yup.string().default(''),
      postalCode: yup.string().default(''),
    }).required("Billing address is required"),
    sameAddress: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: selectedAccount ? {
      ...DEFAULT_VALUES,
      ownerUserId: selectedAccount.ownerUserId,
      companyName: selectedAccount.companyName,
      companyNumber: selectedAccount.companyRegistrationNumber,
      vatNumber: selectedAccount.companyVatNumber,
      companyUrl: selectedAccount.companyUrl,
      shippingAddress: selectedAccount.shippingAddress,
      billingAddress: selectedAccount.billingAddress,
    } : DEFAULT_VALUES,
  });

  useEffect(() => {
    if (selectedAccount) {
      reset({
        ...DEFAULT_VALUES,
        ownerUserId: selectedAccount.ownerUserId,
        companyName: selectedAccount.companyName,
        companyNumber: selectedAccount.companyRegistrationNumber,
        vatNumber: selectedAccount.companyVatNumber,
        companyUrl: selectedAccount.companyUrl,
        shippingAddress: selectedAccount.shippingAddress,
        billingAddress: selectedAccount.billingAddress,
      });
    }
  }, [selectedAccount, reset]);

  const shippingAddress = watch("shippingAddress");
  const billingAddress = watch("billingAddress");
  const sameAddress = watch("sameAddress");

  useEffect(() => {
    if (sameAddress) {
      setValue("billingAddress", shippingAddress);
      trigger("billingAddress");
    }
  }, [shippingAddress, sameAddress]);

  const resetForm = () => {
    reset(selectedAccount ? {
      ...DEFAULT_VALUES,
      ownerUserId: selectedAccount.ownerUserId,
      companyName: selectedAccount.companyName,
      companyNumber: selectedAccount.companyRegistrationNumber,
      vatNumber: selectedAccount.companyVatNumber,
      companyUrl: selectedAccount.companyUrl,
      shippingAddress: selectedAccount.shippingAddress,
      billingAddress: selectedAccount.billingAddress,
    } : DEFAULT_VALUES);
  };

  const onSubmit = async (formValues) => {
    try {
      const fields = getUpdateAccountFields(selectedAccount.Id, formValues);
      const response = await dispatch(updateAccountAsync(fields));
      if ((response.payload as IUpdateAccountResponse).errors) {
        showErrorToast("Can't update account")
      } else {
        showToastAfterRequest(
          response,
          "Account updated"
        )
        resetForm();
        setShowUpdateAccountDetailsDrawer(false);
      }
    } catch (e) {
      showErrorToast("Can't update account")
    }
  };

  const handleSubmitUpdateAccountDetailsForm = handleSubmit(onSubmit);

  console.log(errors)

  return { 
    register,
    control,
    getValues, 
    setValue,
    trigger,
    errors,
    resetForm,
    shippingAddress,
    billingAddress,
    handleSubmitUpdateAccountDetailsForm 
  }
}

export default useUpdateAccountDetailsForm;