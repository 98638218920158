import React from "react";

import { useSelector } from "react-redux";
import { selectProfileData } from "@client.reducers/profile";
import AccountEntity from "@client.enums/accountEntity";
import PlatformDashboard from "./PlatformDashboard";
import AdvertiserDashboard from "./AdvertiserDashboard";

import './styles.scss'

interface IProfileAccount {
  entity: number,
  accountName: string | null,
}

const DashboardPage = () => {
  const profileAccount: IProfileAccount = useSelector(selectProfileData);

  return (
    <>
      {profileAccount.entity === AccountEntity.Advertiser ? (
        <AdvertiserDashboard />
      ) : (
        <PlatformDashboard />
      )}
    </>
  );
};

export default DashboardPage;
