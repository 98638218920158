import React, { FC } from "react";
import "./Modal.scss";
import Button from "../Button";

interface IModal {
  show: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  title?: string;
  buttonText?: string;
  handleClick?: () => void;
  isShowFooter?: boolean;
}

const Modal: FC<IModal> = ({
  show,
  closeModal,
  children,
  title,
  isShowFooter,
  buttonText,
  handleClick,
}) => {
  if (!show) return null;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{title}</h2>
          <span onClick={closeModal} className="close-modal-icon">
            &times;
          </span>
        </div>
        {children}
        {isShowFooter && (
          <div className="modal-footer">
            <Button text={buttonText} onClick={handleClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
