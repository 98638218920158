import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppDispatch } from "@client.store";
import { getAccountsAsync, selectAccountsData } from "@client.reducers/accounts";
import { getAllUsersAsync, selectUsersData } from "@client.reducers/users";

import { IAccount } from "@client.types/account";
import { IUser } from "@client.types/user";
import DeviceTypes from "@client.enums/deviceTypes";

import MapFilter from "@client.components/MapFilter";
import ToggleSwitch from "@client.core.components/ToggleSwitch";

import SearchByAccountsAndUsers from "./SearchByAccountsAndUsers";
import LogsAlertsDataTable from "./LogsAlertsDataTable";
import DevicesInfo from "./DevicesInfo";
import ZonesInfo from "./ZonesInfo";
import HeatmapSearch from "./HeatmapSearch";
import HeatmapSummary from "./HeatmapSummary";
import { IDashboardMapFilter } from "..";
import { IDevicesStatistics } from "@client.types/devicesStatistics";
import { IZone } from "@client.types/zone";
import { IDevice } from "@client.types/device";
import { IAdvertisement } from "@client.types/advertisement";
import { selectAdvertisementsData } from "@client.reducers/advertisements";

function switchDeviceType(deviceTypes: number[], type: number, switchTo: boolean): number[] {
  if (switchTo) {
    return deviceTypes.includes(type) ? deviceTypes : [...deviceTypes, type];
  } else {
    return deviceTypes.filter((item) => item !== type);
  }
}

enum PopoverType {
  None = 0,
  SearchByAccountsAndUsers = 1,
  LogsAndAlerts = 2,
  DevicesInfo = 3,
  ZonesInfo = 4,
  HeatmapSearch = 5,
  HeatmapSummary = 6,
}

interface IProps {
  mapFilter: IDashboardMapFilter,
  setMapFilter: Dispatch<SetStateAction<IDashboardMapFilter>>,

  onRecenterClick,
  devicesStatistics: IDevicesStatistics | null,
  selectedDeviceInfos: IDevice[],
  selectedZones: IZone[],
  isDrawingActive: boolean,
  changeDrawingMode: () => void,
  heatmapSearchStartDate: string,
  setHeatmapSearchStartDate: Dispatch<SetStateAction<string>>,
  heatmapSearchEndDate: string,
  setHeatmapSearchEndDate: Dispatch<SetStateAction<string>>,
  polygonsData,
  setPolygonsData,
}

const DashboardMapFilter = ({
  onRecenterClick,
  devicesStatistics,
  selectedDeviceInfos,
  selectedZones,
  isDrawingActive,
  changeDrawingMode,
  heatmapSearchStartDate,
  setHeatmapSearchStartDate,
  heatmapSearchEndDate,
  setHeatmapSearchEndDate,
  polygonsData,
  setPolygonsData,

  mapFilter,
  setMapFilter,
}: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const accounts: IAccount[] = useSelector(selectAccountsData);
  const users: IUser[] = useSelector(selectUsersData);

  const [popoverType, setPopoverType] = useState(PopoverType.None);

  useEffect(() => {
    if (!accounts.length) {
      dispatch(
        getAccountsAsync()
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (!users.length) {
      dispatch(
        getAllUsersAsync({})
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (polygonsData.length && heatmapSearchStartDate && heatmapSearchEndDate) {
      setMapFilter((prev) => ({
        ...prev,
        showHeatmap: true,
        accountId: '',
        userId: '',
      }))
      setPopoverType(PopoverType.HeatmapSummary);
      setPolygonsData([]);
      setHeatmapSearchStartDate("");
      setHeatmapSearchEndDate("");
      if (isDrawingActive) {
        changeDrawingMode();
      }
    }
  }, [polygonsData, heatmapSearchStartDate, heatmapSearchEndDate])

  useEffect(() => {
    if (selectedDeviceInfos.length > 0) {
      setPopoverType(PopoverType.DevicesInfo);
    }
  }, [selectedDeviceInfos]);

  useEffect(() => {
    if (selectedZones.length > 0) {
      setPopoverType(PopoverType.ZonesInfo);
    }
  }, [selectedZones]);

  const handleMapZonesClick = () => setMapFilter((prev) => ({
    ...prev,
    showZones: !prev.showZones,
  }))

  const handleHeatmapClick = () => {
    if (mapFilter.showHeatmap) {
      setMapFilter((prev) => ({
        ...prev,
        showHeatmap: false,
      }))
    } else {
      setPopoverType(PopoverType.HeatmapSearch);
    }
  };

  const mapFilterItems = [
    {
      icon: "FaStreetView",
      title: "components.Dashboard.MapFilter.Search",
      onClick: () =>
        setPopoverType(
          popoverType === PopoverType.SearchByAccountsAndUsers
            ? PopoverType.None
            : PopoverType.SearchByAccountsAndUsers
        ),
      enabled: !!(mapFilter.accountId || mapFilter.userId),
    },
    {
      icon: "FaCrosshairs",
      title: "components.Dashboard.MapFilter.RecenterMap",
      onClick: onRecenterClick,
      enabled: false,
    },
    {
      icon: "FaMapLocationDot",
      title: "components.Dashboard.MapFilter.ShowLocations",
      onClick: handleMapZonesClick,
      enabled: mapFilter.showZones,
    },
    {
      icon: "FaSatellite",
      title: "components.Dashboard.MapFilter.ShowHeatmap",
      onClick: handleHeatmapClick,
      enabled: mapFilter.showHeatmap,
    },
    {
      icon: "FaClipboardList",
      title: "components.Dashboard.MapFilter.LogsAlerts",
      onClick: () =>
        setPopoverType(
          popoverType === PopoverType.LogsAndAlerts
            ? PopoverType.None
            : PopoverType.LogsAndAlerts
        ),
      enabled: popoverType === PopoverType.LogsAndAlerts,
    },
    {
      icon: "FaBuffer",
      title: "components.Dashboard.MapFilter.AdportOptions",
      component: (
        <ul className="map-filter-list">
          <li>
            {t("components.Dashboard.MapFilter.dynamic", {
              val: devicesStatistics?.dynamic || 0,
            })}
            <ToggleSwitch
              isDisabled={!devicesStatistics?.dynamic}
              value={mapFilter.deviceType.includes(DeviceTypes.Dynamic)}
              onChange={(v) => setMapFilter((prev) => ({
                ...prev,
                deviceType: switchDeviceType(prev.deviceType, DeviceTypes.Dynamic, v)
              }))}
            />
          </li>
          <li>
            {t("components.Dashboard.MapFilter.static", {
              val: devicesStatistics?.static || 0,
            })}
            <ToggleSwitch
              isDisabled={!devicesStatistics?.static}
              value={mapFilter.deviceType.includes(DeviceTypes.Static)}
              onChange={(v) => setMapFilter((prev) => ({
                ...prev,
                deviceType: switchDeviceType(prev.deviceType, DeviceTypes.Static, v)
              }))}
            />
          </li>
        </ul>
      ),
    },
    {
      icon: "FaTowerCell",
      title: "components.Dashboard.MapFilter.Status",
      component: (
        <ul className="map-filter-list">
          <li>
            <div className="status-label">
              <span className="status-online"></span>
              {t("components.Dashboard.MapFilter.online", {
                val: devicesStatistics?.online || 0,
              })}
            </div>
            <ToggleSwitch
              isDisabled={!devicesStatistics?.online}
              value={mapFilter.deviceOnline || false}
              onChange={(v) => setMapFilter((prev) => ({
                ...prev,
                deviceOnline: v
              }))}
            />
          </li>
          <li>
            <div className="status-label">
              <span className="status-offline"></span>
              {t("components.Dashboard.MapFilter.offline", {
                val: devicesStatistics?.offline || 0,
              })}
            </div>

            <ToggleSwitch
              isDisabled={!devicesStatistics?.offline}
              value={!mapFilter.deviceOnline}
              onChange={(v) => setMapFilter((prev) => ({
                ...prev,
                deviceOnline: !v
              }))}
            />
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <MapFilter items={mapFilterItems} />
      {popoverType === PopoverType.SearchByAccountsAndUsers && (
        <SearchByAccountsAndUsers
          accounts={accounts}
          users={users}
          mapFilter={mapFilter}
          setMapFilter={setMapFilter}
          onMinimize={() => setPopoverType(PopoverType.None)}
        />
      )}
      {popoverType === PopoverType.LogsAndAlerts && <LogsAlertsDataTable onClose={() => setPopoverType(PopoverType.None)} />}
      {popoverType === PopoverType.DevicesInfo && (
        <DevicesInfo
          devices={selectedDeviceInfos}
          onClose={() => setPopoverType(PopoverType.None)}
        />
      )}
      {popoverType === PopoverType.ZonesInfo && (
        <ZonesInfo
          zones={selectedZones.sort((a, b) => b.category - a.category)}
          onClose={() => setPopoverType(PopoverType.None)}
        />
      )}
      {popoverType === PopoverType.HeatmapSearch && (
        <HeatmapSearch
          onMinimize={() => {
            setPopoverType(PopoverType.None);
            if (isDrawingActive) {
              changeDrawingMode();
            }
          }}
          isDrawingActive={isDrawingActive}
          changeDrawingMode={changeDrawingMode}
          startDate={heatmapSearchStartDate}
          setStartDate={setHeatmapSearchStartDate}
          endDate={heatmapSearchEndDate}
          setEndDate={setHeatmapSearchEndDate}
          accounts={accounts}
        />
      )}
      {(popoverType === PopoverType.HeatmapSummary) && (
        <HeatmapSummary
          onMinimize={() => {
            setPopoverType(PopoverType.None);
          }}
        />
      )}
    </>
  );
};

export default DashboardMapFilter;
