import BaseService from "@client.services/baseService";
import {
  ICreateUserRegisterResponse,
  ICreateUserRegisterVariables,
} from "@client.types/queries/createUserMutation";
import {
  IGetUsersResponse,
  IGetUsersVariables,
} from "@client.types/queries/getUsers";
import {
  IUpdateUserResponse,
  IUpdateUserVariables,
} from "@client.types/queries/updateUser";

export default class UserService extends BaseService {
  async getUsers(
    variables: IGetUsersVariables = {}
  ): Promise<IGetUsersResponse> {
    return super.post("", {
      query: `query($accountId: String, $pagination: PaginationInputType){
        users(accountId: $accountId, pagination: $pagination){
          count
          results{
            Id
            avatarUrl
            firstName
            accountId
            lastName
            emailAddress
            role
            createdAt
            lastLoginTime
            isActive
            phoneNumber
          }
        }
      }`,
      variables,
    });
  }

  async userRegistration(
    variables: ICreateUserRegisterVariables
  ): Promise<ICreateUserRegisterResponse> {
    return super.post("", {
      query: `
        mutation registerUser(
          $user: UserInputType!
          $account: AccountInputType!
          $shippingAddress: AddressInputType!
          $billingAddress: AddressInputType!
        ) {
          register(
            user: $user
            account: $account
            shippingAddress: $shippingAddress
            billingAddress: $billingAddress
          ) {
           status
           message
          }
        }
      `,
      variables,
    });
  }

  async getUserById(userId) {
    return super.post("", {
      query: `query($userId: String){
        user(userId: $userId){
          Id
          accountId
          avatarUrl
          createdAt
          emailAddress
          firstName
          isActive
          jobTitle
          lastLoginTime
          lastName
          password
          phoneNumber
          role
          updatedAt
        }
      }`,
      variables: {
        userId,
      },
    });
  }

  async createUser(user) {
    return super.post("", {
      query: `mutation CreateUser($fields: UserRequiredInputType){
        createUser(fields: $fields){
          message
          user{
            Id
            emailAddress
            password
            phoneNumber
            firstName
            lastName
            lastLoginTime
            avatarUrl
            role
            accountId
          }
        }
      }`,
      variables: {
        fields: {
          avatarUrl: user.avatarUrl || "",
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
          phoneNumber: user.phoneNumber,
          role: user.role,
        },
      },
    });
  }

  async updateUser(
    variables: IUpdateUserVariables
  ): Promise<IUpdateUserResponse> {
    return super.post("", {
      query: `mutation UpdateUser($id: String!, $fields: UserRequiredInputType!) {
        updateUser(id: $id, fields: $fields) {
          message
          user {
            Id
            accountId
            avatarUrl
            createdAt
            emailAddress
            firstName
            isActive
            jobTitle
            lastLoginTime
            lastName
            password
            phoneNumber
            role
            updatedAt
          }
        }
      }`,
      variables,
    });
  }

  async login(emailAddress, password) {
    const result = await super.post(
      "",
      {
        query: `mutation Login($emailAddress: String!, $password: String!){
        login(emailAddress: $emailAddress, password: $password){
          message
          token
        }
      }`,
        variables: {
          emailAddress,
          password,
        },
      },
      false,
      true
    );

    return result;
  }

  async profile() {
    return super.post("", {
      query: `query AccountInformation {
        accountInformation {
          userId
          accountId
          entity
          firstName
          lastName
          fullName
          phoneNumber
          emailAddress
          avatarUrl
          companyName
          role
        }
      }`,
    });
  }

  async inviteProfileByEmail(email_addresses) {
    return super.post("", {
      query: `mutation($email_addresses: [String!]!) {
          invitation(emailAddresses: $email_addresses) {
            message
          }
        }
      `,
      variables: {
        email_addresses,
      },
    });
  }

  async forgotPassword(emailAddress) {
    return super.post("", {
      query: `
        mutation forgotPassword($emailAddress: String){
          forgotPassword(emailAddress: $emailAddress){
            status
            message
          }
        }
      `,
      variables: {
        emailAddress,
      },
    });
  }
  async resetPassword(password: string, token: string) {
    return super.post("", {
      query: `
        mutation resetPassword($password: String, $token: String){
          resetPassword(password: $password, token: $token){
            token
            status
            message
          }
        }
      `,
      variables: {
        password,
        token,
      },
    });
  }

  async verifyAccount(token) {
    return super.post("", {
      query: `
        mutation verifyAccount($token: String!) {
          verifyAccount(token: $token) {
            token
            status
            message
          }
        }
      `,
      variables: {
        token,
      },
    });
  }

  async logout() {
    return super.post(`logout`);
  }

  async delete(userId) {
    return super.post("", {
      query: `mutation($id: String){
        deleteUser(id: $id)
      }`,
      variables: {
        id: userId,
      },
    });
  }

  async changePassword(userId, password) {
    return super.post("", {
      query: `mutation UpdateUser($id: String, $fields: UserInputType){
        updateUser(id: $id, fields: $fields){
          message
        }
      }`,
      variables: {
        id: userId,
        fields: {
          password: password,
        },
      },
    });
  }
}
