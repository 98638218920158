// best

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Title from "@client.components/Title";
import FilterBar from "./FilterBar";
import Viewer from "./Viewer";
import GoogleMap from "@client.components/GoogleMap";
import ViewsChart from "./ViewsChart";
import AgeChart from "./AgeChart";
import Statistic from "./Statistic";
import Loader from "@client.core.components/Loader";
import ServiceProvider from "@client.services/provider";

import "./DataAnalyzer.scss";
import {
  fetchDeviceCapturesAsync,
  makeCaptures,
} from "@client.reducers/devices";
import DataTablePagination from "@client.components/DataTable/DataTablePagination";
import FileDownloader from "./FileDownloader";

const ITEMS_PER_PAGE = 10;

const DataAnalyzer = () => {
  const [selectedFrames, setSelectedFrames] = useState([]);
  const [filter, setFilter] = useState(null);
  const [capturesLoading, setCapturesLoading] = useState(false);
  const [analyzerLoading, setAnalyzerLoading] = useState(false);
  const [analyzerData, setAnalyzerData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State for current page

  const captures = useSelector(makeCaptures);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (filter) {
      const offset = (currentPage - 1) * ITEMS_PER_PAGE; // Calculate pagination offset
      setCapturesLoading(true);

      dispatch(
        fetchDeviceCapturesAsync({
          ...filter,
          limit: ITEMS_PER_PAGE,
          offset,
        })
      ).finally(() => setCapturesLoading(false));
    }
  }, [filter, currentPage, dispatch]);

  // Fetch analyzer data for selected captureIds
  useEffect(() => {
    if (selectedFrames.length > 0) {
      setAnalyzerLoading(true);
      const fetchAnalyzerData = async () => {
        try {
          const allResults = await Promise.all(
            selectedFrames.map((frame) =>
              ServiceProvider.DataAnalyzer.getDataAnalyser(frame.Id)
            )
          );

          // Combine results into a single data object
          const combinedData = allResults.map((res) => res.data.dataAnalyser);
          setAnalyzerData(combineAnalyzerResults(combinedData));
        } catch (error) {
          console.error("Error fetching analyzer data: ", error);
        } finally {
          setAnalyzerLoading(false);
        }
      };

      fetchAnalyzerData();
    }
  }, [selectedFrames]);

  // Combine multiple analyzer results into one
  const combineAnalyzerResults = (dataArray) => {
    const combined = {
      ageGraph: [],
      ivpGraph: [],
      peopleAgeCount: [],
      playLocations: [],
      totalViews: 0,
      totalImpressions: 0,
      totalPeopleDetected: 0,
    };

    dataArray.forEach((data) => {
      if (data.ageGraph) combined.ageGraph.push(...data.ageGraph);
      if (data.ivpGraph) combined.ivpGraph.push(...data.ivpGraph);
      if (data.peopleAgeCount)
        combined.peopleAgeCount.push(...data.peopleAgeCount);
      if (data.playLocations)
        combined.playLocations.push(...data.playLocations);

      combined.totalViews += data.views || 0;
      combined.totalImpressions += data.impressions || 0;
      combined.totalPeopleDetected += data.peopleDetected || 0;
    });

    return combined;
  };

  const getSelectedPathCoordinates = () => {
    if (!analyzerData || !analyzerData.playLocations) return [];
    return analyzerData.playLocations.sort((a, b) => a.latitude - b.latitude);
  };

  return (
    <div className="data-analyzer">
      <Title text="components.NavigationBar.dataAnalyzer" />
      <FilterBar onFilterChange={setFilter} dataAnalyzer={true}
      />
      {capturesLoading ? (
        <Loader loading={true} />
      ) : (
        <>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50px",
              height: "50px",
              backgroundcolor: "red",
              border: "1px solid black",
            }}
          >
            <FileDownloader  />
            
          </div> */}
          <Viewer data={captures.results} onSelect={setSelectedFrames} />
          <div className="pagination" style={
            {marginBottom: "40px"}
          }>
            <span className="pagination-summary">
              {`${(currentPage - 1) * ITEMS_PER_PAGE + 1}-${Math.min(
                currentPage * ITEMS_PER_PAGE,
                captures.count
              )} of ${captures.count} items`}
            </span>
            <DataTablePagination
              total={captures.count}
              itemsPerPage={ITEMS_PER_PAGE}
              onChange={handlePageChange} // Sync pagination
              currentPage={currentPage} // Controlled by the state
            />
          </div>
        </>
      )}

      {analyzerLoading && <Loader loading={true} />}

      {selectedFrames.length > 0 && !analyzerLoading && analyzerData && (
        <>
          <Statistic data={analyzerData} />
          {/* <ViewsChart data={analyzerData} /> */}
          <div className="data-analyzer-chart">
            <div className="chart-title">
              {t("pages.DataAnalyzer.map.title")}
            </div>
            <GoogleMap
              className="map"
              pathCoordinates={getSelectedPathCoordinates()}
            />
          </div>
          <AgeChart data={analyzerData} />
        </>
      )}
    </div>
  );
};

export default DataAnalyzer;
