import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { IAdvertisementInsights } from "@client.types/advertisementInsights";

import ToggleButton from "@client.components/core/ToggleButton";
import ChartTooltip from "./ChartTooltip";

interface IProps {
  advertisementInsights: IAdvertisementInsights | null;
}

const AdvertisementInsightsAgeRange = ({ advertisementInsights }: IProps) => {
  const { t } = useTranslation();

  const [isMale, setIsMale] = useState(true);
  const [isFemale, setIsFemale] = useState(true);

  const ageRangeData = advertisementInsights?.ageGraph
    .reduce<{ age: number; qty: number }[]>((acc, d) => {
      const { age, male, female } = d;

      let qty = 0;
      if (isMale) qty += male || 0;
      if (isFemale) qty += female || 0;

      if (qty > 0) {
        acc.push({ age, qty });
      }
      return acc;
    }, []) || [];

  return (
    <div className="advertisement-insights-age-range">
      <div className="flex-row">
        <p className="title">{t("pages.DataAnalyzer.ageChart.title")}</p>
        <div className="filter-buttons">
          <ToggleButton
            text={t("pages.DataAnalyzer.gender.female")}
            value={isFemale}
            onChange={setIsFemale}
          />
          <ToggleButton
            text={t("pages.DataAnalyzer.gender.male")}
            value={isMale}
            onChange={setIsMale}
          />
        </div>
      </div>
      <div className="demographics-charts">
        <ResponsiveContainer width="100%" height={320}>
          <BarChart
            data={ageRangeData}
            margin={{ top: 20, right: 30, left: -20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="age" name="Age" />
            <YAxis allowDecimals={false} />
            <Tooltip content={({ active, payload }) => <ChartTooltip active={active} payload={payload} />} />
            <Bar dataKey="qty" fill="#2D99FF" name={t("pages.DataAnalyzer.quantity")} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
};

export default AdvertisementInsightsAgeRange;
