import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { IMapLocation, IZone } from "@client.types/zone";
import LocationTypes from "@client.enums/locationTypes";

import Button from "@client.components/core/Button";

import {
  Step,
  useCreateAdvertisementForm,
} from "../../../CreateAdvertisementFormContext";
import LocationsInformation from "./LocationsInformation";
import LocationsMap from "./LocationsMap";
import { MdDeleteOutline } from "react-icons/md";
import useToast from "@client.hooks/useToast";
import { IZonePolygon, IZonePolygonData } from "@client.types/zonePolygon";
import { ILocation } from "../..";

interface IProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const LocationsStep = ({ currentStep, setCurrentStep }: IProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const { 
    createAdvertisementFormMethods,
    isFormFilled,
    setFormFilled,
    selectedLocationsList,
    setSelectedLocationsList,
  } =
    useCreateAdvertisementForm();

  const {
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = createAdvertisementFormMethods;

  const zoneIds = watch("zoneIds");
  const radiusLocations = watch("radiusLocations");
  const customPolygonLocations = watch("customPolygonLocations");

  const [selectedTab, setSelectedTab] = useState<LocationTypes>(LocationTypes.Zones);

  const [drawnCustomPolygon, setDrawnCustomPolygon] = useState<IZonePolygonData[]>([]);
  const [mapLocations, setMapLocations] = useState<IZonePolygon[]>([]);

  useEffect(() => {
    setMapLocations((prev) => prev.filter((mapLocation) => mapLocation.id !== 'temporary'));
    if (selectedTab !== LocationTypes.Custom) {
      setDrawnCustomPolygon([]);
    }
  }, [selectedTab])

  const handleSubmitLocationsStepForm = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        setCurrentStep((prev) => prev + 1);
      } else {
        showErrorToast(errors.zoneIds?.message)
      }
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : "Validation error";
      showErrorToast(errorMessage)
    }
  };

  const handleDeleteSelectedLocation = (locationToDelete: ILocation) => {
    setSelectedLocationsList((prev) => prev.filter((location) => location.id !== locationToDelete.id));
    setMapLocations((prev) => prev.filter((location) => location.id !== locationToDelete.id));
    switch (locationToDelete.type) {
    case LocationTypes.Zones:
      return setValue("zoneIds", zoneIds.filter((zone) => zone !== locationToDelete.id));
    case LocationTypes.Radius:
      return setValue("radiusLocations", radiusLocations.filter((location) => location.id !== locationToDelete.id));
    case LocationTypes.Custom:
      return setValue("customPolygonLocations", customPolygonLocations.filter((location) => location.id !== locationToDelete.id));
    }
  }

  return (
    <div className="locations-container">
      <div className="step-title-container">
        <div className="step-title">Geo Location</div>
        {currentStep === Step.SlotCalculator || isFormFilled ? (
          <Button
            icon="edit"
            text={t("Pages.CreateAdvertisement.edit")}
            className="wizard"
            onClick={() => {
              setFormFilled(false);
              setCurrentStep(Step.Locations);
            }}
          />
        ) : (
          false
        )}
      </div>
      {currentStep === Step.Locations ? (
        <>
          <div className="locations-information-map-container">
            <LocationsInformation
              mapLocations={mapLocations}
              setMapLocations={setMapLocations}
              drawnCustomPolygon={drawnCustomPolygon}
              setDrawnCustomPolygon={setDrawnCustomPolygon}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <LocationsMap 
              selectedTab={selectedTab}
              mapLocations={mapLocations}
              setMapLocations={setMapLocations}
              setDrawnPolygon={setDrawnCustomPolygon}
            />
          </div>
          <div className="chosen-locations">
            <p className="title">Selected locations</p>
            {selectedLocationsList.map((location) => (
              <div className="chosen-location-item" key={location.id}>
                <p className="chosen-location-item-text">
                  {location.title}
                </p>
                <MdDeleteOutline
                  onClick={() => handleDeleteSelectedLocation(location)}
                  title="Delete"
                  className="chosen-location-item-icon"
                />
              </div>
            ))}
          </div>
          <Button
            text={t("Pages.CreateAdvertisement.next")}
            className="btn-next wizard"
            onClick={handleSubmitLocationsStepForm}
          />
        </>
      ) : (
        false
      )}
    </div>
  );
};

export default LocationsStep;
