import moment from "moment";
import { ICreateAdvertisementFormValues } from "./CreateAdvertisementFormContext";

const getCreateAdvertisementFields = (
  createAdvertisementFormValues: ICreateAdvertisementFormValues,
) => {
  console.log(createAdvertisementFormValues)
  return {
    title: createAdvertisementFormValues.title,
    advertiserId: createAdvertisementFormValues.advertiserId,
    startDate: moment(createAdvertisementFormValues.startDate).toISOString(),
    endDate: moment(createAdvertisementFormValues.endDate).toISOString(),
    targets: createAdvertisementFormValues.targets,
    mediaId: createAdvertisementFormValues.mediaId,
    budgetType: createAdvertisementFormValues.budgetType,
    budgetAmount: createAdvertisementFormValues.budgetAmount,
    consecutiveSlots: createAdvertisementFormValues.consecutiveSlots,
    geofenceRadius: createAdvertisementFormValues.radiusLocations.map((radiusLocation) => JSON.stringify(radiusLocation)),
    geofenceZoneIds: createAdvertisementFormValues.zoneIds,
    geofenceCustomPolygons: createAdvertisementFormValues.customPolygonLocations.map((customPolygonLocation) => JSON.stringify(customPolygonLocation.data)),
  }
}

export default getCreateAdvertisementFields;