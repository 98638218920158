import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@client.store";
import {
  getZonesAsync,
  selectZonesTree,
  selectZonesLoading,
} from "@client.reducers/zones";

import { IZone } from "@client.types/zone";

import Title from "@client.components/Title";
import ZoneMapForm from "@client.components/ZoneMapForm";
import Button from "@client.components/core/Button";

import ZonesList from "./ZonesList";
import "./styles.scss";
import Drawer from "@client.components/Drawer";
import ZoneForm from "./ZoneForm";
import useCreateZoneForm from "./useCreateZoneForm";
import ZoneTypes from "@client.enums/zoneTypes";
import useUpdateZoneForm from "./useUpdateZoneForm";

const ZonesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const zones = useSelector(selectZonesTree);
  const zonesLoading = useSelector(selectZonesLoading);
  
  const [selectedZone, setSelectedZone] = useState<IZone | null>(null);
  const [customZoneToUpdate, setCustomZoneToUpdate] = useState<IZone | null>(null);
  const [zoneToUpdate, setZoneToUpdate] = useState<IZone | null>(null);
  const [formZoneCategory, setFormZoneCategory] = useState(ZoneTypes.Country);
  const [showCreateZoneDrawer, setShowCreateZoneDrawer] = useState(false);
  const [showUpdateZoneDrawer, setShowUpdateZoneDrawer] = useState(false);

  const {
    register: createZoneRegister,
    setValue: createZoneSetValue,
    getValues: createZoneGetValues,
    resetForm: resetCreateZoneForm,
    errors: createZoneErrors,
    zoneName: createZoneNameValue,
    handleSubmitCreateZoneForm,
    handleCreateChildZone,
  } = useCreateZoneForm(setShowCreateZoneDrawer, setFormZoneCategory, setSelectedZone);

  const {
    register: updateZoneRegister,
    setValue: updateZoneSetValue,
    getValues: updateZoneGetValues,
    resetForm: resetUpdateZoneForm,
    errors: updateZoneErrors,
    zoneName: updateZoneNameValue,
    handleSubmitUpdateZoneForm,
  } = useUpdateZoneForm(zoneToUpdate, setShowUpdateZoneDrawer);

  useEffect(() => {
    if (!zones.length) {
      dispatch(getZonesAsync());
    }
  }, []);

  const handleCloseCreateZoneDrawer = () => {
    resetCreateZoneForm();
    setShowCreateZoneDrawer(false);
  };

  const handleUpdateZoneClick = (zone: IZone) => {
    if (zone.category === ZoneTypes.Custom) {
      setCustomZoneToUpdate(zone);
    } else {
      setZoneToUpdate(zone);
      setFormZoneCategory(zone.category);
      setShowUpdateZoneDrawer(true);
    }
  }

  const handleCloseUpdateZoneDrawer = () => {
    resetUpdateZoneForm();
    setShowUpdateZoneDrawer(false);
  }

  return (
    <div className="zones-page">
      <Title text="components.NavigationBar.locations">
        <Button
          icon="plus"
          text={t("pages.Locations.components.ZonesTree.create")}
          onClick={() => setShowCreateZoneDrawer(true)}
        />
      </Title>
      <div className="zones-content">
        <ZonesList
          zones={zones || []}
          selectedZoneId={selectedZone?.Id || ''}
          onSelectZone={setSelectedZone}
          zonesLoading={zonesLoading}
          onCreateChildZone={handleCreateChildZone}
          onUpdateZoneClick={handleUpdateZoneClick}
        />
        {customZoneToUpdate ? (
          <ZoneMapForm 
            selectedZone={selectedZone}
            isUpdatingMode
            onSuccess={() => {
              setCustomZoneToUpdate(null);
              dispatch(getZonesAsync());
            }}
          />
        ) : (
          <ZoneMapForm
            selectedZone={selectedZone}
            isCreatingMode
            onSuccess={() => dispatch(getZonesAsync())}
          />
        )}
      </div>
      <Drawer
        isOpen={showCreateZoneDrawer}
        onClose={handleCloseCreateZoneDrawer}
        title={t("pages.Locations.components.ZonesTree.modal.create.title")}
        footer={
          <Button
            text={t("pages.Locations.components.ZonesTree.modal.save")}
            onClick={handleSubmitCreateZoneForm}
          />
        }
      >
        <ZoneForm
          register={createZoneRegister}
          getValues={createZoneGetValues}
          setValue={createZoneSetValue}
          errors={createZoneErrors}
          zoneNameValue={createZoneNameValue}
          zoneCategory={formZoneCategory}
        />
      </Drawer>
      <Drawer
        isOpen={showUpdateZoneDrawer}
        onClose={handleCloseUpdateZoneDrawer}
        title={t("pages.Locations.components.ZonesTree.modal.update.title")}
        footer={
          <Button
            text={t("pages.Locations.components.ZonesTree.modal.save")}
            onClick={handleSubmitUpdateZoneForm}
          />
        }
      >
        <ZoneForm
          register={updateZoneRegister}
          getValues={updateZoneGetValues}
          setValue={updateZoneSetValue}
          errors={updateZoneErrors}
          zoneNameValue={updateZoneNameValue}
          zoneCategory={formZoneCategory}
        />
      </Drawer>
    </div>
  );
};

export default ZonesPage;
