import React, { useCallback } from "react";
import config from "@client.config";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { useDispatch } from "react-redux";
import { AppDispatch } from "@client.store";
import { updateUserAsync } from "@client.reducers/users";

import useToast from "@client.hooks/useToast";

import ServiceProvider from "@client.services/provider";
import { IUser } from "@client.types/user";
import { IGetUsersVariables } from "@client.types/queries/getUsers";

import Input from "@client.core.components/Input";
import Button from "@client.core.components/Button";
import TextInput from "@client.components/form/TextInput";
import FileUploader from "@client.components/FileUploader";

import useUpdateUserForm from "./useUpdateUserForm";
import "./styles.scss";

interface IProps {
  selectedUser: IUser;
  getUsersVariables: IGetUsersVariables;
}

const GeneralTab = ({ selectedUser, getUsersVariables }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessfulToast, showErrorToast } = useToast();

  const {
    register,
    errors,
    handleSubmitUpdateUserForm,
  } = useUpdateUserForm(selectedUser);

  const changeAvatar = useCallback(async (file) => {
    try {
      const uploadAssetResponse = await ServiceProvider.Account.uploadAsset(file, selectedUser.Id);
      
      if (uploadAssetResponse.url) {
        const resultAction = await dispatch(updateUserAsync({
          updateUserVariables: {
            id: selectedUser.Id,
            fields: {
              avatarUrl: uploadAssetResponse.url
            }
          },
          getUsersVariables
        }))

        if (updateUserAsync.fulfilled.match(resultAction)) {
          showSuccessfulToast("New image was uploaded");
        } else if (updateUserAsync.rejected.match(resultAction)) {
          showErrorToast("Can't upload image");
        }
      }
    } catch {
      showErrorToast("Can't upload image");
    }
  }, [selectedUser.Id]);

  return (
    <div className="user-general">
      <div className="content">
        <div className="uploader-container">
          <FileUploader
            placeholderUrl={selectedUser.avatarUrl}
            onChange={changeAvatar}
          />
        </div>
        <div className="update-user-form">
          <TextInput
            id="firstName"
            name="firstName"
            label={t("components.Advertisers.firstName")}
            placeholder={t("components.Advertisers.firstName")}
            register={register}
            error={errors.firstName}
          />
          <TextInput
            id="lastName"
            name="lastName"
            label={t("components.Advertisers.lastName")}
            placeholder={t("components.Advertisers.lastName")}
            register={register}
            error={errors.lastName}
          />
          <TextInput
            id="emailAddress"
            name="emailAddress"
            label={t("components.Advertisers.emailAddress")}
            placeholder={t("components.Advertisers.emailAddress")}
            register={register}
            error={errors.emailAddress}
          />
          <TextInput
            id="phoneNumber"
            name="phoneNumber"
            label={t("components.Advertisers.phoneNumber")}
            placeholder={t("components.Advertisers.phoneNumber")}
            register={register}
            error={errors.phoneNumber}
          />
          <Input
            value={moment(selectedUser.createdAt).format(config.dateTimeFormat)}
            label={t("components.Advertisers.created")}
            readOnly={true}
          />
          <Input
            value={selectedUser.lastLoginTime ? moment(selectedUser.lastLoginTime).format(config.dateTimeFormat) : ""}
            label={t("components.Advertisers.lastLoggedIn")}
            readOnly={true}
          />
          <div>
            <Button
              text={t("pages.ProfileGeneral.save")}
              onClick={handleSubmitUpdateUserForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralTab;
