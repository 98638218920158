import React from "react";

import Title from "@client.components/Title";

import "./Invoice.scss";
import { InvoiceInfo } from "./InvoiceInfo";
import { InvoicePreview } from "./InvoicePreview";
import { useTranslation } from "react-i18next";
import Button from "@client.components/core/Button";
import { IInvoiceData } from "./types";


const IndividualInvoice = ({ invoice: data }: { invoice: IInvoiceData }) => {
  const { t } = useTranslation();

  const { invoice } = data

  return (
    <div className="invoice">
      <div className="head">
        <Title text={`Invoice #${invoice.Id.slice(0, 4)}...${invoice.Id.slice(-4)}`} />
        <div>
          <Button
            text={t("pages.Invoices.markAsPaid")}
            onClick={() => { }}
            icon="check"
          />
          <Button
            text={t("button.AttemptPayment")}
            onClick={() => { }}
            icon="payment"
          />
        </div>
      </div>
      <div className="content">
        <InvoiceInfo invoice={data} />
        <InvoicePreview invoice={data} />
      </div>
    </div>
  );
};

export default IndividualInvoice;
