import { ICreateUserRegisterVariables } from "@client.types/queries/createUserMutation";
import { IRegisterFormValues } from "./useRegisterForm";

export const getRegisterFields = (
  createRegisterFormValues: IRegisterFormValues
): ICreateUserRegisterVariables => {
  return {
    user: {
      firstName: createRegisterFormValues.firstName,
      lastName: createRegisterFormValues.lastName,
      emailAddress: createRegisterFormValues.emailAddress,
      phoneNumber: createRegisterFormValues.phoneNumber,
      password: createRegisterFormValues.password,
    },
    account: {
      companyName: createRegisterFormValues.companyName,
      companyNumber: createRegisterFormValues.companyNumber,
      vatNumber: createRegisterFormValues.vatNumber,
      entity: +createRegisterFormValues.entity,
    },
    billingAddress: {
      addressLineOne: createRegisterFormValues.billingAddress.addressLineOne,
      city: createRegisterFormValues.billingAddress.city,
      postalCode: createRegisterFormValues.billingAddress.postalCode,
      country: createRegisterFormValues.billingAddress.country,
    },
    shippingAddress: {
      addressLineOne: createRegisterFormValues.shippingAddress.addressLineOne,
      city: createRegisterFormValues.shippingAddress.city,
      postalCode: createRegisterFormValues.shippingAddress.postalCode,
      country: createRegisterFormValues.shippingAddress.country,
    },
  };
};
