import BaseService from "@client.services/baseService";
import { IGetMapResponse, IGetMapVariables } from "@client.types/queries/mapQuery";

export default class DashboardService extends BaseService {
  async getDashboardBoxes() {
    return super.post("", {
      query: `query DashboardBoxes {
        dashboardBoxes {
          activeCampaigns
          dailyAdPlays
          dailyAdViews
          dailyBudgetSpent
        }
      }`,
    });
  }

  async getMap(variables: IGetMapVariables): Promise<IGetMapResponse> {
    return super.post("", {
      query: `query Map (
        $search: String, 
        $showZones: Boolean,
        $showHeatmap: Boolean, 
        $deviceOnline: Boolean,
        $deviceType: [Int]
      ) {
        map (
        search: $search, 
        showZones: $showZones, 
        showHeatmap: $showHeatmap, 
        deviceOnline: $deviceOnline
        deviceType: $deviceType
      ) {
          zones {
            count
            results {
              Id
              category
              color
              createdAt
              isActive
              parentZoneId
              polygon
              standardPpvRate
              primePpvRate
              updatedAt
              zoneName
            }
          }
          heatmap {
            count
            results {
              intensity
              latitude
              longitude
              name
              zoneId
            }
          }
          devices {
            count
            results {
              Id
              assignedAccountId
              createdAccountId
              createdAt
              deviceName
              deviceType
              hardwareVersion
              isActive
              isOnline
              lastMessageDate
              latitude
              longitude
              machineId
              updatedAt
              vpnId
              vpnLocalIp
              timezone
              softwareVersion
              lastTelemetry {
                campaignId
                machineId
                network {
                  connectionTime
                  localIp
                  remoteIp
                }
                sensors {
                  accelerometer {
                    xValue
                    yValue
                    zValue
                  }
                  cpuTemperature
                  gnss {
                    latitude
                    longitude
                  }
                  gyroscope {
                    yValue
                    xValue
                    zValue
                  }
                  screen {
                    brightness
                    temperature
                  }
                  lightSensor
                  battery {
                    percentage
                    temperatureA
                    temperatureB
                  }
                  fan {
                    dutyA
                    dutyB
                  }
                  utilization {
                    core
                    disk
                    memory
                  }
                }
                uptime
                createdAt
              }
              status
            }
          }
          deviceStatistics {
            online
            offline
            dynamic
            static
          }
        }
      }`,
      variables,
    });
  }
}
