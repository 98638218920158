import BaseService from "@client.services/baseService";
import { IGetUserLogsResponse, IGetUserLogsVariables } from "@client.types/queries/getUserLogs";

export default class UserLogService extends BaseService {
  async getUserLogs(
    variables: IGetUserLogsVariables = {}
  ): Promise<IGetUserLogsResponse> {
    const response = await super.post("", {
      query: `query UserLogs($pagination: PaginationInputType) {
          userLogs(pagination: $pagination) {
            count
            results {
              Id
              type
              userId
              userFullName
              ipAddress
              createdAt
              updatedAt
              description
            }
          }
        }`,
      variables,
    });

    return response;
  }
}
