import React from "react";

import "./styles.scss";

interface IProps {
  items: any[];
  renderItem: (item: any) => React.ReactNode;
  Footer?: () => JSX.Element;
}

const Tree = ({
  items,
  renderItem,
  Footer,
}: IProps) => {
  return (
    <div className="tree-container">
      <ul className="tree">
        {items.map((item) => renderItem(item))}
      </ul>
      {Footer ? (
        <div className="tree-footer">
          <Footer />
        </div>
      ) : false}
    </div>
  );
};

export default Tree;
