import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DateRangePicker from "@client.components/DateRangePicker";

import MinimizeIcon from "./MinimizeIcon";
import ToggleButton from "@client.components/core/ToggleButton";
import Dropdown from "@client.components/core/Dropdown";
import { IAccount } from "@client.types/account";
import { IAdvertisement } from "@client.types/advertisement";
import ServiceProvider from "@client.services/provider";
import useToast from "@client.hooks/useToast";

interface IProps {
  onMinimize: () => void;
  isDrawingActive: boolean;
  changeDrawingMode: () => void;
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  accounts: IAccount[];
}

const HeatmapSearch = ({ 
  onMinimize,
  isDrawingActive,
  changeDrawingMode,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  accounts,
}: IProps) => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const [advertisements, setAdvertisements] = useState<IAdvertisement[]>([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedAdvertisement, setSelectedAdvertisement] = useState('');

  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const response = await ServiceProvider.Advertisement.getAdvertisements({
          advertiserId: selectedAccount, 
        });
        const advertisementsResponse = response.data.campaigns;
        if (advertisementsResponse) {
          setAdvertisements(advertisementsResponse.results);
        } else {
          showErrorToast("Can't get advertisements")
        }
      } catch (e) {
        const errorMessage =
        e instanceof Error ? e.message : "Can't get advertisements";
        showErrorToast(errorMessage)
      }
    }

    fetchAdvertisements();
  }, [selectedAccount])

  return (
    <div className="search-heatmaps-modal">
      <div className="modal-title-container">
        <p className="modal-title">Heatmap Selection</p>
        <MinimizeIcon onClick={onMinimize} />
      </div>
      <div className="search-heatmaps-modal-content">
        <div className="search-heatmaps-form">
          <DateRangePicker
            dateFrom={startDate}
            dateTo={endDate}
            labelStart="Start DateTime"
            labelEnd="End DateTime"
            onChange={(v) => {
              setStartDate(v[0]);
              setEndDate(v[1]);
            }}
            twoFieldsView
            isColumn
          />
          <Dropdown
            items={accounts}
            defaultValue={selectedAccount}
            label="Accounts"
            valueKey="Id"
            labelKey="companyName"
            onSelect={setSelectedAccount}
          />
          <Dropdown
            items={advertisements}
            defaultValue={selectedAdvertisement}
            label="Advertisements"
            valueKey="Id"
            labelKey="title"
            onSelect={setSelectedAdvertisement}
          />
          <ToggleButton
            text={
              isDrawingActive
                ? "Cancel"
                : t("pages.Locations.startDrawing")
            }
            value={isDrawingActive}
            onChange={changeDrawingMode}
          />
        </div>
      </div>
    </div>
  );
};

export default HeatmapSearch;
