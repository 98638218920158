import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getProfileAsync } from "@client.reducers/profile";

import { getDeviceByIdAsync, makeDeviceInfo, fetchDeviceAccountInfo } from "@client.reducers/devices";
import Drawer from '@client.components/Drawer';
import Tabs from "@client.components/Tabs";
import Title from "@client.components/Title";
import Loader from "@client.core.components/Loader";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import Button from "@client.core.components/Button";

import useStableParams from "@client.hooks/useStableParams";

import DeviceOverview from "./Tabs/Details/DeviceOverview";
import ActiveTab from './Tabs/ActiveTab'
import DeviceMap from './Tabs/Map'
import PartnerTab from "./Tabs/Partner";

import "./Device.scss";

const TABS = [
  {
    id: 0,
    name: "Details",
    component: DeviceOverview
  },
  {
    id: 1,
    name: "Location",
    component: DeviceMap
  },
  {
    id: 2,
    name: "Playlist",
  },
  {
    id: 3,
    name: "Running Ad",
  },
  {
    id: 4,
    name: "VPN",
  },
  {
    id: 5,
    name: "Partner",
    component: PartnerTab
  },
];

const Device = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id } = useStableParams();
  const overview = useSelector(makeDeviceInfo);
  const loading = !overview || !overview.device;

  const accountId = overview?.device?.assignedAccountId;

  const [showDeviceEditDrawer, setShowDeviceEditDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabSelect = (newTab) => {
    setActiveTab(newTab)
  };

  const toggleDrawer = (newState) => () => {
    setShowDeviceEditDrawer(newState)
  }

  useEffect(() => {
    dispatch(getDeviceByIdAsync(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getProfileAsync());
  }, []);

  useEffect(() => {
    if(accountId) {
      dispatch(fetchDeviceAccountInfo(accountId));
    }
  }, [accountId]);

  if (loading) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }

  if (!overview || !overview.device) {
    return <NoDataPlaceholder description="No device data available" />;
  }

  return (
    <div className="device-page">
      <div className="header">
        <Title text={`Device: ${overview.device.deviceName}`} />
        <Button
          text='Update device'
          onClick={toggleDrawer(true)}
          icon='edit'
        />
      </div>
      <Tabs tabs={TABS} value={activeTab} onSelect={handleTabSelect} data/>
      <div className="content">
        <ActiveTab tabs={TABS} activeTabIndex={activeTab} data={overview} />
      </div>
      <Drawer 
        isOpen={showDeviceEditDrawer}
        onClose={toggleDrawer(false)}
        title='Update Device'
        footer={
          <Button
            text={'Save'}
            onClick={()=>{}}
          />
        }
      >
        <div></div>
      </Drawer>
    </div>
  );
};

export default Device;
