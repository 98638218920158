import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import Loader from "@client.core.components/Loader";

const TimelineItem = ({ item, className, onClick }) => {
  const src = item.afterMediaUrl==="" ? "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4": "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4";
  const [loading, setLoading] = useState(true);

  return (
    <div className={classNames("timeline-item", className)} onClick={onClick}>
      {src && (
        <div className="video-wrapper">
          {loading && <Loader loading={true} />}
          <video
            muted
            autoPlay={false}
            loop={false}
            preload="metadata"
            onLoadedMetadata={() => setLoading(false)}
            height={50}
          >
            <source src={`${src}#t=1`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div className="timeline-frame">
        <div className="track-segment" />
        <div className="track-separator" />
      </div>
      <div className={"time-range"}>
        {moment(item.startDate).format("HH:mm:ss")} -{" "}
        {moment(item.endDate).format("HH:mm:ss")}
      </div>
    </div>
  );
};

TimelineItem.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  item: PropTypes.shape({
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    afterMediaUrl: PropTypes.string,
  }).isRequired,
};

export default TimelineItem;
