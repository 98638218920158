import type React from "react";
import Button from "@client.components/core/Button";
import useToast from "@client.hooks/useToast";

import { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, {
  type Crop,
  type PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./ImageEditor.scss";

type EditorStep = "crop" | "preview";

const ImageEditor = ({
  image,
  onSuccess,
}: {
  image: File | undefined;
  onSuccess: (processedFile: File) => void;
}) => {
  const [imgSrc, setImgSrc] = useState<string>("");
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [step, setStep] = useState<EditorStep>("crop");
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [processedFile, setProcessedFile] = useState<File | null>(null);
  const [originalDimensions, setOriginalDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  const [finalDimensions, setFinalDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [error, setError] = useState<string>("");

  const MIN_RESOLUTION = 720;
  const MAX_RESOLUTION = 1080;
  const ASPECT_RATIO = 16 / 9;

  const { showToastAfterRequest } = useToast();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          setOriginalDimensions({ width: img.width, height: img.height });

          const crop = centerCrop(
            makeAspectCrop(
              {
                unit: "%",
                width: 100,
              },
              ASPECT_RATIO,
              img.width,
              img.height
            ),
            img.width,
            img.height
          );

          setCrop(crop);
          setStep("crop");
        };
        img.src = reader.result as string;
        setImgSrc(reader.result as string);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  const generatePreview = useCallback(async () => {
    if (!imgRef.current || !completedCrop) return "";

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return "";

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = imgSrc;

    return new Promise<string>((resolve, reject) => {
      image.onload = () => {
        const scaleX = image.naturalWidth / imgRef.current!.width;
        const scaleY = image.naturalHeight / imgRef.current!.height;

        const pixelCrop = {
          x: completedCrop.x * scaleX,
          y: completedCrop.y * scaleY,
          width: completedCrop.width * scaleX,
          height: completedCrop.height * scaleY,
        };

        let width = pixelCrop.width;
        let height = pixelCrop.height;

        if (width / height !== ASPECT_RATIO) {
          height = width / ASPECT_RATIO;
        }

        if (width > MAX_RESOLUTION) {
          width = MAX_RESOLUTION;
          height = width / ASPECT_RATIO;
        } else if (width < MIN_RESOLUTION) {
          width = MIN_RESOLUTION;
          height = width / ASPECT_RATIO;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          width,
          height
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Failed to process image"));
          } else {
            const previewUrl = URL.createObjectURL(blob);
            setFinalDimensions({
              width: Math.round(width),
              height: Math.round(height),
            });
            resolve(previewUrl);
          }
        }, "image/jpeg");
      };

      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
    });
  }, [completedCrop, imgSrc, ASPECT_RATIO, MAX_RESOLUTION, MIN_RESOLUTION]);

  const handleCompleteCrop = async () => {
    try {
      const previewUrl = await generatePreview();
      if (previewUrl) {
        setPreviewUrl(previewUrl);

        const response = await fetch(previewUrl);
        const blob = await response.blob();
        const file = new File(
          [blob],
          `processed-image-${finalDimensions.width}x${finalDimensions.height}.jpg`,
          {
            type: "image/jpeg",
            lastModified: Date.now(),
          }
        );

        setProcessedFile(file);
        setStep("preview");
      }
    } catch (err) {
      setError("Failed to process image");
      console.error(err);
    }
  };

  const handleSaveImage = () => {
    if (!processedFile) return;

    showToastAfterRequest(
      "Image saved successfully!",
      "Image saved successfully"
    );
    onSuccess(processedFile);
  };

  const handleReset = () => {
    setImgSrc("");
    setCrop(undefined);
    setCompletedCrop(undefined);
    setPreviewUrl("");
    setStep("crop");
    setError("");
  };

  const handleTabChange = (tab: EditorStep) => {
    setStep(tab);
  };

  return (
    <div className="image-editor-card">
      <div className="image-editor-container">
        <div className="image-editor-title">
          Upload and crop your image to 16:9 aspect ratio (min 720p, max 1080p)
        </div>
        <div className="tabs">
          <div
            className={`tab ${step === "crop" ? "active" : ""}`}
            onClick={() => handleTabChange("crop")}
          >
            Crop
          </div>
          <div
            className={`tab ${step === "preview" ? "active" : ""}`}
            onClick={() => handleTabChange("preview")}
          >
            Preview
          </div>
        </div>

        <div className="tab-content">
          {step === "crop" && imgSrc && (
            <div className="crop-step">
              <div className="crop-container">
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={ASPECT_RATIO}
                  className="crop-image"
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc || "/placeholder.svg"}
                    className="crop-img"
                  />
                </ReactCrop>
              </div>

              <div className="image-dimensions">
                Original dimensions: {originalDimensions.width} x{" "}
                {originalDimensions.height}
              </div>

              <div className="action-buttons">
                <Button onClick={handleReset} text="Cancel" />
                <Button onClick={handleCompleteCrop} text="Crop Image" />
              </div>
            </div>
          )}

          {step === "preview" && previewUrl && (
            <div className="preview-step">
              <div className="preview-container">
                <img
                  src={previewUrl || "/placeholder.svg"}
                  alt="Preview"
                  className="preview-img"
                />
              </div>

              <div className="preview-info">
                <p>Image processed successfully</p>
                <div>
                  Final dimensions: {finalDimensions.width} x{" "}
                  {finalDimensions.height} (16:9 aspect ratio)
                </div>
              </div>

              <div className="action-buttons">
                <Button onClick={handleReset} text="Start Over" />
                <Button onClick={handleSaveImage} text="Save Image" />
              </div>
            </div>
          )}
        </div>

        {error && (
          <div className="error-message">
            <div>Error</div>
            <div>{error}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageEditor;
