import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectProfileData } from "@client.reducers/profile";

import useToast from "@client.hooks/useToast";

import Pages from "@client.enums/pages";
import AdvertisementStatuses from "@client.enums/advertisementStatuses";
import AccountEntity from "@client.enums/accountEntity";

import Title from "@client.components/Title";
import DataTable from "@client.components/DataTable";
import Button from "@client.core.components/Button";

import AdvertisementsInnerTabs, {
  AdvertisementsTabsEnum,
} from "./AdvertisementsTabs";

import { IAdvertisement } from "@client.types/advertisement";
import ServiceProvider from "@client.services/provider";

import { getColumns } from "./getColumns";
import AdvertisementsFilter from "./AdvertisementsFilter";

import './styles.scss';

const ITEMS_PER_PAGE = 10;

export interface IAdvertisementsFilter {
  search: string,
  advertiserId: string,
  startDate: string,
  endDate: string,
  status: number | null,
}

const AdvertisementsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showErrorToast } = useToast();

  const profile = useSelector(selectProfileData);

  const isAdvertiser = profile?.entity === AccountEntity.Advertiser;

  const [advertisements, setAdvertisements] = useState<IAdvertisement[]>([]);
  const [advertisementsCount, setAdvertisementsCount] = useState<number>(0);
  const [isLoading, setLoading] = useState(true);

  const [filter, setFilter] = useState<IAdvertisementsFilter>({
    search: "",
    advertiserId: "",
    startDate: "",
    endDate: "",
    status: null,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedTab, setSelectedTab] = useState(
    AdvertisementsTabsEnum.AllTheRest
  );

  useEffect(() => {
    setLoading(true);
    const fetchAdvertisements = async () => {
      try {
        const offset = (currentPage - 1) * ITEMS_PER_PAGE;
        const response = await ServiceProvider.Advertisement.getAdvertisements({
          ...(filter.status ? { status: +filter.status } : {}),
          ...(filter.advertiserId || isAdvertiser ? { advertiserId: filter.advertiserId || profile.accountId } : {}),
          pagination: {
            limit: ITEMS_PER_PAGE,
            offset,
            search: filter.search,
            searchOn: "",
            startDate: filter.startDate, 
            endDate: filter.endDate,
          },
        });
        const advertisementsResponse = response.data.campaigns;
        if (advertisementsResponse) {
          setAdvertisements(advertisementsResponse.results);
          setAdvertisementsCount(advertisementsResponse.count);
        } else {
          showErrorToast("Can't get advertisements");
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error ? e.message : "Can't get advertisements";
        showErrorToast(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchAdvertisements();
  }, [filter, currentPage]);

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab);
    setCurrentPage(1);
  };

  const handleCreateAdvertisementClick = async () => {
    navigate(Pages.CreateAdvertisement);
  };

  const handleTableRowClick = ({ data }) => {
    const { Id, status } = data;

    navigate(
      status === AdvertisementStatuses.Draft
        ? `${Pages.CreateAdvertisement}/${Id}`
        : `${Pages.Advertisement}/${Id}`
    );
  };

  return (
    <div className="advertisements">
      <Title text={t("components.NavigationBar.advertisements")}>
        <div className="management-buttons">
          <Button
            icon="plus"
            text={t("components.NavigationBar.createAdvertisement")}
            onClick={handleCreateAdvertisementClick}
          />
        </div>
      </Title>

      <AdvertisementsFilter
        filter={filter}
        setFilter={setFilter}
        selectedTab={selectedTab}
        isAdvertiser={isAdvertiser}
      />
      <AdvertisementsInnerTabs
        value={selectedTab}
        onSelect={handleTabChange}
      />
      <div className="advertisement-list">
        <DataTable
          columns={getColumns(t)}
          data={advertisements}
          isLoading={isLoading}
          onSelectionChanged={handleTableRowClick}
          pagination={{
            currentPage,
            total: advertisementsCount,
            itemsPerPage: ITEMS_PER_PAGE,
            onChange: setCurrentPage,
          }}
        />
      </div>
    </div>
  );
};

export default AdvertisementsPage;
