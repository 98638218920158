import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import Button from "@client.components/core/Button";
import FormRadioGroup from "@client.components/form/FormRadioGroup";
import BudgetLimit from "@client.enums/budgetLimit";
import FormSlider from "@client.components/form/FormSlider";
import TagList from "@client.components/TagList";
import {
  Step,
  useCreateAdvertisementForm,
} from "@client.pages/CreateAdvertisement/CreateAdvertisementFormContext";

const MIN_AMOUNT = 10;

interface IProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const SlotCalculatorStep = ({ currentStep, setCurrentStep }: IProps) => {
  const { t } = useTranslation();

  const { createAdvertisementFormMethods, isFormFilled, setFormFilled } =
    useCreateAdvertisementForm();

  const budgetLimits = [
    {
      value: BudgetLimit.Total,
      label: t("Pages.CreateAdvertisement.budget.total"),
    },
    {
      value: BudgetLimit.Daily,
      label: t("Pages.CreateAdvertisement.budget.daily"),
    },
  ];

  const amountTags = [
    { value: 50, label: `$50` },
    { value: 200, label: `$200` },
    { value: 300, label: `$300` },
    { value: 450, label: `$450` },
  ];

  const consecutiveSlotsTags = [
    { value: 10, label: `10` },
    { value: 20, label: `20` },
    { value: 30, label: `30` },
    { value: 40, label: `40` },
  ];

  const { control, setValue, watch, trigger } = createAdvertisementFormMethods;

  const handleSubmitSlotCalculatorStepForm = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        setCurrentStep((prev) => prev + 1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const budgetAmount = watch("budgetAmount");
  const consecutiveSlots = watch("consecutiveSlots");

  return (
    <div className="slot-calculator-container">
      <div className="step-title-container">
        <div className="step-title">Budget</div>
        {isFormFilled ? (
          <Button
            icon="edit"
            text={t("Pages.CreateAdvertisement.edit")}
            className="wizard"
            onClick={() => {
              setFormFilled(false);
              setCurrentStep(Step.SlotCalculator);
            }}
          />
        ) : (
          false
        )}
      </div>
      {currentStep === Step.SlotCalculator ? (
        <div className="slot-calculator-body">
          <FormRadioGroup
            groupName="budgetType"
            items={budgetLimits}
            control={control}
          />
          <div className="slot-calculator-sections-container">
            <div className="slot-calculator-item">
              <div className="daily-amount-title">
                {t("Pages.CreateAdvertisement.budget.insertDailyAmount")}
              </div>
              <div className="budget-amount-controls">
                <div className="slider-container">
                  <FormSlider
                    min={MIN_AMOUNT}
                    max={5000}
                    name="budgetAmount"
                    control={control}
                    isMoney
                  />
                  <div className="slot-calculator-tags">
                    <TagList
                      items={amountTags}
                      selected={budgetAmount}
                      onSelect={(value) => setValue("budgetAmount", value)}
                      isMultiSelect={false}
                      nameKey="label"
                      valueKey="value"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="slot-calculator-item slot-length-container">
              <div className="daily-amount-title">
                Slot length
              </div>
              <div>
                <div className="budget-amount-controls">
                  <div className="slider-container">
                    <FormSlider
                      min={10}
                      max={60}
                      name="consecutiveSlots"
                      control={control}
                    />
                    <div className="slot-calculator-tags">
                      <TagList
                        items={consecutiveSlotsTags}
                        selected={consecutiveSlots}
                        onSelect={(value) => setValue("consecutiveSlots", value)}
                        isMultiSelect={false}
                        nameKey="label"
                        valueKey="value"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slot-calculator-item estimations">
              <div className="estimations-row">
                <p className="estimations-title">Screen Time:</p>
                <p className="estimations-value">10 hours</p>
              </div>
              <div className="estimations-row">
                <p className="estimations-title">Estimated Views:</p>
                <p className="estimations-value">15k</p>
              </div>
              <div className="estimations-row">
                <p className="estimations-title">Estimated Expressions:</p>
                <p className="estimations-value">50k</p>
              </div>
            </div>
          </div>
          <Button
            text={t("Pages.CreateAdvertisement.next")}
            className="btn-next wizard"
            onClick={handleSubmitSlotCalculatorStepForm}
          />
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default SlotCalculatorStep;
