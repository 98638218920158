import React from "react";

import { TOOLTIP_ID } from "@client.constants";

import IconRenderer from "@client.components/FA/IconRenderer";

import classNames from "classnames";

interface IProps {
  value: number | string;
  icon?: string;
  info?: string;
  color?: string;
}

const OverviewBadge = ({
  value,
  icon,
  info,
  color,
}: IProps) => {
  return (
    <div className={classNames("overview-badge", color)}>
      {icon ? (
        <div className="overview-badge-icon">
          <IconRenderer iconName={icon} size={23} />
        </div>
      ) : false}
      <div className="overview-badge-value">
        {value}
      </div>
      {info ? (
        <IconRenderer
          iconName="FaCircleInfo"
          size={14}
          className="overview-badge-info-icon"
          data-tooltip-id={TOOLTIP_ID}
          data-tooltip-content={info}
        />
      ) : false}
    </div>
  );
};

export default OverviewBadge;
