import React, { useEffect, useState, useCallback, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import LocationTypes from "@client.enums/locationTypes";
import { IZonePolygon, IZonePolygonData } from "@client.types/zonePolygon";

import Map from "@client.components/Map";
import ToggleButton from "@client.components/core/ToggleButton";

interface IProps {
  selectedTab: LocationTypes,
  mapLocations: IZonePolygon[],
  setMapLocations: Dispatch<SetStateAction<IZonePolygon[]>>,
  setDrawnPolygon: Dispatch<SetStateAction<IZonePolygonData[]>>,
}

const LocationsMap = ({selectedTab, mapLocations, setMapLocations, setDrawnPolygon}: IProps) => {
  const { t } = useTranslation();
  

  const [isDrawingActive, setDrawingActive] = useState(false);
  const [drawingManagerKey, setDrawingManagerKey] = useState(
    new Date().getTime().toString()
  );

  useEffect(() => {
    if (selectedTab !== LocationTypes.Custom) {
      setDrawingActive(false);
    }
  }, [selectedTab])

  const changeDrawingMode = useCallback(() => {
    if (isDrawingActive) {
      setDrawnPolygon([]);
      setDrawingManagerKey(new Date().getTime().toString());
    }
    setDrawingActive((prev) => !prev);
  }, [isDrawingActive]);

  const handleFinishPolygonDrawing = (coordinates) => {
    const locationId = `temporary`;
    setMapLocations((prev) => [...prev, {
      color: 'red',
      data: coordinates,
      id: locationId,
    }])
    setDrawnPolygon(coordinates)
  }

  return (
    <div className="locations-map-container">
      <Map
        zoom={2}
        options={{
          mapTypeControl: false,
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: false,
        }}
        polygons={mapLocations}
        drawingManagerKey={drawingManagerKey}
        drawingManagerRef={
          isDrawingActive
            ? (v) => {
              if (v) {
                v.setDrawingMode("polygon");
              }
            }
            : null
        }
        onDrawingManagerPolygonComplete={handleFinishPolygonDrawing}
        className="zones-map"
      >
        {selectedTab === LocationTypes.Custom ? (
          <div className="drawing-button">
            <ToggleButton
              text={t(
                isDrawingActive
                  ? "pages.Locations.stopDrawing"
                  : "pages.Locations.startDrawing"
              )}
              value={isDrawingActive}
              onChange={changeDrawingMode}
            />
          </div>
        ) : false}
      </Map>
    </div>
  );
};

export default LocationsMap;
