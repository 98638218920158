import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useToast from "@client.hooks/useToast";
import { AppDispatch } from "@client.store";
import { IAddress } from "@client.types/address";
import { createUserRegisterAsync } from "@client.reducers/users";
import { ICreateUserRegisterResponse } from "@client.types/queries/createUserMutation";
import { getRegisterFields } from "./getRegisterFields";

export interface IRegisterFormValues {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  entity: number;
  companyName: string;
  companyNumber: string;
  vatNumber: string;
  shippingAddress: IAddress;
  billingAddress: IAddress;
  password: string;
  sameAddress?: boolean;
}

const DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  entity: 0,
  companyName: "",
  companyNumber: "",
  vatNumber: "",
  shippingAddress: {
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  },
  billingAddress: {
    addressLineOne: "",
    city: "",
    country: "",
    postalCode: "",
  },
  password: "",
  sameAddress: true,
};

const useRegisterForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showToastAfterRequest, showErrorToast } = useToast();

  const nameRegex = /^[a-zA-Z\s-]+$/;

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .matches(
        nameRegex,
        "First Name must contain only alphabetic characters, spaces, or hyphens"
      )
      .required("First name is required."),

    lastName: yup
      .string()
      .matches(
        nameRegex,
        "Last Name must contain only alphabetic characters, spaces, or hyphens"
      )
      .required("Last name is required."),

    emailAddress: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Email address is required."),

    phoneNumber: yup
      .string()
      .required("Phone number is required.")
      .min(10, "Phone number must be at least 10 characters long.")
      .max(15, "Phone number must be at most 15 characters long."),

    entity: yup
      .number()
      .required("Entity type is required.")
      .min(1, "Entity type is required."),

    companyName: yup.string().required("Company name is required."),

    companyNumber: yup.string().required("Company number is required."),

    vatNumber: yup.string().required("VAT number is required."),

    shippingAddress: yup
      .object()
      .shape({
        addressLineOne: yup.string().required("Shipping address is required."),
        city: yup.string().default(""),
        country: yup.string().default(""),
        postalCode: yup.string().default(""),
      })
      .required("Shipping address is required."),

    billingAddress: yup
      .object()
      .shape({
        addressLineOne: yup.string().required("Billing address is required."),
        city: yup.string().default(""),
        country: yup.string().default(""),
        postalCode: yup.string().default(""),
      })
      .required("Billing address is required."),

    password: yup
      .string()
      .required("Password is required.")
      .min(6, "Password must be at least 6 characters long.")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter."),

    sameAddress: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_VALUES,
  });

  const shippingAddress = watch("shippingAddress");
  const billingAddress = watch("billingAddress");
  const password = watch("password");
  const sameAddress = watch("sameAddress");

  useEffect(() => {
    if (sameAddress) {
      setValue("billingAddress", shippingAddress);
      trigger("billingAddress");
    }
  }, [shippingAddress, sameAddress]);

  const resetForm = () => {
    reset(DEFAULT_VALUES);
  };

  const onSubmit = async (formValues) => {
    try {
      const fields = getRegisterFields(formValues);
      const response = await dispatch(createUserRegisterAsync(fields));
      if ((response.payload as ICreateUserRegisterResponse).errors) {
        showErrorToast("Registration failed");
      } else {
        showToastAfterRequest(response, "Successfully registered");
        resetForm();
      }
    } catch (e) {
      showErrorToast("Registration failed");
    }
  };

  const handleSubmitRegisterForm = handleSubmit(onSubmit);

  return {
    register,
    control,
    getValues,
    setValue,
    trigger,
    errors,
    shippingAddress,
    billingAddress,
    password,
    handleSubmitRegisterForm,
  };
};

export default useRegisterForm;
