import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import NavigationBar from "@client.components/layout/NavigationBar";
import TopBar from "@client.components/layout/TopBar";
import Toast from "@client.core.components/Toast";
import Modal from "@client.components/Modal";
import ErrorMessages from "@client.components/ErrorMessages";
import "./styles.scss";

const AuthorizedLayout = () => {
  const [showAdvertiser, setShowAdvertiser] = useState(false);
  const [navigationLink, setNavigationLink] = useState<string | null>(null);

  return (
    <div className="authorized-layout">
      <Toast />
      <Modal />
      <ErrorMessages />
      <NavigationBar />
      <div className="page">
        <TopBar
          showAdvertiser={showAdvertiser}
          navigationLink={navigationLink}
        />
        <main className="main">
          <Outlet context={{ setShowAdvertiser, setNavigationLink }} />
        </main>
      </div>
    </div>
  );
};

export default AuthorizedLayout;
