import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

import Button from "@client.core.components/Button";
import Dropdown from "@client.components/core/Dropdown";
import "./styles.scss";
import moment from "moment";
import config from "@client.config";

interface IProps {
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  toggleCalendarView: () => void;
  onChange: (startEndDates: string[]) => void;
  withTime?: boolean;
}

const CalendarView = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  toggleCalendarView,
  onChange,
  withTime,
}: IProps) => {
  const [leftMonth, setLeftMonth] = useState(new Date());
  const [rightMonth, setRightMonth] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );

  const timeOptions = Array.from({ length: 24 * 2 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? "00" : "30";
    return {
      label: `${hour.toString().padStart(2, "0")}:${minute}`,
      value: `${hour.toString().padStart(2, "0")}:${minute}`,
    };
  });

  const handleDateSelection = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate("");
    } else if (startDate && !endDate && date > startDate) {
      setEndDate(date);
    } else {
      setStartDate(date);
    }
  };

  const navigateMonth = (direction, calendar) => {
    if (calendar === "left") {
      const newMonth = new Date(leftMonth);
      newMonth.setMonth(leftMonth.getMonth() + direction);
      setLeftMonth(newMonth);
    } else {
      const newMonth = new Date(rightMonth);
      newMonth.setMonth(rightMonth.getMonth() + direction);
      setRightMonth(newMonth);
    }
  };

  const clearRange = () => {
    setStartDate("");
    setEndDate("");
  };

  const applyRange = () => {
    toggleCalendarView();
    if (onChange) {
      onChange([startDate, endDate]);
    }
  };

  const applyTimeToDate = (time: string, date: string) => {
    const combined = moment(date).format("YYYY-MM-DD") + "T" + time;
    return moment(combined).toISOString();
  };

  const renderCalendar = (viewMonth) => {
    const lastDayOfMonth = new Date(
      viewMonth.getFullYear(),
      viewMonth.getMonth() + 1,
      0
    );
    const days: JSX.Element[] = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const currentDay = new Date(
        viewMonth.getFullYear(),
        viewMonth.getMonth(),
        i
      );

      const isSelected =
        (startDate && currentDay.getTime() === new Date(startDate).getTime()) ||
        (endDate && currentDay.getTime() === new Date(endDate).getTime()) ||
        (startDate &&
          endDate &&
          currentDay >= new Date(startDate) &&
          currentDay <= new Date(endDate));

      const isToday =
        currentDay.getDate() === new Date().getDate() &&
        currentDay.getMonth() === new Date().getMonth() &&
        currentDay.getFullYear() === new Date().getFullYear();

      days.push(
        <button
          key={i}
          className={`calendar-day ${isSelected ? "selected" : ""} ${
            isToday ? "today" : ""
          }`}
          onClick={() => handleDateSelection(currentDay.toISOString())}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="calendar-grid">
        {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
          <div key={index} className="calendar-day-name">
            {day}
          </div>
        ))}
        {days}
      </div>
    );
  };

  return (
    <div className="daterangepicker-calendar-view">
      <Button
        icon="close"
        className="daterangepicker-calendar-view-close-button"
        onClick={toggleCalendarView}
      />
      <div className="daterangepicker-calendar-container">
        <div className="calendar">
          <div className="calendar-header">
            <button
              onClick={() => navigateMonth(-1, "left")}
              className="calendar-nav"
            >
              {"<"}
            </button>
            <span className="calendar-title">
              {leftMonth.toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </span>
            <button
              onClick={() => navigateMonth(1, "left")}
              className="calendar-nav"
            >
              {">"}
            </button>
          </div>
          {renderCalendar(leftMonth)}
        </div>
        <hr style={{ opacity: 0.3, marginTop: -15 }} />
        <div className="calendar">
          <div className="calendar-header">
            <button
              onClick={() => navigateMonth(-1, "right")}
              className="calendar-nav"
            >
              {"<"}
            </button>
            <span className="calendar-title">
              {rightMonth.toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </span>
            <button
              onClick={() => navigateMonth(1, "right")}
              className="calendar-nav"
            >
              {">"}
            </button>
          </div>
          {renderCalendar(rightMonth)}
        </div>
      </div>

      <div className="daterangepicker-footer">
        {withTime && startDate && endDate ? (
          <div className="timepicker-container">
            <div className="timepicker-items">
              <div className="timepicker-label">Start Date:</div>
              <div className="timepicker-label">
                {moment(startDate).format(config.dateFormat)}
              </div>
              <div className="timepicker-dropdown">
                <Dropdown
                  items={timeOptions}
                  onSelect={(v) =>
                    setStartDate((prev) => applyTimeToDate(v, prev))
                  }
                  defaultValue={moment(startDate).format("HH:mm")}
                />
              </div>
            </div>
            <div className="timepicker-items">
              <div className="timepicker-label">End Date:</div>
              <div className="timepicker-label">
                {moment(endDate).format(config.dateFormat)}
              </div>
              <div className="timepicker-dropdown">
                <Dropdown
                  items={timeOptions}
                  onSelect={(v) =>
                    setEndDate((prev) => applyTimeToDate(v, prev))
                  }
                  defaultValue={moment(endDate).format("HH:mm")}
                />
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
        <div className="daterangepicker-footer-buttons">
          <Button
            text="Clear"
            onClick={clearRange}
            className="button alternative"
          />
          <Button text="Apply" onClick={applyRange} />
        </div>
      </div>
    </div>
  );
};

export default CalendarView;
