import { verifyAccountAsync } from "@client.reducers/login";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useLayoutEffect } from "react";
import { AppDispatch } from "@client.store";
import useToast from "@client.hooks/useToast";

function Verify() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessfulToast } = useToast();
  const navigation = useNavigate();

  useLayoutEffect(() => {
    verifyAccount();
  }, [dispatch, params.id]);

  const verifyAccount = async () => {
    if (params.id) {
      const res = await dispatch(
        verifyAccountAsync({ token: params.id })
      ).unwrap();
      if (res.data.verifyAccount.message) {
        showSuccessfulToast(res.data.verifyAccount.message);
        navigation("/");
      }
    }
  };

  return <div>Verify</div>;
}

export default Verify;
