import React, { useEffect, useState } from "react";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import "./TelemetryDataTable.scss";
import ServiceProvider from "@client.services/provider";
import { useSelector } from "react-redux";
import { makeDeviceInfo } from "@client.reducers/devices";

const TelemetryDataTable = () => {
  const { t } = useTranslation();
  const overview = useSelector(makeDeviceInfo);
  const [data, setData] = useState({ count: 0, results: [] });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    ServiceProvider?.Device?.fetchDeviceTelemetry(
      // ITEMS_PER_PAGE,
      // offset,
      overview.device.Id
    )
      .then((resp) => {
        setData(resp?.data?.telemetries);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  return (
    <div className="telemetry-history">
      <DataTable
        columns={getColumns(t)}
        data={data?.results}
        isLoading={loading}
      />
    </div>
  );
};

export default TelemetryDataTable;
