import React, { useEffect, useState, useCallback } from "react";
import DataTable from "@client.components/DataTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import config from "@client.config";
import LogActions from "@client.enums/logActions";
import LogTypes from "@client.enums/logTypes";
import Pages from "@client.enums/pages";
import {
  getSystemLogsAsync,
  selectSystemLogsCount,
  selectSystemLogsData,
  selectSystemLogsLoading
} from "@client.reducers/systemLogs";
import { AppDispatch } from "@client.store";
import MinimizeIcon from "./MinimizeIcon";
import EllipsisCellRenderer from "@client.components/DataTable/EllipsisCellRenderer";

const ITEMS_PER_PAGE = 5;

const getColumns = (t, onRowClick) => [
  {
    field: "createdAt",
    headerName: t("Created At"),
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format(config.dateTimeSecondsFormat)
        : "N/A";
    }
  },
  {
    field: "entityName",
    headerName: t("systemLogs.Related"),
    cellRenderer: (params) => {
      const entityPage = (() => {
        switch (params.data.entity) {
        case LogTypes.Device:
          return t("systemLogs.goToPage.Device");
        case LogTypes.User:
          return t("systemLogs.goToPage.User");
        case LogTypes.Accounts:
          return t("systemLogs.goToPage.Accounts");
        default:
          return t("N/A");
        }
      })();

      return (
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            onRowClick(entityPage, params.data.entityId);
          }}
        >
          {params.value || "N/A"}
        </a>
      );
    }
  },
  {
    field: "userFullName",
    headerName: t("Full Name")
  },
  {
    field: "type",
    headerName: t("Action"),
    maxWidth: 90,
    valueGetter: (params) => {
      switch (params.data.type) {
      case LogActions.CREATE:
        return t("systemLogs.type.CREATE");
      case LogActions.UPDATE:
        return t("systemLogs.type.UPDATE");
      case LogActions.DELETE:
        return t("systemLogs.type.DELETE");
      case LogActions.LOGIN:
        return t("systemLogs.type.LOGIN");
      default:
        return t("N/A");
      }
    }
  },
  {
    field: "description",
    headerName: t("Description"),
    cellRenderer: EllipsisCellRenderer,
  },
  {
    field: "ipAddress",
    headerName: t("IP Address")
  }
];

interface IProps {
  onClose: () => void;
}

const LogsAlertsDataTable = ({ onClose }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const systemLogs = useSelector(selectSystemLogsData);
  const systemLogsCount = useSelector(selectSystemLogsCount);
  const systemLogsLoading = useSelector(selectSystemLogsLoading);

  const [currentPage, setCurrentPage] = useState(1);

  const onRowClick = useCallback(
    (page, entityId) => {
      switch (page) {
      case "Device":
        navigate(`${Pages.Devices}/${entityId}`);
        break;
      case "User":
        navigate(`${Pages.Users}`);
        break;
      case "Accounts":
        navigate(`${Pages.Accounts}/${entityId}`);
        break;
      default:
        console.warn("Unknown entity type");
        break;
      }
    },
    [navigate]
  );

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getSystemLogsAsync({
        offset,
        limit: ITEMS_PER_PAGE,
        search: "",
        searchOn: "",
        startDate: "",
        endDate: ""
      })
    );
  }, [currentPage, dispatch]);

  return (
    <div className="logs-alerts-modal">
      <DataTable
        columns={getColumns(t, onRowClick)}
        data={systemLogs}
        isLoading={systemLogsLoading}
        isMinified
        pagination={{
          total: systemLogsCount,
          itemsPerPage: ITEMS_PER_PAGE,
          currentPage,
          onChange: setCurrentPage
        }}
      />
      <div className="minimize-icon-absolute">
        <MinimizeIcon onClick={onClose} />
      </div>
    </div>
  );
};

export default LogsAlertsDataTable;
