import React, { useState } from "react";

import { IZone } from "@client.types/zone";

import Tree from "@client.components/Tree";
import Loader from "@client.core.components/Loader";

import DeleteZoneConfirmation from "./DeleteZoneConfirmation";
import ZoneItem from "./ZoneItem";

interface IProps {
  zones: IZone[],
  selectedZoneId: string,
  onSelectZone: (zone: IZone | null) => void,
  zonesLoading: boolean,
  onCreateChildZone: (parentZoneId: string, category: number) => void,
  onUpdateZoneClick: (zone: IZone) => void,
}

const ZonesList = ({
  zones,
  selectedZoneId,
  onSelectZone,
  zonesLoading,
  onCreateChildZone,
  onUpdateZoneClick,
}: IProps) => {
  const [showDeleteZoneDialog, setShowDeleteZoneDialog] = useState(false);

  const [zoneIdToDelete, setZoneIdToDelete] = useState<string | null>(null);

  const handleDeleteZoneClick = (zoneId: string) => {
    setZoneIdToDelete(zoneId);
    setShowDeleteZoneDialog(true);
  }

  if (zonesLoading) {
    return (
      <div className="zones-loading">
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div className="zones-list-container">
      <Tree
        items={zones}
        renderItem={(zone) => (
          <ZoneItem
            key={zone.Id}
            zone={zone}
            onDelete={handleDeleteZoneClick}
            selectedZoneId={selectedZoneId}
            onSelectZone={onSelectZone}
            onCreateChildZone={onCreateChildZone}
            onUpdateZoneClick={onUpdateZoneClick}
          />
        )}
      />
      <DeleteZoneConfirmation 
        isOpen={showDeleteZoneDialog}
        onClose={() => setShowDeleteZoneDialog(false)}
        zoneId={zoneIdToDelete}
        setZoneIdToDelete={setZoneIdToDelete}
      />
    </div>
  );
};

export default ZonesList;
