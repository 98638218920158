import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@client.components/core/Button";
import FormRadioGroup from "@client.components/form/FormRadioGroup";
import BudgetLimit from "@client.enums/budgetLimit";
import FormSlider from "@client.components/form/FormSlider";
import TagList from "@client.components/TagList";
import {
  Step,
  useCreateAdvertisementForm,
} from "@client.pages/CreateAdvertisement/CreateAdvertisementFormContext";
import SectionTitleDescription from "@client.components/SectionTitleDescription";
import RadioGroup from "@client.components/RadioGroup";
import NewCreditCardForm from "./NewCreditCardForm";

const CREDIT_CARDS = [
  { value: '1', label: "**** **** **** 1234" },
  { value: '2', label: "**** **** **** 2345" },
  { value: '3', label: "Another Card" },
]

interface IProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const PaymentStep = ({ currentStep, setCurrentStep }: IProps) => {
  const { t } = useTranslation();

  const { createAdvertisementFormMethods, isFormFilled, setFormFilled } =
    useCreateAdvertisementForm();

  const [selectedCreditCard, setSelectedCreditCard] = useState<string | null>(null);

  const { trigger } = createAdvertisementFormMethods;

  const handleSubmitPaymentStepForm = async () => {
    // UNTIL WE DON"T HAVE BE SUPPORT OF CREDIT CARDS
    // try {
    //   const isValid = await trigger();
    //   if (isValid) {
    //     setFormFilled(true);
    //     setCurrentStep(-1);
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
    setFormFilled(true);
    setCurrentStep(-1);
  };

  return (
    <div className="payment-container">
      <div className="step-title-container">
        <div className="step-title">Payment</div>
        {isFormFilled ? (
          <Button
            icon="edit"
            text={t("Pages.CreateAdvertisement.edit")}
            className="wizard"
            onClick={() => {
              setFormFilled(false);
              setCurrentStep(Step.Payment);
            }}
          />
        ) : (
          false
        )}
      </div>
      {currentStep === Step.Payment ? (
        <div className="payment-body">
          <div>
            <RadioGroup 
              groupName="credit-card"
              items={CREDIT_CARDS}
              value={selectedCreditCard}
              onChange={setSelectedCreditCard}
              className="credit-card-options"
            />
          </div>
          {selectedCreditCard === "3" ? (
            <NewCreditCardForm />
          ) : false}
          <Button
            text={t("Pages.CreateAdvertisement.next")}
            className="btn-next wizard"
            onClick={handleSubmitPaymentStepForm}
          />
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default PaymentStep;
