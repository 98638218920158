import React, { useEffect, Dispatch, SetStateAction } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getZonesAsync,
  selectZonesData,
  selectZonesTree,
} from "@client.reducers/zones";
import { AppDispatch } from "@client.store";

import { UseFormSetValue } from "react-hook-form";

import { IZone } from "@client.types/zone";

import Tree from "@client.components/Tree";

import ZoneItem from "./ZoneItem";
import { ICreateAdvertisementFormValues, useCreateAdvertisementForm } from "../../../../../../CreateAdvertisementFormContext";
import LocationTypes from "@client.enums/locationTypes";
import { IZonePolygon } from "@client.types/zonePolygon";
import { ILocation } from "@client.pages/CreateAdvertisement/CreateAdvertisementForm";

interface IProps {
  setMapLocations: Dispatch<SetStateAction<IZonePolygon[]>>;
}

const ZonesTab = ({ setMapLocations }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const zonesList = useSelector(selectZonesData);
  const zonesTree = useSelector(selectZonesTree);

  const { 
    createAdvertisementFormMethods,
    setSelectedLocationsList,
  } = useCreateAdvertisementForm();

  const {
    setValue,
    watch,
  } = createAdvertisementFormMethods;

  const zoneIds = watch("zoneIds");

  useEffect(() => {
    if (!zonesTree.length) {
      dispatch(getZonesAsync());
    }
  }, []);

  const handleSelectZone = (zoneId) => {
    const selectedZone = zonesList.find((zone) => zone.Id === zoneId);
    if (!selectedZone) {
      return;
    }
    if (zoneIds.find((selectedZone) => selectedZone === zoneId)) {
      setValue("zoneIds", zoneIds.filter((selectedZone) => selectedZone !== zoneId))
      setSelectedLocationsList((prev) => prev.filter((location) => location.id !== zoneId));
      setMapLocations((prev) => prev.filter((location) => location.id !== zoneId));
    } else {
      setValue("zoneIds", [...zoneIds, zoneId])
      setSelectedLocationsList((prev) => [...prev, {
        title: selectedZone.zoneName,
        id: zoneId,
        type: LocationTypes.Zones
      }])
      setMapLocations((prev) => [...prev, {
        color: 'red',
        data: selectedZone.polygon.map((point) => ({
          lat: point[1],
          lng: point[0],
        })),
        id: zoneId,
      }]);
    }
    setValue("zoneIds", [selectedZone.Id]);
  };

  return (
    <Tree
      items={zonesTree}
      renderItem={(zone) => (
        <ZoneItem
          zone={zone}
          selectedZonesId={zoneIds}
          onSelectZone={handleSelectZone}
        />
      )}
    />
  );
};

export default ZonesTab;
