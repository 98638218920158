import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import IconRenderer from "@client.components/FA/IconRenderer";

import "./styles.scss";

interface IMapFilterItem {
  icon: string;
  title: string;
  onClick?: () => void;
  enabled?: boolean;
  component?: React.ReactNode;
}

interface IProps {
  items: IMapFilterItem[];
}

const MapFilter = ({ items }: IProps) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(true);

  return (
    <div className="map-filter">
      <div className="map-filter-header" onClick={() => setExpand(!expand)}>
        <span>{t("components.MapFilter.Menu")}</span>
        <IconRenderer iconName={expand ? "FaChevronUp" : "FaChevronDown"} size={15} />
      </div>
      <div className={expand ? "options expanded" : "options"}>
        {items.map((item) => {
          return (
            <div
              className={item.enabled ? "option option-enabled" : "option"}
              onClick={item.onClick}
              key={item.title}
            >
              <div className="option-header">
                <IconRenderer iconName={item.icon} size={15} />
                {t(item.title)}
              </div>
              {item.component}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MapFilter;
