import React, { useState } from "react";
import classNames from "classnames";

import ZoneTypes from "@client.enums/zoneTypes";

import {
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineEdit,
  MdAdd,
  MdDeleteOutline,
} from "react-icons/md";
import { FaVideo } from "react-icons/fa";

import { getZoneTypeNameByCode } from "@client.utils/utils";
import Pages from "@client.enums/pages";
import { useNavigate } from "react-router-dom";
import { IZone } from "@client.types/zone";

interface IProps {
  zone: IZone,
  selectedZoneId: string,
  onSelectZone: (zone: IZone | null) => void,
  onCreateChildZone: (zoneId: string, category: number) => void,
  onUpdateZoneClick: (zone: IZone) => void,
  onDelete: (zoneId: string) => void,
}

const ZoneItem = ({
  zone,
  selectedZoneId,
  onSelectZone,
  onCreateChildZone,
  onUpdateZoneClick,
  onDelete,
}: IProps) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

  const renderExpandCollapse = () => {
    if ([ZoneTypes.Custom].includes(zone.category)) {
      return null;
    }

    return expanded ? (
      <MdOutlineExpandLess />
    ) : (
      <MdOutlineExpandMore className="expand" />
    );
  };

  return (
    <li className="tree-item">
      <div 
        className={classNames("tree-item-title", {
          selected: zone.Id === selectedZoneId,
        })}
        onClick={() => {
          const expandedVal = !expanded;
          setExpanded(expandedVal);
          onSelectZone(expandedVal ? zone : null);
        }}
      >
        {renderExpandCollapse()}
        {zone.zoneName}
        <span className="item-type">({getZoneTypeNameByCode(zone.category)})</span>
      </div>
      {expanded && (
        <>
          <div className="tree-item-data">
            <div className="action-icons">
              <FaVideo
                title="Default advertisements"
                style={{
                  filter: "grayscale(100%)",
                }}
                onClick={() => {
                  navigate(`${Pages.Zones}/${zone.Id}${Pages.DefaultAdvertisements}`);
                }}
              />
              {![ZoneTypes.Custom].includes(zone.category) && (
                <MdAdd
                  onClick={(e) => {
                    e.stopPropagation();
                    onCreateChildZone(zone.Id, zone.category + 1);
                  }}
                  title="Add"
                />
              )}
              <MdOutlineEdit
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdateZoneClick(zone);
                }}
                title="Edit"
              />
              <MdDeleteOutline
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(zone.Id);
                }}
                title="Delete"
              />
            </div>
            <div>Standard PPV Rate: {`$${zone.standardPpvRate}`}</div>
            <div>Prime PPV Rate: {`$${zone.primePpvRate}`}</div>
          </div>
          {zone.items && (
            <ul>
              {zone.items.map((item) => (
                <ZoneItem
                  key={item.Id}
                  zone={item}
                  onDelete={onDelete}
                  selectedZoneId={selectedZoneId}
                  onSelectZone={onSelectZone}
                  onCreateChildZone={onCreateChildZone}
                  onUpdateZoneClick={onUpdateZoneClick}
                />
              ))}
            </ul>
          )}
        </>
      )}
    </li>
  );
};

export default ZoneItem;
