import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import { IAccount } from "@client.types/account";
import { ICreateAccountResponse, ICreateAccountVariables } from "@client.types/queries/createAccountMutation";
import { IUpdateAccountResponse, IUpdateAccountVariables } from "@client.types/queries/updateAccountMutation";

interface IAccountsSlice {
  data: IAccount[];
  error: string | null;
  loading: boolean;
  selectedAccount: IAccount | null;
}

const initialState: IAccountsSlice = {
  data: [],
  error: null,
  loading: false,
  selectedAccount: null,
};

export const getAccountsAsync = createAsyncThunk(
  "accounts/getAll",
  async () => {
    const resp = await ServiceProvider.Account.getAccounts();
    return resp;
  }
);

export const createAccountAsync = createAsyncThunk<
  ICreateAccountResponse,
  ICreateAccountVariables
>(
  "accounts/create",
  async (createAccountFields, thunkAPI) => {
    const response = await ServiceProvider.Account.createAccount(createAccountFields);
    if (!response.errors) {
      thunkAPI.dispatch(getAccountsAsync());
    }
    return response;
  }
);

export const updateAccountAsync = createAsyncThunk<
  IUpdateAccountResponse,
  IUpdateAccountVariables
>(
  "accounts/update",
  async (updateAccountFields, thunkAPI) => {
    const response = await ServiceProvider.Account.updateAccount(updateAccountFields);
    if (!response.errors) {
      thunkAPI.dispatch(getAccountsAsync());
    }
    return response;
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setSelectedAccount: (state, action) => {
      state.selectedAccount = get(action, "payload", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccountsAsync.fulfilled, (state, action) => {
        const response = get(
          action,
          "payload.data.accounts",
          []
        );
        state.data = response.results;
        state.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAccountsAsync.rejected, (state, action) => {
        state.data = [];
        state.error = getResponseError(action);
      });
  },
});

export const { setSelectedAccount } = accountsSlice.actions;

export const selectAccountsData = (state) => state.accounts.data;
export const selectAccountsError = (state) => state.accounts.error;
export const selectAccountsLoading = (state) => state.accounts.loading;
export const makeSelectedAccount = (state) => state.accounts.selectedAccount;

export const selectAccountsHierarchy = (state) => {
  const accounts = [...state.accounts.data];

  const accountsMap = {};
  const hierarchy: IAccount[] = [];

  accounts.forEach((account) => {
    accountsMap[account.Id] = { ...account, children: [] };
  });

  accounts.forEach((account) => {
    if (account.parentAccountId) {
      if (accountsMap[account.parentAccountId]) {
        accountsMap[account.parentAccountId].children.push(accountsMap[account.Id]);
      }
    } else {
      hierarchy.push(accountsMap[account.Id]);
    }
  });
  return hierarchy.sort((a, b) => b.entity - a.entity);
};

export default accountsSlice.reducer;
