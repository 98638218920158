import UserService from "./userService";
import AdvertisementService from "./advertisementService";
import DeviceService from "./deviceService";
import DeviceRegistrationService from "./deviceRegistrationService";
import ZoneService from "./zoneService";
import DashboardService from "./dashboardService";
import DataAnalyzerService from "./dataAnalyzer";
import UserLogService from "./userLogService";
import SystemLogService from "./systemLogService";
import LocalStorageService from "./localStorage";
import EventEmitterService from "./eventEmitter";
import AccountService from "./accountService";
import InvoiceService from "./invoiceService";

export default class ServiceProvider {
  static Account = new AccountService();
  static User = new UserService();
  static Dashboard = new DashboardService();
  static Advertisement = new AdvertisementService();
  static EventEmitter = new EventEmitterService();
  static Device = new DeviceService();
  static DeviceRegistration = new DeviceRegistrationService();
  static LocalStorage = new LocalStorageService();
  static Zone = new ZoneService();
  static DataAnalyzer = new DataAnalyzerService();
  static UserLog = new UserLogService();
  static SystemLogService = new SystemLogService();
  static Invoice = new InvoiceService();
}
