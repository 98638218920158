import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  selectLoginLoading,
  selectLoginError,
  processLoginAsync,
} from "@client.reducers/login"
import {
  getProfileAsync,
} from "@client.reducers/profile"

import ServiceProvider from "@client.services/provider";

import Pages from "@client.enums/pages";
import LocalStorageKeys from "@client.enums/localStorageKeys";

import Button from "@client.core.components/Button";
import TextInput from "@client.components/form/TextInput";
import FormCheckbox from "@client.components/form/FormCheckbox";
import WelcomeCard from "../components/WelcomeCard/WelcomeCard";
import UnauthorizedPageCard from "../components/UnauthorizedPageCard/UnauthorizedPageCard";

import "./index.scss";
import { AppDispatch } from "@client.store";
import AuthLeftPanel from "../components/AuthLeftPanel";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const isLoginLoading = useSelector(selectLoginLoading);
  const loginError = useSelector(selectLoginError);

  const validationSchema = yup.object({
    emailAddress: yup
      .string()
      .email(t("pages.Login.validation.invalidEmail"))
      .required(t("pages.Login.validation.emailAddress")),
    password: yup.string().required(t("pages.Login.validation.password")),
    rememberMe: yup.boolean(),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      emailAddress: "",
      password: "",
      rememberMe: false,
    },
  });

  useEffect(() => {
    const token = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Token);
    if (token) {
      dispatch(getProfileAsync());
    }
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(processLoginAsync(data));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="login-page">
      <AuthLeftPanel />
      <UnauthorizedPageCard isLoading={isLoginLoading}>
        <div className="title">{t("pages.Login.title")}</div>
        <div className="description">{t("pages.Login.subTitle")}</div>
        <TextInput
          id="emailAddress"
          name="emailAddress"
          label={t("pages.Login.fieldset.emailAddress")}
          placeholder={t("pages.Login.placeholder.emailAddress")}
          register={register}
          error={errors.emailAddress}
          handleKeyPress={handleKeyPress}
        />
        <TextInput
          id="password"
          name="password"
          type="password"
          label={t("pages.Login.fieldset.password")}
          placeholder={t("pages.Login.placeholder.password")}
          register={register}
          error={errors.password}
          handleKeyPress={handleKeyPress}
        />
        <div className="remember-container">
          <FormCheckbox
            id="rememberMe"
            name="rememberMe"
            label={t("pages.Login.rememberMe")}
            control={control}
            error={errors.rememberMe}
          />
          <NavLink to={Pages.ForgotPassword}>
            {t("pages.Login.forgotPassword")}
          </NavLink>
        </div>
        <div className="btn">
          <Button text={t("pages.Login.button.login")} onClick={handleSubmit(onSubmit)} />
        </div>
      </UnauthorizedPageCard>
    </div>
  );
};

export default Login;

