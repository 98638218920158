import React from 'react'

const ActiveTab = ({tabs, activeTabIndex, data}) => {
  const Component = tabs[activeTabIndex]?.component;

  if(!Component) {
    return null;
  }

  return (
    <Component data={data}/>
  )
}

export default ActiveTab