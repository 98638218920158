export const validateExpirationDate = (value) => {
  if (!value) return false;
  const [monthStr, yearStr] = value.split("/");
  const month = Number(monthStr);
  const year = Number(yearStr);
  const currentDate = new Date();
  const currentYear = Number(
    currentDate.getFullYear().toString().slice(-2)
  );
  const currentMonth = currentDate.getMonth() + 1;

  if (isNaN(month) || isNaN(year)) {
    return false;
  } else if (
    year < currentYear ||
    (year === currentYear && month < currentMonth)
  ) {
    return false;
  } else if (month < 1 || month > 12) {
    return false;
  }
  return true;
}