import React, { useState } from "react";

import SvgIcon from "@client.core.components/SvgIcon";
import Input from "@client.core.components/Input";
import "./styles.scss";

interface IProps {
  id: string,
  value: string,
  className?: string,
  onChange: (value: string) => void,
  placeholder: string,
  label?: string,
  onKeyDown: () => void,
}

export const PasswordInput = ({
  id,
  value,
  className,
  onChange,
  placeholder,
  label,
  onKeyDown,
}: IProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="password-container">
      <Input
        className={className}
        type={showPassword ? "text" : "password"}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        optional={{
          id: id,
          autoComplete: "new-password",
        }}
      />
      <SvgIcon
        name={"eye-cross"}
        className={showPassword ? "" : "hide"}
        onClick={() => setShowPassword(!showPassword)}
      />
      <SvgIcon
        name={"eye"}
        className={showPassword ? "hide" : ""}
        onClick={() => setShowPassword(!showPassword)}
      />
    </div>
  );
};

export default PasswordInput;

