import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useStableParams from "@client.hooks/useStableParams";
import { getDeviceByIdAsync } from "../../../../reducers/devices";

import GoogleMap from "@client.components/GoogleMap_Device";
import DeviceMapFilter from "./DeviceMapFilter";
import { AppDispatch } from "@client.store";

const DeviceMap = ({data}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { id } = useStableParams();

  const [isFollow, setIsFollow] = useState(false);

  let followIntervalId: null | NodeJS.Timeout = null;

  useEffect(() => {
    if (!id) {
      return;
    }
  
    const intervalId = setInterval(() => {
      dispatch(getDeviceByIdAsync(id));
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const clearFollowInterval = () => {
      if (followIntervalId) {
        clearInterval(followIntervalId);
      }
    };
    if (isFollow) {
      clearFollowInterval();
      followIntervalId = setInterval(
        () => dispatch(getDeviceByIdAsync(id)),
        1000
      );
    } else {
      clearFollowInterval();
    }

    return () => {
      clearFollowInterval();
    };
  }, [isFollow]);

  return (
    <div className="device-map-container">

      <GoogleMap
        width="100%"
        height="76vh"
        options={{
          mapTypeControl: false,
        }}
        recenterMapTimestamp={Date.now()} // Trigger re-centering only when needed
        markers={[
          {
            lat: data.device.latitude || 0,
            lng: data.device.longitude || 0,
          },
        ]}
      >
        <DeviceMapFilter
          onFilterChange={(filter) => {
            setIsFollow(filter.isFollow);
          }}
        />
      </GoogleMap>
    </div>
  )
}

export default DeviceMap