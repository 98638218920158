import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "@client.hooks/useAuth";
import Toast from "@client.core.components/Toast";
import ErrorMessages from "@client.components/ErrorMessages";

const UnauthorizedLayout = () => {
  const { user, locationToNavigate } = useAuth();
  const outlet = useOutlet();

  if (user) {
    return <Navigate to={locationToNavigate} />;
  }

  return (
    <div>
      <Toast />
      <ErrorMessages />
      {outlet}
    </div>
  );
};

export default UnauthorizedLayout;