import Title from "@client.components/Title";

import React from "react";

import ReportsOverview from "./ReportsOverview";
import DemographicAnalysis from "./DemographicAnalysis";
import TimeAnalysis from "./TimeAnalysis";
import "./styles.scss";

const ReportsPage = () => {
  return (
    <div className="reports-page">
      <Title text="components.NavigationBar.reports" />
      <ReportsOverview />
      <div className="analasysis-container">
        <DemographicAnalysis />
        <TimeAnalysis />
      </div>
    </div>
  );
};

export default ReportsPage;