import React, { useEffect } from "react";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import Input from "@client.core.components/Input";
import "./styles.scss";
import { FieldValues, Path, PathValue, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import ServiceProvider from "@client.services/provider";
import { IAddress } from "@client.types/address";

interface IProps<T extends FieldValues> {
  addressStringName?: Path<T>,
  addressObjectName?: Path<T>,
  coordinatesName?: Path<T>,
  value: string | IAddress,
  setValue: UseFormSetValue<T>,
  trigger?: UseFormTrigger<T>,
  disabled?: boolean,
  error?: string,
  label: string,
  placeType?: string,
}

const FormPlacesInput = <T extends FieldValues>({ 
  addressStringName,
  addressObjectName,
  coordinatesName,
  value,
  setValue,
  trigger,
  disabled,
  error,
  label,
  placeType,
}: IProps<T>) => {
  const {
    setValue: setAutocompleteValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: placeType ? [placeType] : []
    }
  });

  const handleSelect = async (address) => {
    if (addressStringName) {
      setValue(addressStringName, address);
    }
    setAutocompleteValue(address, false);
    clearSuggestions();

    if (addressObjectName) {
      const results = await getGeocode({ address });
      const place = results[0];
      const addressComponents = place.address_components;

      const getAddressComponent = (type: string) => {
        const component = addressComponents.find((component) =>
          component.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      const addressLineOne = `${getAddressComponent("street_number")} ${getAddressComponent("route")}`;
      const city = getAddressComponent("locality");
      const country = getAddressComponent("country");
      const postalCode = getAddressComponent("postal_code");
    
      setValue(addressObjectName, {
        addressLineOne,
        city,
        country,
        postalCode,
      } as unknown as PathValue<T, Path<T>>);

      if (trigger) {
        await trigger(addressObjectName);
      }
    }

    if (coordinatesName) {
      try {
        const data = await ServiceProvider.Zone.getPolygon(address.description)
        setValue(coordinatesName, data as PathValue<T, Path<T>>);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = () => clearSuggestions();
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const getInputValue = () => {
    if (addressStringName) {
      return value as string;
    }
    if (addressObjectName) {
      return (value as IAddress).addressLineOne;
    }
    return '';
  }

  const handleInputChange = (v) => {
    setAutocompleteValue(v);
    if (addressStringName) {
      setValue(addressStringName, v);
    }
    if (addressObjectName) {
      setValue(addressObjectName, {
        ...(value as PathValue<T, Path<T>>),
        addressLineOne: v,
      });
    }
  }

  return (
    <div className="places-autocomplete">
      <Input
        value={getInputValue()}
        label={label}
        onChange={handleInputChange}
        disabled={disabled}
      />
      {error && <span className="error-message">{error}</span>}
      {data.length > 0 && (
        <ul className="suggestions-list">
          {data.map((suggestion) => (
            <li
              key={suggestion.description}
              onClick={() => handleSelect(suggestion.description)}
              className="suggestion-item"
            >
              {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormPlacesInput;