import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { UseFormSetValue } from "react-hook-form";

import LocationTypes from "@client.enums/locationTypes";
import { IMapLocation, IZone } from "@client.types/zone";

import Tabs from "@client.components/Tabs";

import ZonesTab from "./tabs/Zones";
import RadiusTab from "./tabs/Radius";
import CustomTab from "./tabs/Custom";
import { IZonePolygon, IZonePolygonData } from "@client.types/zonePolygon";

interface IProps {
  mapLocations: IZonePolygon[];
  setMapLocations: Dispatch<SetStateAction<IZonePolygon[]>>;
  drawnCustomPolygon: IZonePolygonData[];
  setDrawnCustomPolygon: Dispatch<SetStateAction<IZonePolygonData[]>>;
  selectedTab: LocationTypes;
  setSelectedTab: Dispatch<SetStateAction<LocationTypes>>;
}

const LocationsInformation = ({
  mapLocations,
  setMapLocations,
  drawnCustomPolygon,
  setDrawnCustomPolygon,
  selectedTab,
  setSelectedTab,
}: IProps) => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: LocationTypes.Zones,
      name: t("Pages.CreateAdvertisement.locationType.zones"),
    },
    {
      id: LocationTypes.Radius,
      name: "Radius",
    },
    {
      id: LocationTypes.Custom,
      name: t("Pages.CreateAdvertisement.locationType.custom"),
    },
  ];

  const renderTabContent = () => {
    switch (selectedTab) {
    case LocationTypes.Zones:
      return (
        <ZonesTab
          setMapLocations={setMapLocations}
        />
      );
    case LocationTypes.Radius:
      return (
        <RadiusTab
          mapLocations={mapLocations}
          setMapLocations={setMapLocations}
        />
      );
    case LocationTypes.Custom:
      return <CustomTab 
        drawnPolygon={drawnCustomPolygon}
        setDrawnPolygon={setDrawnCustomPolygon}
        setMapLocations={setMapLocations}
      />;
    default:
      return null;
    }
  };

  return (
    <div className="locations-information-container">
      <Tabs
        tabs={tabs}
        value={selectedTab}
        onSelect={setSelectedTab}
      />
      {renderTabContent()}
    </div>
  );
};

export default LocationsInformation;
