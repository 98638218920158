import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeDeviceInfo } from "@client.reducers/devices";

import moment from "moment";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import { useTranslation } from "react-i18next";
import TelemetryDataTable from "./TelemetryDataTable/TelemetryDataTable";
import RenderXYZ from "@client.components/RenderXYZ/RenderXYZ";
import IconRenderer  from "@client.components/FA/IconRenderer";

const DeviceOverview = () => {
  const [isActive, setIsActive] = useState(true);

  const { t } = useTranslation();

  const overview = useSelector(makeDeviceInfo);

  const copyToClipboard = (txt) => {
    navigator.clipboard.writeText(txt);
  };

  const lastTelemetry = overview?.device?.lastTelemetry || {};
  const sensors = lastTelemetry?.sensors || {};
  const network =lastTelemetry?.network || {};

  return (
    <div className="device-data-card">
      <div className="device-data-card-row">
        <div className="device-data-column">
          {/* 
          General Parameters are displayed in a table format.
          */}
          <div className="caption">General Parameters</div>
          {[
            {
              title: "Machine ID",
              value: overview?.device?.machineId || "N/A",
            },
            {
              title: "Device Name",
              value: overview?.device?.deviceName || "N/A",
            },
            {
              title: "Timezone",
              value: overview?.device?.timezone || "N/A",
            },
            // { title: "Country", value: overview?.device?.country || "N/A" },
            {
              title: "Hardware Version",
              value: overview?.device?.hardwareVersion || "N/A",
            },
            {
              title: "Software Version",
              value: overview?.device?.softwareVersion || "N/A",
            },
            {
              title: "Assigned To",
              // value: overview?.device?.assignedTo?.fullName || "N/A",
              value: overview?.device?.assignedAccountId || "N/A",
            },
            {
              title: "VPN IP",
              // value: overview?.device?.vpn?.ipAddress || "N/A",
              value: overview?.device?.vpnId || "N/A",
            },
          ].map((v) => (
            <React.Fragment key={v.title}>
              <div className="device-cell">{v.title}:</div>
              <div
                className="device-cell copy"
                title={v.value}
                onClick={() => copyToClipboard(v.value)}
              >
                {v.value}
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* 
        Telemetry data is displayed in a table format.
        */}
        <div className="device-data-column">
          <div className="caption">
            <div onClick={() => setIsActive(!isActive)}>Telemetry</div>

            <IconRenderer iconName="FaCircleInfo"
              size={17}
              onClick={() => {
                ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                  show: true,
                  className: "telemetry-history-modal",
                  title: t("pages.Device.telemetry.history"),
                  component: <TelemetryDataTable />,
                });
              }}
            />
          </div>
          {[
            {
              title: "Received At",
              value: moment(overview?.device?.updatedAt)
                .utc() 
                .local() 
                .format("YYYY-MM-DD HH:mm:ss") || "N/A",
            },
            { title: "Uptime", value: lastTelemetry?.uptime || "N/A" },
            { title: "Light", value: sensors?.light || "N/A" },
            { title: "Battery A", value: `${sensors?.batteryA?.percentage || "N/A"}% (${sensors?.batteryA?.temperature || "N/A"}°C)` },
            { title: "Battery B", value: `${sensors?.batteryB?.percentage || "N/A"}% (${sensors?.batteryB?.temperature || "N/A"}°C)` },
            { title: "CPU Temperature", value: `${sensors?.cpuTemperature || "N/A"}°C` },
            {
              title: "Gyroscope",
              valueToCopy: `X: ${sensors.gyroscope?.xValue || "0"}, Y: ${sensors.gyroscope?.yValue || "0"}, Z: ${sensors.gyroscope?.zValue || "0"}`,
              value: (
                <RenderXYZ
                  x={sensors.gyroscope?.xValue || "0"}
                  y={sensors.gyroscope?.yValue || "0"}
                  z={sensors.gyroscope?.zValue || "0"}
                />
              ),
            },
            {
              title: "Accelerometer",
              valueToCopy: `X: ${sensors.accelerometer?.xValue || "0"}, Y: ${sensors.accelerometer?.yValue || "0"}, Z: ${sensors.accelerometer?.zValue || "0"}`,
              value: (
                <RenderXYZ
                  x={sensors.accelerometer?.xValue || "0"}
                  y={sensors.accelerometer?.yValue || "0"}
                  z={sensors.accelerometer?.zValue || "0"}
                />
              ),
            },
            { title: "Fan A Speed", value:`${sensors?.fanA?.speed+" RP/M" || "OFF"}` },
            { title: "Fan B Speed", value:`${sensors?.fanB?.speed+" RP/M" || "OFF"}` },
            {
              title: "GNSS",
              valueToCopy: `X: ${sensors.accelerometer?.xValue || "0"}, Y: ${sensors.accelerometer?.yValue || "0"}, Z: ${sensors.accelerometer?.zValue || "0"}`,
              value: (
                <RenderXYZ
                  paramX="Lat"
                  paramY="Lng"
                  paramZ="Satellites"
                  x={sensors?.gnss?.latitude.toFixed(2) || "N/A"}
                  y={sensors?.gnss?.longitude.toFixed(2) || "N/A"}
                  z={sensors?.gnss?.satellites || "N/A"}
                />
              ),
            },
            { title: "Screen Brightness", value: `${sensors?.screen?.brightness+" %" || 0}` },
            { title: "Screen Temperature", value: `${sensors?.screen?.temperature || "N/A"}°C` },
            { title: "Network Connection Time", value: network?.connectionTime || "N/A" },
            { title: "Network Local IP", value: network?.localIp || "N/A" },
            { title: "Network Remote IP", value: network?.remoteIp || "N/A" },
            { title: "Intrusion Detected", value: lastTelemetry?.intrusion ? "Yes" : "No" },
            { title: "Service Name", value: lastTelemetry?.services?.serviceName || "N/A" },
            { 
              title: "Advertisement Id", 
              value: lastTelemetry?.campaignId ? lastTelemetry.campaignId.split('/').pop() : "N/A"
            },
            
          ].map((v) => (
            <React.Fragment key={v.title}>
              <div className="device-cell">{v.title}:</div>
              <div
                className="device-cell copy"
                title={v.value}
                onClick={() => copyToClipboard(v.valueToCopy || v.value)}
              >
                {v.value}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* wait For Bernhard */}
      {/* <DeviceCaptures /> */}
    </div>
  );
};

export default DeviceOverview;
