import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import TimelineItem from "./TimelineItem";
import MediaFile from "@client.components/MediaFile";

const Viewer = ({ data, onSelect }) => {
  const [selectedFrameIds, setSelectedFrameIds] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Filter items to show
  useEffect(() => {
    const newItems = data.map((d) => ({
      ...d,
      // afterMediaUrl: d.afterMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4",
      afterMediaUrl: d.campaignMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4",
    }));
    setItemsToShow(newItems);
  }, [data]);

  // Update selected items
  useEffect(() => {
    const selectedItems = itemsToShow.filter((d) =>
      selectedFrameIds.includes(d.Id)
    );
    onSelect(selectedItems);
  }, [selectedFrameIds, itemsToShow, onSelect]);

  const getMediaFileSource = () => {
    const file = itemsToShow.find(
      (d) => selectedFrameIds[currentVideoIndex] === d.Id
    );
    return file?.campaignMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4";
    // return file?.afterMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4";
    // return file?.afterMediaUrl || "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4";
    // return "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4"|| "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4";
    // return (
    //   "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4" ||
    //   "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4"
    // );
  };

  const handleItemClick = (id) => {
    setSelectedFrameIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((frameId) => frameId !== id)
        : [...prevSelected, id]
    );
    setCurrentVideoIndex(0);
  };

  const handleVideoEnded = () => {
    if (currentVideoIndex < selectedFrameIds.length - 1) {
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentVideoIndex(0);
    }
  };

  return (
    <div className="data-analyzer-viewer">
      <div className="data-analyzer-viewer-block">
        {data.length === 0 && (
          <NoDataPlaceholder description="pages.DataAnalyzer.capturesNoData" />
        )}
        {data.length > 0 && selectedFrameIds.length === 0 && (
          <NoDataPlaceholder description="pages.DataAnalyzer.selectFrame" />
        )}
        {selectedFrameIds.length > 0 && (
          <div className="video-container">
            <MediaFile
              src={getMediaFileSource()}
              // src={
              // "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4"
              // }
              controls
              onEnded={handleVideoEnded}
            />
          </div>
        )}
      </div>
      {itemsToShow.length > 0 && (
        <div className="timeline-container">
          <div className="timeline-slider">
            {itemsToShow.map((item) => (
              <TimelineItem
                item={item}
                key={item.Id}
                className={
                  selectedFrameIds.includes(item.Id) ? "selected" : null
                }
                onClick={
                  item.status === 5 // Restrict clicks to "Processed" items only
                    ? () => handleItemClick(item.Id)
                    : null
                }
                disabled={item.status !== 5}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Viewer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      afterMediaUrl: PropTypes.string,
      status: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Viewer;
