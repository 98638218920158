import React, { useRef, useEffect, useState } from 'react';
import { TOOLTIP_ID } from '@client.constants';

interface IProps {
  value: string,
}

const EllipsisCellRenderer = ({ value }: IProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      setIsOverflowed(el.scrollWidth > el.clientWidth);
    }
  }, [value]);

  const linesValue = value.replace(/\\n/g, '\n');

  return (
    <>
      <div
        ref={textRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        data-tooltip-id={isOverflowed ? TOOLTIP_ID : ''}
        data-tooltip-content={isOverflowed ? linesValue : ""}
      >
        {value}
      </div>
    </>
  );
};

export default EllipsisCellRenderer;