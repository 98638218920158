import React, { useEffect, useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import LoginPage from "@client.pages/auth/Login";
import ForgotPasswordPage from "@client.pages/auth/ForgotPassword";
import LogoutPage from "@client.pages/auth/Logout";
import DevicesPage from "@client.pages/Devices";
import AdvertisementsPage from "@client.pages/Advertisements";
import ZonesPage from "@client.pages/Zones";
import CreateAdvertisementPage from "@client.pages/CreateAdvertisement";
import DashboardPage from "@client.pages/Dashboard";
import AuthorizedLayout from "@client.components/layout/AuthorizedLayout";
import UnauthorizedLayout from "@client.components/layout/UnauthorizedLayout";
import RequireAuth from "@client.components/RequireAuth";
import Pages from "@client.enums/pages";
import AdvertisementPage from "@client.pages/Advertisement";
import FlickrDemoPage from "@client.pages/FlickrDemo";
import Loader from "@client.core.components/Loader";
import DevicePage from "@client.pages/Device";
import UsersPage from "@client.pages/Users";
import ReportsPage from "@client.pages/ReportsPage";
import InviteUser from "@client.pages/auth/InviteUser";
import RegisterUser from "@client.pages/auth/RegisterUser";
import AdvertisementApprovals from "@client.pages/AdvertisementApprovals/AdvertisementApprovals";
import DataAnalyzer from "@client.pages/DataAnalyzer/DataAnalyzer";
import DataAnalyzerBlob from "@client.pages/DataAnalyzer_blob/DataAnalyzer";
import DataAnalyzerDemo from "@client.pages/DataAnalyzerDemo/DataAnalyzer";
import AccountsPage from "@client.pages/Accounts";
import UserLogs from "@client.pages/UserLogs";
import SystemLogs from "@client.pages/SystemLogs";
import InvoicePage from "@client.pages/Invoice";
import ZoneDefaultAdvertisementsPage from "@client.pages/ZoneDefaultAdvertisements";
import Verify from "@client.pages/auth/Verify";
import DeviceRegistrationsPage from "@client.pages/DeviceRegistrations";

import "./i18n";
import ResetPassword from "@client.pages/auth/ResetPassword";

const AppLoader = () => (
  <div className="app page-loading">
    <Loader loading={true} />
  </div>
);

const AppRoutes = () => {
  const { i18n } = useTranslation();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    const defaultLanguge = "en";
    const existingLanguges = [defaultLanguge];
    let languageToUse = existingLanguges.find((l) => l === i18n.language);
    if (!languageToUse) {
      languageToUse = existingLanguges.find((l) => i18n.language.startsWith(l));
    }

    const language = languageToUse || defaultLanguge;
    import(`@client.i18n/${language}.json`)
      .then((translations) => {
        i18n.changeLanguage(language);
        i18n.addResourceBundle(
          language,
          "translation",
          translations.default,
          true,
          true
        );
        setTranslationsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading translations:", error);
      });
  }, [i18n]);
  // if this is needed /:advertiserId

  if (!translationsLoaded) {
    return <AppLoader />;
  }

  return (
    <div className="app">
      <Suspense fallback={<AppLoader />}>
        <Routes>
          <Route element={<UnauthorizedLayout />}>
            <Route path={Pages.Login} element={<LoginPage />} />
            <Route path={Pages.InviteUser} element={<InviteUser />} />
            <Route path={Pages.RegisterUser} element={<RegisterUser />} />
            <Route path={`${Pages.Verify}`} element={<Verify />} />
            <Route path={`${Pages.ResetPassword}`} element={<ResetPassword />} />
            <Route
              path={Pages.ForgotPassword}
              element={<ForgotPasswordPage />}
            />
            <Route path={Pages.FlickrDemo} element={<FlickrDemoPage />} />
            <Route path="*" element={<LoginPage />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route element={<AuthorizedLayout />}>
              <Route path={Pages.Dashboard} element={<DashboardPage />} />
              <Route path={Pages.Devices} element={<DevicesPage />} />
              <Route path={Pages.DeviceRegistrations} element={<DeviceRegistrationsPage />} />
              <Route path={`${Pages.Devices}/:id`} element={<DevicePage />} />
              <Route path={Pages.Zones} element={<ZonesPage />} />
              <Route path={`${Pages.Zones}/:zoneId${Pages.DefaultAdvertisements}`} element={<ZoneDefaultAdvertisementsPage />} />
              <Route
                path={Pages.AdvertisementApprovals}
                element={<AdvertisementApprovals />}
              />
              <Route path={Pages.Advertisements} element={<AdvertisementsPage />} />
              <Route
                path={`${Pages.Advertisement}/:id`}
                element={<AdvertisementPage />}
              />
              <Route
                path={Pages.CreateAdvertisement}
                element={<CreateAdvertisementPage />}
              />
              <Route
                path={`${Pages.CreateAdvertisement}/:id`}
                element={<CreateAdvertisementPage />}
              />
              <Route path={Pages.Accounts} element={<AccountsPage />} />
              <Route path={`${Pages.Accounts}/:id/:tab`} element={<AccountsPage />} />
              <Route path={Pages.Users} element={<UsersPage />} />
              <Route path={`${Pages.Users}/:id/:tab`} element={<UsersPage />} />
              <Route path={`${Pages.Invoices}/:id`} element={<InvoicePage />} />
              <Route path={Pages.UserLogs} element={<UserLogs />} />
              <Route path={Pages.SystemLogs} element={<SystemLogs />} />

              <Route path={Pages.Reports} element={<ReportsPage />} />
              <Route path={Pages.Logout} element={<LogoutPage />} />
              <Route path={Pages.DataAnalyzer} element={<DataAnalyzer />} />
              <Route
                path={Pages.DataAnalyzerBlob}
                element={<DataAnalyzerBlob />}
              />
              <Route
                path={Pages.DataAnalyzerDemo}
                element={<DataAnalyzerDemo />}
              />

              <Route path={"*"} element={<DashboardPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppRoutes;
