import React from "react";
import WelcomeCard from "../WelcomeCard/WelcomeCard.js";
import { useTranslation } from "react-i18next";

const AuthLeftPanel = () => {
  const { t } = useTranslation();

  return (
    <WelcomeCard>
      <div className="welcome-title">
        <span>{t("Adport")}</span>
        <div className="welcome-description">
          {t("Adport.auth.description")}
        </div>
      </div>
    </WelcomeCard>
  );
};

export default AuthLeftPanel;
